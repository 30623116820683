import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, of, throwError } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators'
import { AuthenticationService } from '../../services/auth/authentication.service';
import { ToastyService, ToastyConfig, ToastOptions } from 'ng2-toasty';
import { SharedService } from '../../services/shared.service';
import { EditWorkOrdersModel, WorkOrdersModel } from '../work-orders.models';
import { WorkOrdersService } from '../../services/work-orders/work-orders.service';
import { ReportsService } from '../../services/reports/reports.service';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-work-order-toPdf',
  templateUrl: './work-order-toPdf.component.html',
  styleUrls: ['./work-order-toPdf.component.scss']
})

export class WorkOrdertoPDFComponent implements OnInit, OnDestroy {
  public sources = [];
  public form: FormGroup;
  public submitted: boolean = false;
  public isLoading = false;
  public rol: string;
  public userId: string;
  public report: any;
  public workOrdersForm!: FormGroup;
  public types = [];
  public workOrder: any;
  public statusValues = [];
  public isDownload = false;
  public zoom: number;

  public showMaptoPrint: boolean = false;
  public imagetoPng: string;
  public isEvent = false;

  private subs: Array<Subscription> = [];

  constructor(
    private fb: FormBuilder,
    private authService: AuthenticationService,
    private workOrdersService: WorkOrdersService,
    private activatedRoute: ActivatedRoute,
    private reportService: ReportsService
  ) {
    this.rol = this.authService.currentUserValue.roles;
    this.userId = this.authService.currentUserValue.id;
  }

  ngOnInit(): void {

    this.subs.push(
      this.activatedRoute.url.subscribe(url => {
        // console.log(url)
        if (url[0].path == "download-report") {
          this.isEvent = false;
          this.activatedRoute.paramMap.pipe(
            map(params => params.get('id')),
            switchMap(id => this.reportService.getReport(id)),
            catchError(error => {
              return throwError(error);
            })
          )
            .subscribe(workOrder => {
              this.workOrder = workOrder;
              //console.log(workOrder)
            })
        }else if(url[0].path == "download-reportEvent"){
          this.isEvent = true;
          this.activatedRoute.paramMap.pipe(
            map(params => params.get('id')),
            switchMap(id => this.reportService.getReport(id)),
            catchError(error => {
              return throwError(error);
            })
          )
            .subscribe(workOrder => {
              this.workOrder = workOrder;
              //console.log(workOrder)
            })
        }else {
          this.isEvent = false;
          this.activatedRoute.paramMap.pipe(
            map(params => params.get('id')),
            switchMap(id => this.workOrdersService.getWorkOrder(id)),
            catchError(error => {
              return throwError(error);
            })
          )
            .subscribe(workOrder => {
              this.workOrder = workOrder;

            })
        }
        this.zoom = 16;
      })
    );

    this.reportService
      .getValuestoCatalog('ReportStatus')
      .subscribe(data => {
        this.statusValues = data;
      });

    this.workOrdersForm = this.fb.group({
      Status: ['', Validators.required],
      DescriptionResponse: ['', Validators.required]
    });
  }

  ngOnDestroy(): void {
    let sub: Subscription;
    while (sub = this.subs.shift()) {
      sub.unsubscribe();
    }
  }

  public get formState() {
    return this.workOrdersForm.controls;
  }

  downloadPDF() {
    this.isDownload = true;
    const DATA = document.getElementById('htmlData');
    this.isDownload = true;

    const options = {
      scale: 3
    };

    html2canvas(DATA, options).then((canvas) => {

      const img = canvas.toDataURL("image/png");
      const doc = new jsPDF({
        orientation:'portrait',
        format: 'a4',
        compress: true
      });

      // Add image Canvas to PDF
      const bufferX = 0;
      const bufferY = 0;
      const imgProps = (doc as any).getImageProperties(img);
      const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      doc.addImage(img, 'PNG', bufferX, bufferY, pdfWidth, pdfHeight);
      return doc;
    }).then((docResult) => {
      //docResult.save(`${new Date().toISOString()}_tutorial.pdf`);
      docResult.save(`reporte.pdf`);
      setTimeout(() => {
        this.isDownload = false;
      }, 2000);
    });
  }


}



