<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="card">
        <div class="card-body">
            <div class="btn-popup pull-right">
                <button class="btn btn-secondary" (click)="exportExcel()">Exportar a Excel</button>
            </div>
            <div id="batchDelete" class="">
                <div class="table-responsive">
                    <table datatable
                    [dtOptions]="dtOptions"
                    [dtTrigger]="dtTrigger"
                    class=" table table-lg table-hover row-border hover">
                    <thead>
                        <tr>
                        <th>Nº cotización</th>
                        <th>Nombre</th>
                        <th>Ciudad</th>
                        <th class="w-status">Status</th>
                        <th>Fecha de solicitud</th>
                        <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let quotation of quotations">
                        <td>{{quotation.id}}</td>
                        <td>{{quotation.FirstName}} {{quotation.LastName}}</td>
                        <td>{{quotation.City}}</td>
                        <td><span [ngClass]="{'received': quotation.RequestStatus == 'Recibida', 'authorized': quotation.RequestStatus == 'Atendida'}">{{quotation.RequestStatus}}</span></td>
                        <td>{{quotation.created_at | date}}</td>
                        <td class="actions-cell">
                            <span>
                            <a [routerLink]="['show', quotation.id]"
                                title="Ver cotización">
                                <i class="fa fa-eye"></i>
                            </a>
                            </span>
                            <span *ngIf="quotation.RequestStatus !== 'Atendida'" (click)="setModalContent(content, quotation.id)"
                            title="Actualizar cotización" class="cursor">
                                <i class="fa fa-check-circle"></i>
                            </span>
                        </td>
                        </tr>
                    </tbody>
                    </table>
                </div>

                <div class="table-responsive noshow">
                    <table datatable
                    [dtOptions]="dtOptions"
                    [dtTrigger]="dtTrigger"
                    class=" table table-lg table-hover row-border hover"
                    id="excel-quotations">
                    <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Apellido</th>
                            <th>Nombre comercial</th>
                            <th>Correo electrónico</th>
                            <th>Ciudad</th>
                            <th>Estado</th>
                            <th>Código Postal</th>
                            <th>Departamento</th>
                            <th>Meses Financiamiento</th>
                            <th>Método de pago</th>
                            <th>Mensaje</th>
                            <!-- <th>Producto</th>
                            <th>Marca</th>
                            <th>Descripción</th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let quotation of quotations">
                            <td>{{quotation.FirstName}}</td>
                            <td>{{quotation.LastName}}</td>
                            <td>{{quotation.BusinessName}}</td>
                            <td>{{quotation.Email}}</td>
                            <td>{{quotation.City}}</td>
                            <td>{{quotation.State}}</td>
                            <td>{{quotation.ZipCode}}</td>
                            <td>{{quotation.Department}}</td>
                            <td>{{quotation.FinantialMonthQty}} meses</td>
                            <td>{{quotation.PaymentMethod}}</td>
                            <td>{{quotation.Message}}</td>
                            <!-- <td>{{quotation.Product.Title}}</td>
                            <td>{{quotation.Product.Brand}}</td>
                            <td>{{quotation.Product.Description}}</td> -->
                        </tr>
                    </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->

<ng-template #content
let-modal>
    <div class="modal-header">
    <h4 class="modal-title text-center width-100"
        id="modal-title">Agregar mensaje</h4>
    </div>
    <div class="modal-body text-center">
    <ng-container *ngIf="!messageIsSuccess">
        <p class="no-mrg">Se agregará el mensaje a la cotización y se actualizará el status a <strong>ATENDIDA</strong>.</p>
    </ng-container>

    <ng-container *ngIf="messageIsSuccess">
        <p>Mensaje agregado correctamente</p>
    </ng-container>
    </div>
    <ng-container *ngIf="!messageIsSuccess">
    <div class="modal-footer">
        <form class="needs-validation right" [formGroup]="form"
        (ngSubmit)="onSubmit()">
        <div class="form-group">
            <textarea type="text"
            class="form-control"
            placeholder="Mensaje"
            rows="5"
            [formControl]="form.controls['MessageResponse']"
            [ngClass]="{'error': submitted && formState?.MessageResponse?.errors?.required}">
            </textarea>
        </div>
        <a type="button"
        class="btn btn-outline-light"
        (click)="modal.close()">Cancelar</a>
        <button type="submit"
        class="btn btn-inverse btn-success">Actualizar
        <i class="fa fa-spinner fa-spin"
        [hidden]="!isLoading"></i>
        </button>
        </form>
    </div>
    </ng-container>
</ng-template>