<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-xl-4" *ngFor="let survey of surveys">
            <div class="card">
                <div class="card-body">
                    <div class="media static-top-widget row">
                        <div class="icons-widgets col-4">
                            <div class="align-self-center text-center">
                                <i class="fa fa-file-text" aria-hidden="true"></i>
                            </div>
                        </div>
                        <div class="media-body col-8"><span class="m-0">{{survey.Title}}</span>
                        </div>
                    </div>
                    <div class="row mt-30">
                        <div class="col-xs-12 col-sm-6 center">
                            <button [routerLink]="['show', survey.id]" class="btn bt-action" type="button" data-original-title="">Ver estudio</button>
                        </div>
                        <div class="col-xs-12 col-sm-6 center">
                            <button [routerLink]="['results', survey.id]" class="btn bt-action" type="button" data-original-title="">Resultados</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->