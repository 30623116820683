import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CreateWorkOrderComponent } from '../work-orders/create-work-order/create-work-order.component';
import { CreateReportComponent } from './create-report/create-report.component';
import { ExportReportAreaComponent } from './export-report-area/export-report-area.component';
import { ExportReportComponent } from './export-report/export-report.component';
import { ReportDetailsComponent } from './report-detail/report-detail.component';
import { ReportsAreaComponent } from './reports-area/reports-area.component';
import { ReportsByAreaComponent } from './reports-by-area/reports-by-area.component';
import { ReportsByUserComponent } from './reports-by-user/reports-by-user.component';
import { ReportsGeneralComponent } from './reports-general/reports-general.component';
import { ReportsNotAssignedComponent } from './reports-not-assigned/reports-not-assigned.component';
import { ReportsSourceComponent } from './reports-source/reports-source.component';
import { ReportsSuburbComponent } from './reports-suburb/reports-suburb.component';
import { ReportsUsersComponent } from './reports-users/reports-users.component';
import { ReportsComponent } from './reports.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: ReportsComponent,
        data: {
          title: "Reportes",
          breadcrumb: "Reportes"
        }
      },
      {
        path: 'edit/:id',
        component: CreateReportComponent,
        data: {
          title: "Editar Reporte",
          breadcrumb: "Editar Reporte"
        }
      },
      {
        path: 'not-assigned',
        component: ReportsNotAssignedComponent,
        data: {
          title: "Reportes sin turnar",
          breadcrumb: "Reportes sin turnar"
        }
      },
      {
        path: 'not-assigned/show/:id',
        component: ReportDetailsComponent,
        data: {
          title: "Detalle Reporte",
          breadcrumb: "Detalle Reporte"
        }
      },
      {
        path: 'not-assigned/edit/:id',
        component: CreateReportComponent,
        data: {
          title: "Editar Reporte",
          breadcrumb: "Editar Reporte"
        }
      },
      {
        path: 'create-report',
        component: CreateReportComponent,
        data: {
          title: "Crear Reporte",
          breadcrumb: "Crear Reporte"
        }
      },
      {
        path: 'show/:id',
        component: ReportDetailsComponent,
        data: {
          title: "Detalle Reporte",
          breadcrumb: "Detalle Reporte"
        }
      },
      {
        path: 'general',
        component: ReportsGeneralComponent,
        data: {
          title: "Reporte General",
          breadcrumb: "Reporte General"
        }
      },
      {
        path: 'general/export',
        component: ExportReportComponent,
        data: {
          title: "Exportar Reporte General",
          breadcrumb: "Exportar Reporte General"
        }
      },
      {
        path: 'fuente',
        component: ReportsSourceComponent,
        data: {
          title: "Reportes por Fuente",
          breadcrumb: "Reportes por Fuente"
        }
      },
      {
        path: 'area',
        component: ReportsAreaComponent,
        data: {
          title: "Reportes por Área",
          breadcrumb: "Reportes por Área"
        }
      },
      {
        path: 'area/export',
        component: ExportReportAreaComponent,
        data: {
          title: "Exportar Reporte Área",
          breadcrumb: "Exportar Reporte Área"
        }
      },
      {
        path: 'area/show/:id',
        component: ReportDetailsComponent,
        data: {
          title: "Detalle Reporte",
          breadcrumb: "Detalle Reporte"
        }
      },
      {
        path: 'ciudadano',
        component: ReportsUsersComponent,
        data: {
          title: "Reportes por Ciudadano",
          breadcrumb: "Reportes por Ciudadano"
        }
      },
      {
        path: 'ciudadano/show/:id',
        component: ReportDetailsComponent,
        data: {
          title: "Detalle Reporte",
          breadcrumb: "Detalle Reporte"
        }
      },
      {
        path: 'colonia',
        component: ReportsSuburbComponent,
        data: {
          title: "Reportes por Colonia",
          breadcrumb: "Reportes por Colonia"
        }
      },
      {
        path: 'colonia/show/:id',
        component: ReportDetailsComponent,
        data: {
          title: "Detalle Reporte",
          breadcrumb: "Detalle Reporte"
        }
      },
      {
        path: 'my-user-reports',
        component: ReportsByUserComponent,
        data: {
          title: "Mis Reportes",
          breadcrumb: "Mis Reportes"
        }
      },
      {
        path: 'my-user-reports/create-report',
        component: CreateReportComponent,
        data: {
          title: "Crear Reporte",
          breadcrumb: "Crear Reporte"
        }
      },
      {
        path: 'my-user-reports/show/:id',
        component: ReportDetailsComponent,
        data: {
          title: "Detalle Reporte",
          breadcrumb: "Detalle Reporte"
        }
      },
      {
        path: 'my-area-reports',
        component: ReportsByAreaComponent,
        data: {
          title: "Reportes",
          breadcrumb: "Reportes"
        }
      },
      {
        path: 'my-area-reports/show/:id',
        component: ReportDetailsComponent,
        data: {
          title: "Detalle Reporte",
          breadcrumb: "Detalle Reporte"
        }
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportsRoutingModule { }
