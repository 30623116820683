<div class="page-wrapper">
    <div class="authentication-box">
        <div class="container">
            <div class="row">
                <div class="col-md-3">  </div>
                <div class="col-md-6 p-0">
                    <div class="card tab2-card">
                        <div class="card-body">
                            <div class="tcenter">
                                <img src="../../../../assets/images/logo-next.png" style="width: 60%;" alt="">
                            </div>
                            <div class="mb-4">
                                <small class="">Ingresa el correo electrónico que usaste para crear tu cuenta, te enviaremos un enlace a tu bandeja de entrada para que configures tu nueva contraseña.</small>
                            </div>
                            <ngb-tabset class="tab-coupon mb-0">
                                <ngb-tab>
                                    <ng-template ngbTabContent>
                                        <div class="tab-pane fade active show" id="account" role="tabpanel"
                                            aria-labelledby="account-tab">
                                            <form [formGroup]="forgotPasswordForm" 
                                                (ngSubmit)="onSubmit()"
                                                class="form-horizontal auth-form" novalidate="">
                                                <div class="form-group">
                                                    <input required="" formControlName="email" type="email"
                                                        class="form-control" placeholder="Correo electrónico"
                                                        [ngClass]="{'error': submittedEmail && formState?.email?.errors?.required}">
                                                        <label class="errorRed"
                                                            *ngIf="submittedEmail && formState?.email?.errors?.pattern">
                                                            Ingresa un correo válido.
                                                        </label>
                                                </div>
                                                <button class="btn btn-primary"
                                                [disabled]="isLoading">
                                                {{ isLoading ? 'Enviando correo electrónico' : 'Recuperar contraseña'}}
                                                <i class="fa fa-spinner fa-spin"
                                                [hidden]="!isLoading"></i>
                                                </button>
                                            </form>
                                        </div>
                                    </ng-template>
                                </ngb-tab>
                            </ngb-tabset>
                        </div>
                    </div>
                    <!-- <div class="row mb-2">
                        <small class="pad-left">¿No tienes cuenta? <a [routerLink]="['/registro']">Ingresa aquí</a></small>
                    </div> -->
                    <!-- <div class="row">
                        <small class="pad-left">¿Quieres hacer un reporte como invitado? <a [routerLink]="['/reporte']">Hazlo aquí</a></small>
                    </div> -->
                </div>
                <div class="col-md-3">  </div>
            </div>
            <!-- <a [routerLink]="'/dashboard/default'" class="btn btn-primary back-btn">
                <app-feather-icons [icon]="'arrow-left'"></app-feather-icons>
                back
            </a> -->
        </div>
    </div>
</div>

<ng2-toasty></ng2-toasty>