<div class="page-wrapper">
    <div class="authentication-box paddd">
        <div class="container">
            <div class="col-md-12 p-0">
                <div class="card tab2-card">
                    <div class="card-body">
                        <ngb-tabset class="tab-coupon mb-0">
                            <ngb-tab>
                                <ng-template ngbTabContent>
                                    <div class="tab-pane fade active show" id="account" role="tabpanel"
                                        aria-labelledby="account-tab">
                                        <div class="mb-4">
                                            <img src="../../../../assets/images/SIRC Logo.fw.png" style="width: 21%;" alt="">
                                        </div>
                                        <h3 class="mb-3 title">Reporte ciudadano</h3>
                                        <form [formGroup]="personalDetails" class="form-horizontal auth-form" novalidate="">
                                            <fieldset>
                                                <div class="row a-initial">
                                                    <div class="col-xs-12 col-md-6">
                                                        <span class="no-spacing">DATOS GENERALES</span>
                                                        <hr>
                                                        <div class="row">
                                                            <!--Nombre-->
                                                            <div class="col-xs-12 col-md-12">
                                                                <div class="form-group">
                                                                    <input required="" formControlName="Name" type="text"
                                                                    class="form-control" placeholder="Nombre completo"
                                                                    [ngClass]="{'error': submitted && formState?.Name?.errors?.required}">
                                                                </div>
                                                            </div>
                                                            <!--Teléfono-->
                                                            <div class="col-xs-12 col-md-12">
                                                                <div class="form-group">
                                                                    <input required="" formControlName="Phone" type="number"
                                                                    class="form-control" placeholder="Teléfono" maxlength="10"
                                                                    [ngClass]="{'error': submitted && formState?.Phone?.errors?.required}">
                                                                    <label class="errorRed"
                                                                        *ngIf="submitted && formState?.Phone?.errors?.pattern">
                                                                        Ingresa solo 10 números.
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <!--Email-->
                                                            <div class="col-xs-12 col-md-12">
                                                                <div class="form-group">
                                                                    <input required="" formControlName="Email" type="text"
                                                                    class="form-control" placeholder="Correo electrónico"
                                                                    [ngClass]="{'error': submitted && formState?.Email?.errors?.required}">
                                                                    <label class="errorRed"
                                                                        *ngIf="submitted && formState?.Email?.errors?.pattern">
                                                                        Ingresa un correo válido.
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <!--Asunto-->
                                                            <div class="col-xs-12 col-md-12">
                                                                <div class="form-group">
                                                                    <ng-select name="Subject" id="Subject" formControlName="Subject" placeholder="Seleccione el problema" [(ngModel)]="other" [ngClass]="{'error': submitted && formState?.Subject?.errors?.required}">
                                                                        <ng-option *ngFor="let problem of problems" [value]="problem">
                                                                            {{problem.ShortDescription}}
                                                                        </ng-option>
                                                                    </ng-select>
                                                                </div>
                                                            </div>
                                                            <!--Otro-->
                                                            <div class="col-xs-12 col-md-12" *ngIf="this.other?.ShortDescription === 'Otro'">
                                                                <div class="form-group">
                                                                    <ng-select name="Area" id="Area" formControlName="Area" placeholder="Seleccione el área">
                                                                        <ng-option value="" disabled selected>Seleccione el área</ng-option>
                                                                        <ng-option *ngFor="let value of values" [value]="value">
                                                                            {{value}}
                                                                        </ng-option>
                                                                    </ng-select>
                                                                </div>
                                                            </div>
                                                            <!--Descripción-->
                                                            <div class="col-xs-12 col-md-12">
                                                                <div class="form-group">
                                                                    <textarea required="" formControlName="Description" id="" cols="30" rows="5"
                                                                    class="form-control" placeholder="Descripción"
                                                                    [ngClass]="{'error': submitted && formState?.Description?.errors?.required}"></textarea>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-xs-12 col-md-6">
                                                        <span class="no-spacing">UBICACIÓN</span>
                                                        <hr>
                                                        <div class="row center mb-4">
                                                            <div class="col-xs-12 col-md-12">
                                                                <div class="form-group mb-1">
                                                                    <input matInput class="form-control" 
                                                                    formControlName="Address"
                                                                    matGoogleMapsAutocomplete
                                                                    country="mx"
                                                                    (onAutocompleteSelected)="onAutocompleteSelected($event)"
                                                                    (onLocationSelected)="onLocationSelected($event)">
                                                                </div>
                                                                <span>o</span>
                                                                <button type="button" (click)="setCurrentLocation();"
                                                                    class="btn btn-primary mt-1 mb-4">
                                                                    Busca mi ubicación
                                                                    <i class="fa fa-spinner fa-spin"
                                                                    [hidden]="!isSearching"></i>
                                                                </button>
                                                                <agm-map *ngIf="showMap" [latitude]="latitude" [longitude]="longitude" [scrollwheel]="true" [zoom]="zoom">
                                                                    <agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="true"
                                                                    (dragEnd)="markerDragEnd($event)"></agm-marker>
                                                                </agm-map>
                                                                <p *ngIf="showMap" class="address">La dirección del reporte es: <strong>{{completeAddress}}</strong></p>
                                                                <span *ngIf="showMessage" class="address">Si la dirección mostrada no es la correcta, por favor use el buscador.</span>
                                                            </div>
                                                        </div>
                                                        <br>
                                                        <span class="no-spacing">ARCHIVO</span>
                                                        <hr>
                                                        <div class="row mt-3">
                                                            <div class="col-xs-12 col-xl-4">
                                                                <div *ngIf="previewImg" class="box-input-file">
                                                                    <img class="imgUpload" width="100%" alt="" [src]="previewImg.base">
                                                                </div>
                                                                <div *ngIf="viewLoaderImg" class="spinner-border text-danger" role="status">
                                                                    <span class="sr-only">Loading...</span>
                                                                </div>
                                                            </div>
                                                            <div class="col-xs-12 col-xl-8">
                                                                <input type="file" class="file-input" [accept]="requiredFileType" (change)="onImgSelected($event)" #fileImgUpload>
                                                                <div class="file-upload">
                                                                {{ImgfileName || "Agregar imagen...  "}}
                                                                    <button class="btn btn-primary mat-btn" (click)="fileImgUpload.click()">
                                                                        <i class="fa fa-cloud-upload" aria-hidden="true"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="mt-15">
                                                    <input type="checkbox" class="mr-10" formControlName="Check" value="true" [ngClass]="{'error': submitted && formState?.Check?.errors?.required}">
                                                    <small>He leído el <a href="http://gobiernoabierto.veracruzmunicipio.gob.mx/avisos-de-privacidad/" target="_blank">aviso de privacidad</a>. Y estoy de acuerdo en ser contactado por un agente.</small>
                                                </div>
                                                <button type="button"
                                                    class="btn btn-primary width-25"
                                                    [disabled]="isLoading"
                                                    (click)="onSubmit()">
                                                    {{ isLoading ? 'Enviando reporte' : 'Enviar reporte'}}
                                                    <i class="fa fa-spinner fa-spin"
                                                        [hidden]="!isLoading"></i>
                                                </button>
                                            </fieldset>
                                        </form>
                                    </div>
                                </ng-template>
                            </ngb-tab>
                        </ngb-tabset>
                    </div>
                </div>
                <div class="row">
                    <small class="pad-left">¿Tienes una cuenta? <a [routerLink]="['/iniciar-sesion']">Inicia sesión</a></small>
                </div>
                <div class="row">
                    <small class="pad-left">¿No tienes cuenta? <a [routerLink]="['/registro']">Ingresa aquí</a></small>
                </div>
                <!-- <hr>
                <small>Al registrarte estás aceptando nuestros
                    <a href="">Términos y Política de Privacidad</a>
                </small> -->
            </div>
        </div>
    </div>
</div>

<ng2-toasty></ng2-toasty>
