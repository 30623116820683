import { Component, OnInit, OnDestroy } from '@angular/core';
import { UsersService } from '../../services/users/users.service';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { Subscription, Subject, throwError } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReportsService } from '../../services/reports/reports.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-reports-general',
  templateUrl: './reports-general.component.html',
  styleUrls: ['./reports-general.component.scss']
})
export class ReportsGeneralComponent implements OnInit, OnDestroy {

  private subs: Array<Subscription> = [];
  private modalContent: any;
  private modalConfig: any = {
    ariaLabelledBy: 'modal-basic'
  };

  public reports = [];
  public values: any;
  public dtOptions: DataTables.Settings = {};
  public dtTrigger: Subject<any> = new Subject();
  public deleteIsSuccess = false;
  public userId: string;
  public total: number;
  public page = 1;
  public limit: number = 10;
  public offset: number = 0;
  public area: string;
  public startDate: string = '';
  public endDate: string = '';
  public showClean = false;
  public showTable = true;
  public hideButton = false;
  dtElement: any;

  constructor(
    private usersService: UsersService,
    private reportService: ReportsService,
    private authService: AuthenticationService,
    private modalService: NgbModal,
    private router: Router,
  ) {  }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      destroy: true,
      language: {
        emptyTable: '',
        zeroRecords: '',
        lengthMenu: 'Mostrar _MENU_ elementos',
        search: 'Buscar:',
        info: 'De _START_ a _END_ de _TOTAL_ elementos',
        infoEmpty: 'De 0 a 0 de 0 elementos',
        infoFiltered: '(filtrados de _MAX_ elementos totales)',
        paginate: {
          first: 'Prim.',
          last: 'Últ.',
          next: 'Sig.',
          previous: 'Ant.'
        },
      },
      order: [0, 'desc']
    };
    this.subs.push(
      this.reportService
        .getReportGeneral(this.startDate, this.endDate, this.limit, this.offset)
        .pipe(
          catchError(err => this.authService.handleUnauthorizedError(err))
        )
        .subscribe(data => {
          this.reports = data.list;
          this.total = data.deparments;
          this.rerender();
        })
    );
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    this.subs.forEach(sub => sub.unsubscribe());
  }

  rerender(): void {
    this.dtElement?.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }
    
    ngAfterViewInit(): void {
    this.dtTrigger.next();
     }

  generateReport(){
    localStorage.setItem("start", this.startDate);
    localStorage.setItem("end", this.endDate);
    this.router.navigateByUrl('/reports/general/export');
  }

  getResults(): any {
    this.showClean = true;
    this.showTable = true;
    this.hideButton = true;
    this.subs.push(
      this.reportService
        .getReportGeneral(this.startDate, this.endDate, this.limit, this.offset)
        .pipe(
          catchError(err => this.authService.handleUnauthorizedError(err))
        )
        .subscribe(data => {
          this.reports = data.list;
          this.total = data.deparments;
          this.rerender();
        })
    );
  }

  clearInputs(): any {
    this.area = '';
    this.startDate = '';
    this.endDate = '';
    this.showClean = false;
    this.showTable = true;
    this.hideButton = false;
    this.ngOnInit();
  }

  setModalContent(content: any, userId: string): void {
    this.userId = userId;
    this.modalContent = content;
    this.modalService.open(this.modalContent, this.modalConfig);
  }

  public loadPage(page: number) {
    this.subs.push(
      this.reportService
        .getReportGeneral(this.startDate, this.endDate, this.limit, page-1)
        .pipe(
          catchError(err => this.authService.handleUnauthorizedError(err))
        )
        .subscribe(data => {
          this.reports = data.list;
          this.total = data.deparments;          
        })
    );
  }

}

