import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OrdersComponent } from './orders/orders.component';
import { OrdersDetailsComponent } from './orders/order-detail/order-detail.component';
import { TransactionsComponent } from './transactions/transactions.component';
import { QuotationsComponent } from './quotations/quotations.component';
import { QuotationsDetailsComponent } from './quotations/quotation-detail/quotation-detail.component';
import { ServicesComponent } from './services/services.component';
import { AddServiceComponent } from './services/add-service/add-service.component';
import { TransactionsDetailsComponent } from './transactions/transaction-detail/transaction-detail.component';
import { ServiceDetailComponent } from './services/service-detail/service-detail.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'orders',
        component: OrdersComponent,
        data: {
          title: "Pedidos",
          breadcrumb: "Pedidos"
        }
      },
      {
        path: 'orders/show/:id',
        component: OrdersDetailsComponent,
        data: {
          title: "Detalle de pedido",
          breadcrumb: "Detalle de pedido"
        }
      },
      {
        path: 'quotations',
        component: QuotationsComponent,
        data: {
          title: "Cotizaciones",
          breadcrumb: "Cotizaciones"
        }
      },
      {
        path: 'quotations/show/:id',
        component: QuotationsDetailsComponent,
        data: {
          title: "Detalle de cotización",
          breadcrumb: "Detalle de cotización"
        }
      },
      {
        path: 'services',
        component: ServicesComponent,
        data: {
          title: "Lista de Servicios",
          breadcrumb: "Lista de Servicios"
        }
      },
      {
        path: 'services/add-service',
        component: AddServiceComponent,
        data: {
          title: "Agregar Servicio",
          breadcrumb: "Agregar Servicio"
        }
      },
      {
        path: 'services/edit/:id',
        component: AddServiceComponent,
        data: {
          title: "Editar servicio",
          breadcrumb: "Editar servicio"
        }
      },
      {
        path: 'services/show/:id',
        component: ServiceDetailComponent,
        data: {
          title: "Detalle de servicio",
          breadcrumb: "Detalle de servicio"
        }
      },
      
      {
        path: 'transactions',
        component: TransactionsComponent,
        data: {
          title: "Pagos",
          breadcrumb: "Pagos"
        }
      },
      {
        path: 'transactions/show/:id',
        component: TransactionsDetailsComponent,
        data: {
          title: "Detalle de pago",
          breadcrumb: "Detalle de pago"
        }
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SalesRoutingModule { }
