<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <!-- <div class="card-header">
                    <h5>Agregar Maquinaria</h5>
                </div> -->
                <div class="card-body">
                    <div class="row product-adding">
                        <div class="col-md-3">
                        </div>
                        <div class="col-md-6 p-0">
                            <div class="card tab2-card">
                                <div class="card-body center">
                                    <div class="mp-2">
                                        <img src="../../../../assets/images/logo-next.png" style="width: 60%;" alt="">
                                    </div>
                                    <ngb-tabset class="tab-coupon mb-0">
                                        <ngb-tab>
                                            <ng-template ngbTabContent>
                                                <div class="tab-pane fade active show" id="account" role="tabpanel"
                                                    aria-labelledby="account-tab">
                                                    <div>
                                                        <small>Detalle del evento</small><br>
                                                        {{ticket?.Event.EventTitle}}<br>
                                                        {{ticket?.Event?.EventDate | date:'dd/MM/yyyy'}}<br>
                                                        {{ticket?.Event?.EventDescription}}
                                                    </div>
                                                    <hr>
                                                    <div>
                                                        <small>Detalle del boleto</small><br>
                                                        <span [ngClass]="{'ticket-active': ticket?.Status === 'Activo', 'ticket-inactive': ticket?.Status === 'Inactivo', 'ticket-apply': ticket?.Status === 'Aplicado'}">{{ticket?.Status}}</span><br>
                                                        {{ticket?.Type}}<br>
                                                        {{ticket?.Cost | currency}}
                                                    </div>
                                                    <hr>
                                                    <div class="mb-4">
                                                        <small>Detalle del cliente</small><br>
                                                        {{ticket?.ClientName}}<br>
                                                        {{ticket?.ClientPhoneNumber}}<br>
                                                        {{ticket?.ClientEmail}}<br>
                                                    </div>
                                                    <button *ngIf="ticket?.Status === 'Activo'" (click)="applyTicketSend(ticket?.id)"
                                                        class="btn btn-primary"
                                                        [disabled]="isLoading">
                                                        Aplicar boleto
                                                        <i class="fa fa-spinner fa-spin"
                                                        [hidden]="!isLoading"></i>
                                                    </button>
                                                    <div *ngIf="ticket?.Status === 'Aplicado'" class="text-warning">
                                                        <strong>Este boleto ya ha sido aplicado</strong>
                                                    </div>
                                                    <div *ngIf="ticket?.Status === 'Inactivo'" class="text-danger">
                                                        <strong>El boleto se encuentra inactivo</strong>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </ngb-tab>
                                    </ngb-tabset>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->

<ng2-toasty></ng2-toasty>