<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="row product-adding">
                <div class="col-xl-12">
                    <div class="card bordertop">
                        <div class="add-product-form">
                            <div class="row">
                                <div class="col-xs-12 col-sm-6">
                                    <div class="form-group">
                                        <label class="label">Nombre del cuestionario</label>
                                        <p><strong>{{quiz?.Title}}</strong></p>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-6">
                                    <div class="form-group">
                                        <label class="label">Fecha de creación</label>
                                        <p><strong>{{quiz?.Created | date:'dd/MM/yyyy'}}</strong></p>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="row">
                                <div class="col-xs-12 col-sm-12">
                                    <div class="form-group">
                                        <label class="label">Descripción del cuestionario</label>
                                        <p><strong>{{quiz?.Description}}</strong></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="card borderleft" *ngFor="let question of quiz?.questions; let i = index">
                            <div class="col-xs-12">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-xs-12 col-sm-6">
                                            <h4>Pregunta Nº {{i+1}}</h4>
                                            <p>{{question.Question}}</p>
                                            <!-- <p>Tipo: {{question.Type}}</p> -->
                                        </div>
                                        <div class="col-xs-12 col-sm-6" style="display: inherit;">
                                            <table class="table table-striped">
                                                <tbody>
                                                    <tr *ngFor="let answer of question.answers">
                                                        <td><span [ngClass]="{'true': answer.Correct == true, 'false': answer.Correct == false}">{{answer.Answer}}</span></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->