import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ToastyService, ToastyConfig, ToastOptions } from 'ng2-toasty';
import { map, catchError } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { TicketModel } from '../tickets.models';
import { TicketsService } from '../../services/tickets/tickets.service';
import { ProductsService } from '../../services/products/products.service';
import { AuthenticationService } from '../../services/auth/authentication.service';

@Component({
  selector: 'app-add-ticket',
  templateUrl: './add-ticket.component.html',
  styleUrls: ['./add-ticket.component.scss']
})
export class AddTicketComponent implements OnInit {
  public ticketForm: FormGroup;
  public isLoading = false;
  public submitted = false;

  public eventId: number;
  public id: number;
  public event: any;

  private subs: Array<Subscription> = [];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private toastyService: ToastyService,
    private toastyConfig: ToastyConfig,
    private ticketsService: TicketsService,
    private productsService: ProductsService,
    private authService: AuthenticationService,
    private activatedRoute: ActivatedRoute,
  ) {
      this.toastyConfig.theme = 'bootstrap';
  }

  ngOnInit() {
    this.isLoading = false;
    this.subs.push(
      this.activatedRoute.paramMap.subscribe((data: any) => {
        this.eventId = data.params.id;
        this.initializeForm();
      })
    );
    this.productsService
        .getEvent(this.eventId)
        .pipe(
          catchError(err => this.authService.handleUnauthorizedError(err))
        )
        .subscribe(data => {
          this.event = data;
    })

  }

  public initializeForm(): void {
    this.ticketForm = this.fb.group({
        Type: ['', [Validators.required]],
        Section: [''],
        RowLabel: [''],
        Cost: ['', Validators.required],
        ClientName: ['', Validators.required],
        ClientEmail: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
        ClientPhoneNumber: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
    })
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }

  public get formState() {
    return this.ticketForm.controls
  }

  private setValues(ticket: any): void {
    const values = this.ticketsService.mapRequiredValues(ticket);
    this.id = values.id;
    const { controls } = this.ticketForm;

    for (const value in values) {
      if (controls.hasOwnProperty(value)) {
        this.ticketForm.controls[value].setValue(values[value]);
      }
    }
  }

  private buildTicket(): TicketModel {
    return {
        EventId: this.eventId,
        Type: this.ticketForm.controls['Type'].value,
        Section: 'A',
        RowLabel: '1',
        Cost: this.ticketForm.controls['Cost'].value,
        ClientName: this.ticketForm.controls['ClientName'].value,
        ClientEmail: this.ticketForm.controls['ClientEmail'].value,
        ClientPhoneNumber: this.ticketForm.controls['ClientPhoneNumber'].value,
        DealerId: this.authService.currentUserValue.id,
    }
  }

  private buildTicketUpdate(): TicketModel {
    return {
        id: this.id,
        EventId: this.eventId,
        Type: this.ticketForm.controls['Type'].value,
        Section: this.ticketForm.controls['Section'].value,
        RowLabel: this.ticketForm.controls['RowLabel'].value,
        Cost: this.ticketForm.controls['Cost'].value,
        ClientName: this.ticketForm.controls['ClientName'].value,
        ClientEmail: this.ticketForm.controls['ClientEmail'].value,
        ClientPhoneNumber: this.ticketForm.controls['ClientPhoneNumber'].value,
        DealerId: this.authService.currentUserValue.id,
    }
  }

  private handleError(error: any) {
    const toastOptions: ToastOptions = {
      title: 'Error',
      msg: 'No se pudo crear el boleto',
      showClose: true,
      timeout: 2000
    };

    this.toastyService.error(toastOptions);

    return [];
  }

  private handleErrorEdit(error: any) {
    const toastOptions: ToastOptions = {
      title: 'Error',
      msg: 'No se pudo actualizar el boleto',
      showClose: true,
      timeout: 2000
    };

    this.toastyService.error(toastOptions);

    return [];
  }

  private handleTicketCreation() {
    const toastOptions: ToastOptions = {
      title: 'Boleto',
      msg: 'Boleto creado correctamente',
      showClose: true,
      timeout: 1700
    };

    this.toastyService.success(toastOptions);
  }

  private handleTicketEdit() {
    const toastOptions: ToastOptions = {
      title: 'Boleto',
      msg: 'Boleto actualizado correctamente',
      showClose: true,
      timeout: 1700
    };

    this.toastyService.success(toastOptions);
  }

  onSubmit() {
    this.submitted = true;

    if (this.ticketForm.invalid) {
        return;
    }

    // if (this.onEdit) {
    //   this.updateProduct();
    // } else {
    this.createProduct();
    // } 
  }

  createProduct() {

    const ticket: TicketModel = this.buildTicket();
    this.isLoading = true;

    this.subs.push(
        this.ticketsService.createTicket(ticket)
        .pipe(
        map(() => {
            this.handleTicketCreation();
            setTimeout(() => {
            // this.router.navigateByUrl('products/product-list');
            this.initializeForm();
            this.submitted = false;
            this.isLoading = false;
            }, 1500);
        }),
        catchError(error => this.handleError(error))
        ).subscribe()
    );
  }

}
