<div class="container-fluid">
    <div class="row">
        <div class="col-xl-12">
            <div class="card tab2-card">
                <div class="card-body">
                    <div class="tab-pane fade show active" id="top-profile" role="tabpanel"
                        aria-labelledby="top-profile-tab">
                        <h5 class="f-w-600 card-title">Viaje</h5>
                        <div class="row">
                            <div class="col-xs-12 col-xl-6">
                                <div class="table-responsive profile-table">
                                    <table class="table table-responsive">
                                        <tbody>
                                            <tr>
                                                <td>N° de viaje:</td>
                                                <td>{{trip?.TripNumber}}</td>
                                            </tr>
                                            <tr>
                                                <td>Nombre:</td>
                                                <td>{{trip?.client?.displayName}}</td>
                                            </tr>
                                            <tr>
                                                <td>Teléfono:</td>
                                                <td>{{trip?.client.phoneNumber ? trip?.client.phoneNumber : 'Sin número'}}</td>
                                            </tr>
                                            <tr>
                                                <td>Correo electrónico:</td>
                                                <td>{{trip?.client.email}}</td>
                                            </tr>
                                            <tr>
                                                <td>Fecha de inicio:</td>
                                                <td>{{trip?.StartDateTime | date: 'dd/MM/yyyy, h:mm a'}} </td>
                                            </tr>
                                            <tr>
                                                <td>Fecha de término:</td>
                                                <td>{{trip?.EndDateTime | date: 'dd/MM/yyyy, h:mm a'}} </td>
                                            </tr>
                                            <tr>
                                                <td>Estatus:</td>
                                                <td>{{trip?.Status}}</td>
                                            </tr>
                    
                                        </tbody>
                                    </table>
                                </div>
                                <!-- <div class="row" *ngIf="rol === 'admin' || rol === 'supervisor'">
                                    <div class="col-xs-12 col-lg-6 mt-2" *ngIf="!report?.Department">
                                        <button class="btn btn-primary"
                                            (click)="rejectModalContent(contentArea, report?.id)"
                                            title="Asignar reporte">
                                            Asignar reporte
                                        </button>
                                    </div>
                                    <div class="col-xs-12 col-lg-6 mt-2" *ngIf="!report?.Department">
                                        <button class="btn btn-danger"
                                            (click)="acceptModalContent(contentDelete, report?.id)"
                                            title="Eliminar reporte">
                                            Eliminar reporte
                                        </button>
                                    </div>
                                </div> -->
                                <!-- <div class="row" *ngIf="rol === 'funcionario'">
                                    <div class="col-xs-12 col-lg-6 mt-2">
                                        <button class="btn btn-danger"
                                            (click)="rejectModalContent(contentReject, report?.id)"
                                            title="Turnar reporte">
                                            Turnar reporte
                                        </button>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xs-6 col-lg-6 mt-2 ">
                                        <a class="btn btn-primary width-25 mt-2"
                                            [routerLink]="[ '/download-report', report?.id ]" target="_blank">Exportar
                                            PDF</a>
                                    </div>
                                    <div class="col-xs-6 col-lg-6 mt-2 ">
                                        <a class="btn btn-primary width-25 mt-2"
                                            [routerLink]="[ '/download-reportEvent', report?.id ]"
                                            target="_blank">Exportar
                                            Seguimiento</a>
                                    </div>
                                </div> -->
                            </div>
                            <div class="col-xs-12 col-xl-6">
                                <p class="no-spacing mb-3 pb-2 border-bottom">RUTA</p>
                                <div class="">
                                    <agm-map [latitude]="trip?.origin.lat"
                                        [longitude]="trip?.origin?.lng" [scrollwheel]="true" [zoom]="zoom">
                                        <!-- <agm-direction [origin]="trip?.origin" [destination]="destination"></agm-direction> -->
                                        <agm-marker [latitude]="trip?.origin?.lat"
                                            [longitude]="trip?.origin?.lng"
                                            label="A"></agm-marker>
                                    </agm-map>
                                </div>
                                <!-- <p class="address">La dirección del reporte es: <strong>{{completeAddress}}</strong>
                                    <br *ngIf="report?.Address.Street1">
                                    <span *ngIf="report?.Address.Street1">Entre calles:
                                        <strong>{{report?.Address.Street1 + ' y ' +
                                            report?.Address.Street2}}</strong></span>
                                </p> -->
                                <br>
                                <!-- <p class="no-spacing mt-4 pb-2 border-bottom">ARCHIVO</p>
                                <p *ngIf="!report?.ImgUrl">El reporte no cuenta con archivos.</p>
                                <img *ngIf="report?.ImgUrl" [src]="[report?.ImgUrl]"
                                    class="img-fluid blur-up lazyload bg-img" alt=""> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>