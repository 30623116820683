<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row mb-20">
        <div class="col-xl-4">
            <div class="card">
                <div class="card-body">
                    <div class="profile-details text-center">
                        <img src="assets/images/dashboard/profile-user.png" alt=""
                            class="img-fluid img-90 rounded-circle blur-up lazyloaded">
                        <h5 class="f-w-600 mb-0">{{user?.Name}}</h5>
                        <br>
                        <p class="mb-3"><strong>Núm. RET:</strong> {{user?.RET}}</p>
                        <p class="mb-3"><strong>RFC:</strong> {{user?.TaxId}}</p>
                        <p class="mb-3"><strong>Régimen fiscal:</strong> {{user?.TaxRegime}}</p>
                        <hr>
                        <p class="mb-3"><strong>Correo electrónico:</strong> {{user?.ContactEmail}}</p>
                        <p class="mb-3"><strong>Móvil:</strong> {{user?.MobilePhone}}</p>
                        <p class="mb-3"><strong>Oficina:</strong> {{user?.OfficePhone}}</p>
                        <hr>
                        <p class="mb-3"><strong>Dirección:</strong> {{user?.Address[0].Street + ' ' + user?.Address[0].ExtNumber + ' ' + user?.Address[0].Neighborhood + ' ' + user?.Address[0].State + ' ' + user?.Address[0].City + ' ' + user?.Address[0].ZipCode}}</p>
                        <br>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-8">
            <ngb-tabset class="tab-coupon profile-tabs">
                <ngb-tab>
                    <ng-template ngbTabContent>
                        <div class="tab-pane fade show active" id="top-profile" role="tabpanel"
                            aria-labelledby="top-profile-tab">
                            <h5 class="f-w-600"> Editar perfil</h5>
                            <form [formGroup]="form" class="form-horizontal auth-form" (ngSubmit)="updateUser()">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label>Nombre o razón social</label>
                                            <input type="text" class="form-control" formControlName="Name"
                                            [ngClass]="{'error': submitted && formState?.Name?.errors?.required}">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label>RFC</label>
                                            <input type="text" class="form-control" formControlName="TaxId"
                                            [ngClass]="{'error': submitted && formState?.TaxId?.errors?.required}">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label>Régimen fiscal</label>
                                            <select formControlName="TaxRegime" type="text" class="padd-select form-control" [ngClass]="{'error': submitted && formState?.TaxRegime?.errors?.required}">
                                                <option value="Física">Persona Física</option>
                                                <option value="Moral">Persona Moral</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label>Clasificación</label>
                                            <ng-select name="LocalType" id="LocalType" formControlName="LocalType" [ngClass]="{'error': submitted && formState?.LocalType?.errors?.required}">
                                                <ng-option *ngFor="let value of values" [value]="value.ValueAlpha">
                                                    {{value.ValueAlpha}}
                                                </ng-option>
                                            </ng-select>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label>Teléfono de oficina</label>
                                            <input required="" formControlName="OfficePhone" type="tel" class="form-control" maxlength="10"
                                            [ngClass]="{'error': submitted && formState?.OfficePhone?.errors?.required}">
                                            <label class="errorRed"
                                                *ngIf="submitted && formState?.OfficePhone?.errors?.pattern">
                                                Ingresa solo 10 números.
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label>Celular</label>
                                            <input required="" formControlName="MobilePhone" type="tel" class="form-control" maxlength="10"
                                            [ngClass]="{'error': submitted && formState?.MobilePhone?.errors?.required}">
                                            <label class="errorRed"
                                                *ngIf="submitted && formState?.MobilePhone?.errors?.pattern">
                                                Ingresa solo 10 números.
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xs-12 col-sm-12">
                                        <div class="form-group">
                                            <label>Correo electrónico</label>
                                            <input required="" formControlName="ContactEmail" type="text" class="form-control"
                                            [ngClass]="{'error': submitted && formState?.ContactEmail?.errors?.required}">
                                            <label class="errorRed"
                                                *ngIf="submitted && formState?.ContactEmail?.errors?.pattern">
                                                Ingresa un correo válido.
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label>Descripción</label>
                                            <textarea type="text" formControlName="Description" class="form-control"  cols="100%" rows="4"
                                            [ngClass]="{'error': submitted && formState?.Description?.errors?.required}"></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                    <div class="text-right mrg-top-5">
                                        <button type="submit"
                                        class="btn btn-primary mrg-right-15"
                                        [disabled]="isLoading">
                                        {{ isLoading ? 'Actualizando establecimiento' : 'Actualizar establecimiento'}}
                                        <i class="fa fa-spinner fa-spin"
                                        [hidden]="!isLoading"></i>
                                        </button>
                                    </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </ng-template>
                </ngb-tab>
            </ngb-tabset>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->

<ng2-toasty></ng2-toasty>