<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <!-- <div class="col-xl-6 xl-100">
            <div class="card">
                <div class="card-header">
                    <h5 class="text-normal">Datos generales</h5>
                </div>
                <div class="card-body">
                    <div class="market-chart">
                        <x-chartist class="board-chart ct-golden-section" [data]="chart3.data" [type]="chart3.type"
                            [options]="chart3.options" [events]="chart3.events">
                        </x-chartist>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5 class="text-normal">Datos generales</h5>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-xl-6 col-sm-6 xl-50">
                            <div class="order-graph">
                                <h6>Sexo</h6>
                                <div class="chart-block chart-vertical-center">
                                    <ngx-charts-pie-chart class="pie-colours-1" [view]="view"
                                        [scheme]="doughnutChartColorScheme" [results]="sexo" [explodeSlices]="false"
                                        [labels]="doughnutChartShowLabels" [arcWidth]=0.50 [doughnut]="true"
                                        [animations]="true" [tooltipDisabled]="doughnutChartTooltip"
                                        [gradient]="doughnutChartGradient">
                                    </ngx-charts-pie-chart>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6 col-sm-6 xl-50">
                            <div class="order-graph sm-order-space">
                                <h6>Edad</h6>
                                <div class="market-chart">
                                    <x-chartist class="board-chart ct-golden-section" [data]="age.data" [type]="age.type"
                                        [options]="age.options" [events]="age.events">
                                    </x-chartist>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5 class="text-normal">1. Hasta antes de esta encuesta ¿Había escuchado del distintivo Anfitrión Responsable?</h5>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-xl-3 col-sm-6 xl-50">
                            <div class="order-graph">
                                <div class="chart-block chart-vertical-center">
                                    <ngx-charts-pie-chart class="pie-colours-1" [view]="view"
                                        [scheme]="doughnutChartColorScheme" [results]="question1" [explodeSlices]="false"
                                        [labels]="doughnutChartShowLabels" [arcWidth]=0.50 [doughnut]="true"
                                        [animations]="false" [tooltipDisabled]="doughnutChartTooltip"
                                        [gradient]="doughnutChartGradient">
                                    </ngx-charts-pie-chart>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-sm-6 xl-50">
                            <div class="order-graph sm-order-space">
                                <div class="order-graph-bottom">
                                    <div class="media">
                                        <div class="order-color-yes"></div>
                                        <div class="media-body">
                                            <h6 class="mb-0">Si<span class="pull-right">110</span></h6>
                                        </div>
                                    </div>
                                    <div class="media">
                                        <div class="order-color-primary"></div>
                                        <div class="media-body">
                                            <h6 class="mb-0">No <span class="pull-right">190</span></h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5 class="text-normal">2. ¿Sabe si este establecimiento cuenta con el distintivo Anfitrión Responsable? </h5>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-xl-3 col-sm-6 xl-50">
                            <div class="order-graph sm-order-space">
                                <div class="peity-chart-dashboard text-center chart-vertical-center">
                                    <ngx-charts-pie-chart class="pie-colours-1" [view]="view"
                                        [scheme]="doughnutChartColorScheme" [results]="question2"
                                        [explodeSlices]="false" [labels]="doughnutChartShowLabels" [arcWidth]=0.99
                                        [doughnut]="true" [animations]="true" [tooltipDisabled]="doughnutChartTooltip"
                                        [gradient]="doughnutChartGradient">
                                    </ngx-charts-pie-chart>
                                </div>
                                
                            </div>
                        </div>
                        <div class="col-xl-3 col-sm-6 xl-50">
                            <div class="order-graph sm-order-space">
                                <div class="order-graph-bottom sales-location">
                                    <div class="media">
                                        <div class="order-shape-yes"></div>
                                        <div class="media-body">
                                            <h6 class="mb-0 mr-0">Si <span class="pull-right">70</span></h6>
                                        </div>
                                    </div>
                                    <div class="media">
                                        <div class="order-shape-primary"></div>
                                        <div class="media-body">
                                            <h6 class="mb-0 mr-0">No <span class="pull-right">230</span></h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5 class="text-normal">3. ¿Conoce qué beneficios trae para el cliente el distintivo Anfitrión Responsable?</h5>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-xl-3 col-sm-6 xl-50">
                            <div class="order-graph">
                                <div class="chart-block chart-vertical-center">
                                    <ngx-charts-pie-chart class="pie-colours-1" [view]="view"
                                        [scheme]="doughnutChartColorScheme" [results]="question3" [explodeSlices]="false"
                                        [labels]="doughnutChartShowLabels" [arcWidth]=0.50 [doughnut]="true"
                                        [animations]="true" [tooltipDisabled]="doughnutChartTooltip"
                                        [gradient]="doughnutChartGradient">
                                    </ngx-charts-pie-chart>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-sm-6 xl-50">
                            <div class="order-graph sm-order-space">
                                <div class="order-graph-bottom">
                                    <div class="media">
                                        <div class="order-color-yes"></div>
                                        <div class="media-body">
                                            <h6 class="mb-0">Sí, lo conozco bien<span class="pull-right">30</span></h6>
                                        </div>
                                    </div>
                                    <div class="media">
                                        <div class="order-color-primary"></div>
                                        <div class="media-body">
                                            <h6 class="mb-0">Tengo una idea, pero no lo conozco completamente<span class="pull-right">70</span></h6>
                                        </div>
                                    </div>
                                    <div class="media">
                                        <div class="order-color-gray"></div>
                                        <div class="media-body">
                                            <h6 class="mb-0">No<span class="pull-right">200</span></h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5 class="text-normal">4. ¿El distintivo fue un factor importante para elegir este establecimiento?  </h5>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-xl-3 col-sm-6 xl-50">
                            <div class="order-graph sm-order-space">
                                <div class="peity-chart-dashboard text-center chart-vertical-center">
                                    <ngx-charts-pie-chart class="pie-colours-1" [view]="view"
                                        [scheme]="doughnutChartColorScheme" [results]="question4"
                                        [explodeSlices]="false" [labels]="doughnutChartShowLabels" [arcWidth]=0.99
                                        [doughnut]="true" [animations]="true" [tooltipDisabled]="doughnutChartTooltip"
                                        [gradient]="doughnutChartGradient">
                                    </ngx-charts-pie-chart>
                                </div>
                                
                            </div>
                        </div>
                        <div class="col-xl-3 col-sm-6 xl-50">
                            <div class="order-graph sm-order-space">
                                <div class="order-graph-bottom sales-location">
                                    <div class="media">
                                        <div class="order-shape-yes"></div>
                                        <div class="media-body">
                                            <h6 class="mb-0 mr-0">Si, fue definitivo <span class="pull-right">25</span></h6>
                                        </div>
                                    </div>
                                    <div class="media">
                                        <div class="order-shape-primary"></div>
                                        <div class="media-body">
                                            <h6 class="mb-0 mr-0">Influyó, pero había otros elementos más importantes <span class="pull-right">70</span></h6>
                                        </div>
                                    </div>
                                    <div class="media">
                                        <div class="order-shape-gray"></div>
                                        <div class="media-body">
                                            <h6 class="mb-0 mr-0">No<span class="pull-right">195</span></h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5 class="text-normal">5. Según su experiencia, evalúe qué tanto se aplican las siguientes medidas sanitarias en el establecimiento.</h5>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-xl-12 col-sm-12 xl-100">
                            <div class="market-chart">
                                <x-chartist class="board-chart ct-golden-section" [data]="question5.data" [type]="question5.type"
                                    [options]="question5.options" [events]="question5.events">
                                </x-chartist>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->