import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddTicketComponent } from './add-ticket/add-ticket.component';
import { ApplyTicketComponent } from './apply-ticket/apply-ticket.component';
import { ListTicketsByUserComponent } from './tickets-by-user/tickets-by-user.component';
import { ListTicketsComponent } from './tickets-list/tickets-list.component';


const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: ListTicketsComponent,
        data: {
          title: "Boletos",
          breadcrumb: "Boletos"
        }
      },
      {
        path: 'my-tickets',
        component: ListTicketsByUserComponent,
        data: {
          title: "Mis boletos",
          breadcrumb: "Mis boletos"
        }
      },

    //   {
    //     path: 'product-list/show/:id',
    //     component: ProductDetailComponent,
    //     data: {
    //       title: "Detalle evento",
    //       breadcrumb: "Detalle "
    //     }
    //   },
    //   {
    //     path: 'product-list/edit/:id',
    //     component: AddProductComponent,
    //     data: {
    //       title: "Editar",
    //       breadcrumb: "Editar"
    //     }
    //   },
      {
        path: 'add-tickets/:id',
        component: AddTicketComponent,
        data: {
          title: "Agregar boleto",
          breadcrumb: "Agregar boleto"
        }
      },
      {
        path: 'aplicar-boleto/:uuid',
        component: ApplyTicketComponent,
        data: {
          title: "Aplicar boleto",
          breadcrumb: "Aplicar boleto"
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TicketsRoutingModule { }
