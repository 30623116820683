<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-body">
                    <div id="batchDelete" class="">
                        <div class="table-responsive">
                            <table datatable
                            [dtOptions]="dtOptions"
                            [dtTrigger]="dtTrigger"
                            class=" table table-lg table-hover row-border hover">
                            <thead>
                                <tr>
                                <th>Id Orden</th>
                                <th>Id Transacción</th>
                                <th>Fecha</th>
                                <th>Status</th>
                                <th>Método Pago</th>
                                <th>Total</th>
                                <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let transaction of transactions">
                                <td>{{transaction.id}}</td>
                                <td>{{transaction.order_id}}</td>
                                <td>{{transaction.operation_date | date}}</td>
                                <td><span [ngClass]="{'completed': transaction.status == 'completed', 'fail': transaction.status == 'fail'}">{{transaction.status}}</span></td>
                                <td><span *ngIf="transaction.method == 'card'"></span>Tarjeta</td>
                                <td>{{transaction.amount | currency}} {{transaction.currency}}</td>
                                <td class="actions-cell">
                                    <span>
                                    <a [routerLink]="['show', transaction.id]"
                                        title="Ver Transacción">
                                        <i class="fa fa-eye"></i>
                                    </a>
                                    </span>
                                </td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->