import { MapsAPILoader } from '@agm/core';
import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { TripsService } from '../../services/trips/trips.service';
import { UsersService } from '../../services/users/users.service';

@Component({
    selector: 'app-start-trip',
    templateUrl: './start-trip.component.html',
    styleUrls: ['./start-trip.component.scss']
})

export class StartTripComponent implements OnInit {
    @ViewChild('angel')
    public modalAngel: ElementRef;

    private modalContent: any;
    private modalConfig: any = {
      ariaLabelledBy: 'modal-basic'
    };
    public zoom: number = 16;
    latitude: any;
    longitude: any;
    private subs: Array<Subscription> = [];
    currentUser = JSON.parse(localStorage["currentUser"]);
    tripData = JSON.parse(localStorage["tripData"]);
    sInterval: any
    showMap = true;
    userInfo:any;
    constructor(
        private tripsService: TripsService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private modalService: NgbModal,
        private mapsAPILoader: MapsAPILoader,
        private userService: UsersService,
        private ngZone: NgZone
    ) { }

    ngOnInit(): void {
        this.subs.push(
            this.userService.getUser(this.currentUser.id).subscribe(data => {
                this.userInfo = data;
                this.modalService.open(this.modalAngel, this.modalConfig);
            })
        )


        this.latitude = this.tripData.origin.lat;
        this.longitude = this.tripData.origin.lng;
        this.sInterval = setInterval(() => {
            this.updateLocation();
        }, 30000)

    }
    ngOnDestroy(): void {
        this.subs.forEach(sub => sub.unsubscribe());
        clearInterval(this.sInterval);
    }

    updateLocation() {
        this.mapsAPILoader.load().then(() => {
            if ('geolocation' in navigator) {
                navigator.geolocation.getCurrentPosition((pos) => {
                    let position = {
                        position:{
                            lat: pos.coords.latitude,
                            lng: pos.coords.longitude
                        }
                    }
                    this.subs.push(
                        this.tripsService.updatePosition(this.tripData.tripId, position).subscribe(res => {

                        })
                    )
                });
            }
        })

    }

    finishTrip(){

        this.mapsAPILoader.load().then(() => {
            if ('geolocation' in navigator) {
                navigator.geolocation.getCurrentPosition((pos) => {
                    let position = {
                        destination:{
                            lat: pos.coords.latitude,
                            lng: pos.coords.longitude
                        }
                    }
                    this.subs.push(
                        this.tripsService.finishTrip(this.tripData.tripId, position).subscribe(res => {
                            if(res.message === "Viaje finalizado correctamente"){
                                this.showMap = false;
                                clearInterval(this.sInterval);
                                setTimeout(() => {
                                    this.router.navigateByUrl('/menus')
                                },3000)
                            }
                        })
                    )
                });
            }
        })
    }
}