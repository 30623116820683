import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { getHeaders } from 'src/app/shared/utils/headers.functions';
import { GuideModel, MessageModel, ServiceModel, responseModel } from '../../sales/sales.models';

const BASE_URL: string = environment.API_URL;

@Injectable({
  providedIn: 'root'
})
export class SalesService {
  constructor(
    private http: HttpClient
  ) { }

  public getSales(): Observable<any> {
    return this.http.get<any>(`${BASE_URL}/invitations`);
  }

  public getSale(thisSaleId: string): Observable<any> {
    return this.http.get<any>(`${BASE_URL}/sales/${thisSaleId}`);
  }

  public getQuotations(): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/requests`, { headers });
  }

  public getQuotation(thisQuotationId: string): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/requests/${thisQuotationId}`, { headers });
  }

  public addMessage(message: MessageModel, id: number): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.put<Observable<any>>(`${BASE_URL}/request-status/${id}`, message, { headers });
  }

  public deleteQuotation(id: string): Observable<any> {
    return this.http.delete<any>(`${BASE_URL}/requests/${id}`);
  }

  public addTrackingNumber(trackingNumber: GuideModel, id: number): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.put<Observable<any>>(`${BASE_URL}/sales-trackingNumber/${id}`, trackingNumber, { headers });
  }

  public finishOrder(id: number): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.put<Observable<any>>(`${BASE_URL}/sales-close/${id}`, { headers });
  }

  public createService(service: ServiceModel): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.post<any>(`${BASE_URL}/products`, service, { headers });
  }

  public getService(productId: string): Observable<any> {
    return this.http.get<any>(`${BASE_URL}/products/${productId}`);
  }

  public updateProduct(id: number, product: ServiceModel): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.put<any>(`${BASE_URL}/products/${id}`, product, {headers});
  }

  public deleteService(id: number): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.delete<Observable<any>>(`${BASE_URL}/products/${id}`, { headers });
  }

  public getServices(family: string): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/products-filter?family=${family}`);
  }

  public getPayments(): Observable<any> {
    return this.http.get<any>(`${BASE_URL}/payments`);
  }

  public getPayment(thisPaymentId: string): Observable<any> {
    return this.http.get<any>(`${BASE_URL}/payments/${thisPaymentId}`);
  }

  public getInvitation(phoneNumber: string): Observable<any> {
    return this.http.get<any>(`${BASE_URL}/invitation/${phoneNumber}`);
  }

  public sendResponseInvitation(phoneNumber: string, invitationResponse: responseModel): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.put<any>(`${BASE_URL}/invitation/${phoneNumber}`, invitationResponse, {headers});
  }

  public mapRequiredValues(product: any): any {
    //console.log(product);
    const productInfo: any = {};

    productInfo.id = product.id,
    productInfo.Title = product.Title,
    productInfo.Description = product.Description,
    productInfo.featuresProduct = product.Features

    return productInfo;
  }
}
