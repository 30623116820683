import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CatalogsComponent } from './catalogs.component';


const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'areas',
        component: CatalogsComponent,
        data: {
          title: "Áreas",
          breadcrumb: "Áreas"
        }
      },
      {
        path: 'services',
        component: CatalogsComponent,
        data: {
          title: "Servicios",
          breadcrumb: "Servicios"
        }
      },
      {
        path: 'sources',
        component: CatalogsComponent,
        data: {
          title: "Fuentes",
          breadcrumb: "Fuentes"
        }
      },
      {
        path: 'users',
        component: CatalogsComponent,
        data: {
          title: "Perfiles",
          breadcrumb: "Perfiles"
        }
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CatalogsRoutingModule { }
