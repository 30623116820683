import { Component, OnInit } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { transactionsDB } from 'src/app/shared/tables/transactions';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { SalesService } from '../../services/sales/sales.service';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss']
})
export class TransactionsComponent implements OnInit {

  public transactions = []

  private subs: Array<Subscription> = [];
  public dtOptions: DataTables.Settings = {};
  public dtTrigger: Subject<any> = new Subject();

  constructor(
    private salesService: SalesService,
    private authService: AuthenticationService,
  ) {
  }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      language: {
        emptyTable: '',
        zeroRecords: 'No hay coincidencias',
        lengthMenu: 'Mostrar _MENU_ elementos',
        search: 'Buscar:',
        info: 'De _START_ a _END_ de _TOTAL_ elementos',
        infoEmpty: 'De 0 a 0 de 0 elementos',
        infoFiltered: '(filtrados de _MAX_ elementos totales)',
        paginate: {
          first: 'Prim.',
          last: 'Últ.',
          next: 'Sig.',
          previous: 'Ant.'
        },
      },
      order:[[0, 'desc']]
    };

    this.subs.push(
      this.salesService
        .getPayments()
        .pipe(
          catchError(err => this.authService.handleUnauthorizedError(err))
        )
        .subscribe(data => {
          this.transactions = data;
          this.dtTrigger.next();
        })
    );
  }

}
