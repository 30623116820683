import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { getHeaders } from 'src/app/shared/utils/headers.functions';
import { FollowReportModel, ReportsModel } from '../../reports/reports.models';

const BASE_URL: string = environment.API_URL;

@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  constructor(
    private http: HttpClient
  ) { }

  public createReport(report: ReportsModel): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.post<any>(`${BASE_URL}/report`, report, { headers });
  }
  public updateReport(reportId:any,report: ReportsModel): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.put<any>(`${BASE_URL}/report/${reportId}`, report, { headers });
  }
  public getReports(limit: number, offset: number, status: string): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/report?limit=${limit}&offset=${offset}&Status=${status}`, { headers });
  }

  public getReportsNotAssigned(limit: number, offset: number): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/reports-not-assigned?limit=${limit}&offset=${offset}`, { headers });
  }

  public getReportsByText(limit: number, offset: number, status: string, search: string): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/report?limit=${limit}&offset=${offset}&Status=${status}&search=${search}`, { headers });
  }

  public getReportsByArea(area: string, limit: number, offset: number, status: string): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/reportsarea/${area}?limit=${limit}&offset=${offset}&Status=${status}`, { headers });
  }

  public getReportsByAreaText(area: string, limit: number, offset: number, status: string, search: string): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/reportsarea/${area}?limit=${limit}&offset=${offset}&Status=${status}&search=${search}`, { headers });
  }

  public getReportsByUser(email: string, limit: number, offset: number, status: string): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/reportsuser/${email}?limit=${limit}&offset=${offset}&Status=${status}`, { headers });
  }

  public getReportsBySource(source: string, limit: number, offset: number, status: string): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/reports-source/${source}?limit=${limit}&offset=${offset}&Status=${status}`, { headers });
  }

  public getReportsSource(source: string, limit: number, offset: number): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/reports-source/${source}?limit=${limit}&offset=${offset}`, { headers });
  }

  public getReportGeneral(startDate: string, endDate: string, limit: number, offset: number): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/general-report?startDate=${startDate}&endDate=${endDate}&limit=${limit}&offset=${offset}`, { headers });
  }

  public getReportArea(department: string, startDate: string, endDate: string, limit: number, offset: number, status: string): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/report?endDate=${endDate}&Department=${department}&startDate=${startDate}&limit=${limit}&offset=${offset}&Status=${status}`, { headers });
  }

  public getReportByTextUser(startDate: string, endDate: string, textSearch: string, limit: number, offset: number, status: String): Observable<any> {
    const headers: HttpHeaders = getHeaders();
      return this.http.get<any>(`${BASE_URL}/report?startDate=${startDate}&endDate=${endDate}&search=${textSearch}&limit=${limit}&offset=${offset}&Status=${status}`, { headers });
  }

  public getReportByZone(department:string,zone: string, startDate: string, endDate: string, limit: number, offset: number, status:string): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/reportsbyzone?Department=${department}&limit=${limit}&offset=${offset}&Neighboorhood=${zone}&startDate=${startDate}&endDate=${endDate}&Status=${status}`, { headers });
  }

  public getReport(id: (number | string)): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/reportspecific/${id}`);
  }

  public createFollowReport(follow: FollowReportModel): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.post<any>(`${BASE_URL}/report-event/${follow.EventId}`, follow, { headers });
  }

  public getValuestoCatalog(Catalog: string): Observable<any> {
    return this.http.get<any>(`${BASE_URL}/catalogs/${Catalog}?limit=10000`);
  }

  public getSourcetoUser(UserId:any):Observable<any>{
    return this.http.get<any>(`${BASE_URL}/catalogs2/SourceReport/${UserId}`);
  }

  public getDataCatalogs(catalogName:any, limit:any, offset:any):Observable<any>{
    return this.http.get<any>(`${BASE_URL}/catalogs-data/${catalogName}?limit=${limit}&offset=${offset}`);
  }

  public deleteCatalogItem(catalogId:any):Observable<any>{
    const headers: HttpHeaders = getHeaders();
    return this.http.delete<any>(`${BASE_URL}/catalog/${catalogId}`, {headers});
  }

  public getValuesCatalogtoUser(id: string): Observable<any> {
    return this.http.get<any>(`${BASE_URL}/catalogs2/EventCategory/${id}`);
  }

  public getProblems(): Observable<any> {
    return this.http.get<any>(`${BASE_URL}/problems`);
  }
  public assignReport(id: number | string, area: any): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.put<any>(`${BASE_URL}/asignarea/${id}`, {Department: area}, {headers});
  }

  public changeStatusReport(id: number | string): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.put<any>(`${BASE_URL}/reports/${id}`, {headers});
  }

  public changeSpeceficStatusReport(status: string, id: number | string): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.put<any>(`${BASE_URL}/statusreport/${id}`, {Status: status}, {headers});
  }

  public AcceptOrRejectReport(id: string, status: string, message: string): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.put<any>(`${BASE_URL}/reportspecific/${id}`, {Option: status, Message: message} , {headers});
  }
  public deleteReport(id: string): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.delete<any>(`${BASE_URL}/report/${id}`, {headers});
  }

  public uploadImg(formData: any): Observable<any> {
    return this.http.post<any>(`${BASE_URL}/uploadimg`, formData, { });
  }

  public getReportSource():Observable<any>{
    return this.http.get<any>(`${BASE_URL}/catalogs/SourceReport`)
  }

  public getAreaServices(limit:any, offset:any): Observable<any> {
    return this.http.get<any>(`${BASE_URL}/problems-data?limit=${limit}&offset=${offset}&`);
  }

  public saveItemCatalog(catalog:any):Observable<any>{
    const headers: HttpHeaders = getHeaders();
    return this.http.post<any>(`${BASE_URL}/catalogs`, catalog, { headers });
  }

  public updateCatalogItem(catalogId:any, valueAlpha:any):Observable<any>{
    const headers: HttpHeaders = getHeaders();
    return this.http.put<any>(`${BASE_URL}/catalog/${catalogId}`,valueAlpha, {headers});
  }

  public createProblem(problem:any):Observable<any>{
    const headers: HttpHeaders = getHeaders();
    return this.http.post<any>(`${BASE_URL}/problems`, problem, { headers });
  }

  public updateService(problemId:any,problem:any):Observable<any>{
    const headers: HttpHeaders = getHeaders();
    return this.http.put<any>(`${BASE_URL}/problems/${problemId}`, problem, { headers });
  }

  public deleteService(problemId:any):Observable<any>{
    const headers: HttpHeaders = getHeaders();
    return this.http.delete<any>(`${BASE_URL}/problems/${problemId}`, { headers });
  }

  getServicesbyArea(department:any):Observable<any>{
    return this.http.get<any>(`${BASE_URL}/problems-department/${department}`);
  }


  public mapRequiredValues(report: any): any {
    //console.log(product);
    const reportInfo: any = {};

    reportInfo.id = report.id;
    reportInfo.Name = report.Name;
    reportInfo.Email = report.Email;
    reportInfo.PhoneNumber = report.PhoneNumber;
    reportInfo.Source = report.Source;
    reportInfo.Status = report.Status;
    reportInfo.Subject = report.Subject;
    reportInfo.Address = report.Address;
    reportInfo.Description = report.Description;
    reportInfo.Street1 = report.Address.Street1;
    reportInfo.Street2 = report.Address.Street2;

    return reportInfo;
  }
  
}
