<div class="container-fluid">
    <div class="row">
        <div class="col-xl-12">
            <div class="card tab2-card">
                <div class="card-body">
                    <div class="tab-pane fade show active" id="top-profile" role="tabpanel"
                        aria-labelledby="top-profile-tab">
                        <h5 class="f-w-600 card-title">Reporte</h5>
                        <div class="row">
                            <div class="col-xs-12 col-xl-6">
                                <div class="table-responsive profile-table">
                                    <table class="table table-responsive">
                                        <tbody>
                                            <tr>
                                                <td>Nº de reporte:</td>
                                                <td>{{report?.Folio}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Fecha de reporte:</td>
                                                <td>{{report?.created_at | date: 'dd/MM/yyyy, h:mm a'}} </td>
                                            </tr>
                                            <tr>
                                                <td>Fuente:</td>
                                                <td>{{report?.Source ? report?.Source : 'Invitado'}} </td>
                                            </tr>
                                            <tr>
                                                <td>Estatus:</td>
                                                <td>{{report?.Status}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Nombre:</td>
                                                <td>{{report?.Name}}</td>
                                            </tr>
                                            <tr>
                                                <td>Teléfono:</td>
                                                <td>{{report?.PhoneNumber ? report?.PhoneNumber : 'Sin número'}}</td>
                                            </tr>
                                            <tr>
                                                <td>Correo electrónico:</td>
                                                <td>{{report?.Email}}</td>
                                            </tr>
                                            <tr>
                                                <td>Problema:</td>
                                                <td>{{report?.Subject}}</td>
                                            </tr>
                                            <tr>
                                                <td>Descripción del problema:</td>
                                                <td>{{report?.Description}}</td>
                                            </tr>
                                            <tr>
                                                <td>Reportó:</td>
                                                <td>{{report?.UserId ? report?.User.firstName + ' ' +
                                                    report?.User.lastName : 'Invitado'}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="row" *ngIf="rol === 'admin' || rol === 'supervisor' || rol === 'superadmin'">
                                    <div class="col-xs-12 col-lg-6 mt-2" *ngIf="!report?.Department">
                                        <button class="btn btn-primary"
                                            (click)="rejectModalContent(contentArea, report?.id)"
                                            title="Asignar reporte">
                                            Asignar reporte
                                        </button>
                                    </div>
                                    <div class="col-xs-12 col-lg-6 mt-2" >
                                        <button class="btn btn-danger"
                                            (click)="acceptModalContent(contentDelete, report?.id)"
                                            title="Eliminar reporte">
                                            Eliminar reporte
                                        </button>
                                    </div>
                                </div>
                                <div class="row" *ngIf="rol === 'funcionario'">
                                    <!-- <div class="col-xs-12 col-lg-6 mt-2">
                                        <button class="btn btn-primary" (click)="acceptModalContent(contentAccept, report?.id)"
                                            title="Aceptar reporte">
                                            Aceptar reporte
                                        </button>
                                    </div> -->
                                    <div class="col-xs-12 col-lg-6 mt-2">
                                        <button class="btn btn-danger"
                                            (click)="rejectModalContent(contentReject, report?.id)"
                                            title="Turnar reporte">
                                            Turnar reporte
                                        </button>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xs-6 col-lg-6 mt-2 ">
                                        <a class="btn btn-primary width-25 mt-2"
                                            [routerLink]="[ '/download-report', report?.id ]" target="_blank">Exportar
                                            PDF</a>
                                        <!-- <button class="btn btn-primary w-100" (click)="onCreateWorkOrderClick()"
                                            title="Crear orden de trabajo">
                                            Crear orden de trabajo
                                        </button> -->
                                    </div>
                                    <div class="col-xs-6 col-lg-6 mt-2 ">
                                        <a class="btn btn-primary width-25 mt-2"
                                            [routerLink]="[ '/download-reportEvent', report?.id ]"
                                            target="_blank">Exportar
                                            Seguimiento</a>
                                        <!-- <button class="btn btn-primary w-100" (click)="onCreateWorkOrderClick()"
                                            title="Crear orden de trabajo">
                                            Crear orden de trabajo
                                        </button> -->
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-xl-6">
                                <p class="no-spacing mb-3 pb-2 border-bottom">UBICACIÓN</p>
                                <div class="">
                                    <agm-map [latitude]="report?.Address.Location.lat"
                                        [longitude]="report?.Address.Location.lng" [scrollwheel]="true" [zoom]="zoom">
                                        <agm-marker [latitude]="report?.Address.Location.lat"
                                            [longitude]="report?.Address.Location.lng"></agm-marker>
                                    </agm-map>
                                </div>
                                <p class="address">La dirección del reporte es: <strong>{{completeAddress}}</strong>
                                    <br *ngIf="report?.Address.Street1">
                                    <span *ngIf="report?.Address.Street1">Entre calles:
                                        <strong>{{report?.Address.Street1 + ' y ' +
                                            report?.Address.Street2}}</strong></span>
                                </p>
                                <br>
                                <p class="no-spacing mt-4 pb-2 border-bottom">ARCHIVO</p>
                                <p *ngIf="!report?.ImgUrl">El reporte no cuenta con archivos.</p>
                                <img *ngIf="report?.ImgUrl" [src]="[report?.ImgUrl]"
                                    class="img-fluid blur-up lazyload bg-img" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-xs-12 col-xl-6" *ngIf="rol !== 'user'">
                    <h6 class="card-title mb-5">Agregar seguimiento</h6>

                    <!--Image-->
                    <div class="row mb-4 mt-3">
                        <div class="col-xs-12 col-xl-6">
                            <div *ngIf="prevImages.length > 0 ">
                                <div class="row">
                                    <div class="card ml-4" *ngFor="let item of prevImages">
                                        <div class="add-product">
                                            <div *ngIf="item.previewImg" class="box-input-file">
                                                <img class="imgUpload" alt="" [src]="item?.previewImg.base">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-xs-12 col-xl-6">
                            <input type="file" class="file-input" [accept]="requiredFileType"
                                (change)="onImgSelected($event)" #fileImgUpload>
                            <div class="file-upload">
                                {{viewLoaderImg ? 'Cargando archivo...':'Seleccionar archivo... '}}
                                <button class="btn btn-primary mat-btn" (click)="fileImgUpload.click()">
                                    <i *ngIf="viewLoaderImg"><span class="spinner-border" role="status"></span></i>
                                    <i *ngIf="!viewLoaderImg" class="fa fa-cloud-upload" aria-hidden="true"></i>
                                    <!-- <div *ngIf="viewLoaderImg" class="spinner-border text-danger" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div> -->
                                </button>
                            </div>

                        </div>
                    </div>
                    <div id="content">
                        <form [formGroup]="followReport" (ngSubmit)="onSubmit()" class="form-horizontal auth-form"
                            novalidate="">
                            <div class="row a-initial">
                                <div class="col-xs-12 col-md-12 pl-0">
                                    <!--EventCategory-->
                                    <div class="col-xs-12 col-md-12">
                                        <div class="form-group">
                                            <ng-select name="EventCategory" id="EventCategory"
                                                formControlName="EventCategory"
                                                [ngClass]="{'error': submitted && formState?.EventCategory?.errors?.required}">
                                                <ng-option value="" disabled selected>Evento</ng-option>
                                                <ng-option *ngFor="let category of categories" [value]="category">
                                                    {{category}}
                                                </ng-option>
                                            </ng-select>
                                        </div>
                                    </div>
                                    <!--Descripción-->
                                    <div class="col-xs-12 col-md-12">
                                        <div class="form-group">
                                            <textarea required="" formControlName="Message" id="" cols="30" rows="5"
                                                class="form-control" placeholder="Descripción"
                                                [ngClass]="{'error': submitted && formState?.Message?.errors?.required}"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button type="click" class="btn btn-primary width-25 mt-2" [disabled]="isLoading">
                                {{ isLoading ? 'Agregando seguimiento' : 'Agregar seguimiento'}}
                                <i class="fa fa-spinner fa-spin" [hidden]="!isLoading"></i>
                            </button>
                        </form>
                    </div>
                </div>
                <div class="col-xs-12 col-xl-6">
                    <h6 class="card-title">Historial</h6>
                    <div id="content">
                        <ul class="timeline">
                            <li *ngFor="let event of report?.Event" class="event" data-date="">
                                <div class="row">
                                    <div class="col-6">
                                        <h3>{{event.EventCategory}}</h3>
                                        <p>{{event.Message}}.</p>
                                        <small>{{event.User.firstName + ' ' + event.User.lastName}}</small>
                                        <br>
                                        <small>{{event.created_at | date: 'dd/MM/yyyy, h:mm a'}}</small>
                                    </div>
                                    <div class="col-6">
                                        <!-- <div class="row" *ngIf="isImage">
                                            <div class="add-product">
                                                <div class="box-input-file ml-3">
                                                    <img class="imgUpload" alt="" [src]="event.ImgUrl">
                                                </div>
                                            </div>
                                        </div> -->
                                        <div class="row">
                                            <div *ngFor="let item of event.ImgUrl; let i = index">
                                                <div class="add-product">
                                                    <div class="box-input-file ml-3">
                                                        <img *ngIf="event.fileTypes[i] !== 'pdf'" class="imgUpload" alt="" [src]="item">
                                                        <img *ngIf="event.fileTypes[i] === 'pdf'" src="../../../../assets/images/pdf.png"  class="pdf-file" alt="">
                                                        <div class="mt-1">
                                                            <a href="{{item}}" target="_blank"><span>Descargar</span></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->

<ng2-toasty></ng2-toasty>

<!-- Change status -->
<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title text-center width-100" id="modal-title">Actualizar Estatus</h4>
    </div>
    <div class="modal-body text-center">
        <ng-container *ngIf="!updateIsSuccess">
            <p class="no-mrg" *ngIf="report?.Status === 'Pendiente'">Se actualizará el estatus del reporte de
                <strong>Pendiente</strong> a <strong>En Desarrollo</strong> .
            </p>
            <p class="no-mrg" *ngIf="report?.Status === 'En desarrollo'">Se actualizará el estatus del reporte de
                <strong>En Desarrollo</strong> a <strong>Atendida</strong> .
            </p>
            <p class="no-mrg">Esta acción no se puede deshacer.</p>
        </ng-container>

        <ng-container *ngIf="updateIsSuccess">
            <p>Reporte actualizado correctamente</p>
        </ng-container>
    </div>
    <ng-container *ngIf="!updateIsSuccess">
        <div class="modal-footer">
            <a type="button" class="btn btn-outline-light" (click)="modal.close()">Cancelar</a>
            <button type="button" class="btn btn-inverse btn-primary"
                (click)="changeStatus(report?.id)">Actualizar</button>
        </div>
    </ng-container>
</ng-template>

<ng-template #contentAccept let-modal>
    <div class="modal-header">
        <h4 class="modal-title text-center width-100" id="modal-title">Aceptar reporte</h4>
    </div>
    <div class="modal-body text-center pt-4 pb-4">
        <ng-container *ngIf="acceptIsSuccess === false">
            <p class="no-mrg">Se aceptará el reporte ¿Está seguro de aceptarlo?</p>
        </ng-container>
        <!-- <ng-container *ngIf="acceptIsSuccess === true">
            <p>Reporte aceptado correctamente</p>
        </ng-container> -->
    </div>
    <ng-container>
        <div class="modal-footer">
            <button type="button" class="btn btn-inverse btn-primary"
                (click)="acceptOrRejectReport('Aceptado')">Aceptar</button>
        </div>
    </ng-container>
</ng-template>

<!-- Accept or reject report -->
<ng-template #contentReject let-modal>
    <div class="modal-header">
        <h4 class="modal-title text-center width-100" id="modal-title">Rechazar reporte</h4>
    </div>
    <div class="modal-body text-center pt-4 pb-4">
        <ng-container *ngIf="rejectIsSuccess === false">
            <p class="no-mrg">Se rechazará el siguiente reporte, por favor escriba el motivo.</p>
            <div class="col-xs-12 col-md-12 mt-4">
                <div class="form-group">
                    <textarea required="" [(ngModel)]="message" id="" cols="30" rows="5" class="form-control"
                        placeholder="Motivo del rechazo."></textarea>
                </div>
            </div>
        </ng-container>
        <!-- <ng-container *ngIf="rejectIsSuccess === true">
            <p>Reporte rechazado correctamente</p>
        </ng-container> -->
    </div>
    <ng-container>
        <div class="modal-footer" *ngIf="rejectIsSuccess === false">
            <button type="button" class="btn btn-inverse btn-danger" [disabled]="isLoading"
                (click)="acceptOrRejectReport('Rechazado')">Rechazar</button>
        </div>
    </ng-container>
</ng-template>

<ng-template #contentArea let-modal>
    <div class="modal-header">
        <h4 class="modal-title text-center width-100" id="modal-title">Asignar Reporte</h4>
    </div>
    <div class="modal-body text-center">
        <ng-container *ngIf="!assignIsSuccess">
            <p class="no-mrg">Por favor selecciona el área a la que se asignará el reporte.</p>
            <div class="col-xs-12 col-md-12 mt-2">
                <div class="form-group">
                    <ng-select [(ngModel)]="area">
                        <ng-option value="" disabled selected>Área</ng-option>
                        <ng-option *ngFor="let value of values" [value]="value">
                            {{value}}
                        </ng-option>
                    </ng-select>
                </div>
            </div>
        </ng-container>

        <!-- <ng-container *ngIf="assignIsSuccess">
      <p>Reporte asignado correctamente</p>
    </ng-container> -->
    </div>
    <ng-container *ngIf="!assignIsSuccess">
        <div class="modal-footer">
            <a type="button" class="btn btn-outline-light" (click)="modal.close()">Cancelar</a>
            <button class="btn btn-primary" [disabled]="isLoading" (click)="assignReport()">Asignar</button>
        </div>
    </ng-container>
</ng-template>

<ng-template #contentDelete let-modal>
    <div class="modal-header">
        <h4 class="modal-title text-center width-100" id="modal-title">Eliminar Reporte</h4>
    </div>
    <div class="modal-body text-center">
        <ng-container *ngIf="!deleteIsSuccess">
            <p class="no-mrg">Se eliminirán todos los datos del reporte.</p>
            <p class="no-mrg">Esta acción no se puede deshacer.</p>
        </ng-container>

        <!-- <ng-container *ngIf="deleteIsSuccess">
      <p>Reporte eliminado correctamente</p>
    </ng-container> -->
    </div>
    <ng-container *ngIf="!deleteIsSuccess">
        <div class="modal-footer">
            <a type="button" class="btn btn-outline-light" (click)="modal.close()">Cancelar</a>
            <button type="button" [disabled]="isLoading" class="btn btn-inverse btn-danger"
                (click)="eliminarReporte()">Eliminar</button>
        </div>
    </ng-container>
</ng-template>

<ng2-toasty></ng2-toasty>