<div class="container-fluid">
    <div class="row">
        <div class="col-xl-5">
            <div class="card">
                <div class="card-body">
                    <div class="profile-details">
                        <h5 class="f-w-600 mb-0">Cotización Nº. {{quotation?.id}} <span [ngClass]="{'received': quotation?.RequestStatus == 'Recibida', 'authorized': quotation?.RequestStatus == 'Atendida'}">{{quotation?.RequestStatus}}</span></h5>

                        <hr>
                        <div class="table-responsive profile-table">
                            <table class="table table-responsive">
                                <tbody>
                                    <tr>
                                        <td>Fecha de solicitud</td>
                                        <td>{{quotation?.created_at | date}}</td>
                                    </tr>
                                    <hr>
                                    <tr>
                                        <td>Producto:</td>
                                        <td>{{quotation?.Product?.Title}}</td>
                                    </tr>
                                    <tr>
                                        <td>Modelo:</td>
                                        <td>{{quotation?.Product?.Model}}</td>
                                    </tr>
                                    <tr>
                                        <td>Categoría:</td>
                                        <td>{{quotation?.Product?.Category}}</td>
                                    </tr>
                                    <tr>
                                        <td>Sub categoría:</td>
                                        <td>{{quotation?.Product?.SubCategory}}</td>
                                    </tr>
                                    <tr>
                                        <td>Familia:</td>
                                        <td>{{quotation?.Product?.Family}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-7">
            <div class="card tab2-card">
                <div class="card-body">
                    <div class="tab-pane fade show active" id="top-profile" role="tabpanel"
                        aria-labelledby="top-profile-tab">
                        <!-- <h5 class="f-w-600">Detalles</h5> -->
                        <div class="table-responsive profile-table">
                            <table class="table table-responsive">
                                <tbody>
                                    <tr>
                                        <td>Nombre cliente:</td>
                                        <td>{{quotation?.FirstName}} {{quotation?.LastName}}</td>
                                    </tr>
                                    <tr>
                                        <td>Nombre comercial:</td>
                                        <td>{{quotation?.BusinessName}}</td>
                                    </tr>
                                    <tr>
                                        <td>Correo electrónico:</td>
                                        <td>{{quotation?.Email}}</td>
                                    </tr>
                                    <tr>
                                        <td>Teléfono:</td>
                                        <td>{{quotation?.phoneNumber}}</td>
                                    </tr>
                                    <tr>
                                        <td>Método de pago:</td>
                                        <td>{{quotation?.PaymentMethod}}</td>
                                    </tr>
                                    <tr>
                                        <td>Financiamiento:</td>
                                        <td>{{quotation?.FinantialMonthQty}} meses</td>
                                    </tr>
                                    <tr>
                                        <td>Ciudad / Estado:</td>
                                        <td>{{quotation?.City}} / {{quotation?.State}}</td>
                                    </tr>
                                    <tr>
                                        <td>Mensaje:</td>
                                        <td>{{quotation?.Message}}</td>
                                    </tr>
                                </tbody>
                                <hr>
                                <tbody>
                                    <tr>
                                        <td>Respuesta de solicitud:</td>
                                        <td *ngIf="!quotation?.MessageResponse">Aún no se ha atendido la solicitud</td>
                                        <td *ngIf="quotation?.MessageResponse">{{quotation?.MessageResponse}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->
  