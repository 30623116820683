import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Subscription } from 'rxjs';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { TripsService } from '../../services/trips/trips.service';


@Component({
  selector: 'app-trips-list',
  templateUrl: './trips-list.component.html',
  styleUrls: ['./trips-list.component.scss']
})
export class TripsListComponent implements OnInit {

  query:string = '';
  queryValue:string = '';
  limit:number = 10;
  offset:number = 0;
  page:number = 1;
  total:number; 
  public trips:any;

  private subs: Array<Subscription> = [];
  private modalContent: any;
  private modalConfig: any = {
    ariaLabelledBy: 'modal-basic'
  };
  public dtOptions: DataTables.Settings = {};
  public dtTrigger: Subject<any> = new Subject();
  dtElement: any;
    userId: string;

  constructor(
    private tripsService: TripsService,
    private authService: AuthenticationService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: 10,
        destroy:true,
        language: {
          emptyTable: '',
          zeroRecords: '',
          lengthMenu: 'Mostrar _MENU_ elementos',
          search: 'Buscar:',
          info: 'De _START_ a _END_ de _TOTAL_ elementos',
          infoEmpty: 'De 0 a 0 de 0 elementos',
          infoFiltered: '(filtrados de _MAX_ elementos totales)',
          paginate: {
            first: 'Prim.',
            last: 'Últ.',
            next: 'Sig.',
            previous: 'Ant.'
          },
        },
      };
    this.userId = this.authService.currentUserValue.id;
    this.subs.push(
        this.tripsService.getTripsbyCitizen(this.userId,this.limit, this.offset).subscribe(data => {
            this.trips = data.List;
            this.total = data.Count;
            this.dtTrigger.next();
        })
    )

  }

  rerender(): void {
    this.dtElement?.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }
  
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    this.subs.forEach(sub => sub.unsubscribe());
    
  }


  setModalContent(content: any, userId: string): void {
    // this.userId = userId;
    this.modalContent = content;
    this.modalService.open(this.modalContent, this.modalConfig);
  }

  public loadPage(page: number) {
    this.subs.push(
        this.tripsService.getTripsbyCitizen(this.userId, this.limit, page-1).subscribe(data => {
            this.trips = data.List;
            this.total = data.Count;
        })
    )
  }


//   clearInputs(){
//     this.query = '';
//     this.queryValue = '';
//     this.ngOnInit();
//   }

//   getResults(){
//     this.subs.push(
        // this.tripsService.getTripsbyCitizen(this.userId).subscribe(data => {
        //     this.trips = data.List;
        //     this.total = data.Count;
        //     this.rerender();
        // })
//     )
//   }


}