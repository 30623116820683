import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { getHeaders } from 'src/app/shared/utils/headers.functions';
import { LocationApplyModel, TicketModel } from '../../tickets/tickets.models';

const BASE_URL: string = environment.API_URL;

@Injectable({
  providedIn: 'root'
})
export class TicketsService {
  constructor(
    private http: HttpClient
  ) { }

  public createTicket(ticket: TicketModel): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.post<any>(`${BASE_URL}/tickets`, ticket, { headers });
  }

  public getTickets(limit: number, offset: number): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/tickets?limit=${limit}&offset=${offset}`, { headers });
  }

  public getTicketsByStatus(limit: number, offset: number, status: string): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/tickets?limit=${limit}&offset=${offset}&Status=${status}`, { headers });
  }

  public getTicketsByUser(UserId: string | number, limit: number, offset: number): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/tickets-dealer/${UserId}?limit=${limit}&offset=${offset}`, { headers });
  }

  public getTicket(id: (number | string)): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/ticket/${id}`, { headers });
  }

  public applyTicket(ticketId: number | string, location: LocationApplyModel): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.put<any>(`${BASE_URL}/ticket-apply/${ticketId}`, location, { headers });
  }

  public deleteTicket(id: string): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.delete<any>(`${BASE_URL}/tickets/${id}`, {headers});
  }

  public uploadImg(formData: any): Observable<any> {
    return this.http.post<any>(`${BASE_URL}/uploadimg`, formData, { });
  }

  public mapRequiredValues(ticket: any): any {
    //console.log(product);
    const ticketInfo: any = {};

    ticketInfo.id = ticket.id;
    ticketInfo.EventId = ticket.EventId;
    ticketInfo.Type = ticket.Type;
    ticketInfo.Section = ticket.Section;
    ticketInfo.RowLabel = ticket.RowLabel;
    ticketInfo.ClientName = ticket.ClientName;
    ticketInfo.ClientEmail = ticket.ClientEmail;
    ticketInfo.ClientPhoneNumber = ticket.ClientPhoneNumber;
    ticketInfo.DealerId = ticket.DealerId;

    return ticketInfo;
  }
  
}
