<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <!-- <div class="card-header">
                    <h5>Agregar Maquinaria</h5>
                </div> -->
                <div class="card-body">
                    <div class="row product-adding">
                        <div class=" col-xs-12 col-xl-12">
                            <form class="auth-form" [formGroup]="ticketForm" (ngSubmit)="onSubmit()" novalidate>
                                <div class="add-product-form">
                                    <small>Detalle del evento</small><br>
                                    {{event?.EventTitle}}<br>
                                    {{event?.EventDate | date:'dd/MM/yyyy'}}<br>
                                    {{event?.EventDescription}}
                                    
                                    <hr>
                                    <small>Datos del boleto</small>
                                    <div class="row">
                                        <div class="col-xs-12 col-sm-4">
                                            <div class="form-group">
                                                <label for="validationCustom01" class="col-form-label pt-0">Tipo de boleto</label>
                                                <select class="form-control custom-select" [formControl]="ticketForm.controls['Type']"
                                                [ngClass]="{'error': submitted && formState?.Type?.errors?.required}">
                                                    <option value="">Seleccciona el tipo</option>
                                                    <option value="General">General</option>
                                                    <option value="VIP">VIP</option>
                                                    <option value="Ultra VIP">Ultra VIP</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-4">
                                            <div class="form-group">
                                                <label for="validationCustom02" class="col-form-label pt-0">Costo</label>
                                                <input class="form-control" id="validationCustom02" type="number" [formControl]="ticketForm.controls['Cost']"
                                                [ngClass]="{'error': submitted && formState?.Cost?.errors?.required}">
                                            </div>
                                        </div>
                                        <!-- <div class="col-xs-12 col-sm-4">
                                            <div class="form-group">
                                                <label for="validationCustom02" class="col-form-label pt-0">Sección</label>
                                                <input class="form-control" id="validationCustom02" type="text" [formControl]="ticketForm.controls['Section']"
                                                [ngClass]="{'error': submitted && formState?.Section?.errors?.required}">
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-4">
                                            <div class="form-group">
                                                <label for="validationCustom03" class="col-form-label pt-0">Fila</label>
                                                <input class="form-control" id="validationCustom03" type="text" [formControl]="ticketForm.controls['RowLabel']"
                                                [ngClass]="{'error': submitted && formState?.RowLabel?.errors?.required}">
                                            </div>
                                        </div> -->
                                    </div>
                                    <hr>
                                    <small>Datos del cliente</small>
                                    <div class="row">
                                        <div class="col-xs-12 col-sm-4">
                                            <div class="form-group">
                                                <label for="validationCustom04" class="col-form-label pt-0">Nombre</label>
                                                <input class="form-control" id="validationCustom04" type="text" [formControl]="ticketForm.controls['ClientName']"
                                                [ngClass]="{'error': submitted && formState?.ClientName?.errors?.required}">
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-4">
                                            <div class="form-group">
                                                <label for="validationCustom05" class="col-form-label pt-0">Correo electrónico</label>
                                                <input class="form-control" id="validationCustom05" type="text" [formControl]="ticketForm.controls['ClientEmail']"
                                                [ngClass]="{'error': submitted && formState?.ClientEmail?.errors?.required}">
                                                <label class="errorRed"
                                                    *ngIf="submitted && formState?.ClientEmail?.errors?.pattern">
                                                    Ingresa un correo válido.
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-4">
                                            <div class="form-group">
                                                <label for="validationCustom06" class="col-form-label pt-0">Teléfono</label>
                                                <input class="form-control" id="validationCustom06" type="text" maxlength="10" [formControl]="ticketForm.controls['ClientPhoneNumber']"
                                                [ngClass]="{'error': submitted && formState?.ClientPhoneNumber?.errors?.required}">
                                                <label class="errorRed"
                                                    *ngIf="submitted && formState?.ClientPhoneNumber?.errors?.pattern">
                                                    Ingresa solo 10 números.
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row mb-0">
                                    <div class="product-buttons col-sm-12 mt-3 mr-2">
                                        <button type="submit" class="btn btn-primary mright"
                                            [disabled]="isLoading">
                                            {{ isLoading ? 'Creando boleto' : 'Crear boleto'}}
                                            <i class="fa fa-spinner fa-spin"
                                            [hidden]="!isLoading"></i>
                                        </button>
                                    </div>

                                    <!-- <div class="product-buttons col-sm-8 offset-xl-4 offset-sm-4">
                                        <button type="submit" class="btn btn-primary mright" *ngIf="onEdit == true">
                                            {{ isLoading ? 'Editando evento' : 'Editar evento'}}
                                            <i class="fa fa-spinner fa-spin"
                                            [hidden]="!isLoading"></i>
                                        </button>
                                    </div> -->
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->

<ng2-toasty></ng2-toasty>