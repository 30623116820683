<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row" *ngIf="showDocuments">
        <div class="col-xl-12">
            <div class="card tab2-card">
                <div class="card-body">
                    <h5 class="f-w-600">Descargar documentos</h5>
                    <hr>
                    <div class="row">
                        <div class="col-xl-7 col-sm-7 col-xs-12 vertical-center">
                            <p>Descargar formato RET</p>
                        </div>
                        <div class="col-xl-5 col-sm-5 col-xs-12">
                            <div class="file-upload">
                                <a href="./assets/files/Formato_RET.docx" download><button class="btn btn-primary mat-btn">
                                    <i class="fa fa-cloud-download" aria-hidden="true"></i>
                                </button></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card tab2-card" *ngIf="showDocuments">
        <div class="card-body">
            <div class="row" style="margin-bottom: 2%;">
                <div class="col-xs-12 col-xl-4">
                    <h5 class="f-w-600 mb-2">Documentos validados</h5>
                </div>
                <div class="col-xs-12 col-xl-8 mt-6">
                    <div class="progress">
                        <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" [ngClass]="{'bg-danger': color == 1, 'bg-warning': color == 2, 'bg-success': color == 3}" [style.width]="percentage + '%'" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{{percentage}}%</div>
                    </div>
                </div>
            </div>
            <hr>
            <div id="batchDelete" class="">
                <div class="">
                    <table class="table table-lg table-hover row-border hover">
                        <thead>
                            <tr>
                                <th class="noborder">Documento</th>
                                <!-- <th>Validado</th> -->
                                <th class="noborder">Notas</th>
                                <th class="noborder">Cargar</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let file of user?.PersonFile" [ngClass]="{'approved': file.IsValid === true, 'change': file.IsValid === false && file.FileName !== null, 'notapproved': file.IsValid === false && file.FileName === null}">
                                <td class="noborder align-td">
                                    <u><a *ngIf="file.FileName !== null" [href]="file.FileName" target="_blank"><span class="titlefiles">{{file.FileLabel}}</span></a></u>
                                    <p *ngIf="file.FileName === null" class="titlefiles">{{file.FileLabel}}</p>
                                </td>
                                <!-- <td class="noborder">
                                    <div class="form-check t-valid">
                                        <span *ngIf="file.IsValid == true"><i class="fa fa-check-circle" aria-hidden="true"></i></span>
                                        <span *ngIf="file.IsValid == false"><i class="fa fa-times-circle" aria-hidden="true"></i></span>
                                    </div>
                                </td> -->
                                <td class="noborder align-td">
                                    <span>{{file.Notes ? file.Notes : 'Este archivo no tiene ninguna nota.'}}</span>
                                </td>
                                <td class="noborder align-td">
                                    <div *ngIf="file.FileLabel === 'Formato oficial' && file.IsValid === false">
                                        <div *ngIf="viewLoaderFormat" class="spinner-border text-danger" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                        <input type="file" class="file-input" [accept]="requiredFileType" (change)="onFileSelected($event, 'Formato oficial')" #fileFormatUpload>
                                        <div class="file-upload floatnone">
                                        <p class="green">{{FormatfileName || " "}}</p>
                                            <button [hidden]="viewFormat" class="btn btn-primary mat-btn-upload" (click)="fileFormatUpload.click()">
                                                {{file.IsValid === false && file.FileName !== null ? 'Reemplazar archivo' : 'Cargar archivo'}}
                                            </button>
                                        </div>
                                    </div>
                                    <div *ngIf="file.FileLabel === 'Identificación oficial' && file.IsValid === false">
                                        <div *ngIf="viewLoaderIdentify" class="spinner-border text-danger" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                        <input type="file" class="file-input" [accept]="requiredFileType" (change)="onFileSelected($event, 'Identificación oficial')" #fileIdentifyUpload>
                                        <div class="file-upload floatnone">
                                        <p class="green">{{IdentifyfileName || " "}}</p>
                                            <button [hidden]="viewIdentify" class="btn btn-primary mat-btn-upload" (click)="fileIdentifyUpload.click()">
                                                {{file.IsValid === false && file.FileName !== null ? 'Reemplazar archivo' : 'Cargar archivo'}}
                                            </button>
                                        </div>
                                    </div>
                                    <div *ngIf="file.FileLabel === 'Cédula fiscal' && file.IsValid === false">
                                        <div *ngIf="viewLoaderRFC" class="spinner-border text-danger" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                        <input type="file" class="file-input" [accept]="requiredFileType" (change)="onFileSelected($event, 'Cédula fiscal')" #fileRFCUpload>
                                        <div class="file-upload floatnone">
                                        <p class="green">{{RFCfileName || " "}}</p>
                                            <button [hidden]="viewRFC" class="btn btn-primary mat-btn-upload" (click)="fileRFCUpload.click()">
                                                {{file.IsValid === false && file.FileName !== null ? 'Reemplazar archivo' : 'Cargar archivo'}}
                                            </button>
                                        </div>
                                    </div>
                                    <div *ngIf="file.FileLabel === 'Acta constitutiva o Escritura pública' && file.IsValid === false">
                                        <div *ngIf="viewLoaderAct" class="spinner-border text-danger" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                        <input type="file" class="file-input" [accept]="requiredFileType" (change)="onFileSelected($event, 'Acta constitutiva o Escritura pública')" #fileActUpload>
                                        <div class="file-upload floatnone">
                                        <p class="green">{{ActfileName || " "}}</p>
                                            <button [hidden]="viewAct" class="btn btn-primary mat-btn-upload" (click)="fileActUpload.click()">
                                                {{file.IsValid === false && file.FileName !== null ? 'Reemplazar archivo' : 'Cargar archivo'}}
                                            </button>
                                        </div>
                                    </div>
                                    <div *ngIf="file.FileLabel === 'Escritura comodato' && file.IsValid === false">
                                        <div *ngIf="viewLoaderComo" class="spinner-border text-danger" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                        <input type="file" class="file-input" [accept]="requiredFileType" (change)="onFileSelected($event, 'Escritura comodato')" #fileComoUpload>
                                        <div class="file-upload floatnone">
                                        <p class="green">{{ComofileName || " "}}</p>
                                            <button [hidden]="viewComo" class="btn btn-primary mat-btn-upload" (click)="fileComoUpload.click()">
                                                {{file.IsValid === false && file.FileName !== null ? 'Reemplazar archivo' : 'Cargar archivo'}}
                                            </button>
                                        </div>
                                    </div>
                                    <div *ngIf="file.FileLabel === 'Comprobante de domicilio' && file.IsValid === false">
                                        <div *ngIf="viewLoaderVoucher" class="spinner-border text-danger" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                        <input type="file" class="file-input" [accept]="requiredFileType" (change)="onFileSelected($event, 'Comprobante de domicilio')" #fileVoucherUpload>
                                        <div class="file-upload floatnone">
                                        <p class="green">{{VoucherfileName || " "}}</p>
                                            <button [hidden]="viewVoucher" class="btn btn-primary mat-btn-upload" (click)="fileVoucherUpload.click()">
                                                {{file.IsValid === false && file.FileName !== null ? 'Reemplazar archivo' : 'Cargar archivo'}}
                                            </button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <!-- 'change': file.IsValid === false && file.FileName !== null, 'notapproved': file.IsValid === false && file.FileName === null}"> -->

    <div class="card tab2-card" *ngIf="showConstancia">
        <div class="card-body">
            <h5 class="f-w-600">Constancia</h5>
            <hr>
            <p *ngIf="percentage != 100" class="center">Tus archivos no están validados al 100%. Necesitas tener tus archivos validados en su totalidad para poder descargar tu constancia.</p>
            <div *ngIf="percentage == 100" id="batchDelete" class="">
                <div class="row">
                    <div class="col-xs-12 col-sm-6 col-md-6">
                        <div class="container">
                            <div class="row center">
                            <div id="htmlData">
                                <img [src]="[imgBackground]" class="imgBack" style="width: 100%;" alt="">
                                <div class="textCons">
                                    <h2 class="title">REGISTRO ESTATAL DE TURISMO</h2>
                                    <h3 class="subtitle">CONSTANCIA DE INSCRIPCIÓN</h3>
                                    <span class="num">Núm. </span> <span class="numberRET">{{user?.RET}}</span>
                                    <p class="estado">Estado de Morelos, {{day}} de {{month}} de {{year}}.</p>
                                    <p class="emitida">Emitida a favor de:</p>
                                    <p class="text-principal"><strong>
                                        <span class="name">{{user?.Name}}</span><br>
                                        <span class="businessName" style="font-size: 14px;">{{user?.BusinessName}}</span><br>
                                        <span class="businessName">{{user?.Address[0].Street + ' ' + user?.Address[0].ExtNumber + ', ' + 'C.P. ' + user?.Address[0].ZipCode + ','}}</span><br>
                                        <span class="businessName">{{'Col. ' + user?.Address[0].Neighborhood + ',' + user?.Address[0].State}}</span><br>
                                    </strong></p>
                                    <h4 class="prestador">Como prestador de servicios Turísticos</h4>
                                    <p class="text-principal"><span class="name">{{user?.LocalType}}</span></p>
                                    <p class="namefirma"><strong>Lic. Graciela Flores Beltrán</strong><span><img [src]="[firma]" class="imgFirma" style="width: 30%;" alt=""> </span></p>
                                    <hr class="line">
                                    <p class="direct"><strong>Directora General de competitividad y Servicios Turísticos</strong></p>
                                    <p class="text-fin">Con fundamento en lo dispuesto por los artículos 3 fracción XIX, 5, 9 fracción X, 51,52,52 bis,53,54 y 61 bis de la Ley de Turismo del Estado de Morelos, artículo 24 y 28 del Reglamento de la Ley de Turismo del Estado de Morelos</p>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-6 vertical-align">
                        <div class="product-buttons center">
                            <button  class="btn btn-primary mright"
                                [disabled]="isDownload" (click)="downloadPDF()">
                                {{ isDownload ? 'Descargando certificado' : 'Descargar certificado' }}
                                <i class="fa fa-spinner fa-spin"
                                [hidden]="!isDownload"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->

<ng2-toasty></ng2-toasty>