import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription, throwError } from 'rxjs';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ToastyService, ToastyConfig, ToastOptions } from 'ng2-toasty';
import { ActivatedRoute, Router } from '@angular/router';
import { BusinessModel } from '../../auth/auth.models';
import { UsersService } from '../../services/users/users.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ProductsService } from '../../services/products/products.service';


@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit, OnDestroy {

  public form: FormGroup;
  public formpassword: FormGroup;
  public submitted = false;
  public isLoading = false;
  public isDownload = false;
  public onEdit = false;
  public userId: number;
  public disabled = true;
  public values: any;
  public zoom: number;

  private subs: Array<Subscription> = [];
  public user: any;
  public UserId: string;

  constructor(
    private fb: FormBuilder,
    private userService: UsersService,
    private authService: AuthenticationService,
    private productService: ProductsService,
    private toastyService: ToastyService,
    private toastyConfig: ToastyConfig,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    this.toastyConfig.theme = 'bootstrap';
  }

  ngOnInit(): void {
    this.productService
    .getValuestoCatalog('Categories')
    .subscribe(data => {
        this.values = data;
    })
    this.subs.push(
        this.activatedRoute.paramMap.subscribe((data: any) => {
            this.userId = data.params.id;
            this.onEdit = true;
            this.authService.getStore(this.userId)
              .subscribe(user => this.setValues(user));
            this.form = this.fb.group({
                Name: ['', [Validators.required]],
                TaxRegime: ['', Validators.required],
                TaxId: ['', Validators.required],
                LocalType: ['', Validators.required],
                OfficePhone: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
                MobilePhone: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
                ContactEmail: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
                Description: ['', Validators.required],
            })
        })
    );
    this.activatedRoute.paramMap.subscribe((data: any) => {
        this.userId = data.params.id;
        this.onEdit = true;
        this.authService.getStore(this.userId)
            .subscribe(user => {
            this.user = user;
        });
    })
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }

  public get formState(): any {
    return this.form.controls;
  }

  private buildUserUpdate(): BusinessModel {
    return {
        Name: this.form.controls['Name'].value,
        TaxRegime: this.form.controls['TaxRegime'].value,
        TaxId: this.form.controls['TaxId'].value,
        LocalType: this.form.controls['LocalType'].value,
        OfficePhone: this.form.controls['OfficePhone'].value,
        MobilePhone: this.form.controls['MobilePhone'].value,
        ContactEmail: this.form.controls['ContactEmail'].value,
        Description: this.form.controls['Description'].value
    }
  }
  

  private setValues(user: any): void {
    const values = this.authService.mapRequiredValuesBusiness(user);
    const { controls } = this.form;

    for (const value in values) {
      if (controls.hasOwnProperty(value)) {
        this.form.controls[value].setValue(values[value]);
      }
    }
  }

  updateUser() {
    const user: BusinessModel = this.buildUserUpdate();
    this.isLoading = true;
    this.subs.push(
      this.authService.updateUserStore(this.user.id, user)
      .pipe(
      map(() => {
          this.handleUserEdit();
          setTimeout(() => {
            this.ngOnInit();
          }, 1500);
      }),
      catchError(error => this.handleError(error))
      )
      .subscribe()
    ); 
  }

  private handleUserEdit() {
    const toastOptions: ToastOptions = {
      title: 'Perfil',
      msg: 'Usuario actualizado correctamente',
      showClose: true,
      timeout: 1700
    };

    this.toastyService.success(toastOptions);
  }

  private handleError(error: any) {
    const toastOptions: ToastOptions = {
      title: 'Error',
      msg: 'No se pudo actualizar el usuario',
      showClose: true,
      timeout: 2000
    };

    this.toastyService.error(toastOptions);

    return [];
  }
}
