import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReportsService } from 'src/app/components/services/reports/reports.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import * as moment from 'moment';
import * as XLSX from 'xlsx';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reports-source',
  templateUrl: './reports-source.component.html',
  styleUrls: ['./reports-source.component.scss']
})
export class ReportsSourceComponent implements OnInit, OnDestroy {

  fileName= 'Reportes-Fuente.xlsx';

  private subs: Array<Subscription> = [];
  private modalContent: any;
  private modalConfig: any = {
    ariaLabelledBy: 'modal-basic'
  };

  public reports: any;
  public allReports: any;
  public values = [];
  public isLoading = false;
  public dtOptions: DataTables.Settings = {};
  public dtTrigger: Subject<any> = new Subject();
  public total: number;
  public page = 1;
  public limit: number = 10;
  public offset: number = 0;

  public actualDate: any;
  public status = 'Total';
  public sources = [];
  public selectedSource = '';
  public showTable: boolean = false;
  public num: number = 1;

  constructor(
    private reportService: ReportsService,
    private authService: AuthenticationService,
    private modalService: NgbModal,
    private router: Router,
  ) {
    this.actualDate = moment().tz('America/Mexico_City');
  }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      destroy:true,
      language: {
        emptyTable: '',
        zeroRecords: 'No se han encontrado resultados',
        lengthMenu: 'Mostrar _MENU_ elementos',
        search: 'Buscar:',
        info: 'De _START_ a _END_ de _TOTAL_ elementos',
        infoEmpty: 'De 0 a 0 de 0 elementos',
        infoFiltered: '(filtrados de _MAX_ elementos totales)',
        paginate: {
          first: 'Prim.',
          last: 'Últ.',
          next: 'Sig.',
          previous: 'Ant.'
        },
      },
      order: [0, 'asc']
    };
    
    this.subs.push(
        this.reportService.getReportSource().subscribe(data => {
          this.sources = data;
        })
    );
  }

  reloadTable(): void {
    this.subs.push(
      this.reportService
        .getReportsSource(this.selectedSource, this.limit, this.offset)
        .pipe(
          catchError(err => this.authService.handleUnauthorizedError(err))
        )
        .subscribe(data => {
          this.reports = data;
          this.total = data.Total
        })
    );
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    this.subs.forEach(sub => sub.unsubscribe());
  }

  exportExcel(): void {
    this.isLoading = true;
    this.subs.push(
      this.reportService
        .getReportsSource(this.selectedSource, 999999, 0)
        .pipe(
          catchError(err => this.authService.handleUnauthorizedError(err))
        )
        .subscribe(data => {
          this.allReports = data;
          setTimeout(() =>{
            let element = document.getElementById('reports-source');
            const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);
        
            const wb: XLSX.WorkBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Reportes');
          
            XLSX.writeFile(wb, this.fileName);
            this.isLoading = false;
          },2000)
        })
    );
  }

  onFilterSource(){
    this.showTable = true;
    const oldSource = this.selectedSource;
    this.reportService.getReportsSource(this.selectedSource, this.limit, this.offset).subscribe(data => {
        this.reports = data;
        this.total = data.Total;
        if (this.num === 1){
            this.dtTrigger.next();
        }
        this.num++;
    })
  }

  onFilerbyStatus(status: string): void {
    this.status = status;
    this.subs.push(
        this.reportService
            .getReportsBySource(this.selectedSource, this.limit, this.offset, status)
            .pipe(
                catchError(err => this.authService.handleUnauthorizedError(err))
            )
            .subscribe(data => {
                this.reports = data;
                this.total = data.Count;
            })
    );

}

  public loadPage(page: number) {
    if(this.selectedSource !== ''){
      this.subs.push(
        this.reportService
        .getReportsSource(this.selectedSource, this.limit, page-1)
        .pipe(
          catchError(err => this.authService.handleUnauthorizedError(err))
        )
        .subscribe(data => {
          this.reports = data;
          this.total = data.Total
        })
      ) 
    }
  }

}

