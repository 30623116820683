<div class="container-fluid" >
    <div class="card">
        <div class="card tab2-card" *ngIf="!showMap">
            <div class="card-body center">
                <div class="tcenter">
                    <img src="../../../../assets/images/logo-next.png" style="width: 60%;" alt="">
                </div>
                <ngb-tabset class="tab-coupon mb-0">
                    <ngb-tab>
                        <ng-template ngbTabContent>
                            <div class="tab-pane fade active show" id="account" role="tabpanel"
                                aria-labelledby="account-tab">
                                <div class="tcenter">
                                    <img src="../../../../assets/images/family-car.png"
                                        style="width: 35%;" alt="">
                                </div>
                                <h3 class="thankstitle">VIAJE FINALIZADO</h3>
                                <!-- <h3 class="thankstitle fs-20 pb-5">{{trip?.displayName}} ha llegado a su destino</h3> -->
                                <!-- <p class="thanks">Hemos recibido su reporte y trabajaremos en la problemática lo más pronto posible. </p> -->
                                <!-- <a [routerLink]="'/inicio'"><button class="btn btn-primary">
                                        Ir a inicio
                                    </button></a> -->
                                <!-- <p class="slogan"><strong class="morelos">MORELOS</strong> ANFITRIÓN DEL MUNDO</p> -->
                            </div>
                        </ng-template>
                    </ngb-tab>
                </ngb-tabset>
            </div>
        </div>
        <div class="card-body" *ngIf="showMap">
            <agm-map [latitude]="latitude" [longitude]="longitude" [scrollwheel]="true"
                [zoom]="zoom">
                <!-- <agm-direction [origin]="trip?.origin" [destination]="destination"></agm-direction> -->
                <agm-marker [latitude]="latitude" [longitude]="longitude"
                    [iconUrl]='{"url": "../../../../assets/images/user-pin.png","scaledSize": {"height": 55, "width": 55}}'>
                    <!-- <agm-info-window>
                        <div><strong>Pasajero: </strong>{{trip?.displayName}}</div>
                        <div><strong>Núm Económico: </strong> {{trip?.economicNumber}}</div>
                        <div><strong>Última actualización: </strong>{{trip?.updated_at | date:'dd/MM/yyyy, h:mm a'}}
                        </div>
                    </agm-info-window> -->
                </agm-marker>
            </agm-map>
            <button class="btn btn-warning tripButton" (click)="finishTrip()">Terminar Viaje</button>
        </div>
    </div>
</div>

<ng-template #angel let-modal>
    <div class="modal-header">
        <h4 class="modal-title text-center width-100 mb-3" id="modal-title"><strong>Tu Ángel de la Guarda</strong></h4>
        <div class="text-center">
            <img src="../../../../assets/images/info.gif" width="15%">
        </div>
    </div>
    <div class="modal-body text-center">
        <div class="card">
            <p>Notificamos a <strong>{{userInfo.ContactName}}</strong>, podrá ver tu ubicación
            hasta que tu viaje finalice.</p>
        </div>
    </div>
    <ng-container>
        <div class="align-self-center my-3">
            <!-- <a type="button"
        class="btn btn-outline-light"
        (click)="modal.close()">Cancelar</a> -->
            <button type="button" class="btn btn-inverse btn-primary" (click)="modal.close()">Aceptar</button>

        </div>
    </ng-container>
</ng-template>