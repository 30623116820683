import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Ng2SmartTableModule } from 'ng2-smart-table';
import { CKEditorModule } from 'ngx-ckeditor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';

import { TicketsRoutingModule } from './tickets-routing.module';

import { AddTicketComponent } from './add-ticket/add-ticket.component';
import { ListTicketsComponent } from './tickets-list/tickets-list.component';
import { ListTicketsByUserComponent } from './tickets-by-user/tickets-by-user.component';

import { GalleryModule } from '@ks89/angular-modal-gallery';
import 'hammerjs';
import 'mousetrap';

import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';

import { ToastyModule } from 'ng2-toasty';
import { DataTablesModule } from 'angular-datatables';
import { ApplyTicketComponent } from './apply-ticket/apply-ticket.component';


const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  maxFilesize: 50,
  url: 'https://httpbin.org/post',
};

@NgModule({
  declarations: [
    ListTicketsComponent,
    ListTicketsByUserComponent,
    AddTicketComponent,
    ApplyTicketComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CKEditorModule,
    TicketsRoutingModule,
    Ng2SmartTableModule,
    NgbModule,
    DropzoneModule,
    DataTablesModule,
    ToastyModule,
    NgSelectModule,
    GalleryModule.forRoot()
  ],
  providers: [
    {
      provide: DROPZONE_CONFIG,
      useValue: DEFAULT_DROPZONE_CONFIG
    },
    NgbActiveModal
  ]
})
export class TicketsModule { }
