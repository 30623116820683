import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, Subject, throwError } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { map, switchMap, catchError } from 'rxjs/operators';
import { SurveysService } from '../../services/surveys/surveys.service';


@Component({
  selector: 'app-survey-detail',
  templateUrl: './survey-detail.component.html',
  styleUrls: ['./survey-detail.component.scss']
})
export class SurveysDetailsComponent implements OnInit, OnDestroy {

  private subs: Array<Subscription> = [];
  public survey: any;

  public dtOptions: DataTables.Settings = {};
  public dtTrigger: Subject<any> = new Subject()

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private surveysService: SurveysService,
  ) {
  }


  ngOnInit() {
    this.subs.push(
        this.activatedRoute.paramMap.pipe(
          map(params => params.get('id')),
          switchMap(id => this.surveysService.getSurvey(id)),
          catchError(error => {
            this.router.navigate(['/surveys']);
            return throwError(error);
          })
        ).subscribe(survey => {
          this.survey = survey;
          this.dtTrigger.next();
        })
      );
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }

  onSubmit() {
  }

}
