<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="col-xs-12 col-md-2 pl-0 mb-4">
    <ul>
      <li class="list-filter">Total: {{total}}</li>
    </ul>
  </div>
  <div class="card">
      <div class="card-body">
      <div id="batchDelete" class="">
          <div class="table-responsive">
              <table datatable
              [dtOptions]="dtOptions"
              [dtTrigger]="dtTrigger"
              class=" table table-lg table-hover row-border hover">
              <thead>
                  <tr>
                      <th>Nº</th>
                      <th>Folio</th>
                      <!-- <th>Tiempo en espera</th> -->
                      <th>Asunto</th>
                      <th>Dependencia</th>
                      <th>Nombre ciudadano</th>
                      <th>Colonia</th>
                      <th>Status</th>
                      <th>Fecha de reporte</th>
                      <th>Fuente</th>
                      <th>Opciones</th>
                  </tr>
              </thead>
              <tbody>
                  <tr *ngFor="let report of reports?.List; let i = index">
                      <td>{{i+1}}</td>
                      <td>{{report.folio}}</td>
                      <!-- <td>{{report.timeTranscured}} horas</td> -->
                      <td>{{report.Subject}}</td>
                      <td>{{report.Department ? report.Department : 'Sin área asignada'}}</td>
                      <td>{{report.Name}}</td>
                      <td>{{report.Address.Neighborhood ? report.Address.Neighborhood : 'Sin colonia'}}</td>
                      <td>{{report.Status}}</td>
                      <td>{{report.created_at | date:'dd/MM/yyyy'}}</td>
                      <td>{{report.Source ?  report.Source : 'Invitado'}}</td>
                      <td class="overflow-hidden">
                      <div ngbDropdown container="body">
                          <button class="btn btn-outline-primary btn-sm toogle-but" ngbDropdownToggle>Opciones</button>
                          <div ngbDropdownMenu>
                          <button class="option" ngbDropdownItem [routerLink]="['show', report.id]">Ver reporte</button>
                          <button class="option" ngbDropdownItem [routerLink]="['edit', report.id]">Editar reporte</button>
                          <!-- <button class="option" ngbDropdownItem (click)="assignModalContent(contentArea, report.id)">Turnar reporte</button>  
                          <button class="option" ngbDropdownItem (click)="assignModalContent(contentDelete, report.id)">Eliminar reporte</button>   -->
                        </div>
                      </div>
                      </td>
                  </tr>
              </tbody>
              </table>
              <div class="row">
                <div class="col-4 m-auto">
                  Mostrando {{page*10}} de {{total}}
                </div> 
                <div class="col-8">
                  <ngb-pagination (pageChange)="loadPage($event)" [collectionSize]="total" [(page)]="page" [maxSize]="10" [rotate]="true" [boundaryLinks]="true"></ngb-pagination>
                </div> 
              </div>
          </div>
          <table
              class="table table-lg table-hover row-border hover d-none"
              id="reports-general">
              <thead>
                  <tr>
                      <th>No</th>
                      <th>FOLIO</th>
                      <th>ASUNTO</th>
                      <th>DESCRIPCIÓN</th>
                      <th>DEPENDENCIA</th>
                      <th>NOMBRE</th>
                      <th>COLONIA</th>
                      <th>UBICACIÓN</th>
                      <th>ENTRE CALLES</th>
                      <th>STATUS</th>
                      <th>FECHA DE CREADO</th>
                      <th>FECHA DE ATENDIDO</th>*
                      <th>MODO DE CAPTURA</th>
                      <th>TELÉFONO / CELULAR</th>
                  </tr>
              </thead>
              <tbody>
                  <tr *ngFor="let report of allReports?.List; let i = index">
                      <td>{{i+1}}</td>
                      <td>{{report.folio}}</td>
                      <td>{{report.Subject}}</td>
                      <td>{{report.Description}}</td>
                      <td>{{report.Department ? report.Department : 'Sin área asignada'}}</td>
                      <td>{{report.Name}}</td>
                      <td>{{report.Address.Neighborhood ? report.Address.Neighborhood : 'N/A'}}</td>
                      <td>{{report.Address.Street + ' ' + report.Address.ExtNumber + ' ' + report.Address.ExtNumber + ' ' + report.Address.Neighborhood + ' ' + report.Address.State + ' ' + report.Address.ZipCode}}</td>
                      <td>{{report.Address.Street1 ? report.Address.Street1 + ' y ' + report.Address.Street2 : 'N/A'}}</td>
                      <td>{{report.Status}}</td>
                      <td>{{report.created_at | date: 'dd/MM/yyyy'}}</td>
                      <td>{{report.ClosedAt | date: 'dd/MM/yyyy'}}</td>
                      <td>{{report.Source ? report.Source : 'Invitado'}}</td>
                      <td>{{report.PhoneNumber ? report.PhoneNumber : 'Sin número'}}</td>
                  </tr>
              </tbody>
          </table>
      </div>
      </div>
  </div>
  </div>
  <!-- Container-fluid Ends-->

<!-- Assign report -->
<ng-template #contentArea
  let-modal>
  <div class="modal-header">
    <h4 class="modal-title text-center width-100"
      id="modal-title">Asignar Reporte</h4>
  </div>
  <div class="modal-body text-center">
    <ng-container *ngIf="!assignIsSuccess">
      <p class="no-mrg">Por favor selecciona el área a la que se asignará el reporte.</p>
      <div class="col-xs-12 col-md-12 mt-2">
        <div class="form-group">
            <ng-select [(ngModel)]="area">
                <ng-option value="" disabled selected>Área</ng-option>
                <ng-option *ngFor="let value of values" [value]="value">
                    {{value}}
                </ng-option>
            </ng-select>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="assignIsSuccess">
      <p>Reporte asignado correctamente</p>
    </ng-container>
  </div>
  <ng-container *ngIf="!assignIsSuccess">
    <div class="modal-footer">
      <a type="button"
        class="btn btn-outline-light"
        (click)="modal.close()">Cancelar</a>
      <button 
        class="btn btn-primary"
        (click)="assignReport()">Asignar</button>
    </div>
  </ng-container>
</ng-template>


<ng-template #contentDelete
  let-modal>
  <div class="modal-header">
    <h4 class="modal-title text-center width-100"
      id="modal-title">Eliminar Reporte</h4>
  </div>
  <div class="modal-body text-center">
    <ng-container *ngIf="!deleteIsSuccess">
      <p class="no-mrg">Se eliminirán todos los datos del reporte.</p>
      <p class="no-mrg">Esta acción no se puede deshacer.</p>
    </ng-container>

    <ng-container *ngIf="deleteIsSuccess">
      <p>Reporte eliminado correctamente</p>
    </ng-container>
  </div>
  <ng-container *ngIf="!deleteIsSuccess">
    <div class="modal-footer">
      <a type="button"
        class="btn btn-outline-light"
        (click)="modal.close()">Cancelar</a>
      <button type="button"
        class="btn btn-inverse btn-danger"
        (click)="eliminarReporte()">Eliminar</button>
    </div>
  </ng-container>
</ng-template>

<ng2-toasty></ng2-toasty>