<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row" *ngIf="isAdmin">
        <div class="col-xl-12 col-xs-12">
            <div class="card tab2-card">
                <div class="bg-white card-body">
                    <h5 class="f-w-600">Cargar archivos</h5>
                    <hr>
                    <div class="row pb-30">
                        <div class="col-xl-4 col-sm-6 col-xs-12 vertical-center">
                            <p>Cargar archivo xlsx*</p>
                        </div>
                        <div class="col-xl-8 col-sm-6 col-xs-12">
                            <!-- <div *ngIf="previewFormat" class="box-input-file">
                                <img class="imgUpload" alt="" [src]="previewFormat">
                            </div> -->
                            <!-- <div *ngIf="viewLoaderFormat" class="spinner-border text-danger" role="status">
                                <span class="sr-only">Loading...</span>
                            </div> -->
                            <input type="file" class="file-input" [accept]="requiredFileType" (change)="onFormatSelected($event)" #fileFormatUpload>
                            <div class="file-upload">
                            {{FormatfileName || " "}}
                                <button class="btn btn-primary mat-btn" (click)="fileFormatUpload.click()">
                                    <i class="fa fa-cloud-upload" aria-hidden="true"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="history?.length > 0">
        <div class="col-xl-12 col-xs-12">
            <div class="card tab2-card">
                <div class="bg-white card-body">
                    <h5 class="f-w-600">Historial de archivos cargados</h5>
                    <div id="batchDelete" class="">
                        <div class="table-responsive">
                            <table datatable
                            [dtOptions]="dtOptions"
                            [dtTrigger]="dtTrigger"
                            class=" table table-lg table-hover row-border hover">
                            <thead>
                                <tr>
                                    <th>Nombre Documento</th>
                                    <th>Municipio</th>
                                    <th>Fecha de carga</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let value of history">
                                    <td>{{value.DocumentName}}</td>
                                    <td>{{value.Municipality}}</td>
                                    <td>{{value.created_at | date: 'dd/MM/yyyy'}}</td>
                                </tr>
                            </tbody>
                            </table>
                            <!-- <ngb-pagination (pageChange)="loadPageList($event)" [collectionSize]="total" [(page)]="page" [maxSize]="10" [rotate]="true" [boundaryLinks]="true"></ngb-pagination> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->

<ng2-toasty></ng2-toasty>