<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="row col-12 mb-4 mt-4">
    <div class="col-xl-5 col-xs-12 mt-2 pl-0">
      <div class="form-group">
        <input placeholder="Buscar por email, nombre o teléfono" class="form-control" id="validationCustom1" type="text"
          [(ngModel)]="textSearch">
      </div>
    </div>
    <div class="col-xl-7 col-xs-12">
      <button class="btn btn-primary mr-4" (click)="getResults()">
        Filtrar
      </button>
      <button title="Borrar filtros" class="btn btn-primary" (click)="clearInputs()">
        Borrar filtros
      </button>
    </div>
  </div>
  <div class="card">
    <div class="card-body">
      <div class="btn-popup pull-right">
        <a class="btn btn-primary" [routerLink]="['create-supervisor']"><span>Agregar Supervisor</span></a>
      </div>
      <div id="batchDelete" class="">
        <div class="table-responsive">
          <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
            class=" table table-lg table-hover row-border hover">
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Correo electrónico</th>
                <th>Teléfono</th>
                <!-- <th>Área</th> -->
                <th>Editar</th>
                <!-- <th>Acciones</th> -->
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let user of officers_list">
                <td>{{user.firstName}} {{user.lastName}}</td>
                <td>{{user.email}}</td>
                <td>{{!user.phoneNumber ? 'Sin número' : user.phoneNumber}}</td>
                <!-- <td>{{user.Area}}</td> -->
                <td>
                  <span (click)="setModalContent(content2, user.id)" title="Editar rol"
                    class="pointer" style="margin-left: 3px;">
                    <i class="fa fa-refresh"></i></span>
                  <span [routerLink]="['edit', user.id]" title="Editar usuario"
                    class="pointer">
                    <i class="fa fa-edit"></i></span>
                  <span (click)="setModalDeleteContent(content, user.id)"
                    title="Borrar usuario">
                    <i class="fa fa-trash"></i></span>
                </td>
              </tr>
            </tbody>
          </table>
          <ngb-pagination (pageChange)="loadPage($event)" [collectionSize]="total" [(page)]="page" [maxSize]="10"
            [rotate]="true" [boundaryLinks]="true"></ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Container-fluid Ends-->

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title text-center width-100" id="modal-title">Eliminar Usuario</h4>
  </div>
  <div class="modal-body text-center">
    <ng-container *ngIf="!deleteIsSuccess">
      <p class="no-mrg">Se eliminirán todos los datos del usuario.</p>
      <p class="no-mrg">Esta acción no se puede deshacer.</p>
    </ng-container>
  </div>
  <ng-container *ngIf="!deleteIsSuccess">
    <div class="modal-footer">
      <a type="button" class="btn btn-outline-light" (click)="modal.close()">Cancelar</a>
      <button type="button" class="btn btn-inverse btn-danger" (click)="deleteUser()">Eliminar</button>
    </div>
  </ng-container>
</ng-template>

<ng-template #content2 let-modal>
  <div class="modal-header">
    <h4 class="modal-title text-center width-100" id="modal-title">Editar rol</h4>
  </div>
  <div class="modal-body text-center">
    <ng-container *ngIf="!editIsSuccess">
      <div class="form-group">
        <h5>Rol:</h5>
        <ng-select [(ngModel)]="profile">
          <ng-option *ngFor="let item of catalogs" [value]="item">
            <span *ngIf="item === 'superadmin'">Super administrador</span>
            <span *ngIf="item === 'admin'">Administrador</span>
            <span *ngIf="item === 'supervisor'">Supervisor</span>
            <span *ngIf="item === 'funcionario'">Funcionario</span>
            <span *ngIf="item === 'user'">Usuario</span>
          </ng-option>
        </ng-select>
      </div>
      <div class="form-group" *ngIf="profile === 'funcionario'">
        <h5>Área:</h5>
        <ng-select [(ngModel)]="area">
          <ng-option *ngFor="let department of departments" [value]="department">
            {{department}}
          </ng-option>
        </ng-select>
      </div>
    </ng-container>
  </div>

  <ng-container *ngIf="!editIsSuccess">
    <div class="modal-footer">
      <a type="button" class="btn btn-outline-light" (click)="modal.close()">Cancelar</a>
      <button type="button" class="btn btn-inverse btn-primary" (click)="updateRol()">Editar</button>
    </div>
  </ng-container>
</ng-template>

<ng2-toasty></ng2-toasty>