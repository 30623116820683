import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, throwError } from 'rxjs';
import { catchError } from 'rxjs/internal/operators';
import { AuthenticationService } from 'src/app/components/services/auth/authentication.service';
import { ProductsService } from 'src/app/components/services/products/products.service';
import { productDB } from 'src/app/shared/tables/product-list';

@Component({
  selector: 'app-products-by-user',
  templateUrl: './products-by-user.component.html',
  styleUrls: ['./products-by-user.component.scss']
})
export class ProductsByUserComponent implements OnInit {

  public product_list = []
  public families: any;
  public totalProducts: number;
  private subs: Array<Subscription> = [];
  private modalContent: any;
  private modalConfig: any = {
    ariaLabelledBy: 'modal-basic'
  };

  public selectedFamily: string;
  public productDeleteSuccess = false;
  public productId: number;

  public isCollapsed = true;
  public rol: string;

  constructor(
    private productService: ProductsService,
    private authService: AuthenticationService,
    private modalService: NgbModal,
    private router: Router,

  ) {
    // this.product_list = productDB.product;
    this.rol = this.authService.currentUserValue.roles;
  }

  ngOnInit() {
    this.subs.push(
      this.productService
        .getEventsbyUser(this.authService.currentUserValue.id)
        .pipe(
          catchError(err => this.authService.handleUnauthorizedError(err))
        )
        .subscribe(data => {
          this.product_list = data;
          this.totalProducts = this.product_list.length;
        })
    );
  }

  public setModalContent(content: any, productId: number): void {
    this.productId = productId;
    this.modalContent = content;
    this.modalService.open(this.modalContent, this.modalConfig);
  }

  public setModalContentTickets(content: any, productId: number): void {
    this.productId = productId;
    this.modalContent = content;
    this.modalService.open(this.modalContent, this.modalConfig);
  }

  public deleteProduct() : void {
    this.subs.push(
      this.productService.deleteEvent(this.productId)
        .subscribe(resolve => {
          if (resolve.message === 'Evento cancelado correctamente') {
            this.productDeleteSuccess = true;
            setTimeout(() => {
              this.modalService.dismissAll();
              this.ngOnInit();
            }, 1500);
          }
        })
    );
  }

  public addTickets(id): void {
    this.router.navigate([`tickets/add-tickets/${id}`]);
  }

}
