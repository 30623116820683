import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListUserComponent } from './list-user/list-user.component';
import { CreateUserComponent } from './create-user/create-user.component';
import { UserDetailsComponent } from './user-detail/user-detail.component';
import { ListAdminComponent } from './list-admin/list-admin.component';
import { ListInterviewerComponent } from './list-interviewers/list-interviewers.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { UserCertificatesComponent } from './certificates-user/certificates-user.component';
import { FilesAdminComponent } from './files-admin/files-admin.component';
import { EditUserComponent } from './edit-user/edit-user.component';
import { ListSupervisorsComponent } from './list-supervisors/list-supervisors.component';
import { ListNeighborhoodComponent } from './list-neighborhood/list-neighborhood.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: ListUserComponent,
        data: {
          title: "Ciudadanos",
          breadcrumb: "Ciudadanos"
        }
      },
      {
        path: 'neighborhood',
        component: ListNeighborhoodComponent,
        data: {
          title: "Jefes de manzana",
          breadcrumb: "Jefes de manzana"
        }
      },
      {
        path: 'business',
        component: ListUserComponent,
        data: {
          title: "Registro estatal",
          breadcrumb: "Registro estatal"
        }
      },
      {
        path: 'business/show/:id',
        component: UserDetailsComponent
      },
      {
        path: 'business/certificates/:id',
        component: UserCertificatesComponent,
        data: {
          title: "Historial de certificados",
          breadcrumb: "Historial de certificados"
        }
      },
      {
        path: 'business/edit/:id',
        component: EditUserComponent,
        data: {
          title: "Editar establecimiento",
          breadcrumb: "Editar establecimiento"
        }
      },
      {
        path: 'business/documents/:id',
        component: FilesAdminComponent,
        data: {
          title: "Cargar certificados",
          breadcrumb: "Cargar certificados"
        }
      },

      {
        path: 'admins',
        component: ListAdminComponent,
        data: {
          title: "Administradores",
          breadcrumb: "Administradores"
        }
      },
      {
        path: 'admins/create-admin',
        component: CreateUserComponent,
        data: {
          title: "Agregar administrador",
          breadcrumb: "Agregar administrador"
        }
      },
      {
        path: 'admins/edit/:id',
        component: CreateUserComponent,
        data: {
          title: "Editar administrador",
          breadcrumb: "Editar administrador"
        }
      },
      {
        path: 'dealers',
        component: ListInterviewerComponent,
        data: {
          title: "Distribuidores",
          breadcrumb: "Distribuidores"
        }
      },
      {
        path: 'dealers/create-dealer',
        component: CreateUserComponent,
        data: {
          title: "Agregar distribuidor",
          breadcrumb: "Agregar distribuidor"
        }
      },
      {
        path: 'dealers/edit/:id',
        component: CreateUserComponent,
        data: {
          title: "Editar distribuidor",
          breadcrumb: "Editar distribuidor"
        }
      },
      {
        path: 'supervisors',
        component: ListSupervisorsComponent,
        data: {
          title: "Supervisores",
          breadcrumb: "Supervisores"
        }
      },
      {
        path: 'supervisors/create-supervisor',
        component: CreateUserComponent,
        data: {
          title: "Agregar supervisor",
          breadcrumb: "Agregar supervisor"
        }
      },
      {
        path: 'supervisors/edit/:id',
        component: CreateUserComponent,
        data: {
          title: "Editar supervisor",
          breadcrumb: "Editar supervisor"
        }
      },
      {
        path: 'show/:id',
        component: UserDetailsComponent
      },
      {
        path: 'edit/:id',
        component: CreateUserComponent
      },
      {
        path: 'mi-perfil',
        component: UserProfileComponent,
        data: {
          title: "Mi perfil",
          breadcrumb: "Mi perfil"
        }
      },
      {
        path: 'documentos',
        component: UserProfileComponent,
        data: {
          title: "Mis documentos",
          breadcrumb: "Mis documentos"
        }
      },
      {
        path: 'constancias',
        component: UserProfileComponent,
        data: {
          title: "Mis certificados",
          breadcrumb: "Mis certificados"
        }
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UsersRoutingModule { }
