<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row" *ngIf="isAdmin">
        <div class="col-xl-12">
            <div class="card tab2-card">
                <div class="bg-white card-body">
                    <h5 class="f-w-600">Municipios</h5>
                    <hr>
                    <div class="pb-30">
                        <ng-select name="Municipality" id="Municipality" [(ngModel)]="selectedMunicipality" (change)="viewData()" [ngModelOptions]="{standalone: true}">
                            <ng-option value="" disabled selected>Selecciona el municipio</ng-option>
                            <ng-option *ngFor="let value of municipalities" [value]="value.ValueAlpha">
                                {{value.ValueAlpha}}
                            </ng-option>
                        </ng-select>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="card contents" *ngIf="countHistory > 0">
        <div class="card-body bg-white">
            <div id="batchDelete" class="">
                <div class="table-responsive">
                    <table datatable
                    [dtOptions]="dtOptions"
                    [dtTrigger]="dtTrigger"
                    class=" table table-lg table-hover row-border hover">
                    <thead>
                        <tr>
                            <th>Fecha</th>
                            <th>Huéspedes extranjeros</th>
                            <th>Huéspedes por noche</th>
                            <th>Huéspedes locales</th>
                            <th>Habitaciones</th>
                            <th>Precio promedio por habitación</th>
                            <th>Hecha por</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of history?.Items">
                            <td>{{item.created_at | date:'dd/MM/yyyy'}}</td>
                            <td>{{item.ForeignGuest}}</td>
                            <td>{{item.GuestNight}}</td>
                            <td>{{item.LocalGuest}}</td>
                            <td>{{item.RoomQty}}</td>
                            <td>{{item.RoomPriceAvg | currency}}</td>
                            <td>{{item.Interviewer.displayName}}</td>
                        </tr>
                    </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <div class="row mt-5" *ngIf="countHistory > 0">
        <div class="col-xs-12 col-sm-3">
            <div class="card">
                <div class="card-body alt">
                    <div class="media static-top-widget row">
                        <div class="icons-widgets col-4">
                            <div class="align-self-center text-center">
                                <i class="fa fa-address-book" aria-hidden="true"></i>
                            </div>
                        </div>
                        <div class="media-body col-8">
                            <p class="prom-text">Promedio de huéspedes extranjeros</p>
                            <span class="m-0"><strong>{{ForeignGuestAvg}}%</strong></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xs-12 col-sm-3">
            <div class="card">
                <div class="card-body alt">
                    <div class="media static-top-widget row">
                        <div class="icons-widgets col-4">
                            <div class="align-self-center text-center">
                                <i class="fa fa-moon-o" aria-hidden="true"></i>
                            </div>
                        </div>
                        <div class="media-body col-8">
                            <p class="prom-text">Promedio de huéspedes por noche</p>
                            <span class="m-0"><strong>{{GuestNightAvg}}%</strong></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xs-12 col-sm-3">
            <div class="card">
                <div class="card-body alt">
                    <div class="media static-top-widget row">
                        <div class="icons-widgets col-4">
                            <div class="align-self-center text-center">
                                <i class="fa fa-address-card" aria-hidden="true"></i>
                            </div>
                        </div>
                        <div class="media-body col-8">
                            <p class="prom-text">Promedio huéspedes nacionales</p>
                            <span class="m-0"><strong>{{LocalGuestAvg}}%</strong></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xs-12 col-sm-3">
            <div class="card">
                <div class="card-body alt">
                    <div class="media static-top-widget row">
                        <div class="icons-widgets col-4">
                            <div class="align-self-center text-center">
                                <i class="fa fa-bed" aria-hidden="true"></i>
                            </div>
                        </div>
                        <div class="media-body col-8">
                            <p class="prom-text">Precio promedio por habitación</p>
                            <span class="m-0"><strong>{{RoomPriceAvg | currency}}</strong></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->

<ng2-toasty></ng2-toasty>