import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataTablesModule } from 'angular-datatables';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import { Ng2SmartTableModule } from 'ng2-smart-table';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ToastyModule } from 'ng2-toasty';

import { SalesRoutingModule } from './sales-routing.module';
import { OrdersComponent } from './orders/orders.component';
import { TransactionsComponent } from './transactions/transactions.component';
import { QuotationsComponent } from './quotations/quotations.component';
import { OrdersDetailsComponent } from './orders/order-detail/order-detail.component';
import { ServicesComponent } from './services/services.component';
import { AddServiceComponent } from './services/add-service/add-service.component';
import { TransactionsDetailsComponent } from './transactions/transaction-detail/transaction-detail.component';
import { QuotationsDetailsComponent } from './quotations/quotation-detail/quotation-detail.component';
import { ServiceDetailComponent } from './services/service-detail/service-detail.component';

@NgModule({
  declarations: [
    OrdersComponent,
    OrdersDetailsComponent,
    TransactionsComponent,
    QuotationsComponent,
    QuotationsDetailsComponent,
    ServicesComponent,
    ServiceDetailComponent,
    AddServiceComponent,
    TransactionsDetailsComponent
  ],
  imports: [
    CommonModule,
    DataTablesModule,
    SalesRoutingModule,
    Ng2SmartTableModule,
    NgxDatatableModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    ToastyModule
  ],
  bootstrap: [OrdersDetailsComponent, QuotationsDetailsComponent]
})
export class SalesModule { }
