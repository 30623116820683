<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="card">
        <div class="card-body">
            <div id="batchDelete" class="">
                <div class="table-responsive">
                    <table datatable
                    [dtOptions]="dtOptions"
                    [dtTrigger]="dtTrigger"
                    class=" table table-lg table-hover row-border hover">
                    <thead>
                        <tr>
                        <th>Nº</th>
                        <th>Título</th>
                        <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let survey of surveys">
                        <td>{{survey.id}}</td>
                        <td>{{survey.Title}}</td>
                        <td class="actions-cell">
                            <span class="border-r">
                            <a [routerLink]="['show', survey.id]">
                                Ver
                            </a>
                            </span>
                            <!-- <span class="border-r">
                            <a [routerLink]="['results', survey.id]">
                                Resultados
                            </a>
                            </span> -->
                            <span class="share">
                            <a (click)="setModalQR(contentqr, survey.uuids)" title="Ver QR" >
                                Compartir QR
                            </a>
                            </span>
                        </td>
                        </tr>
                    </tbody>
                    </table>
                    <ngb-pagination (pageChange)="loadPage($event)" [collectionSize]="10" [(page)]="page" [maxSize]="10" [rotate]="true" [boundaryLinks]="true"></ngb-pagination>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->

<ng-template #contentqr
  let-modal>
  <div class="modal-header">
    <h4 class="modal-title text-center width-100"
      id="modal-title">Compartir QR</h4>
  </div>
  <div class="modal-body text-center">
    <qrcode [qrdata]="CodeQR" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
    <a [href]="CodeQR" target="_blank">{{CodeQR}}</a>
  </div>
  <ng-container>
    <div class="modal-footer">
      <a type="button"
        class="btn btn-outline-light"
        (click)="modal.close()">Cerrar</a>
    </div>
  </ng-container>
</ng-template>