import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription, throwError } from 'rxjs';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ToastyService, ToastyConfig, ToastOptions } from 'ng2-toasty';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationType } from '../../interfaces/notification.enums';
import { BusinessModel } from '../../auth/auth.models';
import { UsersService } from '../../services/users/users.service';
import { DomSanitizer } from '@angular/platform-browser';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import * as moment from 'moment-timezone';
import { ProductsService } from '../../services/products/products.service';


@Component({
  selector: 'app-files-admin',
  templateUrl: './files-admin.component.html',
  styleUrls: ['./files-admin.component.scss']
})
export class FilesAdminComponent implements OnInit, OnDestroy {

  public form: FormGroup;
  public formpassword: FormGroup;
  public submitted = false;
  public isLoading = false;
  public isDownload = false;
  public onEdit = false;
  public userId: number;
  public disabled = true;
  public imgBackground: string;
  public firma: string;
  public day: string;
  public month: string;
  public year: string;

  private subs: Array<Subscription> = [];
  public user: any;
  public UserId: string;
  public requiredFileType: string;

  public fileName = '';
  public viewLoaderFile = false;
  public responseFile: string;
  public previewFile: any;

   /*Archivos*/
  public FormatfileName = '';
  public viewLoaderFormat = false;
  public viewFormat = false;
  public responseFormat: string;
  public previewFormat: any;

  public IdentifyfileName = '';
  public viewLoaderIdentify = false;
  public viewIdentify = false;
  public responseIdentify: string;
  public previewIdentify: any;

  public RFCfileName = '';
  public viewLoaderRFC = false;
  public viewRFC = false;
  public responseRFC: string;
  public previewRFC: any;

  public ActfileName = '';
  public viewLoaderAct = false;
  public viewAct = false;
  public responseAct: string;
  public previewAct: any;

  public ComofileName = '';
  public viewLoaderComo = false;
  public viewComo = false;
  public responseComo: string;
  public previewComo: any;

  public VoucherfileName = '';
  public viewLoaderVoucher = false;
  public viewVoucher = false;
  public responseVoucher: string;
  public previewVoucher: any;

  public validate: any;
  public porcentage: number;
  public color: number;

  public url: string;
  public values: any;
  public zoom: number;

  public showDocuments = true;
  public showConstancia = true;
  public percentage: number;

  constructor(
    private fb: FormBuilder,
    private userService: UsersService,
    private authService: AuthenticationService,
    private productService: ProductsService,
    private toastyService: ToastyService,
    private toastyConfig: ToastyConfig,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private sanitizer: DomSanitizer,
  ) {
    this.toastyConfig.theme = 'bootstrap';
  }

  ngOnInit(): void {
    this.url = this.router.url;

    this.imgBackground = '/assets/images/constancia.png'
    this.firma = '/assets/images/Firma.png'
    this.UserId = this.authService.currentUserValue.id
    this.day = moment().format('D');
    this.month = moment().locale('es').format('MMMM');
    this.month = this.month.charAt(0).toUpperCase() + this.month.slice(1);
    this.year = moment().format('YYYY');

    this.subs.push(
      this.activatedRoute.paramMap.pipe(
          map(params => params.get('id')),
          switchMap(id => this.authService.getStore(id)),
          catchError(error => {
            this.router.navigate(['/users/business']);
            return throwError(error);
          })
        )
        .subscribe(user => {
        this.user = user;
        this.percentage = Number(this.user.Percentage)
        if(this.percentage<= 40){
          this.color = 1;
        }else if(this.percentage> 40 && this.percentage< 99){
          this.color = 2;
        }else{
          this.color = 3;
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }

  onFileSelected(event, name) {
    const file:File = event.target.files[0];

    if(file.type == 'application/pdf' || file.type === 'image/jpeg' || file.type === 'image/png'){
      if(name == 'Formato oficial'){
        this.viewLoaderFormat = true;
        this.viewFormat = true;
        setTimeout(() => {
          if (file) {
            const formData = new FormData();
            formData.append('FileLabel', name);
            formData.append('FileName' , file);
            this.subs.push(
              this.userService.uploadFile(formData, this.user.id)
                .subscribe(resolve => {
                  //console.log(resolve);
                  if (resolve.Message != "Este archivo ya se encuentra aprobado") {
                    this.responseFile = resolve;
                    this.viewLoaderFormat = false;
                    this.previewFormat = "assets/images/pdf.png";
                    this.FormatfileName = 'Archivo cargado correctamente';
                    this.ngOnInit();
                  }else{
                    this.FileValidate();
                  }
                })
            );
          }
        }, 1800);
      }else if (name == 'Identificación oficial') {
        this.viewLoaderIdentify = true;
        this.viewIdentify = true;
        setTimeout(() => {
          if (file) {
            const formData = new FormData();
            formData.append('FileLabel', name);
            formData.append('FileName' , file);
            this.subs.push(
              this.userService.uploadFile(formData, this.user.id)
                .subscribe(resolve => {
                  if (resolve.Message != "Este archivo ya se encuentra aprobado") {
                    this.responseFile = resolve;
                    this.viewLoaderIdentify = false;
                    this.previewIdentify = "assets/images/pdf.png";
                    this.IdentifyfileName = 'Archivo cargado correctamente';
                    this.ngOnInit();
                  }else{
                    this.FileValidate();
                  }
                })
            );
          }
        }, 1800);
      }else if (name == 'Cédula fiscal') {
        this.viewLoaderRFC = true;
        this.viewRFC = true;
        setTimeout(() => {
          if (file) {
            const formData = new FormData();
            formData.append('FileLabel', name);
            formData.append('FileName' , file);
            this.subs.push(
              this.userService.uploadFile(formData, this.user.id)
                .subscribe(resolve => {
                  if (resolve.Message != "Este archivo ya se encuentra aprobado") {
                    this.responseFile = resolve;
                    this.viewLoaderRFC = false;
                    this.previewRFC = "assets/images/pdf.png";
                    this.RFCfileName = 'Archivo cargado correctamente';
                    this.ngOnInit();
                  }else{
                    this.FileValidate();
                  }
                })
            );
          }
        }, 1800);
      }else if (name == 'Acta constitutiva o Escritura pública') {
        this.viewLoaderAct = true;
        this.viewAct = true;
        setTimeout(() => {
          if (file) {
            const formData = new FormData();
            formData.append('FileLabel', name);
            formData.append('FileName' , file);
            this.subs.push(
              this.userService.uploadFile(formData, this.user.id)
                .subscribe(resolve => {
                  if (resolve.Message != "Este archivo ya se encuentra aprobado") {
                    this.responseFile = resolve;
                    this.viewLoaderAct = false;
                    this.previewAct = "assets/images/pdf.png";
                    this.ActfileName = 'Archivo cargado correctamente';
                    this.ngOnInit();
                  }else{
                    this.FileValidate();
                  }
                })
            );
          }
        }, 1800);
      }else if (name == 'Escritura comodato') {
        this.viewLoaderComo = true;
        this.viewComo = true;
        setTimeout(() => {
          if (file) {
            const formData = new FormData();
            formData.append('FileLabel', name);
            formData.append('FileName' , file);
            this.subs.push(
              this.userService.uploadFile(formData, this.user.id)
                .subscribe(resolve => {
                  if (resolve.Message != "Este archivo ya se encuentra aprobado") {
                    this.responseFile = resolve;
                    this.viewLoaderComo = false;
                    this.previewComo = "assets/images/pdf.png";
                    this.ComofileName = 'Archivo cargado correctamente';
                    this.ngOnInit();
                  }else{
                    this.FileValidate();
                  }
                })
            );
          }
        }, 1800);
      }else{
        this.viewLoaderVoucher = true;
        this.viewVoucher = true;
        setTimeout(() => {
          if (file) {
            const formData = new FormData();
            formData.append('FileLabel', name);
            formData.append('FileName' , file);  
            this.subs.push(
              this.userService.uploadFile(formData, this.user.id)
                .subscribe(resolve => {
                  if (resolve.Message != "Este archivo ya se encuentra aprobado") {
                    this.responseFile = resolve;
                    this.viewLoaderVoucher = false;
                    this.previewVoucher = "assets/images/pdf.png";
                    this.VoucherfileName = 'Archivo cargado correctamente';
                    this.ngOnInit();
                  }else{
                    this.FileValidate();
                  }
                })
            );
          }
        }, 1800);
      }

    }else{
      this.NoTypeFile()
    }
  }

  downloadPDF(){
    const DATA = document.getElementById('htmlData');
    this.isDownload = true;
    
    const options = {
      scale: 3
    };

    html2canvas(DATA, options).then((canvas) => {

      const img = canvas.toDataURL("image/png");
      const doc = new jsPDF({
        orientation:'landscape',
        format: [1281, 988]
      });

      // Add font
      const myFont = 'assets/fonts/ZonaPro-Thin.otf' // load the *.ttf font file as binary string

      // add the font to jsPDF
      doc.addFileToVFS("ZonaPro-Thin.otf", myFont);
      doc.addFont("ZonaPro-Thin.otf", "ZonaPro", "normal");

      // Add image Canvas to PDF
      const bufferX = 0;
      const bufferY = 0;
      const imgProps = (doc as any).getImageProperties(img);
      const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      doc.addImage(img, 'PNG', bufferX, bufferY, pdfWidth, pdfHeight);
      return doc;
    }).then((docResult) => {
      //docResult.save(`${new Date().toISOString()}_tutorial.pdf`);
      docResult.save(`constancia.pdf`);
    });

    setTimeout(() =>{
      this.isDownload = false;
    }, 2000)
  }

  private NoTypeFile() {
    const toastOptions: ToastOptions = {
      title: 'Error',
      msg: 'Debes subir un archivo de tipo pdf, png o jpg',
      showClose: true,
      timeout: 1700
    };

    this.toastyService.error(toastOptions);
  }

  private FileValidate() {
    const toastOptions: ToastOptions = {
      title: 'Error',
      msg: 'No puedes subir otro archivo, ya ha sido validado.',
      showClose: true,
      timeout: 1700
    };

    this.toastyService.warning(toastOptions);
  }

}
