<div class="container-fluid">
    <div class="row" *ngIf="certificates?.length === 0">
        <div class="col-xl-12">
            <div class="card">
                <div class="card-body">
                    <div class="profile-details text-center">
                        <p class="mb-3">El establecimiento no cuenta con ningún certificado actualmente.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="certificates?.length !== 0">
        <div class="col-xl-4" *ngFor="let certificate of certificates">
            <div class="card" [ngClass]="{'active': certificate.Active === true, 'false': certificate.Active === false}">
                <div class="card-body">
                    <div class="profile-details text-center">
                        <h5 class="f-w-600 mb-0">{{certificate?.Name}}</h5>
                        <br>
                        <p class="mb-2"><strong>Fecha de inicio:</strong> {{certificate?.ValidStartDate | date: 'dd/MM/yyyy'}}</p>
                        <p class="mb-2"><strong>Fecha de fin:</strong> {{certificate?.ValidEndDate | date: 'dd/MM/yyyy'}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->