<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-xl-12 col-xs-12">
            <label>SELECCIONA UN PERIODO DE FECHAS</label>
            <div class="row">
                <div class="col-xl-4 col-xs-12">
                    <div class="form-group">
                        <input class="form-control" id="validationCustom1" type="date" [(ngModel)]="startDate">
                    </div>
                </div>
                <div class="col-xl-4 col-xs-12">
                    <div class="form-group">
                        <input class="form-control" id="validationCustom2" type="date" [(ngModel)]="endDate">
                    </div>
                </div>
                <div class="col-xl-4 col-xs-12">
                    <button [hidden]="!endDate || hideButton" class="btn btn-primary" (click)="getResults()">
                        Filtrar
                    </button>
                    <button [hidden]="!showClean" title="Borrar filtros" class="btn btn-primary" (click)="clearInputs()">
                        Borrar filtros
                    </button>
                    <button [hidden]="!showClean" title="Exportar reporte" class="btn btn-primary mt-3 mb-3" (click)="generateReport()">
                        Exportar reporte
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="card" *ngIf="!showTable">
        <div class="card-body">
            <p class="text-center">Por favor seleccione las fechas deseadas para su reporte.</p>
        </div>
    </div>
    <div class="card" *ngIf="showTable">
        <div class="card-body">
            <div id="batchDelete" class="">
                <div class="table-responsive">
                    <table datatable
                    [dtOptions]="dtOptions"
                    [dtTrigger]="dtTrigger"
                    class=" table table-lg table-hover row-border hover">
                    <thead>
                        <tr>
                            <th>Área o dirección</th>
                            <th>Pendiente</th>
                            <th>En desarrollo</th>
                            <th>Atendida</th>
                            <th>Pendiente de atención</th>
                            <th>Total</th>
                            <th>Porcentaje</th>
                            <!-- <th *ngFor="let item of reports[0]?.List">
                                {{item.Status}}
                            </th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let report of reports">
                            <td>{{report.Department}}</td>
                            <td *ngFor="let item of report.List">
                                {{item.Count}}
                            </td>
                        </tr>
                    </tbody>
                    </table>
                    <ngb-pagination (pageChange)="loadPage($event)" [collectionSize]="total" [(page)]="page" [maxSize]="10" [rotate]="true" [boundaryLinks]="true"></ngb-pagination>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->