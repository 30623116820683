<div class="container-fluid">
    <div class="row">
        <div class="col-xl-5">
            <div class="card">
                <div class="card-body">
                    <div class="profile-details text-center">
                        <img src="assets/images/dashboard/profile-user.png" alt=""
                            class="img-fluid img-90 rounded-circle blur-up lazyloaded">
                        <h5 class="f-w-600 mb-0">{{user?.Name}}</h5>
                        <br>
                        <p class="mb-2"><strong>RFC:</strong> {{user?.TaxId}}</p>
                        <p class="mb-2"><strong>Régimen fiscal:</strong> {{user?.TaxRegime}}</p>
                        <hr>
                        <p class="mb-2"><strong>Correo electrónico:</strong> {{user?.ContactEmail}}</p>
                        <p class="mb-2"><strong>Móvil:</strong> {{user?.MobilePhone}}</p>
                        <p class="mb-2"><strong>Oficina:</strong> {{user?.OfficePhone}}</p>
                        <hr>
                        <p class="mb-2"><strong>Dirección:</strong> {{user?.Address[0].Street + ' ' + user?.Address[0].ExtNumber + ' ' + user?.Address[0].Neighborhood + ' ' + user?.Address[0].State + ' ' + user?.Address[0].City + ' ' + user?.Address[0].ZipCode}}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-7">
            <div class="card tab2-card" *ngFor="let address of user?.Address">
                <div class="card-body">
                    <div class="tab-pane fade show active" id="top-profile" role="tabpanel"
                        aria-labelledby="top-profile-tab">
                        <h5 class="f-w-600">{{address.Alias}}</h5>
                        <div class="table-responsive profile-table mb-20">
                            <p>{{address.Street}} {{address.ExtNumber}} {{address.IntNumber}} {{address.Neighborhood}} {{address.ZipCode}}, {{address.State}}, {{address.City}}</p>
                        </div>
                        <agm-map [latitude]="address.Location.lat" [longitude]="address.Location.lng" [scrollwheel]="true" [zoom]="zoom">
                            <agm-marker [latitude]="address.Location.lat" [longitude]="address.Location.lng"></agm-marker>
                        </agm-map>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-xl-12">
            <div class="card">
                <div class="card-body">
                    <div class="row" style="margin-bottom: 2%;">
                        <div class="col-xs-12 col-xl-4">
                            <h5 class="f-w-600 mb-2">Documentos cargados</h5>
                        </div>
                        <div class="col-xs-12 col-xl-8 mt-6">
                            <div class="progress">
                                <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" [ngClass]="{'bg-danger': color == 1, 'bg-warning': color == 2, 'bg-success': color == 3}" [style.width]="percentage + '%'" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{{percentage}}%</div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div id="batchDelete" class="">
                        <div class="">
                            <table class="table table-lg table-hover row-border hover">
                                <thead>
                                    <tr>
                                        <th class="noborder">Documento</th>
                                        <!-- <th class="noborder">Ver documento</th> -->
                                        <th class="noborder center">¿Validar?</th>
                                        <th class="noborder">Validado</th>
                                        <th class="noborder center">Mensaje</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let file of user?.PersonFile" [ngClass]="{'approved': file.IsValid === true, 'change': file.IsValid === false && file.Notes !== null, 'notapproved': file.IsValid === false}">
                                        <td class="noborder align-td">
                                            <a *ngIf="file.FileName !== null" [href]="file.FileName" target="_blank"><span class="titlefiles">{{file.FileLabel}}</span></a>
                                            <p *ngIf="file.FileName === null" class="titlefiles">{{file.FileLabel}}</p>
                                        </td>
                                        <!-- <td class="noborder align-td">
                                            <a [href]="file.FileName" target="_blank"><img src="/assets/images/pdf.png" alt="" class="resize blur-up lazyloaded"> <span class="titlefiles">{{file.FileLabel}}</span></a>
                                        </td> -->
                                        <td class="noborder center">
                                            <div class="form-check t-valid" *ngIf="file.IsValid == false">
                                                <input class="form-check-input" type="checkbox" id="flexCheckDefault" value="file.IsValid" [(ngModel)]="file.check" (click)="addFile(file)">
                                            </div>
                                        </td>
                                        <td class="noborder">
                                            <div class="form-check t-valid">
                                                <span *ngIf="file.IsValid"><i class="fa fa-check-circle" aria-hidden="true"></i></span>
                                                <span *ngIf="!file.IsValid"><i class="fa fa-times-circle" aria-hidden="true"></i></span>
                                            </div>
                                        </td>
                                        <td class="noborder center align-td">
                                            <button class="btn" type="button" (click)="setModalContent(content, file.id)" data-original-title="" title="">
                                                <i class="fa fa-commenting" aria-hidden="true"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="product-buttons col-sm-8 offset-xl-4 offset-sm-4">
                            <button class="btn btn-primary mright"
                                [disabled]="isLoading" (click)="updateFiles()">
                                {{ isLoading ? 'Validando documentos' : 'Validar documentos'}}
                                <i class="fa fa-spinner fa-spin"
                                [hidden]="!isLoading"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-xl-12">
            <div class="card tab2-card">
                <div class="card-body">
                    <h5 class="f-w-600">Constancia</h5>
                    <hr>
                    <p *ngIf="percentage != 100" class="center">Tus archivos no están validados al 100%. Necesitas tener tus archivos validados en su totalidad para poder descargar tu constancia.</p>
                    <div *ngIf="percentage == 100" id="batchDelete" class="">
                        <div class="row">
                            <div class="col-xs-12 col-sm-6 col-md-6">
                                <div class="container">
                                    <div class="row center">
                                    <div id="htmlData">
                                        <img [src]="[imgBackground]" class="imgBack" style="width: 100%;" alt="">
                                        <div class="textCons">
                                            <h2 class="title">REGISTRO ESTATAL DE TURISMO</h2>
                                            <h3 class="subtitle">CONSTANCIA DE INSCRIPCIÓN</h3>
                                            <span class="num">Núm. </span> <span class="numberRET">{{user?.RET}}</span>
                                            <p class="estado">Estado de Morelos, {{day}} de {{month}} de {{year}}.</p>
                                            <p class="emitida">Emitida a favor de:</p>
                                            <p class="text-principal"><strong>
                                                <span class="name">{{user?.Name}}</span><br>
                                                <span class="businessName" style="font-size: 14px;">{{user?.BusinessName}}</span><br>
                                                <span class="businessName">{{user?.Address[0].Street + ' ' + user?.Address[0].ExtNumber + ', ' + 'C.P. ' + user?.Address[0].ZipCode + ','}}</span><br>
                                                <span class="businessName">{{'Col. ' + user?.Address[0].Neighborhood + ',' + user?.Address[0].State}}</span><br>
                                            </strong></p>
                                            <h4 class="prestador">Como prestador de servicios Turísticos</h4>
                                            <p class="text-principal"><span class="name">{{user?.LocalType}}</span></p>
                                            <p class="namefirma"><strong>Lic. Graciela Flores Beltrán</strong><span><img [src]="[firma]" class="imgFirma" style="width: 30%;" alt=""> </span></p>
                                            <hr class="line">
                                            <p class="direct"><strong>Directora General de competitividad y Servicios Turísticos</strong></p>
                                            <p class="text-fin">Con fundamento en lo dispuesto por los artículos 3 fracción XIX, 5, 9 fracción X, 51,52,52 bis,53,54 y 61 bis de la Ley de Turismo del Estado de Morelos, artículo 24 y 28 del Reglamento de la Ley de Turismo del Estado de Morelos</p>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-6 vertical-align">
                                <div class="product-buttons center">
                                    <button  class="btn btn-primary mright"
                                        [disabled]="isDownload" (click)="downloadPDF()">
                                        {{ isDownload ? 'Descargando certificado' : 'Descargar certificado' }}
                                        <i class="fa fa-spinner fa-spin"
                                        [hidden]="!isDownload"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->

<ng2-toasty></ng2-toasty>

<ng-template #content
let-modal>
    <div class="modal-header">
    <h4 class="modal-title text-center width-100"
        id="modal-title">Agregar nota</h4>
    </div>
    <div class="modal-body text-center">
    <ng-container *ngIf="!noteIsSuccess">
        <p class="no-mrg">Escriba una nota para este archivo.</p>
    </ng-container>

    <ng-container *ngIf="noteIsSuccess">
        <p>Nota agregado correctamente</p>
    </ng-container>
    </div>
    <ng-container *ngIf="!noteIsSuccess">
    <div class="modal-footer">
        <form [formGroup]="form"
        (ngSubmit)="onSubmit()" class="right">
        <div class="form-group">
            <textarea name="" id="" cols="100%" rows="10"
            class="form-control"
            placeholder="Nota"
            formControlName="Notes"
            [ngClass]="{'error': submitted && formState?.Notes?.errors?.required}">
            </textarea>
        </div>
        <div class="product-buttons col-sm-12">
            <a type="button"
            class="btn btn-outline-light"
            (click)="modal.close()">Cancelar</a>
            <button type="submit"
                [disabled]="isLoadingNote" class="btn btn-primary mright">
                Agregar
                <i class="fa fa-spinner fa-spin" [hidden]="!isLoadingNote"></i>
            </button>
        </div>
        </form>
    </div>
    </ng-container>
</ng-template>