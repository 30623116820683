import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { ChartsModule } from 'ng2-charts';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ChartistModule } from 'ng-chartist'
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ToastyModule } from 'ng2-toasty';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { DataTablesModule } from 'angular-datatables';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AgmCoreModule } from '@agm/core';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { environment } from 'src/environments/environment';
import { TripsComponent } from './trips.component';
import { TripsRoutingModule } from './trips-routing.module';
import { TripDetailComponent } from './trip-detail/trip-detail.component';
// import { AgmDirectionModule } from 'agm-direction'; 
import { ViewTripComponent } from './view-trip/view-trip.component';
import { StartTripComponent } from './start-trip/start-trip.component';
import { TripsListComponent } from './trips-list/trips-list.component';

const API: string = environment.API_KEY;

@NgModule({
  declarations: [
      TripsComponent,
      TripDetailComponent,
      ViewTripComponent,
      StartTripComponent,
      TripsListComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ChartsModule,
    TripsRoutingModule,
    Ng2GoogleChartsModule,
    NgxChartsModule,
    ChartistModule,
    Ng2SmartTableModule,
    ToastyModule,
    NgSelectModule,
    AgmCoreModule.forRoot({
      apiKey: `${API}`,
      libraries: ["geometry", "places"],
      apiVersion: 'quarterly'
    }),
    MatGoogleMapsAutocompleteModule,
    DataTablesModule,
    NgxDatatableModule,
    NgbModule,
    NgMultiSelectDropDownModule.forRoot()
  ]
})
export class TripsModule { }