import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators'
import { ToastyService, ToastyConfig, ToastOptions } from 'ng2-toasty';
import { Location } from '@angular-material-extensions/google-maps-autocomplete';
import PlaceResult = google.maps.places.PlaceResult;
import { ReportsModel } from '../../reports/reports.models';
import { ReportsService } from '../../services/reports/reports.service';
import { DomSanitizer } from '@angular/platform-browser';
import { SharedService } from '../../services/shared.service';


@Component({
    selector: 'app-report',
    templateUrl: './report.component.html',
    styleUrls: ['./report.component.scss']
})

export class ReportComponent implements OnInit, OnDestroy {
    @Output() folio: EventEmitter<any> = new EventEmitter();
    @Output() childToParent = new EventEmitter<String>();
    public values: any;
    public problems: any;

    public form: FormGroup;
    public submitted: boolean = false;
    public submittedAddress: boolean = false;
    public isLoading = false;
    public isSearching = false;
    public latitude: number;
    public longitude: number;
    public selectedAddress: PlaceResult;
    public completeAddress: string;
    public showMap: boolean = false;
    public showMessage: boolean = false;
    public zoom: number;
    public other: any;

    public requiredFileType: string;
    public ImgfileName = '';
    public responseImgFile: string;
    public previewImg: any;
    public viewLoaderImg = false;

    /*Dirección*/
    public ExtNumber: number;
    public Street: string;
    public IntNumber: string;
    public Neighborhood: string;
    public State: string;
    public City: string;
    public ZipCode: number;


    personalDetails!: FormGroup;
    addressDetails!: FormGroup;
    personal_step = false;
    address_step = false;
    step = 1;

    private subs: Array<Subscription> = [];

    constructor(
        private fb: FormBuilder,
        private reportService: ReportsService,
        private router: Router,
        private toastyService: ToastyService,
        private toastyConfig: ToastyConfig,
        private sanitizer: DomSanitizer,
        private sharedService: SharedService
    ) { }

    ngOnInit(): void {
        this.personalDetails = this.fb.group({
            Name: ['', Validators.required],
            Phone: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
            Email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
            Subject: ['', Validators.required],
            Area: [''],
            Description: ['', Validators.required],
            Address: [''],
            Check: [false]
        });
        this.reportService
        .getProblems()
        .subscribe(data => {
            this.problems = data;
        });
        this.reportService
        .getValuestoCatalog('Departments')
        .subscribe(data => {
            this.values = data;
        });
        this.zoom = 16;
    }

    ngOnDestroy(): void {
        let sub: Subscription;
        while (sub = this.subs.shift()) {
            sub.unsubscribe();
        }
    }

    onImgSelected(event) {
      const file:File = event.target.files[0];
      if(file.type == 'image/png' || file.type == 'image/jpeg'){
        this.viewLoaderImg = true;
        setTimeout(() => {
          if (file) {
            const formData = new FormData();
            formData.append('eventImg', file);
            this.subs.push(
              this.reportService.uploadImg(formData)
                .subscribe(resolve => {
                  if (resolve.FileUrl !== null) {
                    this.responseImgFile = resolve.ImageUrl;
                    this.viewLoaderImg = false;
                    this.viewBase64(file).then(image => {
                      this.previewImg = image;
                    })
                    this.ImgfileName = file.name;
                  }
                })
            );
          }
        }, 1800);
      }else{
        this.NoTypeImg()
      }
    }
  
    viewBase64 = async($event: any) => new Promise((resolve, reject) => {
      try {
        const unsafeImg = window.URL.createObjectURL($event);
        const image = this.sanitizer.bypassSecurityTrustUrl(unsafeImg);
        const reader = new FileReader();
        reader.readAsDataURL($event);
        reader.onload = () => {
          resolve({
            base: reader.result
          });
        };
        reader.onerror = error => {
          resolve ({
            base: null
          });
        };
      } catch(e){
        return null;
      }
    })

    onAutocompleteSelected(result: PlaceResult) {
      this.showMessage = false;
        let address = result.address_components;
          if(address.length < 6){
            this.handleAddressFinal();
            return;
          }

          if(address.length == 8){
            this.ExtNumber = Number(address[0].short_name),
            this.Street = address[1].short_name,
            this.IntNumber = address[2].short_name,
            this.Neighborhood = address[3].short_name,
            this.State = address[4].short_name,
            this.City = address[5].short_name
            this.ZipCode = Number(address[7].short_name)
          }else {
            this.ExtNumber = Number(address[0].short_name),
            this.Street = address[1].short_name,
            this.IntNumber = '',
            this.Neighborhood = address[2].short_name,
            this.State = address[3].short_name,
            this.City = address[4].short_name,
            this.ZipCode = Number(address[5].short_name) || Number(address[6].short_name)
          }
        this.completeAddress = result.formatted_address;
        this.showMap = true;
      }
    
    onLocationSelected(location: Location) {
        this.latitude = location.latitude;
        this.longitude = location.longitude;
    }

    setCurrentLocation() {
        if ('geolocation' in navigator) {
          this.isSearching = true;
          navigator.geolocation.getCurrentPosition((position) => {
            this.latitude = position.coords.latitude;
            this.longitude = position.coords.longitude;
            this.showMap = true;
            this.isSearching = false;
            this.showMessage = true;
            this.getAddress(this.latitude, this.longitude);
          });
        }
      }

    markerDragEnd($event: any) {
      const latitude = $event.latLng.lat();
      const longitude = $event.latLng.lng();
      this.getAddress(latitude, longitude);
    }
    
    getAddress(latitude, longitude) {
      const latlng = new google.maps.LatLng(latitude, longitude);
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({'location': latlng  },  (results, status) =>{
          if (status !== google.maps.GeocoderStatus.OK) {
            alert(status);
        }
        // This is checking to see if the Geoeode Status is OK before proceeding
        if (status == google.maps.GeocoderStatus.OK) {
          this.completeAddress = (results[0].formatted_address);
          let address = results[0].address_components;
          this.personalDetails.controls['Address'].setValue(this.completeAddress);
          if(address.length < 6){
            this.handleAddressFinal();
            return;
          }
          if(address.length == 8){
            this.ExtNumber = Number(address[0].short_name),
            this.Street = address[1].short_name,
            this.IntNumber = '',
            this.Neighborhood = address[3].short_name,
            this.State = address[4].short_name,
            this.City = address[5].short_name
            this.ZipCode = Number(address[7].short_name)
          }else {
            this.ExtNumber = Number(address[0].short_name),
            this.Street = address[1].short_name,
            this.IntNumber = '',
            this.Neighborhood = address[2].short_name,
            this.State = address[3].short_name,
            this.City = address[4].short_name,
            this.ZipCode = Number(address[5].short_name) || Number(address[6].short_name)
          }
        }
      });
    }

    public get formState() {
        return this.personalDetails.controls;
    }

    public onSubmit(): void {
        this.submitted = true;

        if (this.personalDetails.invalid) {
            return;
        }

        if(this.completeAddress == undefined){
            this.addressError();
            return;
        }

        if(this.personalDetails.controls['Check'].value === false){
          this.terminosError();
          return;
      }

        this.createReport();

    }

    private createReport() {
        const report: ReportsModel = this.buildReport();
        this.isLoading = true;
        this.subs.push(
        this.reportService.createReport(report)
          .subscribe(resolve => {
            this.handleReportCreation();
            this.sharedService.setId(resolve.Folio);
            setTimeout(() => {
                this.router.navigateByUrl('/registro-exitoso');
            }, 1800);
          }, error =>{
              this.handleError(error)
          })
        );
      }

    private buildReport(): ReportsModel {
        if( this.other.ShortDescription === 'Otro'){
          return {
            Subject: this.other.ShortDescription,
            Department: this.personalDetails.controls['Area'].value,
            Description: this.personalDetails.controls['Description'].value,
            Name: this.personalDetails.controls['Name'].value,
            PhoneNumber: this.personalDetails.controls['Phone'].value,
            Email: this.personalDetails.controls['Email'].value,
            ImgUrl: this.responseImgFile,
            Address: {
                Street: this.Street,
                ExtNumber: this.ExtNumber,
                IntNumber: this.IntNumber,
                Neighborhood: this.Neighborhood,
                ZipCode: this.ZipCode,
                State: this.State,
                City: this.City,
                Location: {
                    lat: this.latitude,
                    lng: this.longitude
                }
            }
          }
        }else{
          return {
            Subject: this.other.ShortDescription,
            Department: this.other.Department,
            Description: this.personalDetails.controls['Description'].value,
            Name: this.personalDetails.controls['Name'].value,
            PhoneNumber: this.personalDetails.controls['Phone'].value,
            Email: this.personalDetails.controls['Email'].value,
            ImgUrl: this.responseImgFile,
            Address: {
                Street: this.Street,
                ExtNumber: this.ExtNumber,
                IntNumber: this.IntNumber,
                Neighborhood: this.Neighborhood,
                ZipCode: this.ZipCode,
                State: this.State,
                City: this.City,
                Location: {
                    lat: this.latitude,
                    lng: this.longitude
                }
            }
        }
      }
        
    }

    private addressError() {
        const toastOptions: ToastOptions = {
          title: 'Alerta',
          msg: 'No has ingresado la dirección del reporte',
          showClose: true,
          timeout: 2000
        };
        this.toastyService.warning(toastOptions);
        return [];
    }

    private handleError(error: any) {
        const toastOptions: ToastOptions = {
          title: 'Error',
          msg: error.error.message,
          showClose: true,
          timeout: 2000
        };
    
        this.toastyService.error(toastOptions);
        return [];
    }
    
    private handleReportCreation() {
      const toastOptions: ToastOptions = {
        title: 'Reporte',
        msg: 'Reporte creado correctamente',
        showClose: true,
        timeout: 1700
      };
  
      this.toastyService.success(toastOptions);
    }

    private handleAddressFinal() {
      const toastOptions: ToastOptions = {
        title: 'Error',
        msg: 'Por favor agrega la dirección correctamente',
        showClose: true,
        timeout: 2000
      };
  
      this.toastyService.error(toastOptions);
      return [];
    }

    private terminosError() {
      const toastOptions: ToastOptions = {
        title: 'Alerta',
        msg: 'Debes marcar la casilla para ser contactado por un agente',
        showClose: true,
        timeout: 2000
      };
  
      this.toastyService.info(toastOptions);
      return [];
    }

    private NoTypeImg() {
      const toastOptions: ToastOptions = {
        title: 'Error',
        msg: 'Debes subir una imagen de tipo png o jpg',
        showClose: true,
        timeout: 1800
      };
  
      this.toastyService.error(toastOptions);
    }
}
