<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="card">
    <div class="card-body">
      <div id="batchDelete" class="">
        <div class="container pl-0 pr-0 mb-4">
          <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item">
              <a class="nav-link" [ngClass]="{'active': selectedTab === 'Todos'}" (click)="filterTicketsbyStatus('Todos')" id="contact-tab" data-toggle="tab" role="tab">Todos</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [ngClass]="{'active': selectedTab === 'Activo'}" (click)="filterTicketsbyStatus('Activo')" id="home-tab" data-toggle="tab" role="tab">Activos</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [ngClass]="{'active': selectedTab === 'Inactivo'}"  (click)="filterTicketsbyStatus('Inactivo')"id="profile-tab" data-toggle="tab" role="tab">Inactivos</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [ngClass]="{'active': selectedTab === 'Aplicado'}" (click)="filterTicketsbyStatus('Aplicado')" id="contact-tab" data-toggle="tab" role="tab">Aplicados</a>
            </li>
          </ul>
        </div>
        <div class="table-responsive">
          <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
            class=" table table-lg table-hover row-border hover">
            <thead>
              <tr>
                  <th>Folio</th>
                  <th>Fecha y hora de activación</th>
                  <th>Usuario de activación</th>
                  <th>Tipo</th>
                  <th>Costo</th>
                  <th>Estatus</th>
                  <th>Correo enviado</th>
                  <!-- <th>Acciones</th> -->
                <!-- <th>Acciones</th> -->
              </tr>
            </thead>
            <tbody *ngIf="tickets.length !== 0">
              <tr *ngFor="let ticket of tickets; let i = index">
                <td>{{ticket.folio}}</td>
                <td>{{ticket.created_at | date: 'dd/MM/yyyy, h:mm a'}}</td>
                <td>{{ticket.Dealer.displayName}}</td>
                <td>{{ticket.Type}}</td>
                <td>{{ticket.Cost | currency}}</td>
                <td>
                  <span [ngClass]="{'ticket-active': ticket.Status === 'Activo', 'ticket-inactive': ticket.Status === 'Inactivo', 'ticket-apply': ticket.Status === 'Aplicado'}">{{ticket.Status}}</span>
                </td>
                <td class="text-center">
                  <i *ngIf="ticket.MailSent" class="fa fa-check-circle text-success" aria-hidden="true"></i>
                  <i *ngIf="!ticket.MailSent"  class="fa fa-times-circle text-danger" aria-hidden="true"></i>
                </td>
                <!-- <td>
                  <span [routerLink]="['show', ticket.id]" title="Editar usuario"
                    class="pointer">
                    <i class="fa fa-eye"></i></span>
                </td> -->
              </tr>
            </tbody>
          </table>
          <ngb-pagination *ngIf="tickets.length > 0" (pageChange)="loadPage($event)" [collectionSize]="total" [(page)]="page" [maxSize]="10"
            [rotate]="true" [boundaryLinks]="true"></ngb-pagination>
        </div>
        <div *ngIf="tickets.length === 0" class="text-center no-items mt-1">
          <p>No hemos encontrado boletos con estatus <strong>{{selectedTab}}</strong></p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Container-fluid Ends-->

<ng2-toasty></ng2-toasty>