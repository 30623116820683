<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-body">
                    <div class="row product-adding">
                        <div class="col-xs-12 col-md-6 col-xl-5">
                            <form class="auth-form" [formGroup]="productForm" (ngSubmit)="onSubmit()" novalidate>
                                <div class="add-product">
                                    <div class="container pt-4 mt-1">
                                        <div class="row" *ngIf="step === 1">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <h4 class="col-form-label pt-0"> <b>Nombre</b></h4>
                                                    <input class="form-control" type="text" [(ngModel)]="event.EventTitle" [ngModelOptions]="{standalone: true}"
                                                    [ngClass]="{'error': submitted && event.EventTitle.length === 0 }">
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="validationCustom02" class="col-form-label pt-0"><b>Fecha del evento</b></label>
                                                        <input class="form-control" id="validationCustom02" type="date" [(ngModel)]="event.EventDate" [ngModelOptions]="{standalone: true}"
                                                        [ngClass]="{'error': submitted && !event.EventDate }">
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="validationCustom02" class="col-form-label pt-0"><b>Hora de inicio</b></label>
                                                    <input  class="form-control" type="Time" [(ngModel)]="event.EventTime" [ngModelOptions]="{standalone: true}"
                                                    [ngClass]="{'error': submitted && event.EventTime.length === 0}">
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <h4 class="col-form-label pt-0">
                                                        <b>Imagen de portada</b>
                                                    </h4>
                                                    <!-- <img *ngIf="ImgFileUrl" [src]="[ImgFileUrl]" class="img-fluid blur-up lazyload bg-img mb-3" alt="" style="width: 200px;">
                                                    <div *ngIf="previewImg" class="box-input-file">
                                                        <img class="imgUpload" alt="" [src]="previewImg.base">
                                                    </div>-->
                                                    <div *ngIf="viewLoaderImg" class="spinner-border text-danger" role="status">
                                                        <span class="sr-only">Loading...</span>
                                                    </div> 
                                                    <input type="file" name="picture1" class="file-input" accept=".png, .jpg, .jpeg" (change)="onImgSelected($event)" #fileImgUpload>
                                                    <div class="file-upload w-100 mt-1 mb-3" style="float: left;">
                                                        <button class="btn w-100 btn-primary" (click)="fileImgUpload.click()">
                                                            {{event.BannerUrl ? 'Reemplazar imagen' : "Cargar imagen"}}
                                                            <i class="ml-4 fa fa-cloud-upload" aria-hidden="true"></i>
                                                        </button>
                                                    </div>
                                                </div>                                                    
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="validationCustom03" class="col-form-label pt-0"><b>Descripción</b></label>
                                                    <br>
                                                    <textarea   class="form-control" [(ngModel)]="event.EventDescription" name="Description" 
                                                                id="" cols="100%" rows="5" [ngModelOptions]="{standalone: true}"
                                                    [ngClass]="{'error': submitted && event.EventDescription.length === 0}"></textarea>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <h4 class="col-form-label pt-0">
                                                        <b>Ingresa la dirección</b>
                                                    </h4>
                                                    <input matInput class="form-control" 
                                                    [(ngModel)]="event.AddressFormatted"
                                                    [ngModelOptions]="{standalone: true}"
                                                    matGoogleMapsAutocomplete
                                                    country="mx"
                                                    (onAutocompleteSelected)="onAutocompleteSelected($event)"
                                                    (onLocationSelected)="onLocationSelected($event)"
                                                    placeholder="Ingresa la ubicación del evento"
                                                    [ngClass]="{'error': submitted && event.AddressFormatted.length === 0}">
                                                </div>
                                                <agm-map *ngIf="showMap" [latitude]="latitude" [longitude]="longitude" [scrollwheel]="true" [zoom]="zoom">
                                                    <agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="true"
                                                    (dragEnd)="markerDragEnd($event)"></agm-marker>
                                                </agm-map>
                                                <!-- <p *ngIf="showMap" class="address mt-3">La dirección del evento es: <strong>{{completeAddress}}</strong></p> -->
                                                <span *ngIf="showMessage" class="address">Si la dirección mostrada no es la correcta, por favor use el buscador.</span>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group row mt-2 mb-0">
                                                    <div class="col-12 d-flex justify-content-end" (click)="validateStep1()">
                                                        <a class="btn btn-primary">
                                                            Siguiente
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row" *ngIf="step === 2">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <h4 class="col-form-label pt-0">
                                                        <b>Mapa del sitio</b>
                                                    </h4>
                                                    <div *ngIf="viewLoaderImg" class="spinner-border text-danger" role="status">
                                                        <span class="sr-only">Loading...</span>
                                                    </div> 
                                                    <input type="file" name="picture1" class="file-input" accept=".png, .jpg, .jpeg" (change)="onMapSelected($event)" #fileImgUpload>
                                                    <div class="file-upload w-100 mt-1 mb-3" style="float: left;">
                                                        <button class="btn w-100 btn-primary" (click)="fileImgUpload.click()">
                                                            {{event.SiteMap ? 'Reemplazar imagen' : "Cargar imagen"}}
                                                            <i class="ml-4 fa fa-cloud-upload" aria-hidden="true"></i>
                                                        </button>
                                                    </div>
                                                </div>                                                    
                                            </div>
                                            <div class="col-12 my-2">
                                                <fieldset class="border rounded">
                                                    <legend class="col-form-label w-auto px-2"><b>Zonas del evento</b></legend>
                                                    <div class="row">
                                                        <div class="col-12 mb-2">
                                                            <label for="validationCustom03" class="col-form-label pt-0"><b>Nombre</b></label>
                                                            <input type="text" class="form-control" [ngModelOptions]="{standalone: true}"
                                                            [(ngModel)]="zone.name" placeholder="Nombre"
                                                            [ngClass]="{'error': zoneSubmitted && zone.name.length === 0}">
                                                        </div>
                                                        <div class="col-12 mb-2">
                                                            <label for="validationCustom03" class="col-form-label pt-0"><b>Precio</b></label>
                                                            <input type="number" min="0" class="form-control" [ngModelOptions]="{standalone: true}"
                                                            [(ngModel)]="zone.price" placeholder="Precio"
                                                            [ngClass]="{'error': zoneSubmitted && zone.price === 0}">
                                                        </div>
                                                        <div class="col-12 mb-2">
                                                            <label for="validationCustom03" class="col-form-label pt-0"><b>Boletos disponibles</b></label>
                                                            <input type="number" min="0" class="form-control" [ngModelOptions]="{standalone: true}"
                                                            [(ngModel)]="zone.quantity" placeholder="Boletos disponibles"
                                                            [ngClass]="{'error': zoneSubmitted && zone.quantity === 0}">
                                                        </div>
                                                        <div class="col-12 mb-2 d-flex justify-content-end">
                                                            <a class="btn btn-primary" (click)="addZone()">Agregar zona</a>
                                                        </div>
                                                    </div>
                                                </fieldset>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group row mb-0">
                                                    <div class="col-12 d-flex justify-content-between" *ngIf="onEdit == false">
                                                        <button class="btn btn-secondary" (click)="beforeStep()">
                                                            Anterior
                                                        </button>
                                                        <button class="btn btn-primary"
                                                            [disabled]="isLoading">
                                                            {{ isLoading ? 'Creando evento' : 'Agregar evento'}}
                                                            <i class="fa fa-spinner fa-spin"
                                                            [hidden]="!isLoading"></i>
                                                        </button>
                                                    </div>
                
                                                    <div class="col-12 d-flex justify-content-between" *ngIf="onEdit == true">
                                                        <button class="btn btn-secondary" (click)="beforeStep()">
                                                            Anterior
                                                        </button>
                                                        <button type="submit" class="btn btn-primary">
                                                            {{ isLoading ? 'Editando evento' : 'Editar evento'}}
                                                            <i class="fa fa-spinner fa-spin"
                                                            [hidden]="!isLoading"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="scene scene--card col-xs-12 col-md-6 col-xl-7 px-0 p-4">
                            <div id="card" class="card border shadow">
                                <div id="card-front" class="card__face card__face--front">
                                    <div class="card-header">
                                        <h3 class="text-center text-uppercase font-weight-bold">{{ event?.EventTitle ? event?.EventTitle : 'Nombre del evento' }}</h3>
                                        <span class="text-center text-uppercase mt-3">Fecha del evento: 
                                            <b *ngIf="event.EventDate" class="bg-danger rounded p-2">
                                                <span *ngIf="!event.EventTime">{{ event?.EventDate | date: 'dd/MM/YYYY' }}</span>
                                                <span *ngIf="event.EventTime">{{ event?.EventDate +' '+event?.EventTime | date: 'dd/MM/YYYY hh:mm a' }}</span> 
                                            </b>
                                            <b *ngIf="!event.EventDate" class="bg-danger rounded p-2">Sin definir</b>
                                        </span>
                                        <div style="height: 250px; text-align: center;" class="box-input-file mt-3 px-3">
                                            <img style="object-fit: cover;" *ngIf="event.BannerUrl" [src]="[event.BannerUrl]" class="h-100 img-fluid blur-up lazyload bg-img mb-3" alt="" style="width: 200px;">
                                            <img style="object-fit: cover;" *ngIf="!event.BannerUrl" src="../../../../../assets/images/no-available.png" class="h-100 img-fluid blur-up lazyload bg-img mb-3" alt="">
                                        </div>
                                        <fieldset class="mt-4 pb-3 rounded">
                                            <legend class="w-auto px-2" style="font-size: 110%"><b>Descripción</b></legend>
                                            <span class="px-3">{{ event?.EventDescription ? event.EventDescription : 'Sin descripción' }}</span>
                                            <h6 class="px-2 mt-3"><b>Dirección</b></h6>
                                            <hr class="mt-1 mb-3 mx-2">
                                            <span class="p-3">{{ event?.AddressFormatted ? event.AddressFormatted : 'Sin descripción' }}</span>
                                        </fieldset>
                                    </div>
                                </div>
                                <div id="card-back" class="card__face card__face--back">
                                    <div class="card-header">
                                        <h3 class="text-uppercase font-weight-bold">Mapa del sitio</h3>
                                        <div style="height: 250px; text-align: center;" class="box-input-file mt-3 px-3">
                                            <img style="object-fit: cover;" *ngIf="event.SiteMap" [src]="[event.SiteMap]" class="h-100 img-fluid blur-up lazyload bg-img mb-3" alt="" style="width: 200px;">
                                            <img style="object-fit: cover;" *ngIf="!event.SiteMap" src="../../../../../assets/images/no-available.png" class="h-100 img-fluid blur-up lazyload bg-img mb-3" alt="">
                                        </div>
                                        <h3 class="mt-4 text-uppercase font-weight-bold">Zonas del evento</h3>
                                        <div class="bg-primary py-2 text-center" *ngIf="event.Zones.length === 0">
                                            <h5 class="text-white">Sin definir</h5>
                                        </div>
                                        <table class="mt-2 pb-3 rounded table table-striped" *ngIf="event.Zones.length > 0">
                                            <thead class="bg-primary text-center">
                                                <th>Nombre</th>
                                                <th>Precio</th>
                                                <th>Número de boletos</th>
                                            </thead>
                                            <tbody class="text-center">
                                                <tr *ngFor="let eventzone of event.Zones">
                                                    <th>{{ eventzone?.name || 'Sin definir' }}</th>
                                                    <th>{{ eventzone?.price | currency: 'USD' }}</th>
                                                    <th>{{ eventzone?.quantity }}</th>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->

<ng2-toasty></ng2-toasty>