<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="card">
        <div id="element-to-print" class="card-body container pt-5" style="background-image: url(../../../../assets/images/membrete2.png); background-size: 100%; background-repeat: no-repeat;">
            <div id="batchDelete" class="pt-5" style="margin: 4rem auto;">
                <span class="float-right text-right text-title">
                    <strong>ASUNTO: </strong>
                    Informe de solicitudes pendientes de atender por área. <br>
                    H. Veracruz, Ver., a {{ currentDay }} de {{ months[currentMonth] }} de {{ currentYear }}
                </span>
                <br>
                <strong class="text-title">Lic. Patricia Lobeira Rodríguez</strong><br>
                <strong class="text-title">Presidenta Municipal</strong><br>
                <strong class="text-title">H. Ayuntamiento de Veracruz</strong><br>
                <p style="letter-spacing: 3px" class="text-title">Presente</p>
                <p class="text-justify text-body mt-5">
                    Sirva la presente para saludarla cordialmente; y asimismo me permito informarle sobre los reportes atendidos y los reportes pendientes de atención que se
                    encuentran en el Sistema Integral de Relación con los Ciudadanos (SIRC), lo anterior, en lo que respecta a los reportes ingresados durante el período comprendido del 
                    <span *ngIf="startYear == endYear">
                        {{ endMonth === startMonth ? 
                            week[startDay]+' '+dayStart+' al '+week[endDay]+' '+dayEnd+' de '+months[endMonth]+' '+endYear : 
                            week[startDay]+' '+dayStart+' de '+months[startMonth]+' al '+week[endDay]+' '+dayEnd+' de '+months[endMonth]+' '+endYear
                         }}
                    </span>
                    <span *ngIf="startYear !== endYear">
                        {{ week[startDay]+' '+dayStart+' de '+months[startMonth]+' '+startYear+' al '+dayEnd+' de '+months[endMonth]+' '+endYear }}
                    </span>. <br><br>
                    <p class="text-body">Se anexa tabla con el número de reportes atendidos y pendientes</p>
                <div class="mb-3">
                    <table class=" table table-lg table-hover row-border hover border">
                        <thead>
                            <tr class="bg-blue">
                                <th colspan="6">
                                    <p *ngIf="startYear == endYear" class="text-uppercase text-center text-white table-title">
                                        {{ endMonth === startMonth ? 
                                            week[startDay]+' '+dayStart+' al '+week[endDay]+' '+dayEnd+' de '+months[endMonth]+' '+endYear : 
                                            week[startDay]+' '+dayStart+' de '+months[startMonth]+' al '+week[endDay]+' '+dayEnd+' de '+months[endMonth]+' '+endYear
                                         }}
                                    </p>
                                    <p *ngIf="startYear !== endYear" class="text-uppercase text-center text-white table-title">
                                        {{ week[startDay]+' '+dayStart+' de '+months[startMonth]+' '+startYear+' al '+dayEnd+' de '+months[endMonth]+' '+endYear }}
                                    </p>
                                </th>
                            </tr>
                            <tr>
                                <th class="border text-center table-title">DEPENDENCIA</th>
                                <th class="border text-center table-title">PENDIENTES</th>
                                <th class="border text-center table-title">DESARROLLO</th>
                                <th class="border text-center table-title">ATENDIDOS</th>
                                <th class="border text-center table-title">TOTAL</th>
                                <th class="border text-center table-title">PORCENTAJE</th>
                                <!-- <th *ngFor="let item of reports[0]?.List">
                                    {{item.Status}}
                                </th> -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let report of reports">
                                <td class="border text-center table-body">{{report.Department}}</td>
                                <td class="border text-center table-body">{{report.List[0].Count}} ({{report.List[0].Count / report.List[3].Count * 100 | number : '1.2-2'}}%)</td>
                                <td class="border text-center table-body">{{report.List[1].Count}} ({{report.List[1].Count / report.List[3].Count * 100 | number : '1.2-2'}}%)</td>
                                <td class="border text-center table-body">{{report.List[2].Count}} ({{report.List[2].Count / report.List[3].Count * 100 | number : '1.2-2'}}%)</td>
                                <td class="border text-center table-body">{{report.List[3].Count}}</td>
                                <td class="border text-center table-body">{{report.List[4].Count}}</td>
                                <!-- <td *ngFor="let item of report.List; let i = index; let last = last">
                                    {{item.Count}}({{item.Count / item.Count}})
                                </td> -->
                            </tr>
                        </tbody>
                    </table>
                </div>
                <p class="text-justify text-body mt-5">
                    Es de suma importancia comunicarle que, dichos reportes son procedentes de los diferentes canales de contacto y atención con el ciudadano: <strong>giras de la alcaldesa,
                    presencial por área y en atención ciudadana, call center, whatsapp, redes sociales, correo institucional y chat de la página “Veracruz Nuestro Puerto, Nuestra Casa”</strong>.
                </p>
                <p class="text-justify text-body">
                    <strong>NOTA: </strong>Se hace la observación de que la cantidad de <u>reportes pendientes, en desarrollo y atendidos</u> puede variar en un rango de 1 a 8 reportes.
                    Debido a la constante retroalimentación del sistema por las diferentes vías de captura. Se anexan folios SIRC.
                </p>
                <p class="text-justify text-body">
                    Agradeciendo la atención prestada, quedo a sus órdenes para cualquier duda o comentario.
                </p>

                <div class="text-center mt-5 mb-5 pt-5 pb-5">
                    <hr class="line">
                    <p class="text-body">
                        <strong>Mtra. Natalia Gpe. Calleja Martínez</strong><br>
                        Directora de Atención Ciudadana<br>
                        H. Ayuntamiento de Veracruz
                    </p>
                </div>
                <p class="text-justify text-body">
                    <strong>C.c.p:</strong> Archivo
                </p>
                <p class="text-justify text-body">
                    NGCM/NOPL/NHS/MVS
                </p>
                <div class="">
                    <img src="../../../../assets/images/membrete-footer.png" alt="attr" width="100%">
                </div>
            </div>
        </div>
        <div class="card-footer container text-right">
            <button class="btn btn-primary" (click)="getPDF()">Descargar</button>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->