import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription, throwError } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { map, catchError, switchMap } from 'rxjs/operators';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { ToastyService, ToastyConfig, ToastOptions } from 'ng2-toasty';
import { TicketsService } from '../../services/tickets/tickets.service';
import { LocationApplyModel } from '../tickets.models';

@Component({
  selector: 'app-apply-ticket',
  templateUrl: './apply-ticket.component.html',
  styleUrls: ['./apply-ticket.component.scss']
})
export class ApplyTicketComponent implements OnInit, OnDestroy{

  public loginForm: FormGroup;
  public isLoading = false;
  public submitted = false;
  public submittedLogin = false;

  public ticket: any;
  public latitude: any;
  public longitude: any;

  private subs: Array<Subscription> = [];

  constructor(
    public formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthenticationService,
    private ticketsService: TicketsService,
    private toastyService: ToastyService,
    private toastyConfig: ToastyConfig
  ) {
    this.toastyConfig.theme = 'bootstrap';
  }

  ngOnInit() {
    this.isLoading = false;
    this.subs.push(
        this.activatedRoute.paramMap.pipe(
          map(params => params.get('uuid')),
          switchMap(uuid => this.ticketsService.getTicket(uuid)),
          catchError(error => {
            return throwError(error);
          })
        ).subscribe(resolve => {
          this.ticket = resolve;
        })
      );
      this.setCurrentLocation();
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }

  public setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
      });
    }
  }

  private sendLocation(): LocationApplyModel {
    return {
      Location: {
        lat: this.latitude,
        lng: this.longitude,
      }
    }
  }

  //this.authService.currentUserValue.id
  public applyTicketSend(ticketId): void {
    const location: LocationApplyModel = this.sendLocation();
    this.isLoading = true;

    this.subs.push(
      this.ticketsService.applyTicket(ticketId, location)
        .subscribe(resolve => {
            this.handleTicketValidate();
            setTimeout(() => {
              this.ngOnInit();
            }, 1800);
        }, error =>{
            this.errorTicketValidate(error)
        })
    );
  }

  private handleTicketValidate() {
    const toastOptions: ToastOptions = {
      title: 'Boleto',
      msg: 'Tu boleto ha sido aplicado exitosamente',
      showClose: true,
      timeout: 1700
    };

    this.toastyService.success(toastOptions);
  }

  private errorTicketValidate(error: any) {
    this.isLoading = false;
    const toastOptions: ToastOptions = {
      title: 'Boleto',
      msg: 'Ocurrio un error al aplicar su boleto',
      showClose: true,
      timeout: 1700
    };

    this.toastyService.error(toastOptions);
    return [];
  }

}
