import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class SharedService {
    folio: string;
    data: any;
    report: string;
    startDate: string;
    endDate: string;
    workOrder: any;

    constructor(){}

    setId(data){
        this.folio = data;
    }
    getId(){
        return this.folio;
    }

    setData(data, report) {
        this.data = data;
        this.report = report;
    }
    getData(){
        return this.data;
    }
    getReport(){
        return this.report;
    }

    setReportWorkOrder(data){
        this.workOrder = data;
    }

    getReportWokrOrder(){
        return this.workOrder;
    }

}