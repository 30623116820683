<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="card">
        <div class="card-body">
            <div class="btn-popup pull-right">
                <a class="btn btn-secondary" [routerLink]="['add-service']"><span>Agregar Servicio</span></a>
            </div>
            <div id="batchDelete" class="">
                <div class="table-responsive">
                    <table datatable
                    [dtOptions]="dtOptions"
                    [dtTrigger]="dtTrigger"
                    class=" table table-lg table-hover row-border hover">
                    <thead>
                        <tr>
                        <th>Nombre</th>
                        <th class="widthdes">Descripción</th>
                        <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let service of services">
                        <td>{{service.Title}}</td>
                        <td>{{(service.Description.length>200)? (service.Description | slice:0:170)+'...':(service.Description)}}</td>
                        <td class="actions-cell">
                            <span>
                            <a [routerLink]="['show', service.id]"
                                title="Ver cotización">
                                <i class="fa fa-eye"></i>
                            </a>
                            </span>
                            <span>
                            <a [routerLink]="['edit', service.id]"
                                title="Editar servicio">
                                <i class="fa fa-pencil"></i>
                            </a>
                            </span>
                            <span (click)="setModalContent(content, service.id)"
                            title="Borrar servicio">
                            <i class="fa fa-trash"></i>
                            </span>
                        </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->

<ng-template #content
  let-modal>
  <div class="modal-header center">
    <h4 *ngIf="!serviceDeleteSuccess" class="modal-title text-center width-100"
      id="modal-title">Eliminar Servicio</h4>

    <h4 *ngIf="serviceDeleteSuccess" class="modal-title text-center width-100"
      id="modal-title"><strong>Servicio Eliminado</strong></h4>
  </div>
  <div class="modal-body text-center">
    <ng-container *ngIf="!serviceDeleteSuccess">
      <p class="no-mrg">Se eliminará el servicio</p>
      <p class="no-mrg">Esta acción no se puede deshacer.</p>
    </ng-container>

    <ng-container *ngIf="serviceDeleteSuccess">
      <p>Servicio eliminado correctamente</p>
    </ng-container>
  </div>
  <ng-container *ngIf="!serviceDeleteSuccess">
    <div class="modal-footer right">
      <a type="button"
        class="btn btn-outline-light"
        (click)="modal.close()">Cancelar</a>
      <button type="button"
        class="btn btn-inverse btn-danger"
        (click)="deleteProduct()">Eliminar</button>
    </div>
  </ng-container>
</ng-template>