import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, of, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators'
import { AuthenticationService } from '../../services/auth/authentication.service';
import { ToastyService, ToastyConfig, ToastOptions } from 'ng2-toasty';
import { Location } from '@angular-material-extensions/google-maps-autocomplete';
import PlaceResult = google.maps.places.PlaceResult;
import { ProductsService } from '../../services/products/products.service';
import { ReportsModel } from '../../reports/reports.models';
import { ReportsService } from '../../services/reports/reports.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-create-report',
  templateUrl: './create-report.component.html',
  styleUrls: ['./create-report.component.scss']
})

export class CreateReportComponent implements OnInit, OnDestroy {

  public values: any;
  public problems: any;

  public sources = [];
  public form: FormGroup;
  public submitted: boolean = false;
  public submittedAddress: boolean = false;
  public isLoading = false;
  public isSearching = false;
  public latitude: number;
  public longitude: number;
  public selectedAddress: PlaceResult;
  public completeAddress: string;
  public showMap: boolean = false;
  public showMessage: boolean = false;
  public zoom: number;
  public source: string;
  public other: any;
  public rol: string;
  public folio: number;

  public requiredFileType: string;
  public ImgfileName = '';
  public responseImgFile: string;
  public previewImg: any;
  public viewLoaderImg = false;

  /*Dirección*/
  public ExtNumber: number;
  public Street: string;
  public IntNumber: string;
  public Neighborhood: string;
  public State: string;
  public City: string;
  public ZipCode: number;

  reportId: any;


  personalDetails!: FormGroup;
  addressDetails!: FormGroup;
  personal_step = false;
  address_step = false;
  step = 1;

  onEdit = false;

  private subs: Array<Subscription> = [];
  report: any;
  partAddress = [];
  address = [];

  @ViewChild('search')
  public searchElementRef: ElementRef;
  userId: string;

  constructor(
    private fb: FormBuilder,
    private reportService: ReportsService,
    private authService: AuthenticationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastyService: ToastyService,
    private toastyConfig: ToastyConfig,
    private sanitizer: DomSanitizer
  ) {
    this.rol = this.authService.currentUserValue.roles;
    this.userId = authService.currentUserValue.id
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((data: any) => {
      if (data.params.id) {
        this.reportId = data.params.id;
        this.onEdit = true;
        this.subs.push(
          this.reportService.getReport(data.params.id).pipe(
            catchError(error => {
              return throwError(error);
            })
          ).subscribe(report => {
            this.setValues(report)
            this.report = report;
            this.sendAddress(this.report.Address);
          })
        );

      } else {
        this.onEdit = false;
      }
    });
    if (this.authService.currentUserValue.roles === 'user') {
      this.personalDetails = this.fb.group({
        Name: [this.authService.currentUserValue.displayName, Validators.required],
        Phone: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
        Email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
        Source: [''],
        Subject: ['', Validators.required],
        Area: [''],
        Description: ['', Validators.required],
        Address: [''],
        Street1: [''],
        Street2: [''],
        Check: [false]
      });
    } else {
      this.personalDetails = this.fb.group({
        Name: ['', Validators.required],
        Phone: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
        Email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
        Source: [''],
        Subject: ['', Validators.required],
        Area: [''],
        Description: ['', Validators.required],
        Address: [''],
        Street1: [''],
        Street2: [''],
        Check: [false]
      });
    }
    this.reportService
      .getProblems()
      .subscribe(data => {
        this.problems = data;
      });
    this.reportService
      .getValuestoCatalog('Departments')
      .subscribe(data => {
        this.values = data;
      });
    this.reportService
      .getSourcetoUser(this.userId)
      .subscribe(data => {
        this.sources = data;
      })
    this.zoom = 16;
  }

  sendAddress(address: any): void {
    if (address.Street !== null) {
      this.partAddress.push(address.Street);
      this.address = [...this.partAddress];
      const addressParse = JSON.stringify(this.address);
      this.completeAddress = JSON.parse(addressParse);
    };
    if (address.ExtNumber !== null) {
      this.partAddress.push(address.ExtNumber);
      this.address = [...this.partAddress];
      const addressParse = JSON.stringify(this.address);
      this.completeAddress = JSON.parse(addressParse);
    };
    if (address.Neighborhood !== null) {
      this.partAddress.push(address.Neighborhood);
      this.address = [...this.partAddress];
      const addressParse = JSON.stringify(this.address);
      this.completeAddress = JSON.parse(addressParse);
    };
    if (address.ZipCode !== null) {
      this.partAddress.push(address.ZipCode);
      this.address = [...this.partAddress];
      const addressParse = JSON.stringify(this.address);
      this.completeAddress = JSON.parse(addressParse);
    };
    if (address.State !== null) {
      this.partAddress.push(address.State);
      this.address = [...this.partAddress];
      const addressParse = JSON.stringify(this.address);
      this.completeAddress = JSON.parse(addressParse);
    };
    if (address.City !== null) {
      this.partAddress.push(address.City);
      this.address = [...this.partAddress];
      const addressParse = JSON.stringify(this.address);
      this.completeAddress = JSON.parse(addressParse);
    };
  }

  ngOnDestroy(): void {
    let sub: Subscription;
    while (sub = this.subs.shift()) {
      sub.unsubscribe();
    }
  }

  onImgSelected(event) {
    const file: File = event.target.files[0];
    if (file.type == 'image/png' || file.type == 'image/jpeg') {
      this.viewLoaderImg = true;
      setTimeout(() => {
        if (file) {
          const formData = new FormData();
          formData.append('eventImg', file);
          this.subs.push(
            this.reportService.uploadImg(formData)
              .subscribe(resolve => {
                if (resolve.FileUrl !== null) {
                  this.responseImgFile = resolve.ImageUrl;
                  this.viewLoaderImg = false;
                  this.viewBase64(file).then(image => {
                    this.previewImg = image;
                  })
                  this.ImgfileName = file.name;
                }
              })
          );
        }
      }, 1800);
    } else {
      this.NoTypeImg()
    }
  }

  viewBase64 = async ($event: any) => new Promise((resolve, reject) => {
    try {
      const unsafeImg = window.URL.createObjectURL($event);
      const image = this.sanitizer.bypassSecurityTrustUrl(unsafeImg);
      const reader = new FileReader();
      reader.readAsDataURL($event);
      reader.onload = () => {
        resolve({
          base: reader.result
        });
      };
      reader.onerror = error => {
        resolve({
          base: null
        });
      };
    } catch (e) {
      return null;
    }
  })

  onAutocompleteSelected(result: PlaceResult) {
    // console.log(result.geometry.location.lat(),result.geometry.location.lng())
    this.showMessage = false;
    let address = result.address_components;
    if (address.length < 6) {
      this.handleAddressFinal();
      return;
    }

    if (address.length == 8) {
      this.ExtNumber = Number(address[0].short_name),
        this.Street = address[1].short_name,
        this.IntNumber = address[2].short_name,
        this.Neighborhood = address[3].short_name,
        this.State = address[4].short_name,
        this.City = address[5].short_name
      this.ZipCode = Number(address[7].short_name)
    } else {
      this.ExtNumber = Number(address[0].short_name),
        this.Street = address[1].short_name,
        this.IntNumber = '',
        this.Neighborhood = address[2].short_name,
        this.State = address[3].short_name,
        this.City = address[4].short_name,
        this.ZipCode = Number(address[5].short_name) || Number(address[6].short_name)
    }
    this.completeAddress = result.formatted_address;
    this.showMap = true;
  }

  onLocationSelected(location: Location) {
    const geocode = new google.maps.Geocoder();
    geocode.geocode({'location':{lat: location.latitude, lng: location.longitude}}, (results,status)=>{
    })
    
    this.latitude = location.latitude;
    this.longitude = location.longitude;
  }

  private setValues(report: any): void {
    const values = this.reportService.mapRequiredValues(report);
    const { controls } = this.personalDetails;

    for (const value in values) {
      if (controls.hasOwnProperty(value)) {
        this.personalDetails.controls[value].setValue(values[value]);
      }
    }

    this.personalDetails.controls['Phone'].setValue(values.PhoneNumber)

    if (values.Address) {
      this.showMap = true;
      this.latitude = values.Address.Location.lat;
      this.longitude = values.Address.Location.lng;
      this.getAddress(this.latitude, this.longitude);
    }
  }

  setCurrentLocation() {
    if ('geolocation' in navigator) {
      this.isSearching = true;
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.showMap = true;
        this.isSearching = false;
        this.showMessage = true;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }

  markerDragEnd($event: any) {
    const latitude = $event.latLng.lat();
    const longitude = $event.latLng.lng();
    this.getAddress(latitude, longitude);
  }

  getAddress(latitude, longitude) {
    const latlng = new google.maps.LatLng(latitude, longitude);
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ 'location': latlng }, (results, status) => {
      if (status !== google.maps.GeocoderStatus.OK) {
        alert(status);
      }
      // This is checking to see if the Geoeode Status is OK before proceeding
      if (status == google.maps.GeocoderStatus.OK) {
        this.completeAddress = (results[0].formatted_address);
        let address = results[0].address_components;
        this.personalDetails.controls['Address'].setValue(this.completeAddress);
        if (address.length < 6) {
          this.handleAddressFinal();
          return;
        }
        if (address.length == 8) {
          this.ExtNumber = Number(address[0].short_name),
            this.Street = address[1].short_name,
            this.IntNumber = '',
            this.Neighborhood = address[3].short_name,
            this.State = address[4].short_name,
            this.City = address[5].short_name
          this.ZipCode = Number(address[7].short_name)
        } else {
          this.ExtNumber = Number(address[0].short_name),
            this.Street = address[1].short_name,
            this.IntNumber = '',
            this.Neighborhood = address[2].short_name,
            this.State = address[3].short_name,
            this.City = address[4].short_name,
            this.ZipCode = Number(address[5].short_name) || Number(address[6].short_name)
        }
      }
    });
  }


  public get formState() {
    return this.personalDetails.controls;
  }


  public onSubmit(): void {
    this.submitted = true;

    if (this.personalDetails.invalid) {
      return;
    }
    if (this.completeAddress == undefined) {
      this.addressError();
      return;
    }

    if (this.personalDetails.controls['Check'].value === false) {
      this.terminosError();
      return;
    }

    if (this.onEdit) {
      this.editReport();
    } else {
      this.createReport();
    }

  }

  private editReport() {
    const report: ReportsModel = this.buildReport();
    this.isLoading = true;
    this.subs.push(
      this.reportService.updateReport(this.reportId, report)
        .subscribe(resolve => {
          this.folio = resolve.Folio;
          if (this.rol === 'user' || this.rol === 'jefe-manzana') {
            this.handleReportEdit();
            setTimeout(() => {
              this.router.navigateByUrl('/reports/my-user-reports');
            }, 3000);
          }
          if (this.rol === 'funcionario') {
            this.handleReportEdit();
            setTimeout(() => {
              this.router.navigateByUrl('/reports/my-area-reports');
            }, 3000);
          }
          if (this.rol === 'superadmin' || this.rol === 'admin' || this.rol === 'supervisor') {
            this.handleReportEdit();
            setTimeout(() => {
              this.router.navigateByUrl('/reports');
            }, 3000);
          }
        }, error => {
          this.handleError(error)
        })

    );
  }

  private createReport() {
    const report: ReportsModel = this.buildReport();
    // console.log(report)
    this.isLoading = true;
    this.subs.push(
      this.reportService.createReport(report)
        .subscribe(resolve => {
          this.folio = resolve.Folio;
          if (this.rol === 'user' || this.rol === 'jefe-manzana') {
            this.handleReportCreation();
            setTimeout(() => {
              this.router.navigateByUrl('/reports/my-user-reports');
            }, 3000);
          }
          if (this.rol === 'funcionario') {
            this.handleReportCreation();
            setTimeout(() => {
              this.router.navigateByUrl('/reports/my-area-reports');
            }, 3000);
          }
          if (this.rol === 'superadmin' || this.rol === 'admin' || this.rol === 'supervisor') {
            this.handleReportCreation();
            setTimeout(() => {
              this.router.navigateByUrl('/reports');
            }, 3000);
          }
        }, error => {
          this.handleError(error)
        })

    );
  }

  private buildReport(): ReportsModel {
    if (this.other.ShortDescription === 'Otro') {
      return {
        Subject: this.other.ShortDescription ? this.other.ShortDescription : '',
        Department: this.personalDetails.controls['Area'].value,
        Description: this.personalDetails.controls['Description'].value,
        Name: this.personalDetails.controls['Name'].value,
        PhoneNumber: this.personalDetails.controls['Phone'].value,
        Email: this.personalDetails.controls['Email'].value,
        Source: this.personalDetails.controls['Source'].value,
        ImgUrl: this.responseImgFile ? this.responseImgFile : '',
        UserId: this.authService.currentUserValue.id,
        Address: {
          Street: this.Street,
          Street1: this.personalDetails.controls['Street1'].value,
          Street2: this.personalDetails.controls['Street2'].value,
          ExtNumber: this.ExtNumber,
          IntNumber: this.IntNumber,
          Neighborhood: this.Neighborhood,
          ZipCode: this.ZipCode,
          State: this.State,
          City: this.City,
          Location: {
            lat: this.latitude,
            lng: this.longitude
          }
        }
      }
    } else {
      return {
        Subject: this.other.ShortDescription ? this.other.ShortDescription : '',
        Department: this.other.Department ? this.other.Department : '',
        Description: this.personalDetails.controls['Description'].value,
        Name: this.personalDetails.controls['Name'].value,
        PhoneNumber: this.personalDetails.controls['Phone'].value,
        Email: this.personalDetails.controls['Email'].value,
        Source: this.personalDetails.controls['Source'].value,
        ImgUrl: this.responseImgFile ? this.responseImgFile : '',
        UserId: this.authService.currentUserValue.id,
        Address: {
          Street: this.Street,
          Street1: this.personalDetails.controls['Street1'].value,
          Street2: this.personalDetails.controls['Street2'].value,
          ExtNumber: this.ExtNumber,
          IntNumber: this.IntNumber,
          Neighborhood: this.Neighborhood,
          ZipCode: this.ZipCode,
          State: this.State,
          City: this.City,
          Location: {
            lat: this.latitude,
            lng: this.longitude
          }
        }
      }
    }
  }

  private addressError() {
    const toastOptions: ToastOptions = {
      title: 'Alerta',
      msg: 'No has ingresado la dirección del reporte',
      showClose: true,
      timeout: 2000
    };

    this.toastyService.warning(toastOptions);
    return [];
  }

  private handleError(error: any) {
    const toastOptions: ToastOptions = {
      title: 'Error',
      msg: error.error.message,
      showClose: true,
      timeout: 2000
    };
    this.toastyService.error(toastOptions);
    return [];
  }

  private handleReportCreation() {
    const toastOptions: ToastOptions = {
      title: 'Reporte',
      msg: 'Reporte creado correctamente con el folio Nº ' + this.folio,
      showClose: true,
      timeout: 3000
    };

    this.toastyService.success(toastOptions);
    return [];
  }
  private handleReportEdit() {
    const toastOptions: ToastOptions = {
      title: 'Reporte',
      msg: 'Reporte editado correctamente',
      showClose: true,
      timeout: 3000
    };

    this.toastyService.success(toastOptions);
    return [];
  }

  private handleAddressFinal() {
    const toastOptions: ToastOptions = {
      title: 'Error',
      msg: 'Por favor agrega la dirección correctamente',
      showClose: true,
      timeout: 2000
    };

    this.toastyService.error(toastOptions);
    return [];
  }

  private terminosError() {
    const toastOptions: ToastOptions = {
      title: 'Alerta',
      msg: 'Debes marcar la casilla para ser contactado por un agente',
      showClose: true,
      timeout: 2000
    };

    this.toastyService.info(toastOptions);
    return [];
  }

  private NoTypeImg() {
    const toastOptions: ToastOptions = {
      title: 'Error',
      msg: 'Debes subir una imagen de tipo png o jpg',
      showClose: true,
      timeout: 1800
    };

    this.toastyService.error(toastOptions);
  }
}
