export class listCouponsDB {
    static list_coupons = [
        {
            nombre: " 10% Off",
            codigo: " Percent10",
            descuento: " 10%",
            status: "<i class=\"fa fa-circle font-success f-12\"></i>",
        },
        {
            nombre: " 25% Off",
            codigo: " Percent25",
            descuento: " 25%",
            status: "<i class=\"fa fa-circle font-warning f-12\"></i>",
        },
        {
            nombre: " 5% Off",
            codigo: " Percent5",
            descuento: " 5%",
            status: "<i class=\"fa fa-circle font-success f-12\"></i>",
        },
        {
            nombre: " 2% Off",
            codigo: " Percent2",
            descuento: " 2%",
            status: "<i class=\"fa fa-circle font-warning f-12\"></i>",
        },
        {
            nombre: " 50% Off",
            codigo: " Percent50",
            descuento: " 50%",
            status: "<i class=\"fa fa-circle font-danger f-12\"></i>",
        },
        {
            nombre: " 70% Off",
            codigo: " Percent70",
            descuento: " 70%",
            status: "<i class=\"fa fa-circle font-success f-12\"></i>",
        },
        {
            nombre: " 75% Off",
            codigo: " Percent75",
            descuento: " 75%",
            status: "<i class=\"fa fa-circle font-danger f-12\"></i>",
        },
        {
            nombre: " 80% Off",
            codigo: " Percent80",
            descuento: " 80%",
            status: "<i class=\"fa fa-circle font-success f-12\"></i>",
        },

    ]
}