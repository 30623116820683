import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { getHeaders } from 'src/app/shared/utils/headers.functions';
import { QuizModel } from '../../quizs/quizs.models';

const BASE_URL: string = environment.API_URL;

@Injectable({
  providedIn: 'root'
})
export class QuizsService {
  constructor(
    private http: HttpClient
  ) { }

  public getQuizs(limit:number, offset:number): Observable<any> {
    return this.http.get<any>(`${BASE_URL}/listofquestionnaires?limit=${limit}&offset=${offset}`);
  }

  public getQuiz(thisQuizId: string): Observable<any> {
    return this.http.get<any>(`${BASE_URL}/questionnairebyId/${thisQuizId}`);
  }

  public getSurveys(): Observable<any> {
    return this.http.get<any>(`${BASE_URL}/surveys`);
  }

  public getSurvey(surveyId: string): Observable<any> {
    return this.http.get<any>(`${BASE_URL}/survey/${surveyId}`);
  }

  public createQuiz(quiz: QuizModel): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.post<any>(`${BASE_URL}/evaluation`, quiz, { headers });
  }

  public updateQuiz(id: number, quiz: QuizModel): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.put<any>(`${BASE_URL}/quizs/${id}`, quiz, {headers});
  }

//   public getSalesByCustomer(id: string): Observable<any> {
//     return this.http.get<any>(`${BASE_URL}/sales-customer/${id}`);
//   }

//   public getCoursesByOrder(id: string): Observable<any> {
//     return this.http.get<any>(`${BASE_URL}/admin-sales-items/${id}`);
//   }
}
