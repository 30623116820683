import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { Subscription, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastyService } from 'ng2-toasty';
import { TicketsService } from '../../services/tickets/tickets.service';

@Component({
  selector: 'app-tickets-list',
  templateUrl: './tickets-list.component.html',
  styleUrls: ['./tickets-list.component.scss']
})
export class ListTicketsComponent implements OnInit, OnDestroy {
  public tickets = []
  private subs: Array<Subscription> = [];
  private modalContent: any;
  private modalConfig: any = {
    ariaLabelledBy: 'modal-basic'
  };
  public isLoading = false;
  public dtOptions: DataTables.Settings = {};
  public dtTrigger: Subject<any> = new Subject();
  public deleteIsSuccess = false;
  public editIsSuccess = false;
  public userId: string;
  public total: number;
  public page = 1;
  public limit: number = 10;
  public offset: number = 0;
  catalogs = [];
  public role:any;

  public perfil = [];

  area = '';
  profile:any;
  departments: any;

  public selectedTab: string = 'Todos';


  constructor(
    private authService: AuthenticationService,
    private ticketsService: TicketsService,
    private toastyService: ToastyService,
    private modalService: NgbModal
  ) {  }

  ngOnInit(): void {

    this.role = this.authService.currentUserValue.roles;

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      language: {
        emptyTable: '',
        zeroRecords: 'No hay coincidencias',
        lengthMenu: 'Mostrar _MENU_ elementos',
        search: 'Buscar:',
        info: 'De _START_ a _END_ de _TOTAL_ elementos',
        infoEmpty: 'De 0 a 0 de 0 elementos',
        infoFiltered: '(filtrados de _MAX_ elementos totales)',
        paginate: {
          first: 'Prim.',
          last: 'Últ.',
          next: 'Sig.',
          previous: 'Ant.'
        },
      },
    };

    this.subs.push(
      this.ticketsService
        .getTickets(this.limit, this.offset)
        .pipe(
          catchError(err => this.authService.handleUnauthorizedError(err))
        )
        .subscribe(data => {
          this.tickets = data.List;
          this.total = data.Count
          this.dtTrigger.next();
        }),
    );
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    this.subs.forEach(sub => sub.unsubscribe());
  }
  

  setModalContent(content: any, userId: string): void {
    this.userId = userId;
    this.editIsSuccess = false;
    this.modalContent = content;
    this.modalService.open(this.modalContent, this.modalConfig);
  }

  public filterTicketsbyStatus(status): void {
    this.selectedTab = status;
    if (this.selectedTab !== 'Todos') {
      this.subs.push(
        this.ticketsService
          .getTicketsByStatus(this.limit, this.offset, this.selectedTab)
          .pipe(
            catchError(err => this.authService.handleUnauthorizedError(err))
          )
          .subscribe(data => {
            this.tickets = data.List;
            this.total = data.Count
          }),
      );
    }
    else {
      this.subs.push(
        this.ticketsService
          .getTickets(this.limit, this.offset)
          .pipe(
            catchError(err => this.authService.handleUnauthorizedError(err))
          )
          .subscribe(data => {
            this.tickets = data.List;
            this.total = data.Count
          }),
      );
    }
  }

  public loadPage(page: number) {
    if (this.selectedTab !== 'Todos') {
      this.subs.push(
        this.ticketsService
          .getTicketsByStatus(this.limit, page-1, this.selectedTab)
          .pipe(
            catchError(err => this.authService.handleUnauthorizedError(err))
          )
          .subscribe(data => {
            this.tickets = data.List;
            this.total = data.Count
          }),
      );
    }
    else {
      this.subs.push(
        this.ticketsService
          .getTickets(this.limit, page-1)
          .pipe(
            catchError(err => this.authService.handleUnauthorizedError(err))
          )
          .subscribe(data => {
            this.tickets = data.List;
            this.total = data.Count
          }),
      );
    }
  }

}

