import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { content } from './shared/routes/content-routes';
import { ContentLayoutComponent } from './shared/layout/content-layout/content-layout.component';
import { InvitationComponent } from './components/auth/invitation/invitation.component';
import { LoginComponent } from './components/auth/login/login.component';
import { SignUpComponent } from './components/auth/sign-up/sign-up.component';
import { ThanksComponent } from './components/auth/thanks/thanks.component';
import { ActivateComponent } from './components/auth/activate/activate.component';
import { HomeComponent } from './components/auth/home/home.component';
import { ReportComponent } from './components/auth/report/report.component';
import { ForgotPasswordComponent } from './components/auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/auth/reset-password/reset-password.component';
import { WorkOrdertoPDFComponent } from './components/work-orders/work-order-toPdf/work-order-toPdf.component';
import { ViewTripComponent } from './components/trips/view-trip/view-trip.component';

const routes: Routes = [
  {
    path: '',
    /*redirectTo: 'dashboard/default',*/
    redirectTo: 'nuestra-boda',
    pathMatch: 'full'
  },
  {
    path: '',
    component: ContentLayoutComponent,
    children: content
  },
  {
    path: 'sign-in',
    component: LoginComponent,
  },
  {
    path: 'nuestra-boda',
    component: InvitationComponent,
  },
  {
    path: 'reporte',
    component: ReportComponent,
  },
  // {
  //   path: 'detalle',
  //   component: LoginComponent,
  // },
  // {
  //   path: 'registro',
  //   component: SignUpComponent,
  // },
  {
    path: 'registro-exitoso',
    component: ThanksComponent,
  },
  {
    path: 'activar-cuenta/:uuid',
    component: ActivateComponent,
  },
  {
    path: 'recuperar-contrasena',
    component: ForgotPasswordComponent,
  },
  {
    path: 'restablecer-contrasena/:token',
    component: ResetPasswordComponent,
  },
  {
    path: 'download/:id',
    component: WorkOrdertoPDFComponent,
  },
  {
    path: 'download-report/:id',
    component: WorkOrdertoPDFComponent,
  },
  {
    path: 'download-reportEvent/:id',
    component: WorkOrdertoPDFComponent,
  },
  {
    path: 'view-Trip/:tripId',
    component: ViewTripComponent,
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'legacy'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
