import { Component, OnInit, OnDestroy } from '@angular/core';
import { categoryDB } from '../../../../shared/tables/category';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription, Subject, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { map, catchError } from 'rxjs/operators';
import { FeatureModel } from '../../product.models';
import { ProductsService } from '../../../services/products/products.service';
import { AuthenticationService } from '../../../services/auth/authentication.service';
import { ToastyService, ToastyConfig, ToastOptions } from 'ng2-toasty';


@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.scss']
})
export class FeaturesComponent implements OnInit, OnDestroy {
  public closeResult: string;
  public catalogs: any;
  public features = []
  public featureForm: FormGroup;
  public isLoading = false;
  public submitted = false;
  public selectedCatalog: string;
  public values = [];
  public onEdit = false;

  private subs: Array<Subscription> = [];

  public dtOptions: DataTables.Settings = {};
  public dtTrigger: Subject<any> = new Subject();
  public deleteIsSuccess = false;
  public featureId: number;

  private modalContent: any;
  private modalConfig: any = {
    ariaLabelledBy: 'modal-basic'
  };

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private router: Router,
    private productService: ProductsService,
    private authService: AuthenticationService,
    private toastyService: ToastyService,
    private toastyConfig: ToastyConfig
    ) {
    this.toastyConfig.theme = 'bootstrap';
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      language: {
        emptyTable: '',
        zeroRecords: 'Selecciona el catálogo a consultar.',
        lengthMenu: 'Mostrar _MENU_ elementos',
        search: 'Buscar:',
        info: 'De _START_ a _END_ de _TOTAL_ elementos',
        infoEmpty: 'De 0 a 0 de 0 elementos',
        infoFiltered: '(filtrados de _MAX_ elementos totales)',
        paginate: {
          first: 'Prim.',
          last: 'Últ.',
          next: 'Sig.',
          previous: 'Ant.'
        },
      },
    };
    this.dtTrigger.next(); 

    this.featureForm = this.formBuilder.group({
      CatalogName: ['', Validators.required],
      ValueAlpha: ['', Validators.required]
    })

    this.subs.push(
      this.productService
        .getFeatures()
        .pipe(
          catchError(err => this.authService.handleUnauthorizedError(err))
        )
        .subscribe(data => {
          this.features = data;
        })
    );
    this.productService
      .getCatalogs()
      .subscribe(data => {
        this.catalogs = data;
      })
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }

  public getCatalogValues(): any {
    this.subs.push(
      this.productService.getValuestoCatalog(this.selectedCatalog)
        .pipe(catchError(err => {
          this.noResults();
          return throwError(err);
        }))
        .subscribe(data => {
          this.values = data;
          // this.dtTrigger.next();
        })
    );
  }

  public get formState() {
    return this.featureForm.controls
  }

  private buildFeature(): FeatureModel {
    return {
      CatalogName: this.featureForm.controls['CatalogName'].value,
      ValueAlpha: this.featureForm.controls['ValueAlpha'].value,
    }
  }

  private buildUpdateFeature(): FeatureModel {
    return {
      CatalogName: this.featureForm.controls['CatalogName'].value,
      ValueAlpha: this.featureForm.controls['ValueAlpha'].value,
    }
  }

  private handleError(error: any) {
    const toastOptions: ToastOptions = {
      title: 'Error',
      msg: 'No se pudo crear',
      showClose: true,
      timeout: 2000
    };

    this.toastyService.error(toastOptions);

    return [];
  }

  private handleFeatureCreation() {
    const toastOptions: ToastOptions = {
      title: 'Valor de catálogo',
      msg: 'Creado correctamente',
      showClose: true,
      timeout: 1700
    };

    this.toastyService.success(toastOptions);
  }

  private handleFeatureEdition() {
    const toastOptions: ToastOptions = {
      title: 'Valor de catálogo',
      msg: 'Actualizado correctamente',
      showClose: true,
      timeout: 1700
    };

    this.toastyService.success(toastOptions);
  }

  private noResults() {
    const toastOptions: ToastOptions = {
      title: 'Catálogos',
      msg: 'No se pudo consultar el catálogo',
      showClose: true,
      timeout: 1700
    };

    this.toastyService.error(toastOptions);
  }

  onSubmit() {
    this.submitted = true;

    if (this.featureForm.invalid) {
        return;
    }
    const feature: FeatureModel = this.buildFeature();
    this.isLoading = true;
    this.subs.push(
        this.productService.createFeature(feature)
        .pipe(
        map(() => {
            this.handleFeatureCreation();
            setTimeout(() => {
              this.modalService.dismissAll();
              this.productService.getValuestoCatalog(feature.CatalogName)
              .subscribe(data => {
                this.values = data;
              })
            }, 1000);
        }),
        catchError(error => this.handleError(error))
        )
        .subscribe()
    );
    this.featureForm = this.formBuilder.group({
      CatalogName: ['', Validators.required],
      ValueAlpha: ['', Validators.required]
    }) 
  }

  onUpdate() {
    this.submitted = true;

    if (this.featureForm.invalid) {
        return;
    }
    const feature: FeatureModel = this.buildUpdateFeature();
    this.isLoading = true;
    this.subs.push(
        this.productService.updateFeature(this.featureId, feature)
        .pipe(
        map(() => {
            this.handleFeatureEdition();
            setTimeout(() => {
              this.modalService.dismissAll();
              this.productService.getValuestoCatalog(feature.CatalogName)
              .subscribe(data => {
                this.values = data;
              })
            }, 1000);
        }),
        catchError(error => this.handleError(error))
        )
        .subscribe()
    );
    this.featureForm = this.formBuilder.group({
      CatalogName: ['', Validators.required],
      ValueAlpha: ['', Validators.required]
    }) 
  }

  openEdit(contentEdit: any, value: any): void {
    this.onEdit = true;
    this.featureId = value.id;
    this.featureForm = this.formBuilder.group({
      CatalogName: [value.CatalogName, Validators.required],
      ValueAlpha: [value.ValueAlpha, Validators.required]
    }) 
    this.modalContent = contentEdit;
    this.modalService.open(this.modalContent, this.modalConfig);
  }

  setModalContent(contentDelete: any, featureId: number): void {
    this.featureId = featureId;
    this.modalContent = contentDelete;
    this.modalService.open(this.modalContent, this.modalConfig);
  }

  public deleteFeature() {
    this.subs.push(
      this.productService.deleteFeature(this.featureId)
        .subscribe(resolve => {
          if (resolve.Active == false) {
            this.deleteIsSuccess = true;
            setTimeout(() => {
              this.modalService.dismissAll();
              window.location.reload();
            }, 2000);
          }
        })
    );
  }

}