import { Component, OnInit, OnDestroy } from '@angular/core';
import { UsersService } from '../../services/users/users.service';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { Subscription, Subject, throwError } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReportsService } from '../../services/reports/reports.service';
import * as moment from 'moment';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
@Component({
  selector: 'app-export-report',
  templateUrl: './export-report.component.html',
  styleUrls: ['./export-report.component.scss']
})
export class ExportReportComponent implements OnInit, OnDestroy {

  private subs: Array<Subscription> = [];
  private modalContent: any;
  private modalConfig: any = {
    ariaLabelledBy: 'modal-basic'
  };

  public reports = [];
  public startDate: string;
  public endDate: string;
  startDay: number;
  endDay: number;
  public week = ['Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado', 'Domingo'];
  public months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
  startMonth: number;
  endMonth: number;
  startYear: number;
  endYear: number;
  dayStart: string;
  dayEnd: string;
  currentDay: string;
  currentMonth: number;
  currentYear: number;

  constructor(
    private usersService: UsersService,
    private reportService: ReportsService,
    private authService: AuthenticationService,
    private modalService: NgbModal
  ) {  }

  ngOnInit(): void {
    this.getResults();
    let date = moment();
    this.currentDay = date.format('D');
    this.currentMonth = date.month();
    this.currentYear = date.year();
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
    localStorage.removeItem("start");
    localStorage.removeItem("end");
  }

  getResults(): any {
    this.startDate = localStorage["start"];
    this.endDate = localStorage["end"];

    this.dayStart = this.startDate.split("-")[2];
    this.dayEnd = this.endDate.split("-")[2];

    this.startDay = moment(this.startDate).day();
    this.endDay = moment(this.endDate).day();

    this.startMonth = moment(this.startDate).month();
    this.endMonth = moment(this.endDate).month();

    this.startYear = moment(this.startDate).year();
    this.endYear = moment(this.endDate).year();

    this.subs.push(
      this.reportService
        .getReportGeneral(this.startDate, this.endDate, 9999, 0)
        .pipe(
          catchError(err => this.authService.handleUnauthorizedError(err))
        )
        .subscribe(res => {
          this.reports = res.list;
        })
    );
  }

  getPDF(){
    const DATA = document.getElementById('element-to-print');
    
    const options = {
      scale: 1
    };

    html2canvas(DATA, options).then((canvas) => {

      const img = canvas.toDataURL("image/png");
      const doc = new jsPDF({
        orientation:'portrait',
        format: 'tabloid',
        compress: true
      });

      // Add font
      const myFont = 'assets/fonts/ZonaPro-Thin.otf' // load the *.ttf font file as binary string

      // add the font to jsPDF
      doc.addFileToVFS("ZonaPro-Thin.otf", myFont);
      doc.addFont("ZonaPro-Thin.otf", "ZonaPro", "normal");

      // Add image Canvas to PDF
      const bufferX = 15;
      const bufferY = 15;
      const imgProps = (doc as any).getImageProperties(img);
      const pdfWidth = doc.internal.pageSize.getWidth();
      const pdfHeight = doc.internal.pageSize.getHeight();
      doc.addImage(img, 'PNG', bufferX, bufferY, pdfWidth/1.15, pdfHeight/1.15);
      return doc;
    }).then((docResult) => {
      //docResult.save(`${new Date().toISOString()}_tutorial.pdf`);
      docResult.save(`Reportes_quincena.pdf`);
      
    });

  }

}

