import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, throwError } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { map, switchMap, catchError } from 'rxjs/operators';
import { AuthenticationService } from '../../services/auth/authentication.service';

@Component({
  selector: 'app-user-certificates',
  templateUrl: './certificates-user.component.html',
  styleUrls: ['./certificates-user.component.scss']
})
export class UserCertificatesComponent implements OnInit, OnDestroy {

  private subs: Array<Subscription> = [];
  public certificates: any;

  private modalConfig: any = {
    ariaLabelledBy: 'modal-basic'
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthenticationService
  ) {
  }

  ngOnInit() {
    this.subs.push(
      this.activatedRoute.paramMap.pipe(
        map(params => params.get('id')),
        switchMap(id => this.authService.getCertificatesStore(id)),
        catchError(error => {
          this.router.navigate(['/users/list-user']);
          return throwError(error);
        })
      ).subscribe(certificate => {
        this.certificates = certificate;
        // console.log(this.certificates);
      })
    );
  }


  ngOnDestroy(): void {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }
}
