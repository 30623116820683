import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription, Subject, throwError } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { map, switchMap, catchError } from 'rxjs/operators';
import { SalesService } from "../../../services/sales/sales.service";


@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.scss']
})
export class OrdersDetailsComponent implements OnInit, OnDestroy {

  private subs: Array<Subscription> = [];
  public order: any;
  public isCollapsed = true;
  public isCollapsedTran = true;

  public dtOptions: DataTables.Settings = {};
  public dtTrigger: Subject<any> = new Subject()

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private salesService: SalesService,
  ) {
  }


  ngOnInit() {
    this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: 10,
        language: {
          emptyTable: '',
          zeroRecords: 'No hay coincidencias',
          lengthMenu: 'Mostrar _MENU_ elementos',
          search: 'Buscar:',
          info: 'De _START_ a _END_ de _TOTAL_ elementos',
          infoEmpty: 'De 0 a 0 de 0 elementos',
          infoFiltered: '(filtrados de _MAX_ elementos totales)',
          paginate: {
            first: 'Prim.',
            last: 'Últ.',
            next: 'Sig.',
            previous: 'Ant.'
          },
        },
    };

    this.subs.push(
      this.activatedRoute.paramMap.pipe(
        map(params => params.get('id')),
        switchMap(id => this.salesService.getSale(id)),
        catchError(error => {
          this.router.navigate(['/sales/orders']);
          return throwError(error);
        })
      ).subscribe(order => {
        this.order = order;
        this.dtTrigger.next();
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }

  onSubmit() {
  }

}
