import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { catchError } from 'rxjs/internal/operators';
import { AuthenticationService } from 'src/app/components/services/auth/authentication.service';
import { ProductsService } from 'src/app/components/services/products/products.service';
import { digitalListDB } from 'src/app/shared/tables/digital-list';
@Component({
  selector: 'app-digital-list',
  templateUrl: './digital-list.component.html',
  styleUrls: ['./digital-list.component.scss']
})
export class DigitalListComponent implements OnInit {
  
  public digital_list = []
  private subs: Array<Subscription> = [];

  constructor(
    private productService: ProductsService,
    private authService: AuthenticationService,
  ) {
  }

  ngOnInit() {
    this.subs.push(
      this.productService
        .getProductsFiltered('Refacciones')
        .pipe(
          catchError(err => this.authService.handleUnauthorizedError(err))
        )
        .subscribe(data => {
          this.digital_list = data;
        })
    );
  }

}
