<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row mt-3">
        <div class="col-xl-12 col-xs-12">
            <div class="row">
                <div class="col-xl-8 col-xs-12">
                    <div class="form-group">
                        <input placeholder="Buscar por email, nombre, teléfono o folio" class="form-control" id="validationCustom1" type="text" [(ngModel)]="textSearch">
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-6">
                            <div class="form-group">
                                <input class="form-control" id="validationCustom2" type="date" [(ngModel)]="startDate">
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6">
                            <div class="form-group">
                                <input class="form-control" id="validationCustom3" type="date" [(ngModel)]="endDate">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-xs-12">
                    <button [hidden]="!endDate || hideButton" class="btn btn-primary" (click)="getResults()">
                        Filtrar
                    </button>
                    <button [hidden]="!showClean" title="Borrar filtros" class="btn btn-primary" (click)="clearInputs()">
                        Borrar filtros
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="row pl-3 mb-3" *ngIf="showTable">
        <div class="col-8 d-contents">
            <ul *ngFor="let item of reports?.status">
                <li class="list-filter" (click)="onFilerbyStatus(item.Status)">{{item.Status}} ({{item.count}})</li>
            </ul>
        </div>
        <div class="col-4 d-flex flex-row-reverse">
            <button class="btn btn-primary" (click)="exportExcel()">Exportar a Excel</button>
        </div>
    </div>
    <div class="card" *ngIf="!showTable">
        <div class="card-body">
            <p class="text-center">Por favor seleccione los filtros deseados para su reporte.</p>
        </div>
    </div>
    <div class="card" *ngIf="showTable">
        <div class="card-body">
            <div id="batchDelete" class="">
                <div class="table-responsive">
                    <table datatable
                    [dtOptions]="dtOptions"
                    [dtTrigger]="dtTrigger"
                    class=" table table-lg table-hover row-border hover"
                    id="repors-users">
                    <thead>
                        <tr>
                        <th>Folio</th>
                        <th>Fecha reporte</th>
                        <th>Nombre</th>
                        <th>Problema</th>
                        <th>Estatus</th>
                        <th>Opciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let report of reports?.List">
                        <td>{{report.folio}}</td>
                        <td>{{report.created}}</td>
                        <td>{{report.Name}}</td>
                        <td>{{report.Subject}}</td>
                        <td>{{report.Status}}</td>
                        <td class="actions-cell">
                            <span>
                            <a [routerLink]="['show', report.id]"
                                title="Ver reporte">
                                <u>Ver reporte</u>
                            </a>
                            </span>
                        </td>
                        </tr>
                    </tbody>
                    </table>
                    <ngb-pagination (pageChange)="loadPage($event)" [collectionSize]="total" [(page)]="page" [maxSize]="10" [rotate]="true" [boundaryLinks]="true"></ngb-pagination>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->