import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserModel, AuthModel, EstablishmentModel, UserCreateModel, EmailModel, PasswordModel } from '../../auth/auth.models';
import { environment } from 'src/environments/environment';
import { getHeaders } from '../../utils/headers.functions';
import { ClientRoles } from './auth.models';

const BASE_URL: string = environment.API_URL;


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private currentUserSubject: BehaviorSubject<AuthModel>;

  public currentUser: Observable<AuthModel>;

  constructor(
    private http: HttpClient,
    private router: Router
  ) {
    this.currentUserSubject = new BehaviorSubject<AuthModel>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): AuthModel {
    return this.currentUserSubject.value;
  }

  public login(user: UserModel): Observable<AuthModel> {
    return this.http.post<AuthModel>(`${BASE_URL}/auth/signin`, user, {
      // headers: {
      //   'Type-Client': accessType
      // }
    })
      .pipe(map(currentUser => this.setUser(currentUser)));
  }

  public forgotPassword(email: EmailModel): Observable<any> {
    return this.http.post<any>(`${BASE_URL}/auth/changePassword`, email);
  }

  public resetPassword(password: PasswordModel, resetToken: string): Observable<any> {
    return this.http.post<any>(`${BASE_URL}/auth/updatePassword/${resetToken}`, password);
  }

  public validate(uuid: string): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.put<any>(`${BASE_URL}/activate-business/${uuid}`, {headers});
  }

  public logout(): void {
    localStorage.removeItem('currentUser');
    localStorage.clear();
    this.currentUserSubject.next(null);
  }

  public handleUnauthorizedError(err: any) {
    if (err.error && err.error.status === 401) {
      this.logout();
      this.router.navigate(['/iniciar-sesion']);
    }

    return throwError('Session Expired');
  }

  public createUser(user: UserModel): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.post<any>(`${BASE_URL}/auth/signup`, user, { headers });
  }

  public getUser(id: (number | string)): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/user/${id}`);
  }

  public createUserPerson(user: UserCreateModel): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.post<any>(`${BASE_URL}/users`, user, { headers });
  }

  public editUserPerson(user: UserCreateModel, id: (number | string)): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.put<any>(`${BASE_URL}/user/${id}`, user, { headers });
  }

  public getStore(id: (number | string)): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/business/${id}`);
  }

  public getCertificatesStore(id: (number | string)): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/certificates/${id}`);
  }

  public updateUserStore(id: number, user: any): Observable<any> {
    // console.log(id, user);
    const headers: HttpHeaders = getHeaders();
    return this.http.put<any>(`${BASE_URL}/business/${id}`, user, {headers});
  }

  public validateFiles(files: any, id: (number | string)): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.put<any>(`${BASE_URL}/business-validate/${id}`, files, { headers });
  }

  private setUser(user: AuthModel) {
    localStorage.setItem('currentUser', JSON.stringify(user));
    this.currentUserSubject.next(user);
    return user;
  }

  public changePassword(user: UserModel): Observable<any> {
    const buildUser = this.change(user);
    const updatedUser = this.addBackendStructure(buildUser);
    const headers: HttpHeaders = getHeaders();
    return this.http.post<Observable<any>>(`${BASE_URL}/auth/change-password`, updatedUser, { headers });
  }

  public mapRequiredValues(user: any): any {
    const userInfo: any = {};

    userInfo.firstName = user.firstName;
    userInfo.lastName = user.lastName;
    userInfo.email = user.email;
    userInfo.phoneNumber = user.phoneNumber;

    return userInfo;
  }

  public mapRequiredValuesBusiness(user: any): any {
    const userInfo: any = {};

    userInfo.Name = user.Name;
    userInfo.TaxRegime = user.TaxRegime;
    userInfo.TaxId = user.TaxId;
    userInfo.OfficePhone = user.OfficePhone;
    userInfo.MobilePhone = user.MobilePhone;
    userInfo.LocalType = user.LocalType;
    userInfo.ContactEmail = user.ContactEmail;
    userInfo.Address = user.Address;
    userInfo.Description = user.Description;

    return userInfo;
  }

  public mapRequiredValuesForPassword(user: any): any {
    const userInfo: any = {};
    userInfo.email = user.email;
    return userInfo;
  }

  public mapRequiredValuesForAngel(user:any):any{
    const userInfo: any = {};
    userInfo.ContactName = user.ContactName;
    userInfo.ContactPhoneNumber = user.ContactPhoneNumber;

    return userInfo;
  }

  private change(user: any): any {
    const changePassword = {
      email: user.email,
      password: user.password
    };
    return changePassword;
  }

  private addBackendStructure(user: any) {
    return {
      email: user.email,
      password: user.password
    };
  }
}
