import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { Subscription, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SurveysService } from '../../services/surveys/surveys.service';
import { ToastOptions, ToastyConfig, ToastyService } from 'ng2-toasty';
import { ClientRoles } from '../../services/auth/auth.models';
import { Router } from '@angular/router';


@Component({
  templateUrl: './occupation.component.html',
  styleUrls: ['./occupation.component.scss']
})
export class OccupationComponent implements OnInit, OnDestroy {

  private subs: Array<Subscription> = [];
  public dtOptions: DataTables.Settings = {};
  public dtTrigger: Subject<any> = new Subject();

  public UserId: string;
  public history: any;
  public total: number;

  public ForeignGuestAvg: number | string;
  public GuestNightAvg: number | string;
  public LocalGuestAvg: number | string;
  public RoomPriceAvg: number | string;

   /*Archivos*/
   public requiredFileType: string;
   public FormatfileName = '';
   public viewLoaderFormat = false;
   public responseFormat: string;
   public previewFormat: any;

   public isAdmin: boolean;
	 public isBusiness: boolean;

  constructor(
    private router: Router,
    private surveysService: SurveysService,
    private authService: AuthenticationService,
    private toastyService: ToastyService,
    private toastyConfig: ToastyConfig,
  ) {
    this.toastyConfig.theme = 'bootstrap';
    this.isAdmin = this.authService.currentUserValue.roles.includes(ClientRoles.Admin);
   	this.isBusiness = this.authService.currentUserValue.roles.includes(ClientRoles.Business);
  }

  onFormatSelected(event) {
    const file:File = event.target.files[0];
    if(file.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
      this.viewLoaderFormat = true;
      this.FormatfileName = 'Cargando archivo...';
      setTimeout(() => {
        if (file) {
          const formData = new FormData();
          formData.append('InterviewerId' , this.UserId);
          formData.append('FileReport' , file);

          this.subs.push(
            this.surveysService.uploadXLXS(formData)
              .subscribe(resolve => {
                // console.log(resolve);
                if (resolve.Message === 'archivo leido correctamente') {
                  this.responseFormat = resolve;
                  this.viewLoaderFormat = false;
                  // this.previewFormat = "assets/images/check.png";
                  this.FormatfileName = 'Archivo cargado correctamente';
                  this.FileCorrect();
                  setTimeout(() => {
                    this.ngOnInit();
                  }, 2000);
                }
              })
          );
        }
      }, 6000);
      
    }else{
      this.NoTypeFile()
    }
  }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      language: {
        emptyTable: '',
        zeroRecords: 'No hay coincidencias',
        lengthMenu: 'Mostrar _MENU_ elementos',
        search: 'Buscar:',
        info: 'De _START_ a _END_ de _TOTAL_ elementos',
        infoEmpty: 'De 0 a 0 de 0 elementos',
        infoFiltered: '(filtrados de _MAX_ elementos totales)',
        paginate: {
          first: 'Prim.',
          last: 'Últ.',
          next: 'Sig.',
          previous: 'Ant.'
        },
      },
    };
    
    this.UserId = this.authService.currentUserValue.id
    this.subs.push(
      this.surveysService
        .getHistoryReport()
        .pipe(
          catchError(err => this.authService.handleUnauthorizedError(err))
        )
        .subscribe(data => {
          this.history = data;
        })
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  public loadPage(page: number) {
    this.subs.push(
      this.surveysService
        .getHistoryReport()
        .pipe(
          catchError(err => this.authService.handleUnauthorizedError(err))
        )
        .subscribe(data => {
          this.history = data;
          this.total = this.history.length;
        })
    );
  }

  private NoTypeFile() {
    const toastOptions: ToastOptions = {
      title: 'Error',
      msg: 'Debes subir un archivo de tipo xlsx',
      showClose: true,
      timeout: 1800
    };

    this.toastyService.error(toastOptions);
  }

  private FileCorrect() {
    const toastOptions: ToastOptions = {
      title: 'Correcto',
      msg: 'Archivo cargado correctamente',
      showClose: true,
      timeout: 1800
    };

    this.toastyService.success(toastOptions);
  }

}
