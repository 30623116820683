import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Ng2SmartTableModule } from 'ng2-smart-table';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { DataTablesModule } from 'angular-datatables';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SurveysComponent } from './surveys-list/surveys.component';
import { SurveysRoutingModule } from './surveys-routing.module';
import { SurveysDetailsComponent } from './survey-detail/survey-detail.component';
import { OccupationComponent } from './occupation/occupation.component';
import { StudiesComponent } from './studies/studies.component';
import { ResultsComponent } from './results/results.component';
import { ToastyModule } from 'ng2-toasty';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ChartistModule } from 'ng-chartist';
import { QRCodeModule } from 'angularx-qrcode';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OccupationResultsComponent } from './occupation-result/occupation-results.component';

@NgModule({
  declarations: [
    SurveysComponent,
    SurveysDetailsComponent,
    OccupationComponent,
    OccupationResultsComponent,
    StudiesComponent,
    ResultsComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DataTablesModule,
    SurveysRoutingModule,
    Ng2SmartTableModule,
    NgxDatatableModule,
    NgbModule,
    ToastyModule,
    NgxChartsModule,
    ChartistModule,
    QRCodeModule,
    NgSelectModule
  ]
})
export class SurveysModule { }
