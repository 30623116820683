import { Component, OnInit, OnDestroy } from '@angular/core';
import { UsersService } from '../../services/users/users.service';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { Subscription, Subject, throwError } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReportsService } from '../../services/reports/reports.service';
import * as XLSX from 'xlsx';
import { SharedService } from '../../services/shared.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reports-area',
  templateUrl: './reports-area.component.html',
  styleUrls: ['./reports-area.component.scss']
})
export class ReportsAreaComponent implements OnInit, OnDestroy {

  fileName= 'Reportes-Área.xlsx';

  private subs: Array<Subscription> = [];
  private modalContent: any;
  private modalConfig: any = {
    ariaLabelledBy: 'modal-basic'
  };

  public reports: any;
  public values: any;
  public dtOptions: DataTables.Settings = {};
  public dtTrigger: Subject<any> = new Subject();
  public deleteIsSuccess = false;
  public userId: string;
  public total: number;
  public page = 1;
  public limit: number = 10;
  public offset: number = 0;
  public area: string;
  public startDate: string;
  public endDate: string;
  public showClean = false;
  public showTable = false;
  public hideButton = false;

  public status = 'Total';

  constructor(
    private usersService: UsersService,
    private reportService: ReportsService,
    private authService: AuthenticationService,
    private modalService: NgbModal,
    private sharedService: SharedService,
    private router: Router,
  ) {  }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      language: {
        emptyTable: '',
        zeroRecords: 'No se han encontrado resultados',
        lengthMenu: 'Mostrar _MENU_ elementos',
        search: 'Buscar:',
        info: 'De _START_ a _END_ de _TOTAL_ elementos',
        infoEmpty: 'De 0 a 0 de 0 elementos',
        infoFiltered: '(filtrados de _MAX_ elementos totales)',
        paginate: {
          first: 'Prim.',
          last: 'Últ.',
          next: 'Sig.',
          previous: 'Ant.'
        },
      },
      order: [0, 'desc']
    };

    this.reportService
      .getValuestoCatalog('Departments')
      .subscribe(data => {
        this.values = data;
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    this.subs.forEach(sub => sub.unsubscribe());
  }

  generateReport(){
    localStorage.setItem("start", this.startDate);
    localStorage.setItem("end", this.endDate);
    this.sharedService.setData(this.reports, this.area);
    this.router.navigateByUrl('/reports/area/export');
  }

  getResults(): any {
    this.showClean = true;
    this.showTable = true;
    this.hideButton = true;
    this.subs.push(
      this.reportService
        .getReportArea(this.area, this.startDate, this.endDate, this.limit, this.offset, this.status)
        .pipe(
          catchError(err => this.authService.handleUnauthorizedError(err))
        )
        .subscribe(data => {
          this.reports = data;
          this.total = data.Count
          this.dtTrigger.next();
          
        })
    );
  }

  clearInputs(): any {
    this.area = '';
    this.startDate = '';
    this.endDate = '';
    this.showClean = false;
    this.showTable = false;
    this.hideButton = false;
    this.ngOnDestroy();
  }

  setModalContent(content: any, userId: string): void {
    this.userId = userId;
    this.modalContent = content;
    this.modalService.open(this.modalContent, this.modalConfig);
  }

  onFilerbyStatus(status: string): void {
    this.status = status;
    this.subs.push(
      this.reportService
        .getReportArea(this.area, this.startDate, this.endDate, this.limit, this.offset, this.status)
        .pipe(
          catchError(err => this.authService.handleUnauthorizedError(err))
        )
        .subscribe(data => {
          this.reports = data;
          this.total = data.Count
        })
    );
  }

  public loadPage(page: number) {
    this.subs.push(
      this.reportService
        .getReportArea(this.area, this.startDate, this.endDate, this.limit, this.offset, this.status)
        .pipe(
          catchError(err => this.authService.handleUnauthorizedError(err))
        )
        .subscribe(data => {
          this.reports = data;
          this.total = data.Count         
        })
    );
  }

  exportExcel(): void
  {
    let element = document.getElementById('reports-area');
    const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);
 
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Reportes');
  
    XLSX.writeFile(wb, this.fileName);
 
  }

  public deleteUser() {
    this.subs.push(
      this.usersService.deleteUser(this.userId)
        .subscribe(resolve => {
          if (resolve.active == false) {
            this.deleteIsSuccess = true;
            setTimeout(() => {
              this.modalService.dismissAll();
              window.location.reload();
            }, 2000);
          }
        })
    );
  }

}

