import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataTablesModule } from 'angular-datatables';

import { Ng2SmartTableModule } from 'ng2-smart-table';
import { ClientsRoutingModule } from './clients-routing.module';
import { ListClientComponent } from './list-client/list-client.component';
import { ClientDetailsComponent } from './client-detail/client-detail.component';

import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastyModule } from 'ng2-toasty';

@NgModule({
  declarations: [ListClientComponent, ClientDetailsComponent],
  imports: [
    CommonModule,
    NgbModule,
    DataTablesModule,
    Ng2SmartTableModule,
    ReactiveFormsModule,
    ClientsRoutingModule,
    ToastyModule
  ]
})
export class ClientsModule { }
