import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { Subscription, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SurveysService } from '../../services/surveys/surveys.service';
import * as chartData from '../../../shared/data/chart';
import { doughnutData, pieData, sexo, question1, question2, question3, question4 } from '../../../shared/data/chart';



@Component({
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss']
})
export class ResultsComponent implements OnInit, OnDestroy {
  public results = [];

  private subs: Array<Subscription> = [];
  private modalContent: any;
  private modalConfig: any = {
    ariaLabelledBy: 'modal-basic'
  };

  public dtOptions: DataTables.Settings = {};
  public dtTrigger: Subject<any> = new Subject();
  public submitted = false;

  public surveyId: number;
  public form: FormGroup;
  public page = 1;
  public limit = 10;
  public offset = 0;

  public sexo = sexo
  public question1 = question1
  public question2 = question2
  public question3 = question3
  public question4 = question4

  public doughnutData = doughnutData;
  public pieData = pieData;

  constructor(
    private fb: FormBuilder,
    private surveysService: SurveysService,
    private authService: AuthenticationService,
    private modalService: NgbModal
  ) {
    Object.assign(this, { doughnutData, pieData, sexo, question1, question2, question3 })
  }

  // doughnut 2
  public view = chartData.view;
  public doughnutChartColorScheme = chartData.doughnutChartcolorScheme;
  public doughnutChartShowLabels = chartData.doughnutChartShowLabels;
  public doughnutChartGradient = chartData.doughnutChartGradient;
  public doughnutChartTooltip = chartData.doughnutChartTooltip;

  public chart5 = chartData.chart5;

  public chart3 = chartData.chart3;
  public chart6 = chartData.chart6;
  public age = chartData.age;
  public question5 = chartData.question5;

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      language: {
        emptyTable: '',
        zeroRecords: 'No hay coincidencias',
        lengthMenu: 'Mostrar _MENU_ elementos',
        search: 'Buscar:',
        info: 'De _START_ a _END_ de _TOTAL_ elementos',
        infoEmpty: 'De 0 a 0 de 0 elementos',
        infoFiltered: '(filtrados de _MAX_ elementos totales)',
        paginate: {
          first: 'Prim.',
          last: 'Últ.',
          next: 'Sig.',
          previous: 'Ant.'
        },
      },
      order:[[1, 'desc']]
    };

    this.subs.push(
      this.surveysService
        .getSurveys()
        .pipe(
          catchError(err => this.authService.handleUnauthorizedError(err))
        )
        .subscribe(data => {
          this.results = data;
          this.dtTrigger.next();
        })
    );
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    this.subs.forEach(sub => sub.unsubscribe());
  }

  public setModalContent(content: any, surveyId: number): void {
    this.surveyId = surveyId;
    this.modalContent = content;
    this.modalService.open(this.modalContent, this.modalConfig);
  }

  public get formState() {
    return this.form.controls;
  }

  public loadPage(page: number) {
    this.subs.push(
      this.surveysService
        .getSurveys()
        .pipe(
          catchError(err => this.authService.handleUnauthorizedError(err))
        )
        .subscribe(data => {
          this.results = data;
        })
    );
  }

}
