<div class="container-fluid">
    <div class="row">
        <div class="col-xl-5">
            <div class="card">
                <div class="card-body">
                    <div class="profile-details">
                        <h5 class="f-w-600 mb-0">Pedido Nº. {{order?.id}} <span [ngClass]="{'open': order?.SaleOrderStatus == 'Abierto', 'transit': order?.SaleOrderStatus == 'En tránsito', 'close-order': order?.SaleOrderStatus == 'Cerrado'}">{{order?.SaleOrderStatus}}</span></h5>
                        <hr>
                        <div class="table-responsive profile-table">
                            <table class="table table-responsive">
                                <tbody>
                                    <tr>
                                        <td>Fecha de Pedido:</td>
                                        <td>{{order?.SaleOrderDate | date}}</td>
                                    </tr>
                                    <tr>
                                        <td>Método de Pago:</td>
                                        <td>{{order?.PaymentMethod}}</td>
                                    </tr>
                                    <tr>
                                        <td>Subtotal:</td>
                                        <td>{{order?.SubtotalAmount | currency}} {{order?.CurrencyCode}}</td>
                                    </tr>
                                    <tr>
                                        <td>Envío:</td>
                                        <td>{{order?.TaxesAmount | currency}} {{order?.CurrencyCode}}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Total:</strong></td>
                                        <td><strong>{{order?.TotalAmount | currency}} {{order?.CurrencyCode}}</strong></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-7">
            <div class="card tab2-card">
                <div class="card-body">
                    <div class="tab-pane fade show active" id="top-profile" role="tabpanel"
                        aria-labelledby="top-profile-tab">
                        <h5 class="f-w-600">Detalles de entrega</h5>
                        <div class="table-responsive profile-table">
                            <table class="table table-responsive">
                                <tbody>
                                    <tr>
                                        <td>Nombre cliente:</td>
                                        <td>{{order?.Customer.firstName}} {{order?.Customer.lastName}}</td>
                                    </tr>
                                    <tr>
                                        <td>Correo electrónico:</td>
                                        <td>{{order?.Customer.email}}</td>
                                    </tr>
                                    <tr>
                                        <td>Teléfono:</td>
                                        <td>{{order?.Customer.phoneNumber}}</td>
                                    </tr>
                                    <tr>
                                        <td>Dirección Entrega:</td>
                                        <td>{{order?.Address.Street}} #{{order?.Address.ExtNumber}} {{order?.Address.Neighborhood}} {{order?.Address.ZipCode}} {{order?.Address.City}}, {{order?.Address.State}}</td>
                                    </tr>
                                    <tr>
                                        <td>Nº de guía:</td>
                                        <td *ngIf="!order?.TrackingNumber"><strong>Sin Nº de guía</strong></td>
                                        <td *ngIf="order?.TrackingNumber"><strong>{{order?.TrackingNumber}}</strong></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <p>
        <button type="button" class="btn btn-outline-primary" (click)="isCollapsed = !isCollapsed"
                [attr.aria-expanded]="!isCollapsed" aria-controls="collapseItems">
          Productos de Pedido
        </button>
    </p>
    <div id="collapseItems" [ngbCollapse]="isCollapsed">
        <div class="card">
          <div class="card-body">
            <div id="batchDelete" class="">
                <div class="table-responsive">
                    <table
                    class=" table table-lg table-hover row-border hover">
                    <thead>
                        <tr>
                        <th class="w-img">Imagen</th>
                        <th>Cantidad</th>
                        <th>Producto</th>
                        <th>Modelo</th>
                        <th>Precio unitario</th>
                        <th>Importe</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of order?.SaleOrderItem">
                        <td><img [src]="[item.ImgFileUrl]" class="img-fluid blur-up lazyload bg-img" alt=""></td>
                        <td>{{item.SaleOrderItem.Quantity}}</td>
                        <td>{{item.Title}}</td>
                        <td>{{item.Model}}</td>
                        <td>{{item.SaleOrderItem.UnitPrice | currency}} {{order?.CurrencyCode}}</td>
                        <td>{{item.SaleOrderItem.TotalAmount | currency}} {{order?.CurrencyCode}}</td>
                        </tr>
                    </tbody>
                    </table>
                </div>
            </div>
          </div>
        </div>
    </div>
    <hr>
    <!-- <p>
        <button type="button" class="btn btn-outline-primary" (click)="isCollapsedTran = !isCollapsedTran"
                [attr.aria-expanded]="!isCollapsed" aria-controls="collapseTran">
          Detalles Transacción
        </button>
    </p>
    <div id="collapseTran" [ngbCollapse]="isCollapsedTran">
        <div class="card">
          <div class="card-body">
            Aquí irán los datos de la trnsacción.
          </div>
        </div>
    </div> -->
</div>
<!-- Container-fluid Ends-->
  