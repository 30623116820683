export enum ClientRoles {
    Admin = 'admin',
    Business = 'business',
    AdminOT = 'otadmin',
    AdminRNT = 'rntadmin'
}

export enum ClientDepartments {
    Admin = 'admin',
    Agricola = 'Agricola',
    Construccion = 'Construccion',
    Jardin = 'Jardin y Golf'
}