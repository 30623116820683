import { Component, OnInit, OnDestroy } from '@angular/core';
import { UsersService } from '../services/users/users.service';
import { AuthenticationService } from '../services/auth/authentication.service';
import { Subscription, Subject, throwError } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { ReportsService } from '../services/reports/reports.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastyService, ToastyConfig, ToastOptions } from 'ng2-toasty';


@Component({
    selector: 'app-catalogs',
    templateUrl: './catalogs.component.html',
    styleUrls: ['./catalogs.component.scss']
})

export class CatalogsComponent implements OnInit, OnDestroy {

    public dtOptions: DataTables.Settings = {};
    public dtTrigger: Subject<any> = new Subject();
    public page = 1;
    public limit: number = 10;
    public offset: number = 0;
    public catalogs = [];
    public catalogName: any;
    public valueAlpha = '';
    public departments = [];
    public areaSelected: any;
    public isSuccess = false;
    public isLoading = false;
    public problemForm: FormGroup;
    public deleteIsSuccess = false;
    private subs: Array<Subscription> = [];
    private modalContent: any;
    private modalConfig: any = {
        ariaLabelledBy: 'modal-basic'
    };
    catalogId: any;
    onEdit: boolean = false;
    catalog: any;
    total: number;
    departmentSelected = '';

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private authService: AuthenticationService,
        private reportsService: ReportsService,
        private formBuilder: FormBuilder,
        private toastyService: ToastyService,
        private modalService: NgbModal
    ) { }

    dtElement: any;

    ngOnInit(): void {
        this.catalogName = this.activatedRoute.snapshot.url[0].path;

        this.dtOptions = {
            pagingType: 'full_numbers',
            destroy: true,
            pageLength: 100,
            language: {
                emptyTable: '',
                zeroRecords: 'No se han encontrado resultados',
                lengthMenu: 'Mostrar _MENU_ elementos',
                search: 'Buscar:',
                info: 'De _START_ a _END_ de _TOTAL_ elementos',
                infoEmpty: 'De 0 a 0 de 0 elementos',
                infoFiltered: '(filtrados de _MAX_ elementos totales)',
                paginate: {
                    first: 'Prim.',
                    last: 'Últ.',
                    next: 'Sig.',
                    previous: 'Ant.'
                },
            },
            order: [0, 'desc']
        };


        this.vaciaForm();

        switch (this.catalogName) {
            case 'areas':
                this.subs.push(
                    this.reportsService.getDataCatalogs("Departments", this.limit, this.offset).subscribe(data => {
                        // console.log(data)
                        this.catalogs = data.list;
                        this.total = data.Count;
                        this.rerender();
                    })
                )
                break;
            case 'services':
                this.subs.push(
                    this.reportsService.getValuestoCatalog("Departments").subscribe(data => {
                        this.departments = data;
                    }),
                    this.reportsService.getAreaServices(this.limit, this.offset).subscribe(data => {
                        this.catalogs = data.Results;
                        this.total = data.Count;
                        this.rerender();
                    })
                )
                break;
            case 'sources':
                this.subs.push(
                    this.reportsService.getDataCatalogs("SourceReport", this.limit, this.offset).subscribe(data => {
                        // console.log(data);
                        this.catalogs = data.list;
                        this.total = data.Count;
                        this.rerender();
                    })
                )
                break;
            case 'users':
                this.subs.push(
                    this.reportsService.getDataCatalogs("Perfil", this.limit, this.offset).subscribe(data => {
                        // console.log(data)
                        this.catalogs = data.list;
                        this.total = data.Count;
                        this.rerender();
                    })
                )
                break;
        }




    }

    vaciaForm() {
        this.problemForm = this.formBuilder.group({
            ShortDescription: ['', Validators.required],
            Description: ['', Validators.required],
            Department: ['', Validators.required],
            Tags: [''],
            id: ['']
        })
    }

    ngOnDestroy(): void {
        this.dtTrigger.unsubscribe();
        this.subs.forEach((sub: Subscription) => sub.unsubscribe());
    }

    rerender(): void {
        this.dtElement?.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
            this.dtTrigger.next();
        });
    }

    ngAfterViewInit(): void {
        this.dtTrigger.next();
    }

    public setModalContent(content: any, catalog: any): void {
        this.catalogId = catalog.id;
        this.modalContent = content;
        this.modalService.open(this.modalContent, this.modalConfig);
        this.onEdit = catalog === '' ? false : true;
        if (this.catalogName !== 'services') {
            this.valueAlpha = this.onEdit ? catalog.ValueAlpha : '';
        } else {
            if (this.onEdit) {
                this.setValues(catalog);
            } else {
                this.vaciaForm();
            }
        }
    }


    addArea(catalog: any) {
        this.subs.push(
            this.reportsService.saveItemCatalog(catalog).subscribe(res => {
                this.handleValueAsigned();
                setTimeout(() => {
                    this.modalService.dismissAll();
                    this.isLoading = false;
                    this.isSuccess = false;
                    this.valueAlpha = '';
                    window.location.reload();
                }, 3000)
            }))
    }

    addService(catalog: any) {
        this.subs.push(
            this.reportsService.createProblem(catalog).subscribe(res => {
                this.handleValueAsigned();
                setTimeout(() => {
                    this.modalService.dismissAll();
                    this.isLoading = false;
                    this.isSuccess = false;
                    this.valueAlpha = '';
                    this.vaciaForm();
                    window.location.reload();
                }, 3000)
            }))
    }

    addSource(catalog: any) {
        this.subs.push(
            this.reportsService.saveItemCatalog(catalog).subscribe(res => {
                this.handleValueAsigned();
                setTimeout(() => {
                    this.modalService.dismissAll();
                    this.isLoading = false;
                    this.isSuccess = false;
                    this.valueAlpha = '';
                    window.location.reload();
                }, 3000)
            }))
    }

    addUser(catalog: any) {
        this.subs.push(
            this.reportsService.saveItemCatalog(catalog).subscribe(res => {
                this.handleValueAsigned();
                setTimeout(() => {
                    this.modalService.dismissAll();
                    this.isLoading = false;
                    this.isSuccess = false;
                    this.valueAlpha = '';
                    window.location.reload();
                }, 3000)
            }))
    }

    deleteCatalog() {
        this.isLoading = true;
        if (this.catalogName === 'services') {
            this.subs.push(
                this.reportsService.deleteService(this.catalogId).subscribe(res => {
                    this.handleValueDeleted()
                    setTimeout(() => {
                        this.modalService.dismissAll();
                        this.isLoading = false;
                        this.deleteIsSuccess = true;
                        window.location.reload();
                    }, 3000)
                })
            )
        } else {
            this.subs.push(
                this.reportsService.deleteCatalogItem(this.catalogId).subscribe(res => {
                    this.handleValueDeleted()
                    setTimeout(() => {
                        this.modalService.dismissAll();
                        this.isLoading = false;
                        this.deleteIsSuccess = true;
                        window.location.reload();
                    }, 3000)

                })
            )
        }
    }

    filterArea() {
        if (this.departmentSelected === "Todos") {
            this.departmentSelected = '';
            this.ngOnInit();
        } else {
            this.subs.push(
                this.reportsService.getServicesbyArea(this.departmentSelected).subscribe(data => {
                    this.catalogs = data.Results;
                    this.total = data.Count;
                })
            )
        }
    }

    setValues(catalog: any) {
        const { controls } = this.problemForm;
        for (const value in catalog) {
            if (controls.hasOwnProperty(value)) {
                this.problemForm.controls[value].setValue(catalog[value]);
            }
        }
    }

    onSubmit() {
        this.isLoading = true;
        if (this.onEdit) {
            this.updateItem()
        } else {
            this.saveItem();
        }
    }


    updateItem() {
        if (this.catalogName === 'services') {
            let nCatalog = {
                ShortDescription: this.problemForm.controls['ShortDescription'].value,
                Description: this.problemForm.controls['Description'].value,
                Department: this.problemForm.controls['Department'].value,
                Tags: this.problemForm.controls['Tags'].value
            }
            this.subs.push(
                this.reportsService.updateService(this.catalogId, nCatalog).subscribe(res => {
                    this.handleValueUpdate()
                    setTimeout(() => {
                        this.modalService.dismissAll();
                        this.isLoading = false;
                        window.location.reload();
                    }, 3000)
                })
            )
        } else {
            this.subs.push(
                this.reportsService.updateCatalogItem(this.catalogId, { ValueAlpha: this.valueAlpha }).subscribe(res => {
                    this.handleValueUpdate()
                    setTimeout(() => {
                        this.modalService.dismissAll();
                        this.isLoading = false;
                        window.location.reload();
                    }, 3000)
                })
            )
        }
    }

    saveItem() {
        let nCatalog = null;

        if (this.catalogName === 'services') {
            if (this.problemForm.invalid) {
                return;
            }
        } else {
            if (this.valueAlpha === '') {
                return;
            }
        }

        switch (this.catalogName) {
            case 'areas':
                nCatalog = {
                    CatalogName: "Departments",
                    ValueAlpha: this.valueAlpha
                }
                this.addArea(nCatalog);
                break;
            case 'services':
                nCatalog = {
                    ShortDescription: this.problemForm.controls['ShortDescription'].value,
                    Description: this.problemForm.controls['Description'].value,
                    Department: this.problemForm.controls['Department'].value,
                    Tags: this.problemForm.controls['Tags'].value
                }
                // console.log(nCatalog)
                this.addService(nCatalog);
                break;
            case 'sources':
                nCatalog = {
                    CatalogName: "SourceReport",
                    ValueAlpha: this.valueAlpha
                }
                this.addSource(nCatalog);
                break;
            case 'users':
                nCatalog = {
                    CatalogName: "Perfil",
                    ValueAlpha: this.valueAlpha
                }
                this.addUser(nCatalog);
                break;
        }

    }

    public loadPage(page: number) {
        switch (this.catalogName) {
            case 'areas':
                this.subs.push(
                    this.reportsService.getDataCatalogs("Departments", this.limit, page - 1).subscribe(data => {
                        // console.log(data)
                        this.catalogs = data.list;
                        this.total = data.Count;
                        this.rerender();
                    })
                )
                break;
            case 'services':
                this.subs.push(
                    this.reportsService.getValuestoCatalog("Departments").subscribe(data => {
                        this.departments = data;
                    }),
                    this.reportsService.getAreaServices(this.limit, page - 1).subscribe(data => {
                        this.catalogs = data.Results;
                        this.total = data.Count;
                    })
                )
                break;
            case 'sources':
                this.subs.push(
                    this.reportsService.getDataCatalogs("SourceReport", this.limit, page - 1).subscribe(data => {
                        // console.log(data);
                        this.catalogs = data;
                        this.total = data.Count;
                        this.rerender();
                    })
                )
                break;
            case 'users':
                this.subs.push(
                    this.reportsService.getDataCatalogs("Perfil", this.limit, page - 1).subscribe(data => {
                        // console.log(data)
                        this.catalogs = data;
                        this.total = data.Count;
                        this.rerender();
                    })
                )
                break;
        }
    }

    private handleValueAsigned() {
        // this.isLoading = false;
        const toastOptions: ToastOptions = {
            title: 'Atención ciudadana',
            msg: 'Valor agregado correctamente',
            showClose: true,
            timeout: 2500
        };

        this.toastyService.success(toastOptions);
    }

    private handleValueDeleted() {
        // this.isLoading = false;
        const toastOptions: ToastOptions = {
            title: 'Atención ciudadana',
            msg: 'Catálogo eliminado correctamente',
            showClose: true,
            timeout: 2500
        };

        this.toastyService.success(toastOptions);
    }
    private handleValueUpdate() {
        // this.isLoading = false;
        const toastOptions: ToastOptions = {
            title: 'Atención ciudadana',
            msg: 'Catálogo editado correctamente',
            showClose: true,
            timeout: 2500
        };

        this.toastyService.success(toastOptions);
    }

    private handleError(error: any) {
        this.isLoading = false;
        const toastOptions: ToastOptions = {
            title: 'Error',
            msg: 'No hemos podido actualizar el reporte',
            showClose: true,
            timeout: 2000
        };

        this.toastyService.error(toastOptions);

        return [];
    }

}