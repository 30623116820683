import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { StartTripComponent } from './start-trip/start-trip.component';
import { TripDetailComponent } from './trip-detail/trip-detail.component';
import { TripsListComponent } from './trips-list/trips-list.component';
import { TripsComponent } from './trips.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: TripsComponent,
        data: {
          title: "Viajes",
          breadcrumb: "Viajes"
        }
      },
      {
        path: 'show/:id',
        component: TripDetailComponent,
        data: {
          title: "Detalles Viaje",
          breadcrumb: "Detalles Viaje"
        }
      },
      {
        path: 'start-trip',
        component: StartTripComponent,
        data: {
          title: "Recorrido",
          breadcrumb: "Recorrido"
        }
      },
      {
        path: 'my-trips',
        component: TripsListComponent,
        data: {
          title: "Mis Viajes",
          breadcrumb: "Mis Viajes"
        }
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TripsRoutingModule { }
