import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ReportsComponent } from '../reports/reports.component';
import { OccupationResultsComponent } from './occupation-result/occupation-results.component';
import { OccupationComponent } from './occupation/occupation.component';
import { ResultsComponent } from './results/results.component';
import { StudiesComponent } from './studies/studies.component';
import { SurveysDetailsComponent } from './survey-detail/survey-detail.component';
import { SurveysComponent } from './surveys-list/surveys.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: SurveysComponent,
        data: {
          title: "Encuestas",
          breadcrumb: "Encuestas"
        }
      },
      {
        path: 'results/:id',
        component: ResultsComponent,
        data: {
          title: "Resultados",
          breadcrumb: "Resultados"
        }
      },
      {
        path: 'studies',
        component: StudiesComponent,
        data: {
          title: "Estudios",
          breadcrumb: "Estudios"
        }
      },
      {
        path: 'studies/results/:id',
        component: ResultsComponent,
        data: {
          title: "Resultados",
          breadcrumb: "Resultados"
        }
      },
      {
        path: 'reports/:id',
        component: ReportsComponent,
        data: {
          title: "Resultados",
          breadcrumb: "Resultados"
        }
      },
      {
        path: 'studies/show/:id',
        component: SurveysDetailsComponent,
        data: {
          title: "Detalle del estudio",
          breadcrumb: "Detalle del estudio"
        }
      },
      {
        path: 'show/:id',
        component: SurveysDetailsComponent,
        data: {
          title: "Detalle del estudio",
          breadcrumb: "Detalle del estudio"
        }
      },
      {
        path: 'occupation',
        component: OccupationComponent,
        data: {
          title: "Ocupación",
          breadcrumb: "Ocupación"
        }
      },
      {
        path: 'occupation/results',
        component: OccupationResultsComponent,
        data: {
          title: "Resultados ocupación",
          breadcrumb: "Resultados ocupación"
        }
      }

    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SurveysRoutingModule { }