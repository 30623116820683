import { MapsAPILoader } from '@agm/core';
import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { menuDB } from 'src/app/shared/tables/menu';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { TripsService } from '../../services/trips/trips.service';
import { UsersService } from '../../services/users/users.service';

@Component({
  selector: 'app-list-menu',
  templateUrl: './list-menu.component.html',
  styleUrls: ['./list-menu.component.scss']
})
export class ListMenuComponent implements OnInit {
  private modalContent: any;
  private modalConfig: any = {
    ariaLabelledBy: 'modal-basic'
  };
  @ViewChild('angel')
  public modalAngel: ElementRef;
  @ViewChild('angelsaved')
  public modalAngelSaved: ElementRef;
  @ViewChild('content')
  public modalContentTrip: ElementRef;
  public economicNumber: any;
  private subs: Array<Subscription> = [];
  public menus = [];
  public selected = [];
  public userId: any;
  latitude: number;
  longitude: number;
  ContactName = '';
  ContactPhoneNumber = '';
  currentUser = JSON.parse(localStorage["currentUser"]);
  userInfo: any;
  constructor(private modalService: NgbModal,
    private tripsService: TripsService,
    private authService: AuthenticationService,
    private userService: UsersService,
    private router: Router,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone) {
    this.menus = menuDB.data;
  }

  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }

  ngOnInit() {
    this.userId = this.authService.currentUserValue.id;
    this.subs.push(
      this.userService.getUser(this.userId).subscribe(data => {
          this.userInfo = data;
      })
  )
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  setModalContent(content: any): void {
    if(this.userInfo.ContactPhoneNumber === '' || this.userInfo.ContactPhoneNumber === null){
      this.modalContent = this.modalAngel;
    }else{
      this.modalContent = content;
    }
    this.modalService.open(this.modalContent, this.modalConfig);
  }

  saveAngel(){
    const angel = {
      ContactName: this.ContactName,
      ContactPhoneNumber: this.ContactPhoneNumber
    }
    this.subs.push(
      this.userService.updateUser(this.userId, angel)
      .subscribe(res => {
          this.modalService.open(this.modalAngelSaved,this.modalConfig);
      })
    )
  }

  closeModals(){
    this.modalService.dismissAll();
    setTimeout(() => {
      this.modalService.open(this.modalContentTrip,this.modalConfig);
    },1500)
  }

  sendMessage() {
    this.mapsAPILoader.load().then(()=>{
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition((position) => {
          let tripData = {
            clientId: this.userId,
            economicNumber: this.economicNumber,
            origin: {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            }
          }
          this.subs.push(
            this.tripsService.startTrip(tripData).subscribe(res => {
              tripData['tripId'] = res.data.tripId; 
              this.modalService.dismissAll();
              this.economicNumber = '';
              localStorage.setItem("tripData", JSON.stringify(tripData));
              this.router.navigateByUrl('/trips/start-trip');
            })
          )
        });
      }
    })



  }

}
