<div class="page-wrapper">
    <!-- <div class="authentication-box"> -->
        <div class="container">
            <div class="row">
                <div class="col-md-3">  </div>
                <div class="col-md-6 p-0">
                    <div class="card tab2-card">
                        <div class="card-body">
                            <div class="tcenter">
                                <img src="../../../../assets/images/jackyluislogo.png" style="width: 30%;" alt="">
                            </div>
                            <!-- Container-fluid starts-->
                            <div class="container-fluid">
                                <!-- <div class="logo-wrapper"><a [routerLink]="'/dashboard'"><img class="blur-up lazyloaded" src="assets/images/logo-next.png" alt=""></a></div> -->
                                <div class="row">
                                    <div class="col-sm-12 jackyluis-text text-center">
                                        Los mejores momentos en la vida merecen ser compartidos. Con la bendición de dios y de nuestros padres.
                                    </div>
                                </div>

                                <div class="row text-center" style="padding-top:30px">
                                    <div class="col-sm-12">
                                        <h5 class="jackyluis-title-golds text-center">Nuestros padres</h5>
                                        <table class="table">
                                            
                                            <!-- <tbody> -->
                                                <tr>
                                                    <td>
                                                        <p class="jackyluis-text text-center">
                                                            Edith Matias Ruíz<br>
                                                            Felipe Porras Carreón
                                                        </p>
                                                    </td>

                                                    <td style="padding-left: 30px;">
                                                        <p class="jackyluis-text text-center">
                                                            Josefina Wong Aguilar<br>
                                                            Luis Gómez Hernández
                                                        </p>
                                                    </td>
                                                </tr>
                                            <!-- </tbody> -->
                                        </table>
                                            <!-- <div class="col-sm-6">
                                                <p class="jackyluis-text text-center">
                                                    Edith Matias Ruíz<br>
                                                    Felipe Porras Carreón
                                                </p>
                                            </div>
                                            <div class="col-sm-6">
                                                <p class="jackyluis-text text-center"> 
                                                    Josefina Wong Aguilar<br>
                                                    Luis Gómez Hernández
                                                </p>
                                            </div> -->
                                    </div>
                                </div>
                                <div class="row" style="padding-top:50px;padding-bottom:50px">
                                    <div class="col-sm-12">
                                        <h5 class="jackyluis-title-golds text-center">Nuestros padrinos</h5>
                                        <p class="jackyluis-text text-center"> 
                                            María Isabel Pale Barradas<br>
                                            Alejandro Muñoz Ocampo
                                        </p>
                                    </div>
                                    <div class="col-sm-12 jackyluis-title text-center" style="padding-top: 30px;">
                                        Jaquelin & Luis
                                    </div>
                                    <div class="col-sm-12 jackyluis-text text-center">
                                        Tenemos el honor de invitarlos a la ceremonia reiligiosa que se celebrará el 
                                        <h1 class="jackyluis-title-golds" style="font-size:20px">04 · Noviembre · 2023</h1>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <img class="img-responsive" src="assets/images/SanPedroSanPablo.png" alt="" style="width:100%">
                                    </div>
                                    <div class="col-sm-6">
                                        <h5 class="jackyluis-title-golds">Ceremonia Religiosa</h5>
                                        <h6 class="jackyluis-title2">San Pedro y San Pablo</h6>
                                        <p class="jackyluis-text">
                                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"/></svg>  Blvrd Del Mar S/N, Costa de Oro, 94299 Veracruz, Ver.<br>
                                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z"/></svg> 18:00 hrs. <br>    
                                        </p>
                                        
                                        <a href="https://goo.gl/maps/kxqieHDKKSqbuZ6Z7" class="btn btn-primary" style="background-color: #b08c3e!important;">Ver ubicación</a>
                                    </div>
                                </div>

                                <div class="row" style="padding-top:50px;padding-bottom:50px">
                                    <div class="col-sm-6">
                                        <img class="img-responsive" src="assets/images/Veranda.png" alt="" style="width:100%">
                                    </div>
                                    <div class="col-sm-6">
                                        
                                        <h5 class="jackyluis-title-golds">Civil y Recepción</h5>
                                        <h6 class="jackyluis-title2">Veranda Roof Garden</h6>
                                         <p class="jackyluis-text">
                                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"/></svg>  Blvd. Manuel Ávila Camacho 3750, Costa Verde, 94294 Veracruz, Ver.<br>
                                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z"/></svg> 20:00 hrs.<br>   
                                        </p>

                                        <a href="https://goo.gl/maps/is3vw4iMBuiszBEZA" class="btn btn-primary" style="background-color: #b08c3e!important;">Ver ubicación</a>
                                    </div>
                                </div>

                                <div>
                                    <h5 class="jackyluis-title-golds text-center">Agenda</h5>
                                    <img class="img-responsive" src="assets/images/wedding-timeline-2.png" alt="" style="width:100%">
                                </div>

                                <div class="row" style="padding-top:30px;padding-bottom:30px">
                                    <!-- <div class="col-sm-6">
                                        <img class="img-responsive" src="assets/images/Veranda.png" alt="" style="width:100%">
                                    </div> -->
                                    <div class="col-sm-12">
                                        <!-- <img src="../../../../assets/images/jackyluis.jpg" style="width: 100%;" alt=""> -->
                                        <h5 class="jackyluis-title-golds text-center">
                                            <i class="ti-instagram"></i>Código de vestimenta</h5>
                                         <p class="jackyluis-text text-center"> 
                                            Formal <br>
                                            No color blanco, beige, rojo.
                                        </p>
                                    </div>

                                    <div class="col-sm-12">
                                        <!-- <img src="../../../../assets/images/jackyluis.jpg" style="width: 100%;" alt=""> -->
                                        <h5 class="jackyluis-title-golds text-center" style="padding-top: 40px;">
                                            <i class="ti-instagram"></i>Sugerencia de regalo
                                        </h5>
                                        <p class="jackyluis-text text-center"> 
                                            Su presencia en nuestra boda será el mejor regalo. Pero si desean darnos un presente, les compartimos estas sugerencias:
                                        </p>
                                        <a href="https://mesaderegalos.liverpool.com.mx/milistaderegalos/51149494" target="_blank" class="btn btn-primary" style="background-color: #b08c3e!important;" ><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><style>svg</style><path d="M190.5 68.8L225.3 128H224 152c-22.1 0-40-17.9-40-40s17.9-40 40-40h2.2c14.9 0 28.8 7.9 36.3 20.8zM64 88c0 14.4 3.5 28 9.6 40H32c-17.7 0-32 14.3-32 32v64c0 17.7 14.3 32 32 32H480c17.7 0 32-14.3 32-32V160c0-17.7-14.3-32-32-32H438.4c6.1-12 9.6-25.6 9.6-40c0-48.6-39.4-88-88-88h-2.2c-31.9 0-61.5 16.9-77.7 44.4L256 85.5l-24.1-41C215.7 16.9 186.1 0 154.2 0H152C103.4 0 64 39.4 64 88zm336 0c0 22.1-17.9 40-40 40H288h-1.3l34.8-59.2C329.1 55.9 342.9 48 357.8 48H360c22.1 0 40 17.9 40 40zM32 288V464c0 26.5 21.5 48 48 48H224V288H32zM288 512H432c26.5 0 48-21.5 48-48V288H288V512z"/></svg> Mesa de Regalos Liverpool</a>
                                        <span>Si desea darnos un regalo en efectivo. En la recepción, contaremos con lluvia de sobres.</span>
                                    </div>
                                </div>


                                <div class="row" style="padding-top:30px;padding-bottom:30px">
                                    <!-- <div class="col-sm-6">
                                        <img class="img-responsive" src="assets/images/Veranda.png" alt="" style="width:100%">
                                    </div> -->
                                    <div class="col-sm-12 text-center">
                                        <img src="../../../../assets/images/jackyluis2.jpg" style="width: 100%;" alt="">
                                        <h5 class="jackyluis-title-golds"><i class="ti-instagram"></i>En Instagram</h5>
                                        <!-- <h6 class="jackyluis-title2">Instagram</h6> -->
                                        <h3 class="jackyluis-hastag">#JackyLuisBoda</h3>
                                    </div>
                                </div>

                                
                            </div>
                            <!-- Container-fluid Ends-->
                            <ngb-tabset class="tab-coupon mb-0">
                                <ngb-tab>
                                    <ng-template ngbTabContent>
                                        <div class="tab-pane fade active show" id="account" role="tabpanel"
                                            aria-labelledby="account-tab">
                                            <form [formGroup]="loginForm" 
                                                (ngSubmit)="onSubmit2()"
                                                class="form-horizontal auth-form" novalidate="" *ngIf="!invitacion"> <!-- *ngIf="!invitacion"-->
                                                <div class="form-group">
                                                    <h6 class="jackyluis-title text-center" style="padding-top:30px;padding-bottom:30px">Buscar invitación</h6>
                                                    <label>Favor de ingresar su número de teléfono para encontrar su invitación:</label>
                                                    <input required="" formControlName="email" type="text"
                                                        class="form-control" placeholder="10 dígitos"
                                                        [ngClass]="{'error': submittedLogin && formState?.email?.errors?.required}">
                                                        <!-- <label class="errorRed"
                                                            *ngIf="submittedLogin && formState?.email?.errors?.pattern">
                                                            Ingresa un correo válido.
                                                        </label> -->
                                                </div>
                                                <!-- <div class="form-group">
                                                    <input required="" formControlName="password" type="password"
                                                        class="form-control" placeholder="Contraseña"
                                                        [ngClass]="{'error': submittedLogin && formState?.password?.errors?.required}">
                                                </div> -->
                                                <!-- <div class="form-terms">
                                                    <div class="custom-control custom-checkbox mr-sm-2">
                                                        <input type="checkbox" class="custom-control-input"
                                                            id="customControlAutosizing">
                                                        <label class="custom-control-label"
                                                            for="customControlAutosizing">Recordarme</label>
                                                        <a href="javascript:void(0)"
                                                            class="btn btn-default forgot-pass">olvidé mi contraseña</a>
                                                    </div>
                                                </div> -->
                                                <!--<div class="form-button">
                                                    <a [routerLink]="'/dashboard/default'"><button class="btn btn-primary" type="submit">Iniciar sesión</button></a>
                                                </div>-->
                                                <button class="btn btn-primary"
                                                [disabled]="isLoading">
                                                {{ isLoading ? 'Buscando invitación' : 'Buscar invitación   '}}
                                                <i class="fa fa-spinner fa-spin"
                                                [hidden]="!isLoading"></i>
                                                </button>
                                                <!-- <div class="form-footer">
                                                    <span>O inicie sesión con redes sociales</span>
                                                    <ul class="social">
                                                        <li><a class="icon-facebook" href="javascript:void(0)"></a></li>
                                                        <li><a class="icon-twitter" href="javascript:void(0)"></a></li>
                                                        <li><a class="icon-instagram" href="javascript:void(0)"></a>
                                                        </li>
                                                        <li><a class="icon-pinterest" href="javascript:void(0)"></a>
                                                        </li>
                                                    </ul>
                                                </div> -->
                                            </form>

                                            <div *ngIf="errorMessage">
                                                <span>{{errorMessage}}</span>
                                            </div>
                                            <!-- (ngSubmit)="onSubmit3('confirma')" -->
                                            <form *ngIf="invitacion" [formGroup]="responseForm2" class="form-horizontal auth-form" novalidate=""> <!-- *ngIf="invitacion"-->

                                                <!-- Detalle de la invitación -->
                                                <div *ngIf="step2">
                                                    <h6 class="jackyluis-title text-center" style="padding-top:30px;padding-bottom:30px">Hola {{invitacion.Titular}} 
                                                    </h6>
                                                    <p style="text-align: center;">Su invitación cuenta con acceso para:</p>

                                                    <h1 class="text-center">{{invitacion.Pases}}</h1>
                                                    <p class="text-center" style="text-align: center;">PERSONAS</p>
                                                
                                                    <div class="form-group">
                                                        <label>¿Desea escribir un mensaje para los novios?</label>
                                                        <textarea formControlName="messageText" required="" type="text" class="form-control" placeholder="¿Desea enviarle un mensaje a los novios?" > </textarea>
                                                            <!-- <label class="errorRed"
                                                                *ngIf="submittedLogin && formState?.email?.errors?.pattern">
                                                                Ingresa un correo válido.
                                                            </label> -->
                                                    </div>

                                                    <button class="btn btn-primary" (click)="sendInvitationResponse('Confirmada')">Confirmar mi asistencia</button>
                                                    <br><br>

                                                    <button class="btn btn-primary" (click)="sendInvitationResponse('No asistir')">No podré asistir</button>
                                                </div>

                                                <div *ngIf="step3">
                                                    <h4 class="text-center">Respuesta enviada</h4>
                                                    <p class="text-center">{{messageResponse}}</p>
                                                    <h5 class="text-center">No olvides usar #JackyLuisBoda en Instagram</h5>
                                                </div>
                                                    
                                            </form>
                                        </div>
                                    </ng-template>
                                </ngb-tab>
                            </ngb-tabset>
                            <!-- <div class="text-center mt-4">
                                <a
                                    class=""
                                    [routerLink]="['/recuperar-contrasena']">¿Olvidaste tu contraseña?
                                </a>
                            </div> -->
                        </div>
                    </div>
                    <!-- <div class="row mb-2">
                        <small class="pad-left">¿No tienes cuenta? <a [routerLink]="['/registro']">Ingresa aquí</a></small>
                    </div> -->
                    <!-- <div class="row">
                        <small class="pad-left">¿Quieres hacer un reporte como invitado? <a [routerLink]="['/reporte']">Hazlo aquí</a></small>
                    </div> -->
                </div>
                <div class="col-md-3">  </div>
            </div>
            <!-- <a [routerLink]="'/dashboard/default'" class="btn btn-primary back-btn">
                <app-feather-icons [icon]="'arrow-left'"></app-feather-icons>
                back
            </a> -->
        </div>
    <!-- </div> -->
</div>

<ng2-toasty></ng2-toasty>