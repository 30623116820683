<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row pl-3 mb-3">
        <ul *ngFor="let item of reports?.status">
            <li class="list-filter" (click)="onFilerbyStatus(item.Status)">{{item.Status}} ({{item.count}})</li>
        </ul>
    </div>
    <div class="card">
        <div class="card-body">
          <div id="batchDelete" class="">
              <div class="table-responsive">
                  <table datatable
                  [dtOptions]="dtOptions"
                  [dtTrigger]="dtTrigger"
                  class=" table table-lg table-hover row-border hover">
                  <thead>
                      <tr>
                        <th>Folio</th>
                        <th>Fecha de reporte</th>
                        <th>Estatus</th>
                        <th>Área asignada</th>
                        <th>Nombre</th>
                        <th>Opciones</th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr *ngFor="let report of reports?.reports">
                        <td>{{report.folio}}</td>
                        <td>{{report.created_at | date:'dd/MM/yyyy h:mm:ss a'}}</td>
                        <td>{{report.Status}}</td>
                        <td>{{report.Department ? report.Department : 'Sin área asignada'}}</td>
                        <td>{{report.Name}}</td>
                        <td class="actions-cell">
                            <span>
                            <a [routerLink]="['show', report.id]"
                                title="Ver reporte">
                                <u>Ver reporte</u>
                            </a>
                            </span>
                        </td>
                      </tr>
                  </tbody>
                  </table>
                  <ngb-pagination (pageChange)="loadPage($event)" [collectionSize]="total" [(page)]="page" [maxSize]="10" [rotate]="true" [boundaryLinks]="true"></ngb-pagination>
              </div>
          </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->