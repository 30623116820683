<div class="container-fluid">
    <div class="row">
        <div class="col-xl-5">
            <div class="card">
                <div class="card-body">
                    <div class="profile-details">
                        <h5 class="f-w-600 mb-0">Id: {{transaction?.order_id}} <span [ngClass]="{'completed': transaction?.status == 'completed', 'fail': transaction?.status == 'fail'}">{{transaction?.status}}</span></h5>
                        <hr>
                        <div class="table-responsive profile-table">
                            <table class="table table-responsive">
                                <tbody>
                                    <tr>
                                        <td>Fecha de Transacción:</td>
                                        <td>{{transaction?.operation_date | date}}</td>
                                    </tr>
                                    <tr>
                                        <td>Método de Pago:</td>
                                        <td *ngIf="transaction?.method == 'card'"><span>Tarjeta</span></td>
                                    </tr>
                                    <tr>
                                        <td><strong>Total:</strong></td>
                                        <td><strong>{{transaction?.amount | currency}} {{transaction?.currency}}</strong></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-7">
            <div class="card tab2-card">
                <div class="card-body">
                    <div class="tab-pane fade show active" id="top-profile" role="tabpanel"
                        aria-labelledby="top-profile-tab">
                        <h5 class="f-w-600">Detalles de transacción</h5>
                        <div class="table-responsive profile-table">
                            <table class="table table-responsive">
                                <tbody>
                                    <tr>
                                        <td>Nº. de autorización</td>
                                        <td>{{transaction?.authorization}}</td>
                                    </tr>
                                    <tr>
                                        <td>Descripción:</td>
                                        <td>{{transaction?.description}}</td>
                                    </tr>
                                    <hr>
                                    <p>Detalles de tarjeta</p>
                                    <tr>
                                        <td>Nombre:</td>
                                        <td>{{card?.holder_name}}</td>
                                    </tr>
                                    <tr>
                                        <td>Tipo de tajeta:</td>
                                        <td>{{card?.brand}} {{card?.type}} {{card?.bank_name}} terminada en {{cardNumber}}</td>
                                    </tr>
                                    <hr>
                                    <p>Detalles de cobro</p>
                                    <tr>
                                        <td>Total:</td>
                                        <td>{{transaction?.amount | currency}} {{transaction?.currency}}</td>
                                    </tr>
                                    <tr *ngIf="transaction?.currency == 'MXN'">
                                        <td>Tipo de cambio:</td>
                                        <td>{{exchange?.value | currency}}</td>
                                    </tr>
                                    <tr *ngIf="transaction?.currency == 'MXN'">
                                        <td>Total en MXN:</td>
                                        <td>{{transaction?.amount * exchange?.value | currency}} MXN</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->
  