<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-xl-4">
            <div class="card">
                <div class="card-body">
                    <div class="profile-details text-center">
                        <img src="assets/images/dashboard/profile-user.png" alt=""
                            class="img-fluid img-90 rounded-circle blur-up lazyloaded">
                        <h5 class="f-w-600 mb-0">{{user?.displayName}}</h5>
                        <span>{{user?.email}}</span>
                        <hr>
                        <span>{{user?.phoneNumber}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-8">
            <ngb-tabset >

                <ngb-tab >
                    <ng-template ngbTabTitle>
                        <app-feather-icons [icon]="'user'"></app-feather-icons>Perfil
                    </ng-template>
                    <ng-template ngbTabContent>
                        <div class="tab-pane fade show active" id="top-profile" role="tabpanel"
                            aria-labelledby="top-profile-tab">
                            <h5 class="f-w-600"> Editar Perfil</h5>
                            <form [formGroup]="form" class="form-horizontal auth-form" (ngSubmit)="updateUser()">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label>Nombre</label>
                                            <input type="text" class="form-control" formControlName="firstName">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label>Apellido</label>
                                            <input type="text" class="form-control" formControlName="lastName">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label>Correo electrónico</label>
                                            <input type="text"
                                                [ngClass]="{'error': submitted && formState?.email?.errors?.required}"
                                                class="form-control" formControlName="email">
                                            <label class="errorRed"
                                                *ngIf="submitted && formState?.email?.errors?.pattern">
                                                Ingresa un correo válido.
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label>Teléfono</label>
                                            <input type="text"
                                                [ngClass]="{'error': submitted && formState?.phoneNumber?.errors?.required}"
                                                class="form-control" formControlName="phoneNumber">
                                            <label class="errorRed"
                                                *ngIf="submitted && formState?.phoneNumber?.errors?.pattern">
                                                Ingresa solo 10 números.
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="text-right mrg-top-5">
                                            <button type="submit" class="btn btn-primary mrg-right-15">
                                                Actualizar perfil
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </ng-template>
                </ngb-tab>


                <ngb-tab >
                    <ng-template ngbTabTitle>
                        <app-feather-icons [icon]="'key'"></app-feather-icons>Contraseña
                    </ng-template>
                    <ng-template ngbTabContent>
                        <div class="tab-pane fade show active" id="top-profile" role="tabpanel"
                            aria-labelledby="top-profile-tab">
                            <h5 class="f-w-600"> Actualizar contraseña</h5>
                            <form [formGroup]="formpassword" class="form-horizontal auth-form"
                                (ngSubmit)="onChangePassword()">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label>Correo electrónico</label>
                                            <input type="text" class="form-control" formControlName="email"
                                                [attr.disabled]="disabled == true">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>Nueva contraseña</label>
                                            <input type="password" class="form-control" formControlName="password"
                                                [ngClass]="{'error': submitted && formStatePass?.password?.errors?.required}">
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>Confirmar nueva contraseña</label>
                                            <input type="password" class="form-control"
                                                [ngClass]="{'error': submitted && formStatePass?.confirmedPassword?.errors?.required}"
                                                formControlName="confirmedPassword">
                                            <label class="error"
                                                *ngIf="submitted && formStatePass?.confirmedPassword?.errors">
                                                Las contraseñas no coinciden.
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="text-right mrg-top-5">
                                            <button type="submit" class="btn btn-primary mrg-right-15">
                                                Actualizar Contraseña
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </ng-template>
                </ngb-tab>
                <ngb-tab>
                    <ng-template ngbTabTitle>
                        <app-feather-icons [icon]="'eye'"></app-feather-icons>Ángel de la Guarda
                    </ng-template>
                    <ng-template ngbTabContent>
                        <div class="tab-pane fade show active" id="top-profile" role="tabpanel"
                            aria-labelledby="top-profile-tab">
                            <h5 class="f-w-600"> Editar Ángel de la Guarda</h5>
                            <form [formGroup]="formAngel" class="form-horizontal auth-form"
                                (ngSubmit)="onChangeAngel()">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label>Nombre(s)</label>
                                            <input type="text" class="form-control" formControlName="ContactName">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label>Número telefónico</label>
                                            <input type="text"
                                                [ngClass]="{'error': submitted && formStateAngel?.ContactPhoneNumber?.errors?.required}"
                                                class="form-control" formControlName="ContactPhoneNumber">
                                            <label class="errorRed"
                                                *ngIf="submitted && formStateAngel?.ContactPhoneNumber?.errors?.pattern">
                                                Ingresa solo 10 números.
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="text-right mrg-top-5">
                                            <button type="submit" class="btn btn-primary mrg-right-15">
                                                Actualizar Datos
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </ng-template>
                </ngb-tab>


            </ngb-tabset>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->

<ng2-toasty></ng2-toasty>