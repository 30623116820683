import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { getHeaders } from '../../utils/headers.functions';

const BASE_URL: string = environment.API_URL;

@Injectable({
    providedIn: 'root'
  })
  export class TripsService {
    constructor(
      private http: HttpClient
    ) { }


    public getTrips(query:string, limit:any, offset:any): Observable<any> {
        const headers: HttpHeaders = getHeaders();
        return this.http.get<any>(`${BASE_URL}/trips?search=${query}&limit=${limit}&offset=${offset}`);
      }

      public getTripbyId(tripId:any){
        const headers: HttpHeaders = getHeaders();
        return this.http.get<any>(`${BASE_URL}/trip/${tripId}`);
      }

      public getLiveTrip(tripId:any):Observable<any>{
        const headers: HttpHeaders = getHeaders();
        return this.http.get<any>(`${BASE_URL}/trip-live/${tripId}`);
      }

      getTripsbyCitizen(citizenId:any, limit:any,offset:any):Observable<any>{
        return this.http.get<any>(`${BASE_URL}/trips-user/${citizenId}?limit=${limit}&offset=${offset}`);
      }

      public startTrip(tripData:any):Observable<any>{
        const headers: HttpHeaders = getHeaders();
        return this.http.post<any>(`${BASE_URL}/trip-start`,tripData,{headers});
      }

      public updatePosition(tripId:any, position:any):Observable<any>{
        return this.http.post<any>(`${BASE_URL}/trip-position/${tripId}`,position);
      }

      public finishTrip(tripId:any, destination:any):Observable<any>{
        return this.http.post<any>(`${BASE_URL}/trip-finish/${tripId}`,destination);
      }

    }  