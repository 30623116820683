<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row col-12 mb-4 mt-4">
  
      <div class="col-xl-4 col-xs-12 mt-2 pl-0" >
        <div class="form-group">
          <input placeholder="Buscar por email, nombre, número económico" class="form-control" id="validationCustom1" type="text"
            [(ngModel)]="queryValue">
        </div>
      </div>
      <div class="col-xl-6 col-xs-12">
        <button class="btn btn-primary w-25 mr-4" (click)="getResults()">
          Buscar
        </button>
        <button title="Borrar filtros" class="btn btn-primary" (click)="clearInputs()">
          Borrar filtros
        </button>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <!-- <div class="btn-popup pull-right">
                  <a class="btn btn-secondary" [routerLink]="'/users/create-user'">Crear Usuario</a>
                  <a class="btn btn-secondary" [routerLink]="['create-admin']"><span>Agregar Administrador</span></a>
              </div> -->
        <div id="batchDelete" class="">
          <div class="table-responsive">
            <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
              class=" table table-lg table-hover row-border hover">
              <thead>
                <tr>
                  <th>No. viaje</th>
                  <th>Usuario</th>
                  <th>Correo</th>
                  <th>Teléfono</th>
                  <th>Número económico</th>
                  <th>Fecha inicio</th>
                  <th>Fecha término</th>
                  <th>Status</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let trip of trips">
                    <td>{{trip?.TripNumber}}</td>
                    <td>{{trip?.client?.displayName}}</td>
                    <td>{{trip?.client?.email}}</td>
                    <td>{{trip?.client?.phoneNumber || 'Sin número'}}</td>
                    <td>{{trip?.economicNumber}}</td>
                    <td>{{trip?.StartDateTime | date:'dd/MM/yyyy, h:mm a'}}</td>
                    <td>{{trip?.EndDateTime | date:'dd/MM/yyyy, h:mm a'}}</td>
                    <td>{{trip?.Status}}</td>
                  <!-- <td>{{user.firstName}} {{user.lastName}}</td>
                  <td>{{user.email}}</td>
                  <td>{{!user.phoneNumber ? 'Sin número' : user.phoneNumber}}</td>
                  <td>{{user.roles === 'user' ? 'Usuario' : 'N/A'}}</td> -->
                  <td class="overflow-hidden">
                    <div ngbDropdown container="body">
                        <button class="btn btn-outline-primary btn-sm toogle-but" ngbDropdownToggle>Opciones</button>
                        <div ngbDropdownMenu>
                            <button class="option" ngbDropdownItem [routerLink]="['show', trip?.id]">Ver detalles</button>
                        <!-- <button class="option" ngbDropdownItem [routerLink]="['show', report.id]">Ver reporte</button> -->
                        <!--<button *ngIf="report.UserId" class="option" ngbDropdownItem [routerLink]="['edit', report.id]">Editar reporte</button> -->
                        <!-- <button class="option" ngbDropdownItem (click)="assignModalContent(contentArea, report.id)">Turnar reporte</button>  
                        <button class="option" ngbDropdownItem (click)="assignModalContent(contentDelete, report.id)">Eliminar reporte</button>   -->
                      </div>
                    </div>
                    </td>
                  <!-- <td class="actions-cell">
                              <span>
                              <a [routerLink]="['show', user.id]"
                                  title="Ver administrador">
                                  <i class="fa fa-eye"></i>
                              </a>
                              </span>
                              <span (click)="setModalContent(content, user.id)"
                              title="Borrar usuario">
                              <i class="fa fa-trash"></i>
                              </span>
                          </td> -->
                </tr>
              </tbody>
            </table>
            <ngb-pagination (pageChange)="loadPage($event)" [collectionSize]="total" [(page)]="page" [maxSize]="10"
              [rotate]="true" [boundaryLinks]="true"></ngb-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Container-fluid Ends-->
  
  <!-- <ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title text-center width-100" id="modal-title">Eliminar Usuario</h4>
    </div>
    <div class="modal-body text-center">
      <ng-container *ngIf="!deleteIsSuccess">
        <p class="no-mrg">Se eliminirán todos los datos del usuario.</p>
        <p class="no-mrg">Esta acción no se puede deshacer.</p>
      </ng-container>
  
      <ng-container *ngIf="deleteIsSuccess">
        <p>Usuario borrado correctamente</p>
      </ng-container>
    </div>
    <ng-container *ngIf="!deleteIsSuccess">
      <div class="modal-footer">
        <a type="button" class="btn btn-outline-light" (click)="modal.close()">Cancelar</a>
        <button type="button" class="btn btn-inverse btn-danger" (click)="deleteUser()">Eliminar</button>
      </div>
    </ng-container>
  </ng-template> -->
