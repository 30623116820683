import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { catchError } from 'rxjs/internal/operators';
import { AuthenticationService } from 'src/app/components/services/auth/authentication.service';
import { ProductsService } from 'src/app/components/services/products/products.service';

@Component({
  selector: 'app-souvenir-list',
  templateUrl: './souvenir-list.component.html',
  styleUrls: ['./souvenir-list.component.scss']
})
export class SouvenirListComponent implements OnInit {
  
  public digital_list = []
  private subs: Array<Subscription> = [];

  constructor(
    private productService: ProductsService,
    private authService: AuthenticationService,
  ) {
  }

  ngOnInit() {
    this.subs.push(
      this.productService
        .getProductsFiltered('Souvenirs')
        .pipe(
          catchError(err => this.authService.handleUnauthorizedError(err))
        )
        .subscribe(data => {
          this.digital_list = data;
        })
    );
  }

}
