<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-xl-3 col-xs-12">
            <label>ÁREA O DIRECCIÓN</label>
            <div class="form-group">
                <ng-select [(ngModel)]="area">
                    <ng-option value="" disabled selected>Área o Dirección</ng-option>
                    <ng-option *ngFor="let value of values" [value]="value">
                        {{value}}
                    </ng-option>
                </ng-select>
            </div>
        </div>
        <div class="col-xl-9 col-xs-12">
            <label>SELECCIONA UN PERIODO DE FECHAS</label>
            <div class="row">
                <div class="col-xl-4 col-xs-12">
                    <div class="form-group">
                        <input class="form-control" id="validationCustom1" type="date" [(ngModel)]="startDate">
                    </div>
                </div>
                <div class="col-xl-4 col-xs-12">
                    <div class="form-group">
                        <input class="form-control" id="validationCustom2" type="date" [(ngModel)]="endDate">
                    </div>
                </div>
                <div class="col-xl-4 col-xs-12">
                    <button [hidden]="!endDate || hideButton" class="btn btn-primary" (click)="getResults()">
                        Filtrar
                    </button>
                    <button [hidden]="!showClean" title="Borrar filtros" class="btn btn-primary" (click)="clearInputs()">
                        Borrar filtros
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="row pl-3 mb-3" *ngIf="showTable">
        <div class="col-8 d-contents">
            <ul *ngFor="let item of reports?.status">
                <li class="list-filter" (click)="onFilerbyStatus(item.Status)">{{item.Status}} ({{item.count}})</li>
            </ul>
        </div>
        <div class="col-4 d-flex flex-row-reverse">
            <!-- <button class="btn btn-primary" (click)="exportExcel()">Exportar a Excel</button> -->
            <button [hidden]="!showClean" title="Exportar reporte" class="btn btn-primary mt-1 mr-3 mb-3" (click)="generateReport()">
                Exportar reporte
            </button>
        </div>
    </div>
    <div>
        <div class="btn-popup pull-right">
        </div>
    </div>
    <div class="card" *ngIf="!showTable">
        <div class="card-body">
            <p class="text-center">Por favor seleccione los filtros deseados para su reporte.</p>
        </div>
    </div>
    <div class="card" *ngIf="showTable">
        <div class="card-body">
            <div id="batchDelete" class="">
                <div class="table-responsive">
                    <table datatable
                    [dtOptions]="dtOptions"
                    [dtTrigger]="dtTrigger"
                    class=" table table-lg table-hover row-border hover"
                    id="reports-area">
                    <thead>
                        <tr>
                        <th>Folio</th>
                        <th>Fecha/hora</th>
                        <th>Dirección</th>
                        <th>Estatus</th>
                        <th>Tipo de reporte</th>
                        <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let report of reports?.List">
                        <td>{{report.folio}}</td>
                        <td>{{report.created}}</td>
                        <td>{{report.Address.Street}} {{report.Address.ExtNumber}}</td>
                        <td>{{report.Status}}</td>
                        <td>{{report.Subject}}</td>
                        <td class="actions-cell">
                            <span>
                            <a [routerLink]="['show', report.id]"
                                title="Ver reporte">
                                <u>Ver reporte</u>
                            </a>
                            </span>
                        </td>
                        </tr>
                    </tbody>
                    </table>
                    <ngb-pagination (pageChange)="loadPage($event)" [collectionSize]="total" [(page)]="page" [maxSize]="10" [rotate]="true" [boundaryLinks]="true"></ngb-pagination>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->

<ng-template #content
  let-modal>
  <div class="modal-header">
    <h4 class="modal-title text-center width-100"
      id="modal-title">Eliminar Usuario</h4>
  </div>
  <div class="modal-body text-center">
    <ng-container *ngIf="!deleteIsSuccess">
      <p class="no-mrg">Se eliminirán todos los datos del usuario.</p>
      <p class="no-mrg">Esta acción no se puede deshacer.</p>
    </ng-container>

    <ng-container *ngIf="deleteIsSuccess">
      <p>Usuario borrado correctamente</p>
    </ng-container>
  </div>
  <ng-container *ngIf="!deleteIsSuccess">
    <div class="modal-footer">
      <a type="button"
        class="btn btn-outline-light"
        (click)="modal.close()">Cancelar</a>
      <button type="button"
        class="btn btn-inverse btn-danger"
        (click)="deleteUser()">Eliminar</button>
    </div>
  </ng-container>
</ng-template>