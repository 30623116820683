import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription, throwError } from 'rxjs';
import { UserModel } from '../../auth/auth.models';
import { ActivatedRoute, Router } from '@angular/router';
import { map, switchMap, catchError } from 'rxjs/operators';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { FilesUserModel } from '../user.models';
import { ToastOptions, ToastyConfig, ToastyService } from 'ng2-toasty';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UsersService } from '../../services/users/users.service';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import * as moment from 'moment';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailsComponent implements OnInit, OnDestroy {
  public accountForm: FormGroup;
  public isLoading = false;
  public isLoadingNote = false;
  public isDownload = false;
  public submitted = false;
  public imgBackground: string;
  public firma: string;
  public day: string;
  public month: string;
  public year: string;

  private modalContent: any;
  public zoom: number;
  public validate: any;
  public porcentage: number;
  public color: number;
  public actualfile: any;
  public noteIsSuccess = false;
  public percentage: number;

  private subs: Array<Subscription> = [];
  public user: any;
  public userId: any

  public fileId: any;
  private modalConfig: any = {
    ariaLabelledBy: 'modal-basic'
  };

  public filesValidate: Array<FilesUserModel> = [];
  public validates: any = 0;
  public form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private toastyService: ToastyService,
    private toastyConfig: ToastyConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthenticationService,
    private userService: UsersService,
    private modalService: NgbModal
  ) {
  }

  ngOnInit() {

    this.imgBackground = '/assets/images/constancia.png'
    this.firma = '/assets/images/Firma.png'
    this.day = moment().format('D');
    this.month = moment().locale('es').format('MMMM');
    this.month = this.month.charAt(0).toUpperCase() + this.month.slice(1);
    this.year = moment().format('YYYY');

    this.subs.push(
      this.activatedRoute.paramMap.pipe(
        map(params => params.get('id')),
        switchMap(id => this.authService.getStore(id)),
        catchError(error => {
          this.router.navigate(['/users/list-user']);
          return throwError(error);
        })
      ).subscribe(user => {
        this.user = user;
        this.percentage = Number(this.user.Percentage)
        if(this.percentage<= 40){
          this.color = 1;
        }else if(this.percentage> 40 && this.percentage< 99){
          this.color = 2;
        }else{
          this.color = 3;
        }
      })
    );
    this.zoom = 17;
    this.initializeForm();
  }

  initializeForm(){
    this.form = this.fb.group({
      Notes: ['', Validators.required],
    });
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }

  addFile(file){
      this.filesValidate.push({id: file.id, FileLabel: file.FileLabel, IsValid: true, Notes: null});
      this.validates = [ ...this.filesValidate];
  }

  removeFile(file) {
    const index: number = this.filesValidate.indexOf(file);
    this.filesValidate.splice(index, 1);
    this.validates = [ ...this.filesValidate];
  }

  updateFiles() {
    if(this.validates !== 0){
      const files = this.validates;
      this.isLoading = true;
        this.subs.push(
          this.authService.validateFiles(files ,this.user.id)
          .pipe(
          map(() => {
              this.FilesUpdate();
              setTimeout(() => {
                window.location.reload();
              // this.router.navigateByUrl('users/business');
              }, 1800);
          }),
          catchError(error => this.handleError(error))
          )
          .subscribe()
        );
    }else{
      this.NoFilesUpdate();
    }
  
  }

  public setModalContent(content: any, fileId: number): void {
    this.fileId = fileId;
    this.modalContent = content;
    this.modalService.open(this.modalContent, this.modalConfig);
  }

  private buildMessage() {
    return {
      Notes: this.form.controls['Notes'].value
    }
  }

  public get formState() {
    return this.form.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    const message = this.buildMessage();
    this.subs.push(
      this.userService.sendNote(message, this.fileId)
        .subscribe(resolve => {
          if (resolve.Message == 'Nota agregada') {
            this.noteIsSuccess = true;
            setTimeout(() => {
              this.modalService.dismissAll();
              //window.location.reload();
              this.noteIsSuccess = false;
              this.initializeForm();
            }, 1500);
          }
        })
    );
  }

  downloadPDF(){
    const DATA = document.getElementById('htmlData');
    this.isDownload = true;
    
    const options = {
      scale: 3
    };

    html2canvas(DATA, options).then((canvas) => {

      const img = canvas.toDataURL("image/png");
      const doc = new jsPDF({
        orientation:'landscape',
        format: [1281, 988]
      });

      // Add font
      const myFont = 'assets/fonts/ZonaPro-Thin.otf' // load the *.ttf font file as binary string

      // add the font to jsPDF
      doc.addFileToVFS("ZonaPro-Thin.otf", myFont);
      doc.addFont("ZonaPro-Thin.otf", "ZonaPro", "normal");

      // Add image Canvas to PDF
      const bufferX = 0;
      const bufferY = 0;
      const imgProps = (doc as any).getImageProperties(img);
      const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      doc.addImage(img, 'PNG', bufferX, bufferY, pdfWidth, pdfHeight);
      return doc;
    }).then((docResult) => {
      //docResult.save(`${new Date().toISOString()}_tutorial.pdf`);
      docResult.save(`constancia.pdf`);
    });

    setTimeout(() =>{
      this.isDownload = false;
    }, 2000)
  }

  /* Mensajes error */

  private FilesUpdate() {
    const toastOptions: ToastOptions = {
      title: 'Perfil',
      msg: 'Archivos validados exitosamente.',
      showClose: true,
      timeout: 1700
    };

    this.toastyService.success(toastOptions);
  }

  private NoFilesUpdate() {
    const toastOptions: ToastOptions = {
      title: 'Perfil',
      msg: 'No se han seleccionado archivos para validar.',
      showClose: true,
      timeout: 1700
    };

    this.toastyService.warning(toastOptions);
  }

  private handleError(error: any) {
    const toastOptions: ToastOptions = {
      title: 'Error',
      msg: 'No se pudieron validar los archivos.',
      showClose: true,
      timeout: 2000
    };

    this.toastyService.error(toastOptions);

    return [];
  }

}
