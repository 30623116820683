import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { getHeaders } from 'src/app/shared/utils/headers.functions';
import * as moment from 'moment-timezone';
import { AnswerModel } from '../../auth/auth.models';

const BASE_URL: string = environment.API_URL;

@Injectable({
  providedIn: 'root'
})
export class SurveysService {
  constructor(
    private http: HttpClient
  ) { }


  // public getStudies(): Observable<any> {
  //   const headers: HttpHeaders = getHeaders();
  //   return this.http.get<any>(`${BASE_URL}/studies`, { headers });
  // }
  public getStudies(): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/catalogs-data/estudios`, { headers });
  }

  public getReports(): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/catalogs-data/reports`, { headers });
  }

  public getVariables(studie: string): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/catalogs-data/${studie}P`, { headers });
  }

  public getSecondaries(primaries: any): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.post<any>(`${BASE_URL}/variables-secondaries`, primaries, { headers });
  }

  public getResults(startDate: string, endDate: string, report: any): Observable<any> {
    startDate = moment(startDate).tz('America/Mexico_City').format('YYYY-MM-DD');
    endDate = moment(endDate).tz('America/Mexico_City').format('YYYY-MM-DD');
    const headers: HttpHeaders = getHeaders();
    return this.http.post<any>(`${BASE_URL}/reports?StartDate=${startDate}&EndDate=${endDate}`, report, { headers });
  }

  public getValues(studie: string): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/catalogs-data/${studie}P`, { headers });
  }

  public getReportsView(uuid: string): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/suerveyQA/${uuid}`, { headers });
  }

  public getSurveys(): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/surveys`, { headers });
  }

  public getSurvey(surveyId: string): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/survey/${surveyId}`, { headers });
  }

  public getSurveyQA(uuid: string): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/suerveyQA/${uuid}`, { headers });
  }
  
  public createAnswers(quiz: AnswerModel): Observable<any> {
    // console.log(quiz);
    const headers: HttpHeaders = getHeaders();
    return this.http.post<any>(`${BASE_URL}/answers`, quiz, { headers });
  }

  public getHistoryHotel(businessId: string): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/history/${businessId}`, { headers });
  }

  public getHotels(): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/hotels`, { headers });
  }

  public getMunicipalities(): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/municipios`, { headers });
  }

  public getHistoryMunicipality(municipio: string): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/log/${municipio}`, { headers });
  }

  public getHistoryReport(): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/fileshistory`, { headers });
  }

  public uploadFile(formData: any): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.post<any>(`${BASE_URL}/uploadFile`, formData, { headers });
  }

  public uploadXLXS(formData: any): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.post<any>(`${BASE_URL}/file-report`, formData);
  }

  public getDestinies(): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/variable-destiny`);
  }

  public getEvents(): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/variable-events`);
  }

  public getAttractives(): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/variable-atractive`);
  }

  public getReport(studie: string): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/view-report?report=${studie}&year=&catalog=`);
  }

  public getReportforYear(studie: string, year: number): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/view-report?report=${studie}&year=${year}&catalog=`);
  }

  public getReportforCatalog(studie: string, year: number, catalog: number): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/view-report?report=${studie}&year=${year}&catalog=${catalog}`);
  }
}
