import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { SalesService } from "../../../services/sales/sales.service";
import { AuthenticationService } from '../../../services/auth/authentication.service';
import { Subscription, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ClientDepartments } from '../../../services/auth/auth.models';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastyService, ToastyConfig, ToastOptions } from 'ng2-toasty';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceModel } from '../../sales.models';
import { FeatureProductModel } from 'src/app/components/products/product.models';

@Component({
  selector: 'app-add-service',
  templateUrl: './add-service.component.html',
  styleUrls: ['./add-service.component.scss']
})
export class AddServiceComponent implements OnInit, OnDestroy {
  public serviceForm: FormGroup;
  public isLoading = false;
  public submitted = false;
  public onEdit: boolean;
  public serviceId: number;
  public id: number;
  
  public featuresProduct: Array<FeatureProductModel> = [];
  public selectedFeature: string;
  public valueFeature: string;
  public results: any;

  private subs: Array<Subscription> = [];
  private modalContent: any;
  private modalConfig: any = {
    ariaLabelledBy: 'modal-basic'
  };

  public dtOptions: DataTables.Settings = {};
  public dtTrigger: Subject<any> = new Subject();
  public url = [{
    img: "assets/images/photo.png",
  }
  ]

  constructor(
    private salesService: SalesService,
    private authService: AuthenticationService,
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastyService: ToastyService,
    private toastyConfig: ToastyConfig
  ) {
  }

  ngOnInit() {
    this.subs.push(
      this.activatedRoute.paramMap.subscribe((data: any) => {
        if (data.params.id) {
          this.serviceId = data.params.id;
          this.onEdit = true;
          this.salesService.getService(data.params.id)
            .subscribe(service => this.setValues(service));
          this.serviceForm = this.fb.group({
              Title: ['', [Validators.required]],
              Description: ['', [Validators.required]],
              Family: ['Servicios'],
              ProductInfo: []
            })
        }else {
          this.onEdit = false;
          this.serviceForm = this.fb.group({
            Title: ['', [Validators.required]],
            Description: ['', [Validators.required]],
            Family: ['Servicios'],
            ProductInfo: []
          })
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }

  addFeature(){
    if (this.valueFeature == null){
      alert('Debes llenar todos los campos para agregar un beneficio.')
    }
    else{
      this.featuresProduct.push({CatalogName: 'Feature', Property: 'Beneficio', Value: this.valueFeature});
      this.valueFeature = "";
      this.results = [ ...this.featuresProduct];;
    }
  }

  removeFeature(feature) {
    const index: number = this.featuresProduct.indexOf(feature);
    this.featuresProduct.splice(index, 1);
    this.results = [ ...this.featuresProduct];
  }

  public get formState() {
    return this.serviceForm.controls
  }

  private setValues(service: any): void {
    const values = this.salesService.mapRequiredValues(service);
    this.id = values.id;
    this.featuresProduct = values.featuresProduct;
    const { controls } = this.serviceForm;

    for (const value in values) {
      if (controls.hasOwnProperty(value)) {
        this.serviceForm.controls[value].setValue(values[value]);
      }
    }
  }

  private buildService(): ServiceModel {
    return {
        Title: this.serviceForm.controls['Title'].value,
        Description: this.serviceForm.controls['Description'].value,
        Family: 'Servicios',
        ProductInfo: this.results
    }
  }

  private buildServiceUpdate(): ServiceModel {
    return {
        id: this.id,
        Title: this.serviceForm.controls['Title'].value,
        Description: this.serviceForm.controls['Description'].value,
        Family: 'Servicios',
        ProductInfo: this.results
    }
  }

  onSubmit() {
    this.submitted = true;

    if (this.serviceForm.invalid) {
        return;
    }

    if (this.onEdit) {
      this.updateProduct();
    } else {
      this.createProduct();
    } 
  }

  createProduct() {
    const service: ServiceModel = this.buildService();
    this.isLoading = true;
    this.subs.push(
        this.salesService.createService(service)
        .pipe(
        map(() => {
            this.handleServiceCreation();
            setTimeout(() => {
            this.router.navigateByUrl('sales/services');
            }, 1800);
        }),
        catchError(error => this.handleError(error))
        )
        .subscribe()
    ); 
  }

  updateProduct() {
    const service: ServiceModel = this.buildServiceUpdate();
    this.isLoading = true;
    this.subs.push(
        this.salesService.updateProduct(this.serviceId, service)
        .pipe(
        map(() => {
            this.handleServiceEdition();
            setTimeout(() => {
            this.router.navigateByUrl('sales/services');
            }, 1800);
        }),
        catchError(error => this.handleEditError(error))
        )
        .subscribe()
    ); 
  }

  private handleError(error: any) {
    const toastOptions: ToastOptions = {
      title: 'Error',
      msg: 'No se pudo crear el servicio',
      showClose: true,
      timeout: 2000
    };

    this.toastyService.error(toastOptions);

    return [];
  }

  private handleEditError(error: any) {
    const toastOptions: ToastOptions = {
      title: 'Error',
      msg: 'No se pudo actualizar el servicio',
      showClose: true,
      timeout: 2000
    };

    this.toastyService.error(toastOptions);

    return [];
  }

  private handleServiceCreation() {
    const toastOptions: ToastOptions = {
      title: 'Servicio',
      msg: 'Servicio creado correctamente',
      showClose: true,
      timeout: 1700
    };

    this.toastyService.success(toastOptions);
  }

  private handleServiceEdition() {
    const toastOptions: ToastOptions = {
      title: 'Servicio',
      msg: 'Servicio actualizado correctamente',
      showClose: true,
      timeout: 1700
    };

    this.toastyService.success(toastOptions);
  }

  //FileUpload
  readUrl(event: any, i) {
      //console.log(event.target.files);
    if (event.target.files.length === 0)
      return;
    //Image upload validation
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    // Image upload
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.url[i].img = reader.result.toString();
    }
    //console.log(reader);
  }


}
