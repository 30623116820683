<div class="container-fluid">
    <div class="card">
        <div class="row product-page-main card-body">
            <div class="col-xl-12">
                <section class="center">
                    <img *ngIf="product?.ImgFileUrl" [src]="[product?.ImgFileUrl]" class="img-fluid blur-up lazyload bg-img" alt="">
                    <img *ngIf="!product?.ImgFileUrl" src="../assets/images/no-found.jpeg" class="img-fluid blur-up lazyload bg-img" alt="No image found">
                    <p *ngIf="!product?.ImgFileUrl" class="center">No se ha encontrado la imagen</p>
                </section>
                <h2 class="title center">{{product?.Title}}</h2>
                <p class="mb-0">{{product?.Description}}</p>
            </div>
            <hr>
            <div class="row padlr">
                <div class="col-xs-12 col-sm-12 col-lg-12 pb-15">
                    <div class="card-header pl-0">
                        <h5>Beneficios</h5>
                    </div>
                </div>
                <br>
                <div *ngIf="totalFeatures == 0" class="container">
                    <p>No cuenta con beneficios</p>
                </div>
                <div class="container" *ngIf="totalFeatures > 0">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                            <th>Propiedad</th>
                            <th>Valor</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let feature of product?.Features">
                            <td>{{feature.Property}}</td>
                            <td>{{feature.Value}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>