<div class="page-wrapper">
    <div class="authentication-box">
        <div class="container">
            <div class="row">
                <div class="col-md-3">  </div>
                <div class="col-md-6 p-0">
                    <div class="card tab2-card">
                        <div class="card-body">
                            <div class="tcenter">
                                <img src="../../../../assets/images/jackyluislogo.png" style="width: 60%;" alt="">
                            </div>
                            <ngb-tabset class="tab-coupon mb-0">
                                <ngb-tab>
                                    <ng-template ngbTabContent>
                                        <div class="tab-pane fade active show" id="account" role="tabpanel"
                                            aria-labelledby="account-tab">
                                            <form [formGroup]="loginForm" 
                                                (ngSubmit)="onSubmit()"
                                                class="form-horizontal auth-form" novalidate="">
                                                <div class="form-group">
                                                    <input required="" formControlName="email" type="email"
                                                        class="form-control" placeholder="Correo electrónico"
                                                        [ngClass]="{'error': submittedLogin && formState?.email?.errors?.required}">
                                                        <label class="errorRed"
                                                            *ngIf="submittedLogin && formState?.email?.errors?.pattern">
                                                            Ingresa un correo válido.
                                                        </label>
                                                </div>
                                                <div class="form-group">
                                                    <input required="" formControlName="password" type="password"
                                                        class="form-control" placeholder="Contraseña"
                                                        [ngClass]="{'error': submittedLogin && formState?.password?.errors?.required}">
                                                </div>
                                                <!-- <div class="form-terms">
                                                    <div class="custom-control custom-checkbox mr-sm-2">
                                                        <input type="checkbox" class="custom-control-input"
                                                            id="customControlAutosizing">
                                                        <label class="custom-control-label"
                                                            for="customControlAutosizing">Recordarme</label>
                                                        <a href="javascript:void(0)"
                                                            class="btn btn-default forgot-pass">olvidé mi contraseña</a>
                                                    </div>
                                                </div> -->
                                                <!--<div class="form-button">
                                                    <a [routerLink]="'/dashboard/default'"><button class="btn btn-primary" type="submit">Iniciar sesión</button></a>
                                                </div>-->
                                                <button class="btn btn-primary"
                                                [disabled]="isLoading">
                                                {{ isLoading ? 'Iniciando sesión' : 'Iniciar sesión'}}
                                                <i class="fa fa-spinner fa-spin"
                                                [hidden]="!isLoading"></i>
                                                </button>
                                                <!-- <div class="form-footer">
                                                    <span>O inicie sesión con redes sociales</span>
                                                    <ul class="social">
                                                        <li><a class="icon-facebook" href="javascript:void(0)"></a></li>
                                                        <li><a class="icon-twitter" href="javascript:void(0)"></a></li>
                                                        <li><a class="icon-instagram" href="javascript:void(0)"></a>
                                                        </li>
                                                        <li><a class="icon-pinterest" href="javascript:void(0)"></a>
                                                        </li>
                                                    </ul>
                                                </div> -->
                                            </form>
                                        </div>
                                    </ng-template>
                                </ngb-tab>
                            </ngb-tabset>
                            <!-- <div class="text-center mt-4">
                                <a
                                    class=""
                                    [routerLink]="['/recuperar-contrasena']">¿Olvidaste tu contraseña?
                                </a>
                            </div> -->
                        </div>
                    </div>
                    <!-- <div class="row mb-2">
                        <small class="pad-left">¿No tienes cuenta? <a [routerLink]="['/registro']">Ingresa aquí</a></small>
                    </div> -->
                    <!-- <div class="row">
                        <small class="pad-left">¿Quieres hacer un reporte como invitado? <a [routerLink]="['/reporte']">Hazlo aquí</a></small>
                    </div> -->
                </div>
                <div class="col-md-3">  </div>
            </div>
            <!-- <a [routerLink]="'/dashboard/default'" class="btn btn-primary back-btn">
                <app-feather-icons [icon]="'arrow-left'"></app-feather-icons>
                back
            </a> -->
        </div>
    </div>
</div>

<ng2-toasty></ng2-toasty>