import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatatableComponent } from "@swimlane/ngx-datatable";
import { SalesService } from "../../services/sales/sales.service";
import { AuthenticationService } from '../../services/auth/authentication.service';
import { Subscription, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ClientDepartments } from '../../services/auth/auth.models';
import * as XLSX from 'xlsx';


@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit, OnDestroy {
  public services = [];
  public temp = [];
  fileName= 'Servicios.xlsx';

  private subs: Array<Subscription> = [];
  private modalContent: any;
  private modalConfig: any = {
    ariaLabelledBy: 'modal-basic'
  };

  public dtOptions: DataTables.Settings = {};
  public dtTrigger: Subject<any> = new Subject();

  public serviceDeleteSuccess = false;
  public serviceId: number;

  constructor(
    private salesService: SalesService,
    private authService: AuthenticationService,
    private modalService: NgbModal
  ) {
  }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      language: {
        emptyTable: '',
        zeroRecords: 'No hay coincidencias',
        lengthMenu: 'Mostrar _MENU_ elementos',
        search: 'Buscar:',
        info: 'De _START_ a _END_ de _TOTAL_ elementos',
        infoEmpty: 'De 0 a 0 de 0 elementos',
        infoFiltered: '(filtrados de _MAX_ elementos totales)',
        paginate: {
          first: 'Prim.',
          last: 'Últ.',
          next: 'Sig.',
          previous: 'Ant.'
        },
      },
    };

    this.subs.push(  
      this.salesService
        .getServices('Servicios')
        .pipe(
          catchError(err => this.authService.handleUnauthorizedError(err))
        )
        .subscribe(data => {
          this.services = data;
          this.dtTrigger.next();
        })
    );
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    this.subs.forEach(sub => sub.unsubscribe());
  }

  public setModalContent(content: any, serviceId: number): void {
    this.serviceId = serviceId;
    this.modalContent = content;
    this.modalService.open(this.modalContent, this.modalConfig);
  }

  public deleteProduct() : void {
    this.subs.push(
      this.salesService.deleteService(this.serviceId)
        .subscribe(resolve => {
          if (resolve.Active == false) {
            this.serviceDeleteSuccess = true;
            setTimeout(() => {
              this.modalService.dismissAll();
              window.location.reload();
            }, 1500);
          }
        })
    );
  }

  exportExcel(): void
  {
    let element = document.getElementById('excel-services');
    const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);
 
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Servicios');
  
    XLSX.writeFile(wb, this.fileName);
 
  }

}
