import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { Subscription } from 'rxjs';
import { ToastyService, ToastyConfig, ToastOptions } from 'ng2-toasty';
import { ProductsService } from 'src/app/components/services/products/products.service';
import { SpareModel } from '../../product.models';
import { catchError, map } from 'rxjs/operators';


@Component({
  selector: 'app-digital-add',
  templateUrl: './digital-add.component.html',
  styleUrls: ['./digital-add.component.scss']
})
export class DigitalAddComponent implements OnInit {
  public productForm: FormGroup;
  public isLoading = false;
  public submitted = false;
  public onEdit: boolean;
  public counter: number = 1;
  public productId: number;
  public id: number;
  public categories: any;
  public subcategories: any;

  public url = [{
    img: "assets/images/photo.png",
  },
  {
    img: "assets/images/photo.png",
  },
  {
    img: "assets/images/photo.png",
  }
  ]

  private subs: Array<Subscription> = [];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private toastyService: ToastyService,
    private toastyConfig: ToastyConfig,
    private productService: ProductsService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.toastyConfig.theme = 'bootstrap';
  }

  increment() {
    this.counter += 1;
  }

  decrement() {
    this.counter -= 1;
  }

  //FileUpload
  readUrl(event: any, i) {
    if (event.target.files.length === 0)
      return;
    //Image upload validation
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    // Image upload
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.url[i].img = reader.result.toString();
    }
    //console.log(reader);
  }


  public config1: DropzoneConfigInterface = {
    clickable: true,
    maxFiles: 1,
    autoReset: null,
    errorReset: null,
    cancelReset: null
  };

  public onUploadInit(args: any): void { }

  public onUploadError(args: any): void { }

  public onUploadSuccess(args: any): void { }

  ngOnInit() {
    this.subs.push(
      this.activatedRoute.paramMap.subscribe((data: any) => {
        if (data.params.id) {
          this.productId = data.params.id;
          this.onEdit = true;
          this.productService.getProduct(data.params.id)
            .subscribe(user => this.setValues(user));
            this.productForm = this.fb.group({
              Title: ['', [Validators.required]],
              Model: ['', [Validators.required]],
              UnitPrice: ['', [Validators.required]],
              CurrencyCode: ['', [Validators.required]],
              SKU: ['', [Validators.required]],
              Brand: ['', [Validators.required]],
              Family: ['', Validators.required],
              Category: ['', Validators.required],
              SubCategory: ['', Validators.required],
              Quantity: ['', Validators.required],
              Description: ['', Validators.required]
            })
        }else {
          this.onEdit = false;
          this.productForm = this.fb.group({
            Title: ['', [Validators.required]],
            Model: ['', [Validators.required]],
            UnitPrice: ['', [Validators.required]],
            CurrencyCode: ['', [Validators.required]],
            SKU: ['', [Validators.required]],
            Brand: ['', [Validators.required]],
            Family: ['Refacciones', Validators.required],
            Category: ['', Validators.required],
            SubCategory: ['', Validators.required],
            Quantity: ['', Validators.required],
            Description: ['', Validators.required]
          })
        }
        this.productService
        .getCategories()
        .subscribe(data => {
          this.categories = data;
        })
        this.productService
        .getSubCategories()
        .subscribe(data => {
          this.subcategories = data;
        })
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }

  public get formState() {
    return this.productForm.controls
  }

  private setValues(product: any): void {
    const values = this.productService.mapRequiredValues(product);
    this.id = values.id;
    const { controls } = this.productForm;

    for (const value in values) {
      if (controls.hasOwnProperty(value)) {
        this.productForm.controls[value].setValue(values[value]);
      }
    }
  }

  private buildProduct(): SpareModel {
    return {
        Title: this.productForm.controls['Title'].value,
        Model: this.productForm.controls['Model'].value,
        UnitPrice: this.productForm.controls['UnitPrice'].value,
        CurrencyCode: this.productForm.controls['CurrencyCode'].value,
        SKU: this.productForm.controls['SKU'].value,
        Brand: this.productForm.controls['Brand'].value,
        Family: this.productForm.controls['Family'].value,
        Category: this.productForm.controls['Category'].value,
        SubCategory: this.productForm.controls['SubCategory'].value,
        Quantity: this.productForm.controls['Quantity'].value,
        Description: this.productForm.controls['Description'].value
    }
  }

  private buildProductUpdate(): SpareModel {
    return {
      id: this.id,
      Title: this.productForm.controls['Title'].value,
      Model: this.productForm.controls['Model'].value,
      UnitPrice: this.productForm.controls['UnitPrice'].value,
      CurrencyCode: this.productForm.controls['CurrencyCode'].value,
      SKU: this.productForm.controls['SKU'].value,
      Brand: this.productForm.controls['Brand'].value,
      Family: this.productForm.controls['Family'].value,
      Category: this.productForm.controls['Category'].value,
      SubCategory: this.productForm.controls['SubCategory'].value,
      Quantity: this.productForm.controls['Quantity'].value,
      Description: this.productForm.controls['Description'].value
    }
  }

  private handleError(error: any) {
    const toastOptions: ToastOptions = {
      title: 'Error',
      msg: 'No se pudo crear la refacción',
      showClose: true,
      timeout: 2000
    };

    this.toastyService.error(toastOptions);

    return [];
  }

  private handleErrorEdit(error: any) {
    const toastOptions: ToastOptions = {
      title: 'Error',
      msg: 'No se pudo actualizar la refacción',
      showClose: true,
      timeout: 2000
    };

    this.toastyService.error(toastOptions);

    return [];
  }

  private handleProductCreation() {
    const toastOptions: ToastOptions = {
      title: 'Refacción',
      msg: 'Refacción creada correctamente',
      showClose: true,
      timeout: 1700
    };

    this.toastyService.success(toastOptions);
  }

  private handleProductEdit() {
    const toastOptions: ToastOptions = {
      title: 'Refacción',
      msg: 'Refacción actualizada correctamente',
      showClose: true,
      timeout: 1700
    };

    this.toastyService.success(toastOptions);
  }

  onSubmit() {
    this.submitted = true;

    if (this.productForm.invalid) {
        return;
    }

    if (this.onEdit) {
      this.updateProduct();
    } else {
      this.createProduct();
    } 
  }

  createProduct() {
    const product: SpareModel = this.buildProduct();
    this.isLoading = true;
    this.subs.push(
        this.productService.createProduct(product)
        .pipe(
        map(() => {
            this.handleProductCreation();
            setTimeout(() => {
            this.router.navigateByUrl('products/parts-list');
            }, 1800);
        }),
        catchError(error => this.handleError(error))
        )
        .subscribe()
    ); 
  }

  updateProduct() {
    const product: SpareModel = this.buildProductUpdate();
    this.isLoading = true;
    this.subs.push(
        this.productService.updateProduct(this.productId, product)
        .pipe(
        map(() => {
            this.handleProductEdit();
            setTimeout(() => {
            this.router.navigateByUrl('/products/parts-list');
            }, 1800);
        }),
        catchError(error => this.handleErrorEdit(error))
        )
        .subscribe()
    ); 
  }

}
