<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row pl-3 mb-3">
      <div class="col-xs-12 col-md-9" [ngClass]="{' d-contents': reports?.status}">
        <ul *ngFor="let item of reports?.status">
          <li class="list-filter" (click)="onFilerbyStatus(item.Status)">{{item.Status}} ({{item.count}})</li>
        </ul>
      </div>
      <div class="col-xs-12 col-md-3 text-right">
          <button 
            class="btn btn-primary"
            (click)="exportExcel()"
            [disabled]="isLoading">
              {{ isLoading ? 'Exportando' : 'Exportar a Excel'}}
            <i class="fa fa-spinner fa-spin"
                [hidden]="!isLoading">
            </i>
          </button>
      </div>
    </div>
    <div class="col-xs-12 col-md-6 pl-0 mb-4">
      <label for="source"> Filtrar por fuente de origen</label>
      <ng-select id="source" [(ngModel)]="selectedSource" (change)="onFilterSource()">
        <ng-option value="" selected>Selecciona una opción</ng-option>
        <ng-option *ngFor="let source of sources" [value]="source">{{source}}</ng-option>
      </ng-select>
    </div>
    <div class="card" *ngIf="!showTable">
        <div class="card-body">
            <p class="text-center">Por favor seleccione los filtros deseados para su reporte.</p>
        </div>
    </div>
      <div class="card" *ngIf="showTable">
          <div class="card-body">
            <div id="batchDelete" class="">
                <div class="table-responsive">
                    <table datatable
                    [dtOptions]="dtOptions"
                    [dtTrigger]="dtTrigger"
                    class=" table table-lg table-hover row-border hover">
                    <thead>
                        <tr>
                          <th>Nº</th>
                          <th>Folio</th>
                          <th>Fecha del reporte</th>
                          <th>Asunto</th>
                          <th>Colonia</th>
                          <th>Estatus</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr [routerLink]="['show', report.id]" [ngClass]="{'expired': report.expired === true && report.Status !== 'Atendida'}" *ngFor="let report of reports?.list; let i = index">
                          <td>{{i+1}}</td>
                          <td>{{report.folio}}</td>
                          <td>{{report.created_at | date: 'dd/MM/yyyy, h:mm a'}}</td>
                          <td>{{report.Subject}}</td>
                          <td>{{report.Address.Neighborhood ? report.Address.Neighborhood : 'Sin colonia'}}</td>
                          <td>{{report.Status}}</td>
                        </tr>
                    </tbody>
                    </table>
                    <ngb-pagination (pageChange)="loadPage($event)" [collectionSize]="total" [(page)]="page" [maxSize]="10" [rotate]="true" [boundaryLinks]="true"></ngb-pagination>
                </div>
                <table
                    class="table table-lg table-hover row-border hover d-none"
                    id="reports-source">
                    <thead>
                        <tr>
                          <th>No</th>
                          <th>FOLIO</th>
                          <th>ASUNTO</th>
                          <th>DESCRIPCIÓN</th>
                          <th>DEPENDENCIA</th>
                          <th>NOMBRE</th>
                          <th>COLONIA</th>
                          <th>UBICACIÓN</th>
                          <th>ENTRE CALLES</th>
                          <th>STATUS</th>
                          <th>FECHA DE CREADO</th>
                          <th>FECHA DE ATENDIDO</th>*
                          <th>MODO DE CAPTURA</th>
                          <th>TELÉFONO / CELULAR</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let report of allReports?.list; let i = index">
                          <td>{{i+1}}</td>
                          <td>{{report.folio}}</td>
                          <td>{{report.Subject}}</td>
                          <td>{{report.Description}}</td>
                          <td>{{report.Department ? report.Department : 'Sin área asignada'}}</td>
                          <td>{{report.Name}}</td>
                          <td>{{report.Address.Neighborhood ? report.Address.Neighborhood : 'N/A'}}</td>
                          <td>{{report.Address.Street + ' ' + report.Address.ExtNumber + ' ' + report.Address.ExtNumber + ' ' + report.Address.Neighborhood + ' ' + report.Address.State + ' ' + report.Address.ZipCode}}</td>
                          <td>{{report.Address.Street1 ? report.Address.Street1 + ' y ' + report.Address.Street2 : 'N/A'}}</td>
                          <td>{{report.Status}}</td>
                          <td>{{report.created_at | date: 'dd/MM/yyyy, h:mm a'}}</td>
                          <td>{{report.ClosedAt | date: 'dd/MM/yyyy, h:mm a'}}</td>
                          <td>{{report.Source ? report.Source : 'N/A'}}</td>
                          <td>{{report.PhoneNumber ? report.PhoneNumber : 'Sin número'}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
          </div>
      </div>
  </div>
  <!-- Container-fluid Ends-->