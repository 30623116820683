import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NavService } from '../../service/nav.service';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../components/services/auth/authentication.service';
import { AuthModel } from '../../../components/auth/auth.models';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public right_sidebar: boolean = false;
  public open: boolean = false;
  public openNav: boolean = false;
  public isOpenMobile : boolean;
  public currentUser: AuthModel;

  @Output() rightSidebarEvent = new EventEmitter<boolean>();

  constructor(
    public navServices: NavService,
    private authService: AuthenticationService,
    private router: Router
    ) {

    }

  collapseSidebar() {
    this.open = !this.open;
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar
  }
  right_side_bar() {
    this.right_sidebar = !this.right_sidebar
    this.rightSidebarEvent.emit(this.right_sidebar)
  }

  openMobileNav() {
    this.openNav = !this.openNav;
  }

  ngOnInit(): void {
    this.authService.currentUser.subscribe((authenticatedUser: AuthModel) => this.currentUser = authenticatedUser);
    //console.log(this.authService.currentUserValue);
  }

  logout(): void {
    this.authService.logout();
    this.router.navigateByUrl('/detalle');
  }

}