<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="row">
    <div class="col-xl-3 col-xs-12">
      <label>ÁREA O DIRECCIÓN</label>
      <div class="form-group">
          <ng-select [(ngModel)]="area" placeholder="Seleccionar área">
              <ng-option value="" disabled selected>Área o Dirección</ng-option>
              <ng-option *ngFor="let value of values" [value]="value">
                  {{value}}
              </ng-option>
          </ng-select>
      </div>
  </div>
    <div class="col-xl-3 col-xs-12">
        <label>COLONIA O FRACC.</label>
        <div class="form-group">
            <!-- <ng-select [(ngModel)]="area" [ngClass]="{'error': submitted && formState?.LocalType?.errors?.required}">
                <ng-option value="" disabled selected>Clasificación</ng-option>
                <ng-option *ngFor="let value of values" [value]="value.ValueAlpha">
                    {{value.ValueAlpha}}
                </ng-option>
            </ng-select> -->
            <input placeholder="Buscar colonia o fraccionamiento" class="form-control" id="validationCustom1" type="text" [(ngModel)]="suburb">
        </div>
    </div>
    <div class="col-xl-6 col-xs-12">
        <label>SELECCIONA UN PERIODO DE FECHAS</label>
        <div class="row">
            <div class="col-xl-4 col-xs-12">
                <div class="form-group">
                    <input class="form-control" id="validationCustom1" type="date" [(ngModel)]="startDate">
                </div>
            </div>
            <div class="col-xl-4 col-xs-12">
                <div class="form-group">
                    <input class="form-control" id="validationCustom2" type="date" [(ngModel)]="endDate">
                </div>
            </div>
            <div class="col-xl-4 col-xs-12">
                <button [hidden]="!endDate || hideButton" class="btn btn-primary" (click)="getResults()">
                    Filtrar
                </button>
                <button [hidden]="!showClean" title="Borrar filtros" class="btn btn-primary" (click)="clearInputs()">
                  Borrar filtros
              </button>
            </div>
        </div>
    </div>
  </div>

  <div class="row pl-3 mb-4" *ngIf="showTable">
    <div class="col-8 d-contents">
      <ul *ngFor="let item of reports?.status">
          <li class="list-filter" (click)="onFilerbyStatus(item.Status)">{{item.Status}} ({{item.count}})</li>
      </ul>
    </div>
    <div class="col-4 d-flex flex-row-reverse pr-0">
        <!-- <button class="btn btn-primary" (click)="exportExcel()">Exportar a Excel</button> -->
        <button class="btn btn-primary mt-1" (click)="exportExcel()">Exportar a Excel</button>
    </div>
</div>



  <!-- <div class="d-flex flex-row-reverse pl-3 pr-3 mb-3" *ngIf="showTable">
    <ul *ngFor="let item of reports?.status">
        <li class="list-filter" (click)="onFilerbyStatus(item.Status)">{{item.Status}} ({{item.count}})</li>
    </ul>
    <div class="">
        <button class="btn btn-primary" (click)="exportExcel()">Exportar a Excel</button>
    </div>
</div> -->
  <div class="card" *ngIf="!showTable">
    <div class="card-body">
        <p class="text-center">Por favor seleccione los filtros deseados para su reporte.</p>
    </div>
  </div>
  <div class="card" *ngIf="showTable">
      <div class="card-body">
          <div id="batchDelete" class="">
              <div class="table-responsive">
                  <table datatable
                  [dtOptions]="dtOptions"
                  [dtTrigger]="dtTrigger"
                  class=" table table-lg table-hover row-border hover">
                  <thead>
                      <tr>
                      <th>Folio</th>
                      <th>Fecha de reporte</th>
                      <th>Problema</th>
                      <th>Nombre</th>
                      <th>Correo electrónico</th>
                      <th>Estatus</th>
                      <th>Acciones</th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr *ngFor="let report of reports?.List">
                      <td>{{report.folio}}</td>
                      <td>{{report.created_at | date:'dd/MM/yyyy h:mm:ss a'}}</td>
                      <td>{{report.Subject}}</td>
                      <td>{{report.Name}}</td>
                      <td>{{report.Email}}</td>
                      <td>{{report.Status}}</td>
                      <td class="actions-cell">
                          <span>
                            <a [routerLink]="['show', report.id]"
                              title="Ver reporte">
                              <u>Ver reporte</u>
                            </a>
                          </span>
                      </td>
                      </tr>
                  </tbody>
                  </table>
                  <ngb-pagination (pageChange)="loadPage($event)" [collectionSize]="total" [(page)]="page" [maxSize]="10" [rotate]="true" [boundaryLinks]="true"></ngb-pagination>
              </div>
              <table
                class=" table table-lg table-hover row-border hover d-none"
                id="reports-zone">
                <thead>
                    <tr>
                    <th>Folio</th>
                    <th>Fecha de reporte</th>
                    <th>Problema</th>
                    <th>Nombre</th>
                    <th>Correo electrónico</th>
                    <th>Estatus</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let report of allReports?.List">
                      <td>{{report.folio}}</td>
                      <td>{{report.created_at | date:'dd/MM/yyyy h:mm:ss a'}}</td>
                      <td>{{report.Subject}}</td>
                      <td>{{report.Name}}</td>
                      <td>{{report.Email}}</td>
                      <td>{{report.Status}}</td>
                    </tr>
                </tbody>
              </table>
          </div>
      </div>
  </div>
</div>
<!-- Container-fluid Ends-->

<ng-template #content
  let-modal>
  <div class="modal-header">
    <h4 class="modal-title text-center width-100"
      id="modal-title">Eliminar Usuario</h4>
  </div>
  <div class="modal-body text-center">
    <ng-container *ngIf="!deleteIsSuccess">
      <p class="no-mrg">Se eliminirán todos los datos del usuario.</p>
      <p class="no-mrg">Esta acción no se puede deshacer.</p>
    </ng-container>

    <ng-container *ngIf="deleteIsSuccess">
      <p>Usuario borrado correctamente</p>
    </ng-container>
  </div>
  <ng-container *ngIf="!deleteIsSuccess">
    <div class="modal-footer">
      <a type="button"
        class="btn btn-outline-light"
        (click)="modal.close()">Cancelar</a>
      <button type="button"
        class="btn btn-inverse btn-danger"
        (click)="deleteUser()">Eliminar</button>
    </div>
  </ng-container>
</ng-template>