import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription, throwError } from 'rxjs';
import { UserCreateModel } from '../../auth/auth.models';
import { ActivatedRoute, Router } from '@angular/router';
import { map, catchError } from 'rxjs/operators';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { ToastyService, ToastyConfig, ToastOptions } from 'ng2-toasty';
import { UsersService } from '../../services/users/users.service';
import { ReportsService } from '../../services/reports/reports.service';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss']
})
export class CreateUserComponent implements OnInit, OnDestroy {
  public accountForm: FormGroup;
  public isLoading = false;
  public submitted = false;
  public onEdit: boolean;
  public userId: number;
  public passwordsequals: boolean = false;
  public values: any;
  public showArea: boolean;
  public role: string;

  private subs: Array<Subscription> = [];

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private userService: UsersService,
    private authService: AuthenticationService,
    private toastyService: ToastyService,
    private toastyConfig: ToastyConfig,
    private activatedRoute: ActivatedRoute,
    private reportService: ReportsService,
  ) {
    this.toastyConfig.theme = 'bootstrap';
  }


  ngOnInit() {
    if (this.router.url === '/users/officers/create-officer') {
      this.showArea = true;
    }
    this.subs.push(
      this.activatedRoute.paramMap.subscribe((data: any) => {
        if (data.params.id) {
          this.userId = data.params.id;
          this.onEdit = true;
          this.userService.getUser(data.params.id)
            .subscribe(user => this.setValues(user));
            this.accountForm = this.formBuilder.group({
              firstName: ['', Validators.required],
              lastName: ['', Validators.required],
              phoneNumber: ['', Validators.required],
              email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
              password: ['', Validators.required],
              confirmedPassword: ['', [Validators.required]]
            })
        }
        else {
        this.onEdit = false;
          this.accountForm = this.formBuilder.group({
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            phoneNumber: ['', Validators.required],
            email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
            password: ['', Validators.required],
            confirmedPassword: ['', [Validators.required]],
            area: ['']
          })
        }
      })
    );
    this.reportService
      .getValuestoCatalog('Departments')
      .subscribe(data => {
        this.values = data;
      });
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }

  public get formState() {
    return this.accountForm.controls
  }

  private validatePasswords(): void {
    const password: string = this.accountForm.get('password').value;
    const confirmPassword: string = this.accountForm.get('confirmedPassword').value;

    if (password !== confirmPassword) {
      this.accountForm.controls['confirmedPassword'].setErrors({ incorrect: true });
    }
    else {
      this.passwordsequals = true;
    }
  }

  private setValues(user: any): void {
    const values = this.userService.mapRequiredValues(user);
    this.role = user.roles;
    const { controls } = this.accountForm;

    for (const value in values) {
      if (controls.hasOwnProperty(value)) {
        this.accountForm.controls[value].setValue(values[value]);
      }
    }
  }

  private buildUser(): UserCreateModel {
    let url = this.router.url;
    if (url == '/users/admins/create-admin') {
      return {
        firstName: this.accountForm.controls['firstName'].value,
        lastName: this.accountForm.controls['lastName'].value,
        password: this.accountForm.controls['password'].value,
        email: this.accountForm.controls['email'].value,
        phoneNumber: this.accountForm.controls['phoneNumber'].value,
        roles: 'admin'
      }
    } if (url == '/users/dealers/create-dealer') {
      return {
        firstName: this.accountForm.controls['firstName'].value,
        lastName: this.accountForm.controls['lastName'].value,
        password: this.accountForm.controls['password'].value,
        email: this.accountForm.controls['email'].value,
        phoneNumber: this.accountForm.controls['phoneNumber'].value,
        roles: 'dealer',
      }
    }
  }

  private buildUserUpdate(): UserCreateModel {
    return {
      firstName: this.accountForm.controls['firstName'].value,
      lastName: this.accountForm.controls['lastName'].value,
      password: this.accountForm.controls['password'].value,
      email: this.accountForm.controls['email'].value,
      phoneNumber: this.accountForm.controls['phoneNumber'].value,
      roles: this.role
    }
  }

  private handleError(error: any) {
    const toastOptions: ToastOptions = {
      title: 'Error',
      msg: error.error.message,
      showClose: true,
      timeout: 2000
    };

    this.toastyService.error(toastOptions);
    this.isLoading = false;
    return [];
  }

  onSubmit() {
    this.submitted = true;

    if (this.accountForm.invalid) {
      return;
    }

    this.validatePasswords();

    if (this.onEdit && this.passwordsequals) {
      this.editUser();
    }
    if (!this.onEdit && this.passwordsequals) {
      this.createUser();
    } 
    else {
      return;
    }

  }

  createUser() {
    const user: UserCreateModel = this.buildUser();
    this.isLoading = true;
    this.subs.push(
      this.authService.createUserPerson(user)
        .pipe(
          map(() => {
            let url = this.router.url;
            if (url == '/users/admins/create-admin') {
              this.handleUserCreation();
            } else if (url == '/users/supervisors/create-supervisor') {
              this.handleSupervisorCreation();
            } else {
              this.handleInterCreation();
            }
            setTimeout(() => {
              if (url == '/users/admins/create-admin') {
                this.router.navigateByUrl('users/admins');
              } else if (url == '/users/supervisors/create-supervisor') {
                this.router.navigateByUrl('users/supervisors');
              } else {
                this.router.navigateByUrl('users/dealers');
              }
            }, 1800);
          }),
          catchError(error => this.handleError(error))
        )
        .subscribe()
    );
  }

  editUser() {
    const user: UserCreateModel = this.buildUserUpdate();
    this.isLoading = true;
    this.subs.push(
      this.authService.editUserPerson(user, this.userId)
        .pipe(
          map(() => {
              this.handleUserEdit();
            setTimeout(() => {
              if (this.role === 'admin') {
                this.router.navigateByUrl('users/admins');
              } if (this.role === 'supervisor') {
                this.router.navigateByUrl('users/supervisors');
              } if (this.role === 'dealer') {
                this.router.navigateByUrl('users/dealers');
              } else {
                this.router.navigateByUrl('users');
              }
            }, 1800);
          }),
          catchError(error => this.handleError(error))
        )
        .subscribe()
    );
  }

  private handleUserCreation() {
    const toastOptions: ToastOptions = {
      title: 'Administrador',
      msg: 'Administrador creado correctamente',
      showClose: true,
      timeout: 1700
    };

    this.toastyService.success(toastOptions);
  }

  private handleInterCreation() {
    const toastOptions: ToastOptions = {
      title: 'Distribuidor',
      msg: 'Distribuidor creado correctamente',
      showClose: true,
      timeout: 1700
    };

    this.toastyService.success(toastOptions);
  }

  private handleSupervisorCreation() {
    const toastOptions: ToastOptions = {
      title: 'Supervisor',
      msg: 'Supervisor creado correctamente',
      showClose: true,
      timeout: 1700
    };

    this.toastyService.success(toastOptions);
  }

  private handleUserEdit() {
    const toastOptions: ToastOptions = {
      title: 'Usuario',
      msg: 'Usuario actualizado correctamente',
      showClose: true,
      timeout: 1700
    };

    this.toastyService.success(toastOptions);
  }

}
