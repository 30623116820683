<div class="container-fluid">
    <p>Total de productos: <strong>{{digital_list.length}}</strong></p>
    <div class="row products-admin ratio_asos">
        <div class="col-xl-3 col-sm-6" *ngFor="let product of digital_list">
            <div class="card">
                <div class="card-body product-box">
                    <div class="img-wrapper">
                        <div class="lable-block"><span class="lable3" *ngIf="product.tag == 'new'">new</span> <span
                                class="lable4" *ngIf="product.sale == 'on sale'">on sale</span></div>
                        <div class="front">
                            <a href="javascript:void(0)"><img [src]="[product.ImgFileUrl]"
                                    class="img-fluid blur-up lazyload bg-img" alt=""></a>
                            <div class="product-hover">
                                <ul>
                                    <li>
                                        <button [routerLink]="['show', product.id]" class="btn" type="button" data-original-title="" title="">
                                            <i class="icon-eye"></i></button>
                                    </li>
                                    <li>
                                        <button [routerLink]="['edit', product.id]" class="btn" type="button" data-original-title="" title="">
                                            <i class="icon-pencil-alt"></i></button>
                                    </li>
                                    <li>
                                        <button class="btn" type="button" data-toggle="modal"
                                            data-target="#exampleModalCenter" data-original-title="" title=""><i
                                                class="icon-trash"></i></button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="product-detail">
                        <!-- <div class="rating"><i class="fa fa-star"></i> <i class="fa fa-star"></i> <i
                                class="fa fa-star"></i> <i class="fa fa-star"></i> <i class="fa fa-star"></i></div> -->
                        <a href="javascript:void(0)">
                            <h5>{{product.Title}}</h5>
                        </a>
                        <h4>{{product.UnitPrice | currency}} {{product.CurrencyCode}}</h4>
                        <br>
                        <h6>Marca: {{product.Brand}}</h6>
                        <h6>Modelo: {{product.Model}}</h6>
                        <!-- <ul class="color-variant">
                            <li class="bg-light0"></li>
                            <li class="bg-light1"></li>
                            <li class="bg-light2"></li>
                        </ul> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>