import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, Subject, throwError } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReportsService } from 'src/app/components/services/reports/reports.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
@Component({
  selector: 'app-reports-by-user',
  templateUrl: './reports-by-user.component.html',
  styleUrls: ['./reports-by-user.component.scss']
})
export class ReportsByUserComponent implements OnInit, OnDestroy {

  private subs: Array<Subscription> = [];
  private modalContent: any;
  private modalConfig: any = {
    ariaLabelledBy: 'modal-basic'
  };

  public reports: any;
  public values = [];
  public area: string;
  public description: string;
  public dtOptions: DataTables.Settings = {};
  public dtTrigger: Subject<any> = new Subject();
  public deleteIsSuccess = false;
  public assignIsSuccess = false;
  public acceptIsSuccess = false;
  public rejectIsSuccess = false;
  public reportId: string;
  public total: number;
  public page = 1;
  public limit: number = 10;
  public offset: number = 0;

  public status = 'Total';

  constructor(
    private reportService: ReportsService,
    private authService: AuthenticationService,
    private modalService: NgbModal
  ) {  }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      language: {
        emptyTable: '',
        zeroRecords: 'No se han encontrado resultados',
        lengthMenu: 'Mostrar _MENU_ elementos',
        search: 'Buscar:',
        info: 'De _START_ a _END_ de _TOTAL_ elementos',
        infoEmpty: 'De 0 a 0 de 0 elementos',
        infoFiltered: '(filtrados de _MAX_ elementos totales)',
        paginate: {
          first: 'Prim.',
          last: 'Últ.',
          next: 'Sig.',
          previous: 'Ant.'
        },
      },
      order: [0, 'desc']
    };

    // console.log(this.authService.currentUserValue);

    this.subs.push(
      this.reportService
        .getReportsByUser(this.authService.currentUserValue.email, this.limit, this.offset, this.status)
        .pipe(
          catchError(err => this.authService.handleUnauthorizedError(err))
        )
        .subscribe(data => {
          // console.log(data);
          this.reports = data;
          this.total = data.Total
          this.dtTrigger.next();
        })
    );
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    this.subs.forEach(sub => sub.unsubscribe());
  }

  setModalContent(content: any, reportId: string): void {
    this.reportId = reportId;
    this.modalContent = content;
    this.modalService.open(this.modalContent, this.modalConfig);
  }

  assignModalContent(contentArea: any, reportId: string): void {
    this.reportId = reportId;
    this.modalContent = contentArea;
    this.modalService.open(this.modalContent, this.modalConfig);
  }

  onFilerbyStatus(status: string): void {
    this.status = status;
    this.subs.push(
      this.reportService
        .getReportsByUser(this.authService.currentUserValue.email, this.limit, this.offset, this.status)
        .pipe(
          catchError(err => this.authService.handleUnauthorizedError(err))
        )
        .subscribe(data => {
          this.reports = data;
          this.total = data.Total
        })
    );
  }

  public loadPage(page: number) {
    this.subs.push(
      this.reportService
        .getReportsByUser(this.authService.currentUserValue.email, this.limit, page-1, this.status)
        .pipe(
          catchError(err => this.authService.handleUnauthorizedError(err))
        )
        .subscribe(data => {
          this.reports = data;
          this.total = data.Total
        })
    );
  }

}

