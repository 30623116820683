import { Component, OnInit, OnDestroy } from '@angular/core';
import { UsersService } from '../services/users/users.service';
import { AuthenticationService } from '../services/auth/authentication.service';
import { Subscription, Subject, throwError } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReportsService } from '../services/reports/reports.service';
import * as XLSX from 'xlsx';
import { ToastyService, ToastyConfig, ToastOptions } from 'ng2-toasty';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit, OnDestroy {

  fileName= 'Reporte-General.xlsx';
  private subs: Array<Subscription> = [];
  private modalContent: any;
  private modalConfig: any = {
    ariaLabelledBy: 'modal-basic'
  };

  public reports: any;
  public allReports: any;

  public isLoading = false;
  public values = [];
  public area: string;
  public description: string;
  public dtOptions: DataTables.Settings = {};
  public dtTrigger: Subject<any> = new Subject();
  public deleteIsSuccess = false;
  public assignIsSuccess = false;
  public acceptIsSuccess = false;
  public rejectIsSuccess = false;
  public reportId: string;
  public total: number;
  public page = 1;
  public limit: number = 10;
  public offset: number = 0;

  public checkText: boolean = false;
  public status = 'Total';
  public statusValues = [];
  public updateIsSuccess = false;
  public statusSelected: string;
  public textSearch: string;

  constructor(
    private usersService: UsersService,
    private reportService: ReportsService,
    private authService: AuthenticationService,
    private toastyService: ToastyService,
    private modalService: NgbModal
  ) { 
  }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      language: {
        emptyTable: '',
        zeroRecords: 'No se han encontrado resultados',
        lengthMenu: 'Mostrar _MENU_ elementos',
        search: 'Buscar:',
        info: 'De _START_ a _END_ de _TOTAL_ elementos',
        infoEmpty: 'De 0 a 0 de 0 elementos',
        infoFiltered: '(filtrados de _MAX_ elementos totales)',
        paginate: {
          first: 'Prim.',
          last: 'Últ.',
          next: 'Sig.',
          previous: 'Ant.'
        },
      },
      order: [0, 'asc']
    };

    this.subs.push(
      this.reportService
        .getReports(this.limit, this.offset, this.status)
        .pipe(
          catchError(err => this.authService.handleUnauthorizedError(err))
        )
        .subscribe(data => {
          console.log(data);
          this.reports = data;
          this.total = data.Count
          this.dtTrigger.next();
        })
    );
    this.reportService
        .getValuestoCatalog('ReportStatus')
        .subscribe(data => {
          this.statusValues = data;
    });
  }

  reloadTable(): void {
    this.subs.push(
      this.reportService
        .getReports(this.limit, this.offset, this.status)
        .pipe(
          catchError(err => this.authService.handleUnauthorizedError(err))
        )
        .subscribe(data => {
          this.reports = data;
          this.total = data.Count
        })
    );
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    this.subs.forEach(sub => sub.unsubscribe());
  }

  exportExcel(): void {
    this.isLoading = true;
    this.subs.push(
      this.reportService
        .getReports(999999, 0, 'Total')
        .pipe(
          catchError(err => this.authService.handleUnauthorizedError(err))
        )
        .subscribe(data => {
          this.allReports = data;
          setTimeout(() =>{
            let element = document.getElementById('reports-general');
            const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);
        
            const wb: XLSX.WorkBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Reportes');
          
            XLSX.writeFile(wb, this.fileName);
            this.isLoading = false;
          },2000)
        })
    );
  }

  setModalContent(content: any, reportId: string): void {
    this.reportId = reportId;
    this.modalContent = content;
    this.modalService.open(this.modalContent, this.modalConfig);
  }

  assignModalContent(contentArea: any, reportId: string): void {
    this.reportId = reportId;
    this.modalContent = contentArea;
    this.modalService.open(this.modalContent, this.modalConfig);
  }

  changeModalContent(content: any, reportId: string): void {
    this.reportId = reportId;
    this.modalContent = content;
    this.modalService.open(this.modalContent, this.modalConfig);
  }

  onFilerbyStatus(status: string): void {
    this.status = status;
    this.subs.push(
      this.reportService
        .getReports(this.limit, this.offset, this.status)
        .pipe(
          catchError(err => this.authService.handleUnauthorizedError(err))
        )
        .subscribe(data => {
          this.reports = data;
          this.total = data.Count
        })
    );
  }

  public loadPage(page: number) {
    if (this.checkText === true){
      this.subs.push(
        this.reportService
				.getReportsByText(this.limit, page-1, this.status, this.textSearch)
          .pipe(
            catchError(err => this.authService.handleUnauthorizedError(err))
          )
          .subscribe(data => {
            this.reports = data;
            this.total = data.Count
          })
      );
    }else {
      this.subs.push(
        this.reportService
          .getReports(this.limit, page-1, this.status)
          .pipe(
            catchError(err => this.authService.handleUnauthorizedError(err))
          )
          .subscribe(data => {
            this.reports = data;
            this.total = data.Count
          })
      );
    }
    
  }

  getResults(): any {
    this.checkText = true;
		this.subs.push(
			this.reportService
				.getReportsByText(this.limit, this.offset, this.status, this.textSearch)
				.pipe(
					catchError(err => this.authService.handleUnauthorizedError(err))
				)
				.subscribe(data => {
					this.reports = data;
					this.total = data.Count;
				})
		);
	}

  public clearInputs(): any {
		this.textSearch = '';
    this.checkText = false;
    this.reloadTable();
	}

  public assignReport() {
    this.subs.push(
      this.reportService.assignReport(this.reportId, this.area)
        .subscribe(resolve => {
          if (resolve.Message === 'Se asigno area') {
            this.assignIsSuccess = true;
            setTimeout(() => {
              this.modalService.dismissAll();
              this.reloadTable();
            }, 2000);
          }
        })
    );
  }

  public changeStatus() {
    this.subs.push(
      this.reportService.changeSpeceficStatusReport(this.statusSelected, this.reportId)
        .subscribe(resolve => {
          if (resolve.Status === this.statusSelected) {
            // this.updateIsSuccess = true;
            this.handleUpdateStatus();
            setTimeout(() => {
              this.modalService.dismissAll();
              this.reloadTable();
              this.updateIsSuccess = false;
            }, 2000);
          }
        })
    );
  }

  public deleteReport() {
    this.subs.push(
      this.reportService.deleteReport(this.reportId)
        .subscribe(resolve => {
          if (resolve.active == false) {
            this.deleteIsSuccess = true;
            setTimeout(() => {
              this.modalService.dismissAll();
              window.location.reload();
            }, 2000);
          }
        })
    );
  }


  private handleUpdateStatus() {
    this.isLoading = false;
    const toastOptions: ToastOptions = {
      title: 'Atención ciudadana',
      msg: 'Reporte actualizado correctamente',
      showClose: true,
      timeout: 2500
    };

    this.toastyService.success(toastOptions);
  }

  private handleError(error: any) {
    this.isLoading = false;
    const toastOptions: ToastOptions = {
      title: 'Error',
      msg: 'No hemos podido actualizar el reporte',
      showClose: true,
      timeout: 2000
    };

    this.toastyService.error(toastOptions);

    return [];
}

}

