<div class="page-wrapper">
    <div class="authentication-box paddd">
        <div class="container">
            <div class="row">
                <div class="col-md-3"></div>
                <div class="col-md-6 p-0">
                    <div class="card tab2-card">
                        <div class="card-body">
                            <div class="tcenter">
                                <img src="../../../../assets/images/logo-next.png" style="width: 60%;" alt="">
                            </div>
                            <ngb-tabset class="tab-coupon mb-0">
                                <ngb-tab>
                                    <ng-template ngbTabContent>
                                        <div class="tab-pane fade active show" id="account" role="tabpanel"
                                            aria-labelledby="account-tab">
                                            <form [formGroup]="personalDetails" (ngSubmit)="onSubmit()" class="form-horizontal auth-form" novalidate="">
                                                <fieldset>
                                                    <div class="row">
                                                        <div class="col-xs-12 col-sm-12">
                                                            <div class="form-group">
                                                                <input required="" formControlName="FirstName" type="text"
                                                                class="form-control" placeholder="Nombre"
                                                                [ngClass]="{'error': submitted && formState?.FirstName?.errors?.required}">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-xs-12 col-sm-12">
                                                            <div class="form-group">
                                                                <input required="" formControlName="LastName" type="text"
                                                                class="form-control" placeholder="Apellido"
                                                                [ngClass]="{'error': submitted && formState?.LastName?.errors?.required}">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    <!-- <div class="row">
                                                        <div class="col-xs-12 col-sm-12">
                                                            <div class="form-group">
                                                                <ng-select name="LocalType" id="LocalType" formControlName="LocalType" [ngClass]="{'error': submitted && formState?.LocalType?.errors?.required}">
                                                                    <ng-option value="" disabled selected>Clasificación</ng-option>
                                                                    <ng-option *ngFor="let value of values" [value]="value.ValueAlpha">
                                                                        {{value.ValueAlpha}}
                                                                    </ng-option>
                                                                </ng-select>
                                                            </div>
                                                        </div>
                                                    </div> -->
                                                    <div class="row">
                                                        <div class="col-xs-12 col-sm-12">
                                                            <div class="form-group">
                                                                <input required="" formControlName="PhoneNumber" type="tel"
                                                                class="form-control" placeholder="Celular" maxlength="10"
                                                                [ngClass]="{'error': submitted && formState?.PhoneNumber?.errors?.required}">
                                                                <label class="errorRed"
                                                                    *ngIf="submitted && formState?.PhoneNumber?.errors?.pattern">
                                                                    Ingresa solo 10 números.
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-xs-12 col-sm-12">
                                                            <div class="form-group">
                                                                <input required="" formControlName="Email" type="text"
                                                                class="form-control" placeholder="Correo electrónico"
                                                                [ngClass]="{'error': submitted && formState?.Email?.errors?.required}">
                                                                <label class="errorRed"
                                                                    *ngIf="submitted && formState?.Email?.errors?.pattern">
                                                                    Ingresa un correo válido.
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-xs-12 col-sm-12">
                                                            <div class="form-group">
                                                                <input required="" formControlName="Password" type="password"
                                                                class="form-control" placeholder="Contraseña"
                                                                [ngClass]="{'error': submitted && formState?.Password?.errors?.required}">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-xs-12 col-sm-12">
                                                            <div class="form-group">
                                                                <input required="" formControlName="ConfirmedPassword" type="password"
                                                                class="form-control" placeholder="Confirmar contraseña"
                                                                [ngClass]="{'error': submitted && formState?.ConfirmedPassword?.errors?.required}">
                                                                <label class="errorRed"
                                                                    *ngIf="submitted && formState?.ConfirmedPassword?.errors">
                                                                    Las contraseñas no coinciden.
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button type="submit"
                                                        class="btn btn-primary"
                                                        [disabled]="isLoading">
                                                        {{ isLoading ? 'Guardando' : 'Guardar'}}
                                                        <i class="fa fa-spinner fa-spin"
                                                            [hidden]="!isLoading"></i>
                                                    </button>
                                                </fieldset>
                                            </form>
                                        </div>
                                    </ng-template>
                                </ngb-tab>
                            </ngb-tabset>
                            <div class="text-center mt-4">
                                <a
                                    class=""
                                    [routerLink]="['/recuperar-contrasena']">¿Olvidaste tu contraseña?
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <small class="pad-left">¿Ya tienes una cuenta? <a [routerLink]="['/iniciar-sesion']">Inicia sesión</a></small>
                    </div>
                    <!-- <div class="row">
                        <small class="pad-left">¿Quieres hacer un reporte como invitado? <a [routerLink]="['/reporte']">Hazlo aquí</a></small>
                    </div> -->
                    <!-- <hr>
                    <small>Al registrarte estás aceptando nuestros
                        <a href="">Términos y Política de Privacidad</a>
                    </small> -->
                </div>
            </div>
        </div>
    </div>
</div>


<ng2-toasty></ng2-toasty>
