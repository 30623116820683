<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-body">
                    <div class="row product-adding">
                        <div class="col-xl-5">
                            <div class="add-product">
                                <div class="row">
                                    <div class="col-xl-3 xl-50 col-sm-6 col-9">
                                        <strong><label class="s" for="validationCustom01" class="">Imagen:</label></strong>
                                    </div>
                                    <div class="col-xl-3 xl-50 col-sm-6 col-3">
                                        <ul class="file-upload-product">
                                            <li *ngFor="let item of url; let i=index">
                                                <div class="box-input-file">
                                                    <input class="upload" type="file" (change)="readUrl($event,i)">
                                                    <img class="imgUpload" alt="" [src]="item.img"></div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-7">
                            <form class="needs-validation" [formGroup]="serviceForm" (ngSubmit)="onSubmit()" novalidate>
                                <div class="add-product-form">
                                    <div class="form-group row">
                                        <label for="validationCustom01" class="col-xl-3 col-sm-4 mb-0">Nombre:</label>
                                        <input class="form-control col-xl-8 col-sm-7" id="validationCustom01"
                                            type="text" [formControl]="serviceForm.controls['Title']" required>
                                        <div *ngIf="serviceForm.controls.Title.touched && serviceForm.controls.Title.errors?.required"
                                            class="text text-danger">
                                            Nombre es requerido.
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="validationCustom02" class="col-xl-3 col-sm-4">Descripción:</label>
                                        <div class="col-xl-8 col-sm-7 pl-0 description-sm">
                                            <textarea [formControl]="serviceForm.controls['Description']" name="Description" id="validationCustom02" cols="83%" rows="10" required></textarea>    
                                        </div>
                                    </div>
                                </div>
                                <hr>
                                <div class="row">
                                    <div class="col-xs-12 col-sm-12 col-lg-12 pb-15">
                                        
                                        <div class="row">
                                            <div class="col-xs-12 col-lg-6">
                                                <div class="card-header no-border pl-0">
                                                    <h5>Agregar Beneficios</h5>
                                                </div>
                                            </div>
                                            <div class="col-xs-12 col-lg-6 row centerinput">
                                                <div class="col-xs-12 col-lg-10">
                                                    <input class="form-control" id="validationCustom11" type="text" [(ngModel)]="valueFeature" placeholder="Valor" [ngModelOptions]="{standalone: true}">
                                                </div>
                                                <div class="col-xs-12 col-lg-2">
                                                    <button type="button" class="btn btn-primary mr-1" (click)="addFeature()">+</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="container" *ngIf="featuresProduct.length !== 0">
                                        <table class="table table-striped">
                                            <thead>
                                                <tr>
                                                <th>Propiedad</th>
                                                <th>Valor</th>
                                                <th>Eliminar</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let feature of featuresProduct">
                                                <td>{{feature.Property}}</td>
                                                <td>{{feature.Value}}</td>
                                                <td>
                                                    <span>
                                                        <a (click)="removeFeature(feature)"
                                                            title="Eliminar">
                                                            <i class="fa fa-trash"></i>
                                                        </a>
                                                    </span>
                                                </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <hr>
                                <div class="form-group row mb-0">
                                    <div class="product-buttons col-sm-8 offset-xl-4 offset-sm-4">
                                        <button class="btn btn-primary mright"
                                            [disabled]="isLoading" *ngIf="onEdit == false">
                                            {{ isLoading ? 'Creando servicio' : 'Agregar servicio'}}
                                            <i class="fa fa-spinner fa-spin"
                                            [hidden]="!isLoading"></i>
                                        </button>
                                        <button type="submit" class="btn btn-primary mright" *ngIf="onEdit == true">
                                            {{ isLoading ? 'Editando servicio' : 'Editar'}}
                                            <i class="fa fa-spinner fa-spin"
                                            [hidden]="!isLoading"></i>
                                        </button>
                                    </div>
                                </div>
                                <!-- <div class="pull-right">
                                    <button class="btn btn-primary"
                                        [disabled]="isLoading" *ngIf="onEdit == false">
                                        {{ isLoading ? 'Creando servicio' : 'Agregar'}}
                                        <i class="fa fa-spinner fa-spin"
                                        [hidden]="!isLoading"></i>
                                    </button>
                                    <button type="submit" class="btn btn-primary mr-1" *ngIf="onEdit == true">
                                        {{ isLoading ? 'Editando servicio' : 'Editar'}}
                                        <i class="fa fa-spinner fa-spin"
                                        [hidden]="!isLoading"></i>
                                    </button>
                                </div> -->
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->
<ng2-toasty></ng2-toasty>