import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject, Subscription, throwError } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { map, switchMap, catchError } from 'rxjs/operators';
import { SalesService } from "../../../services/sales/sales.service";


@Component({
  selector: 'app-quotation-detail',
  templateUrl: './quotation-detail.component.html',
  styleUrls: ['./quotation-detail.component.scss']
})
export class QuotationsDetailsComponent implements OnInit, OnDestroy {

  private subs: Array<Subscription> = [];
  public dtTrigger: Subject<any> = new Subject()

  public quotation: any;

  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private salesService: SalesService,
  ) {
  }


  ngOnInit() {
    this.subs.push(
        this.activatedRoute.paramMap.pipe(
          map(params => params.get('id')),
          switchMap(id => this.salesService.getQuotation(id)),
          catchError(error => {
            this.router.navigate(['/sales/quotations']);
            return throwError(error);
          })
        ).subscribe(quotation => {
          this.quotation = quotation;
          this.dtTrigger.next();
        })
      );
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }

  onSubmit() {
  }

}
