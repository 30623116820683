import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { Subscription, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SurveysService } from '../../services/surveys/surveys.service';


@Component({
  templateUrl: './studies.component.html',
  styleUrls: ['./studies.component.scss']
})
export class StudiesComponent implements OnInit, OnDestroy {

  public surveys = [];
  public isCollapsed = true;

  private subs: Array<Subscription> = [];
  private modalContent: any;
  private modalConfig: any = {
    ariaLabelledBy: 'modal-basic'
  };

  public dtOptions: DataTables.Settings = {};
  public dtTrigger: Subject<any> = new Subject();
  public submitted = false;

  public surveyId: number;
  public form: FormGroup;
  public page = 1;
  public limit = 10;
  public offset = 0;

  constructor(
    private fb: FormBuilder,
    private surveysService: SurveysService,
    private authService: AuthenticationService,
    private modalService: NgbModal
  ) {
  }

  ngOnInit() {
    this.subs.push(
      this.surveysService
        .getSurveys()
        .pipe(
          catchError(err => this.authService.handleUnauthorizedError(err))
        )
        .subscribe(data => {
          this.surveys = data;
        })
    );
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    this.subs.forEach(sub => sub.unsubscribe());
  }

  public setModalContent(content: any, surveyId: number): void {
    this.surveyId = surveyId;
    this.modalContent = content;
    this.modalService.open(this.modalContent, this.modalConfig);
  }

  public get formState() {
    return this.form.controls;
  }

  public loadPage(page: number) {
    this.subs.push(
      this.surveysService
        .getSurveys()
        .pipe(
          catchError(err => this.authService.handleUnauthorizedError(err))
        )
        .subscribe(data => {
          this.surveys = data;
        })
    );
  }

}
