import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription, throwError } from 'rxjs';
import { AuthModel, UserModel } from '../auth.models';
import { Router } from '@angular/router';
import { map, catchError } from 'rxjs/operators';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { SalesService } from "../../services/sales/sales.service";
import { ToastyService, ToastyConfig, ToastOptions } from 'ng2-toasty';
import { NotificationType } from '../../interfaces/notification.enums';
import { ClientRoles } from '../../services/auth/auth.models'
import { responseModel } from '../../sales/sales.models';


@Component({
  selector: 'app-invitation',
  templateUrl: './invitation.component.html',
  styleUrls: ['./invitation.component.scss']
})
export class InvitationComponent implements OnInit, OnDestroy{

  public loginForm: FormGroup;
  public responseForm2: FormGroup;
  public isLoading = false;
  public submitted = false;
  public submittedLogin = false;
  public step2 = false;
  public step3 = false;

  private subs: Array<Subscription> = [];
  public currentUser: AuthModel;
  public invitacion;
  public errorMessage;
  public messageResponse = '';

  constructor(
    public formBuilder: FormBuilder,
    // public formGroup: FormGroup,
    private router: Router,
    private authService: AuthenticationService,
    private toastyService: ToastyService,
    private toastyConfig: ToastyConfig,
    private salesService: SalesService,
  ) {
    this.toastyConfig.theme = 'bootstrap';
  }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required]], // , Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')
      // password: ['', Validators.required]
    });

    this.responseForm2 = this.formBuilder.group({
    //   // firstName: ['', [Validators.required]], // , Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')
      messageText: [''],
    //   // Pases: this.fb.array([])//['', Validators.required]
    });

    // this.myGroup = new FormGroup({
    //    firstName: new FormControl()
    // });
    // this.myGroup = new FormGroup({
    //    firstName: new FormControl()
    // });
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }

  public get formState() {
    return this.loginForm.controls
  }

  private loginUser(): UserModel {
    return {
        email: this.loginForm.controls['email'].value,
        // password: this.loginForm.controls['password'].value
    }
  }

  public onSubmit(): void {
    this.submittedLogin = true;

    if (this.loginForm.invalid) {
      console.log('invalid...')
      return;
    }

    const user: UserModel = this.loginUser();
    this.isLoading = true;
    this.subs.push(
      this.authService.login(user)
        .pipe(
          map(() => {
            this.authService.currentUser.subscribe((authenticatedUser: AuthModel) => this.currentUser = authenticatedUser);
            setTimeout(() => {
              if(this.currentUser.roles === 'superadmin' || this.currentUser.roles === 'admin' || this.currentUser.roles === 'supervisor'){
                this.router.navigateByUrl('/dashboard');
              }
              if(this.currentUser.roles === 'dealer'){
                this.router.navigateByUrl('/tickets/my-tickets');
              }
              if(this.currentUser.roles === 'user') {
                //this.router.navigateByUrl('/menus');
                this.router.navigateByUrl('/products/my-user-events');
              }
            },500)
          }),
          catchError(err => {
            this.isLoading = false;
            this.sendNotification('Error al acceder', err.error?.message, NotificationType.error);
            return throwError(err);
          })
        ).subscribe()
    );
  }

  public onSubmit2(): void {
    // console.log(this.loginForm.controls.email)
    const phoneNumber: string = this.loginForm.get('email').value;
    this.submittedLogin = true;
    // console.log('submit2')

    if (this.loginForm.invalid) {
      console.log('invalid...')
      return;
    }

    const user: UserModel = this.loginUser();
    this.isLoading = true;
    this.subs.push(
      this.salesService
          .getInvitation(phoneNumber)
          // .pipe(
          //   catchError(err => {
          //     console.log('Invitación no encontrada')
          //   }//this.authService.handleUnauthorizedError(err))
          // )
          .subscribe(
            data => {
              this.invitacion = data;
              // console.log(data)
              this.isLoading = false;
              // this.dtTrigger.next();
              this.step2 = true;
            },
            error  => {
              this.isLoading = false;
              this.errorMessage = 'Lo siento su número de teléfono no ha sido registrado favor de contactarse con los novios';
              console.log(error)
            }
          )
    );  
    // this.subs.push(
    //   this.authService.login(user)
    //     .pipe(
    //       map(() => {
    //         this.authService.currentUser.subscribe((authenticatedUser: AuthModel) => this.currentUser = authenticatedUser);
    //         setTimeout(() => {
    //           if(this.currentUser.roles === 'superadmin' || this.currentUser.roles === 'admin' || this.currentUser.roles === 'supervisor'){
    //             this.router.navigateByUrl('/dashboard');
    //           }
    //           if(this.currentUser.roles === 'dealer'){
    //             this.router.navigateByUrl('/tickets/my-tickets');
    //           }
    //           if(this.currentUser.roles === 'user') {
    //             //this.router.navigateByUrl('/menus');
    //             this.router.navigateByUrl('/products/my-user-events');
    //           }
    //         },500)
    //       }),
    //       catchError(err => {
    //         this.isLoading = false;
    //         this.sendNotification('Error al acceder', err.error?.message, NotificationType.error);
    //         return throwError(err);
    //       })
    //     ).subscribe()
    // );
  }

  public sendInvitationResponse(respuesta:string): void {
    // console.log(this.loginForm.controls.email)
    console.log('submit3: ' + respuesta)
    const phoneNumber: string = this.loginForm.get('email').value;
    const message: string = this.responseForm2.get('messageText').value;
    // const pases: Array = this.responseForm.get('Pases').value;
    // // this.submittedLogin = true;
    // console.log(this.responseForm)
    // console.log(this.myGroup);

    // // if (this.responseForm.invalid) {
    // //   console.log('invalid...')
    // //   return;
    // // }

    // console.log('enviar respuesta');
    const resp: responseModel = {
      Respuesta:respuesta,
      Message:message
      // Pases: pases
    };
    console.log(resp)
    this.subs.push(
      this.salesService
          .sendResponseInvitation(phoneNumber,resp)
          // .pipe(
          //   catchError(err => {
          //     console.log('Invitación no encontrada')
          //   }//this.authService.handleUnauthorizedError(err))
          // )
          .subscribe(
            data => {
              // this.invitacion = data;
              console.log(data)
              // this.isLoading = false;
              // this.dtTrigger.next();
              this.step2 = false;
              this.step3 = true;
              this.messageResponse = data.message;
            },
            error  => {
              this.isLoading = false;
              this.errorMessage = 'Lo siento su número de teléfono no ha sido registrado favor de contactarse con los novios';
              console.log(error)
            }
          )
    );  
  }

  private sendNotification(title: string = 'Atención Ciudadana', message: string, type: string): void {
    const toastOptions: ToastOptions = {
      title,
      msg: message,
      showClose: true,
      timeout: 3500,
      theme: 'bootstrap'
    };

    this.toastyService[type](toastOptions);
  }

}
