import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { SalesService } from "../../services/sales/sales.service";
import { AuthenticationService } from '../../services/auth/authentication.service';
import { Subscription, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import * as XLSX from 'xlsx';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GuideModel } from '../sales.models';

@Component({
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit, OnDestroy {
  public orders = [];
  public resumen = [];
  public temp = [];
  fileName= 'Pedidos.xlsx';

  private subs: Array<Subscription> = [];
  private modalContent: any;
  private modalConfig: any = {
    ariaLabelledBy: 'modal-basic'
  };

  public dtOptions: DataTables.Settings = {};
  public dtTrigger: Subject<any> = new Subject();
  public submitted = false;
  public guideIsSuccess = false;
  public orderCloseSuccess = false;
  public orderId: number;
  public form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private salesService: SalesService,
    private authService: AuthenticationService,
    private modalService: NgbModal
  ) {
  }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 100,
      language: {
        emptyTable: '',
        zeroRecords: 'No hay coincidencias',
        lengthMenu: 'Mostrar _MENU_ elementos',
        search: 'Buscar:',
        info: 'De _START_ a _END_ de _TOTAL_ elementos',
        infoEmpty: 'De 0 a 0 de 0 elementos',
        infoFiltered: '(filtrados de _MAX_ elementos totales)',
        paginate: {
          first: 'Prim.',
          last: 'Últ.',
          next: 'Sig.',
          previous: 'Ant.'
        },
      },
      order:[[0, 'desc']]
    };

    this.subs.push(
      this.salesService
        .getSales()
        .pipe(
          catchError(err => this.authService.handleUnauthorizedError(err))
        )
        .subscribe(data => {
          this.orders = data.invitaciones;
          this.resumen = data.resumen;
          this.dtTrigger.next();
        })
    );

    this.form = this.fb.group({
      TrackingNumber: ['', Validators.required],
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    this.subs.forEach(sub => sub.unsubscribe());
  }

  public setModalContent(content: any, orderId: number): void {
    this.orderId = orderId;
    this.modalContent = content;
    this.modalService.open(this.modalContent, this.modalConfig);
  }

  public setModalContentClose(contentClose: any, orderId: number): void {
    this.orderId = orderId;
    this.modalContent = contentClose;
    this.modalService.open(this.modalContent, this.modalConfig);
  }

  public get formState() {
    return this.form.controls;
  }

  private buildOrderGuide(): GuideModel {
    return {
      TrackingNumber: this.form.controls['TrackingNumber'].value
    }
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    const guide: GuideModel = this.buildOrderGuide();
    this.subs.push(
      this.salesService.addTrackingNumber(guide, this.orderId)
        .subscribe(resolve => {
          if (resolve.TrackingNumber !== null) {
            this.guideIsSuccess = true;
            setTimeout(() => {
              this.modalService.dismissAll();
              window.location.reload();
            }, 2000);
          }
        })
    );
  }

  public finishOrder() : void {
    this.subs.push(
      this.salesService.finishOrder(this.orderId)
        .subscribe(resolve => {
          if (resolve.SaleOrderStatus == 'Cerrado') {
            this.orderCloseSuccess = true;
            setTimeout(() => {
              this.modalService.dismissAll();
              window.location.reload();
            }, 1500);
          }
        })
    );
  }

  exportExcel(): void
  {
    let element = document.getElementById('excel-sales');
    const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);
 
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Pedidos');
  
    XLSX.writeFile(wb, this.fileName);
 
  }

}
