<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-body">
                    <div class="row product-adding">
                        <div class="col-xl-5">
                            <div class="add-product">
                                <div class="row">
                                    <div class="col-xl-9 xl-50 col-sm-6 col-9">
                                        <img src="assets/images/pro3/1.jpg" alt="" class="img-fluid image_zoom_1">
                                    </div>
                                    <div class="col-xl-3 xl-50 col-sm-6 col-3">
                                        <ul class="file-upload-product">
                                            <li *ngFor="let item of url; let i=index">
                                                <div class="box-input-file">
                                                    <input class="upload" type="file" (change)="readUrl($event,i)">
                                                    <img class="imgUpload" alt="" [src]="item.img"></div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-7">
                            <form class="needs-validation" [formGroup]="productForm" (ngSubmit)="onSubmit()" novalidate>
                                <div class="add-product-form">
                                    <div class="row">
                                        <div class="col-xs-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="validationCustom01" class="col-form-label pt-0">Nombre</label>
                                                <input class="form-control" id="validationCustom01" type="text" [formControl]="productForm.controls['Title']"
                                                [ngClass]="{'error': submitted && formState?.Title?.errors?.required}">
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="validationCustom02" class="col-form-label pt-0">Nº de parte</label>
                                                <input class="form-control" id="validationCustom02" type="text" [formControl]="productForm.controls['SKU']"
                                                [ngClass]="{'error': submitted && formState?.SKU?.errors?.required}">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-xs-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="validationCustom03" class="col-form-label pt-0">Precio</label>
                                                <input class="form-control" id="validationCustom03" type="number" [formControl]="productForm.controls['UnitPrice']"
                                                [ngClass]="{'error': submitted && formState?.UnitPrice?.errors?.required}">
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="validationCustom04" class="col-form-label pt-0">Moneda</label>
                                                <select class="form-control custom-select" id="validationCustom04" [formControl]="productForm.controls['CurrencyCode']"
                                                [ngClass]="{'error': submitted && formState?.CurrencyCode?.errors?.required}">
                                                    <option value="">Seleccciona moneda</option>
                                                    <option value="MXN">MXN</option>
                                                    <option value="USD">USD</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-xs-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="validationCustom05" class="col-form-label pt-0">Marca</label>
                                                <input class="form-control" id="validationCustom05" type="text" [formControl]="productForm.controls['Brand']"
                                                [ngClass]="{'error': submitted && formState?.Brand?.errors?.required}">
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="validationCustom06" class="col-form-label pt-0">Modelo</label>
                                                <input class="form-control" id="validationCustom06" type="text" [formControl]="productForm.controls['Model']"
                                                [ngClass]="{'error': submitted && formState?.Model?.errors?.required}">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-xs-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="validationCustom07" class="col-form-label pt-0">Categoría</label>
                                                <!-- <input class="form-control" id="validationCustom07" type="text" [formControl]="productForm.controls['Category']"
                                                [ngClass]="{'error': submitted && formState?.Category?.errors?.required}"> -->
                                                <ng-select name="Category" id="Category" [formControl]="productForm.controls['Category']" required [ngClass]="{'error': submitted && formState?.Category?.errors?.required}">
                                                    <ng-option *ngFor="let category of categories" [value]="category.ValueAlpha">
                                                        {{category.ValueAlpha}}
                                                    </ng-option>
                                                </ng-select>
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="validationCustom08" class="col-form-label pt-0">Línea</label>
                                                <!-- <input class="form-control" id="validationCustom08" type="text" [formControl]="productForm.controls['SubCategory']"
                                                [ngClass]="{'error': submitted && formState?.SubCategory?.errors?.required}"> -->
                                                <ng-select name="SubCategory" id="SubCategory" [formControl]="productForm.controls['SubCategory']" [ngClass]="{'error': submitted && formState?.SubCategory?.errors?.required}">
                                                    <ng-option *ngFor="let subcategory of subcategories" [value]="subcategory.ValueAlpha">
                                                        {{subcategory.ValueAlpha}}
                                                    </ng-option>
                                                </ng-select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-xs-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="validationCustom09" class="col-form-label pt-0">Cantidad de existencias</label>
                                                <input class="form-control" id="validationCustom09" type="number" [formControl]="productForm.controls['Quantity']"
                                                [ngClass]="{'error': submitted && formState?.Quantity?.errors?.required}">
                                                <!-- <br>
                                                <div class="qty-box1">
                                                    <div class="input-group">
                                                        <i class="fa fa-minus btnGtr1" (click)=decrement()></i>
                                                        <input class="touchspin1 text-center" name="counter" id="counter" [value]="counter"
                                                            type="number">
                                                        <i class="fa fa-plus btnLess1" (click)=increment()></i>
                                                    </div>
                                                </div> -->
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="validationCustom10" class="col-form-label pt-0">Descripción</label>
                                                <br>
                                                <textarea class="form-control" [formControl]="productForm.controls['Description']" id="validationCustom10" cols="40%" rows="10"
                                                [ngClass]="{'error': submitted && formState?.Description?.errors?.required}"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row mb-0">
                                    <div class="product-buttons col-sm-8 offset-xl-4 offset-sm-4">
                                        <button class="btn btn-primary mright"
                                            [disabled]="isLoading" *ngIf="onEdit == false">
                                            {{ isLoading ? 'Creando refacción' : 'Agregar refacción'}}
                                            <i class="fa fa-spinner fa-spin"
                                            [hidden]="!isLoading"></i>
                                        </button>
                                    </div>

                                    <div class="product-buttons col-sm-8 offset-xl-4 offset-sm-4">
                                        <button type="submit" class="btn btn-primary mright" *ngIf="onEdit == true">
                                            {{ isLoading ? 'Editando refacción' : 'Editar refacción'}}
                                            <i class="fa fa-spinner fa-spin"
                                            [hidden]="!isLoading"></i>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->

<ng2-toasty></ng2-toasty>

<!-- <div class="container-fluid">
    <div class="row product-adding">
        <div class="col-xl-6">
            <div class="card">
                <div class="card-header">
                    <h5>General</h5>
                </div>
                <div class="card-body">
                    <div class="digital-add needs-validation">
                        <div class="form-group">
                            <label for="validationCustom01" class="col-form-label pt-0"><span>*</span> Title</label>
                            <input class="form-control" id="validationCustom01" type="text" required="">
                        </div>
                        <div class="form-group">
                            <label for="validationCustomtitle" class="col-form-label pt-0"><span>*</span> SKU</label>
                            <input class="form-control" id="validationCustomtitle" type="text" required="">
                        </div>
                        <div class="form-group">
                            <label class="col-form-label"><span>*</span> Categories</label>
                            <select class="custom-select" required="">
                                <option value="">--Select--</option>
                                <option value="1">eBooks</option>
                                <option value="2">Graphic Design</option>
                                <option value="3">3D Impact</option>
                                <option value="4">Application</option>
                                <option value="5">Websites</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label class="col-form-label">Sort Summary</label>
                            <textarea rows="5" cols="12"></textarea>
                        </div>
                        <div class="form-group">
                            <label for="validationCustom02" class="col-form-label"><span>*</span> Product Price</label>
                            <input class="form-control" id="validationCustom02" type="text" required="">
                        </div>
                        <div class="form-group">
                            <label class="col-form-label"><span>*</span> Status</label>
                            <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                                <label class="d-block" for="edo-ani">
                                    <input class="radio_animated" id="edo-ani" type="radio" name="rdo-ani">
                                    Enable
                                </label>
                                <label class="d-block" for="edo-ani1">
                                    <input class="radio_animated" id="edo-ani1" type="radio" name="rdo-ani">
                                    Disable
                                </label>
                            </div>
                        </div>
                        <label class="col-form-label pt-0"> Product Upload</label>
                        <div class="card-body dropzone-custom p-0">
                            <div class="dropzone dropzone-primary" [dropzone]="config1" (error)="onUploadError($event)"
                                (success)="onUploadSuccess($event)">
                                <div class="dz-message needsclick">
                                    <i class="fa fa-cloud-upload"></i>
                                    <h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-6">
            <div class="card">
                <div class="card-header">
                    <h5>Add Description</h5>
                </div>
                <div class="card-body">
                    <div class="digital-add needs-validation">
                        <div class="form-group mb-0">
                            <div class="description-sm">
                                <ck-editor id="editor1" language="en" [fullPage]="false"></ck-editor>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header">
                    <h5>Meta Data</h5>
                </div>
                <div class="card-body">
                    <div class="digital-add needs-validation">
                        <div class="form-group">
                            <label for="validationCustom05" class="col-form-label pt-0"> Meta Title</label>
                            <input class="form-control" id="validationCustom05" type="text" required="">
                        </div>
                        <div class="form-group">
                            <label class="col-form-label">Meta Description</label>
                            <textarea rows="4" cols="12"></textarea>
                        </div>
                        <div class="form-group mb-0">
                            <div class="product-buttons text-center">
                                <button type="button" class="btn btn-primary mr-1">Add</button>
                                <button type="button" class="btn btn-light">Discard</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
