import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { Subscription, Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastyService } from 'ng2-toasty';
import { TicketsService } from '../../services/tickets/tickets.service';


@Component({
  selector: 'app-tickets-by-user',
  templateUrl: './tickets-by-user.component.html',
  styleUrls: ['./tickets-by-user.component.scss']
})
export class ListTicketsByUserComponent implements OnInit, OnDestroy {
  
    public tickets = []
  private subs: Array<Subscription> = [];
  private modalContent: any;
  private modalConfig: any = {
    ariaLabelledBy: 'modal-basic'
  };
  public isLoading = false;
  public dtOptions: DataTables.Settings = {};
  public dtTrigger: Subject<any> = new Subject();

  public userId: string;
  public total: number;
  public page = 1;
  public limit: number = 10;
  public offset: number = 0;


  constructor(
    private authService: AuthenticationService,
    private ticketsService: TicketsService,
    private toastyService: ToastyService,
    private modalService: NgbModal
  ) {  }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      language: {
        emptyTable: '',
        zeroRecords: 'No hay coincidencias',
        lengthMenu: 'Mostrar _MENU_ elementos',
        search: 'Buscar:',
        info: 'De _START_ a _END_ de _TOTAL_ elementos',
        infoEmpty: 'De 0 a 0 de 0 elementos',
        infoFiltered: '(filtrados de _MAX_ elementos totales)',
        paginate: {
          first: 'Prim.',
          last: 'Últ.',
          next: 'Sig.',
          previous: 'Ant.'
        },
      },
    };

    this.subs.push(
      this.ticketsService
        .getTicketsByUser(this.authService.currentUserValue.id, this.limit, this.offset)
        .pipe(
          catchError(err => this.authService.handleUnauthorizedError(err))
        )
        .subscribe(data => {
          this.tickets = data.List;
          this.total = data.Count
          this.dtTrigger.next();
        }),
    );
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    this.subs.forEach(sub => sub.unsubscribe());
  }


  public loadPage(page: number) {
    this.subs.push(
      this.ticketsService
        .getTicketsByUser(this.authService.currentUserValue.id, this.limit, page-1)
        .pipe(
          catchError(err => this.authService.handleUnauthorizedError(err))
        )
        .subscribe(data => {
          this.tickets = data.List;
          this.total = data.Count;
        })
    );
  }
  
}

