import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { UsersService } from '../../services/users/users.service';
import { Subscription, Subject, throwError } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReportsService } from '../../services/reports/reports.service';
import { ToastyService, ToastyConfig, ToastOptions } from 'ng2-toasty';


@Component({
  selector: 'app-list-interviewers',
  templateUrl: './list-interviewers.component.html',
  styleUrls: ['./list-interviewers.component.scss']
})
export class ListInterviewerComponent implements OnInit, OnDestroy {
  public dealers_list = []
  private subs: Array<Subscription> = [];
  private modalContent: any;
  private modalConfig: any = {
    ariaLabelledBy: 'modal-basic'
  };

  public dtOptions: DataTables.Settings = {};
  public dtTrigger: Subject<any> = new Subject();
  public deleteIsSuccess = false;
  public userId: string;
  public total: number;
  public page = 1;
  public limit: number = 10;
  public offset: number = 0;
  public textSearch = '';
  public editIsSuccess = false;
  public isLoading = false;


  dtElement: any;
  area = '';
  profile: any;
  departments: any;
  catalogs = [];


  constructor(
    private usersService: UsersService,
    private authService: AuthenticationService,
    private reportsService: ReportsService,
    private userService: UsersService,
    private toastyService: ToastyService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      destroy: true,
      language: {
        emptyTable: '',
        zeroRecords: '',
        lengthMenu: 'Mostrar _MENU_ elementos',
        search: 'Buscar:',
        info: 'De _START_ a _END_ de _TOTAL_ elementos',
        infoEmpty: 'De 0 a 0 de 0 elementos',
        infoFiltered: '(filtrados de _MAX_ elementos totales)',
        paginate: {
          first: 'Prim.',
          last: 'Últ.',
          next: 'Sig.',
          previous: 'Ant.'
        },
      },
    };

    this.subs.push(
      this.usersService
        .getDealers(this.limit, this.offset)
        .pipe(
          catchError(err => this.authService.handleUnauthorizedError(err))
        )
        .subscribe(data => {
          this.dealers_list = data.List;
          this.total = data.Total;
          this.dtTrigger.next();
        }),
    );
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    this.subs.forEach(sub => sub.unsubscribe());
  }

  setModalContent(content: any, userId: string): void {
    this.userId = userId;
    this.modalContent = content;
    this.modalService.open(this.modalContent, this.modalConfig);
  }

  setModalDeleteContent(content: any, userId: string): void {
    this.userId = userId;
    this.deleteIsSuccess = false;
    this.modalContent = content;
    this.modalService.open(this.modalContent, this.modalConfig);
  }

  public loadPage(page: number) {
    this.subs.push(
      this.usersService
        .getDealers(this.limit, page - 1)
        .pipe(
          catchError(err => this.authService.handleUnauthorizedError(err))
        )
        .subscribe(data => {
          this.dealers_list = data.List;
          this.total = data.Total;
        })
    );
  }


  updateRol() {

    let data = {
      roles: this.profile,
      Area: this.area
    }
    this.subs.push(
      this.userService.updateRole(this.userId, data)
        .pipe(
          map(() => {
            this.handleUpdateRol();
            setTimeout(() => {
              window.location.reload();
            }, 1500);
          }),
        )
        .subscribe()
    );
  }

  public deleteUser() {
    this.subs.push(
      this.usersService.deleteUser(this.userId)
        .subscribe(resolve => {
          if (resolve.active === false) {
            this.handleDeleteUser();
            setTimeout(() => {
              this.modalService.dismissAll();
              window.location.reload();
            }, 1500);
          }
        })
    );
  }

  private handleUpdateRol() {
    this.isLoading = false;
    const toastOptions: ToastOptions = {
      title: 'Atención ciudadana',
      msg: 'Rol editado correctamente',
      showClose: true,
      timeout: 2500
    };

    this.toastyService.success(toastOptions);
  }

  private handleDeleteUser() {
    this.isLoading = false;
    const toastOptions: ToastOptions = {
      title: 'Atención ciudadana',
      msg: 'Usuario eliminado correctamente',
      showClose: true,
      timeout: 2500
    };

    this.toastyService.success(toastOptions);
  }
}

