<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="card">
      <div class="card-body">
        <div id="batchDelete" class="">
          <div class="table-responsive">
            <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
              class=" table table-lg table-hover row-border hover">
              <thead>
                <tr>
                    <th>Folio</th>
                    <th>Fecha y hora de activación</th>
                    <th>Usuario de activación</th>
                    <th>Tipo</th>
                    <th>Costo</th>
                    <th>Estatus</th>
                    <!-- <th>Acciones</th> -->
                  <!-- <th>Acciones</th> -->
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let ticket of tickets; let i = index">
                  <td>{{ticket?.folio}}</td>
                  <td>{{ticket.created_at | date: 'dd/MM/yyyy, h:mm a'}}</td>
                  <td>{{ticket.Dealer.displayName}}</td>
                  <td>{{ticket.Type}}</td>
                  <td>{{ticket.Cost | currency}}</td>
                  <td>
                    <span [ngClass]="{'ticket-active': ticket.Status === 'Activo', 'ticket-inactive': ticket.Status === 'Inactivo', 'ticket-apply': ticket.Status === 'Aplicado'}">{{ticket.Status}}</span>
                  </td>
                  <!-- <td>
                    <span [routerLink]="['show', ticket.id]" title="Editar usuario"
                      class="pointer">
                      <i class="fa fa-eye"></i></span>
                  </td> -->
                </tr>
              </tbody>
            </table>
            <ngb-pagination (pageChange)="loadPage($event)" [collectionSize]="total" [(page)]="page" [maxSize]="10"
              [rotate]="true" [boundaryLinks]="true"></ngb-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Container-fluid Ends-->
  
<ng2-toasty></ng2-toasty>