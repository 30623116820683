<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-body">
                    <div class="row product-adding">
                        <div class="col-xl-5">
                            <div class="add-product">
                                <div class="row">
                                    <div class="col-xl-9 xl-50 col-sm-6 col-9">
                                        <img src="assets/images/pro3/1.jpg" alt="" class="img-fluid image_zoom_1">
                                    </div>
                                    <div class="col-xl-3 xl-50 col-sm-6 col-3">
                                        <ul class="file-upload-product">
                                            <li *ngFor="let item of url; let i=index">
                                                <div class="box-input-file">
                                                    <input class="upload" type="file" (change)="readUrl($event,i)">
                                                    <img class="imgUpload" alt="" [src]="item.img"></div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-7">
                            <form class="needs-validation" [formGroup]="productForm" (ngSubmit)="onSubmit()" novalidate>
                                <div class="add-product-form">
                                    <div class="row">
                                        <div class="col-xs-12 col-sm-4">
                                            <div class="form-group">
                                                <label for="validationCustom01" class="col-form-label pt-0">Nombre</label>
                                                <input class="form-control" id="validationCustom01" type="text" [formControl]="productForm.controls['Title']"
                                                [ngClass]="{'error': submitted && formState?.Title?.errors?.required}">
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-4">
                                            <div class="form-group">
                                                <label for="validationCustom02" class="col-form-label pt-0">Precio</label>
                                                <input class="form-control" id="validationCustom02" type="number" [formControl]="productForm.controls['UnitPrice']"
                                                [ngClass]="{'error': submitted && formState?.UnitPrice?.errors?.required}">
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-4">
                                            <div class="form-group">
                                                <label for="validationCustom03" class="col-form-label pt-0">Moneda</label>
                                                <select class="form-control custom-select" id="validationCustom03" [formControl]="productForm.controls['CurrencyCode']"
                                                [ngClass]="{'error': submitted && formState?.CurrencyCode?.errors?.required}">
                                                    <option value="">Seleccciona moneda</option>
                                                    <option value="MXN">MXN</option>
                                                    <option value="USD">USD</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-xs-12 col-sm-4">
                                            <div class="form-group">
                                                <label for="validationCustom04" class="col-form-label pt-0">Marca</label>
                                                <input class="form-control" id="validationCustom05" type="text" [formControl]="productForm.controls['Brand']"
                                                [ngClass]="{'error': submitted && formState?.Brand?.errors?.required}">
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-4">
                                            <div class="form-group">
                                                <label for="validationCustom05" class="col-form-label pt-0">Modelo</label>
                                                <input class="form-control" id="validationCustom06" type="text" [formControl]="productForm.controls['Model']"
                                                [ngClass]="{'error': submitted && formState?.Model?.errors?.required}">
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-4">
                                            <div class="form-group">
                                                <label for="validationCustom06" class="col-form-label pt-0">Cantidad de existencias</label>
                                                <input class="form-control" id="validationCustom09" type="number" [formControl]="productForm.controls['Quantity']"
                                                [ngClass]="{'error': submitted && formState?.Quantity?.errors?.required}">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-xs-12 col-sm-12">
                                            <div class="form-group">
                                                <label for="validationCustom07" class="col-form-label pt-0">Descripción</label>
                                                <br>
                                                <textarea class="form-control" [formControl]="productForm.controls['Description']" id="validationCustom07" cols="40%" rows="10"
                                                [ngClass]="{'error': submitted && formState?.Description?.errors?.required}"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row mb-0">
                                    <div class="product-buttons col-sm-8 offset-xl-4 offset-sm-4">
                                        <button class="btn btn-primary mright"
                                            [disabled]="isLoading" *ngIf="onEdit == false">
                                            {{ isLoading ? 'Creando souvenir' : 'Agregar souvenir'}}
                                            <i class="fa fa-spinner fa-spin"
                                            [hidden]="!isLoading"></i>
                                        </button>
                                    </div>

                                    <div class="product-buttons col-sm-8 offset-xl-4 offset-sm-4">
                                        <button type="submit" class="btn btn-primary mright" *ngIf="onEdit == true">
                                            {{ isLoading ? 'Editando souvenir' : 'Editar souvenir'}}
                                            <i class="fa fa-spinner fa-spin"
                                            [hidden]="!isLoading"></i>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->

<ng2-toasty></ng2-toasty>
