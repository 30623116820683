import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataTablesModule } from 'angular-datatables';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';

import { Ng2SmartTableModule } from 'ng2-smart-table';
import { UsersRoutingModule } from './users-routing.module';
import { ListUserComponent } from './list-user/list-user.component';
import { CreateUserComponent } from './create-user/create-user.component';
import { UserDetailsComponent } from './user-detail/user-detail.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastyModule } from 'ng2-toasty';
import { ListAdminComponent } from './list-admin/list-admin.component';
import { AgmCoreModule } from '@agm/core';
import { ListInterviewerComponent } from './list-interviewers/list-interviewers.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { UserCertificatesComponent } from './certificates-user/certificates-user.component';
import { FilesAdminComponent } from './files-admin/files-admin.component';
import { EditUserComponent } from './edit-user/edit-user.component';
import { environment } from 'src/environments/environment';
import { ListSupervisorsComponent } from './list-supervisors/list-supervisors.component';
import { ListNeighborhoodComponent } from './list-neighborhood/list-neighborhood.component';

const API: string = environment.API_KEY;

@NgModule({
  declarations: [
    ListUserComponent,
    ListNeighborhoodComponent,
    ListSupervisorsComponent,
    CreateUserComponent,
    UserDetailsComponent,
    ListAdminComponent,
    ListInterviewerComponent,
    UserProfileComponent,
    UserCertificatesComponent,
    FilesAdminComponent,
    EditUserComponent],
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    DataTablesModule,
    Ng2SmartTableModule,
    ReactiveFormsModule,
    UsersRoutingModule,
    ToastyModule,
    NgSelectModule,
    Ng2SearchPipeModule,
    AgmCoreModule.forRoot({
      apiKey: `${API}`,
      libraries: ["geometry", "places"],
      apiVersion: 'quarterly'
    }),
  ]
})
export class UsersModule { }
