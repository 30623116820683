<div class="container-fluid">
    <!-- <div class="form-group row inputfilter">
        <label for="exampleFormControlSelect1" class="col-xl-4 col-sm-4 mb-0 textfilter">Filtrar Máquinas por Familia:</label>
        <ng-select class="col-xl-8 col-sm-8 no-padd" name="Family" id="Family" [(ngModel)]="selectedFamily" (change)="getProducts()">
          <ng-option *ngFor="let family of families" [value]="family.ValueAlpha">
              {{family.ValueAlpha}}
          </ng-option>
      </ng-select>
    </div>
    <br> -->

    <p>Total de eventos: <strong>{{totalProducts}}</strong></p>
    
    <div *ngIf="totalProducts > 0" class="row products-admin ratio_asos">
        <div class="col-xl-4 col-sm-6 col-xs-12" *ngFor="let product of product_list">
            <div class="card">
                <div class="card-body product-box">
                    <div class="img-wrapper">
                        <div class="lable-block"><span class="lable3" *ngIf="product.tag == 'new'">new</span> <span
                                class="lable4" *ngIf="product.sale == 'on sale'">on sale</span></div>
                        <div class="front">
                            <a href="javascript:void(0)"><img [src]="[product.ImgFileUrl]"
                                    class="img-fluid blur-up lazyload bg-img" alt=""></a>
                        </div>
                    </div>
                    <hr>
                    <div class="product-detail">
                        <!-- <div class="rating"><i class="fa fa-star"></i> <i class="fa fa-star"></i> <i
                                class="fa fa-star"></i> <i class="fa fa-star"></i> <i class="fa fa-star"></i></div> -->
                        <a href="javascript:void(0)">
                            <h5>{{product.EventTitle}}</h5>
                        </a>
                        <h4>{{product.UnitPrice | currency}} {{product.CurrencyCode}}</h4>
                        <br>
                        <p>{{(product.EventDescription.length>100)? (product.EventDescription | slice:0:100)+'..':(product.EventDescription)}}</p>

                        <h6>Fecha: {{product.EventDate | date:'dd/MM/yyyy'}}</h6>
                        <br>
                        <span [ngClass]="{'ticket-active': !product.CancelDate, 'ticket-inactive': product.CancelDate}">{{product.CancelDate ? 'Evento cancelado' : 'Evento activo'}}</span>

                        <div class="col-xs-12 text-center mt-3">
                          <button *ngIf="rol !== 'admin'" (click)="addTickets(product.id)" class="btn btn-primary" type="button" data-original-title="">Agregar boletos</button>
                        </div>
                        <div *ngIf="rol === 'admin'" class="col-xs-12 fright">
                          <p>
                          <button type="button" class="btn btn-primary mr-1 mat-btn" (click)="product.isCollapsed = !product.isCollapsed"
                                  [attr.aria-expanded]="product.isCollapsed">
                                  <i class="fa fa-ellipsis-h" aria-hidden="true"></i>
                          </button>
                          </p>
                          <div [ngbCollapse]="!product.isCollapsed">
                              <div class="product-hover">
                                  <ul class="">
                                      <li>
                                          <button [routerLink]="['show', product.id]" class="btn bt-action" type="button" data-original-title="">Ver evento</button>
                                      </li>
                                      <li>
                                          <button [routerLink]="['edit', product.id]" class="btn bt-action" type="button" data-original-title="">Editar evento</button>
                                      </li>
                                      <li>
                                        <button (click)="addTickets(product.id)" class="btn bt-action" type="button" data-original-title="">Agregar boletos</button>
                                      </li>
                                      <li *ngIf="!product.CancelDate">
                                        <button (click)="setModalContent(content, product.id)" class="btn bt-action" type="button" data-original-title="">Cancelar evento</button>
                                      </li>
                                  </ul>
                              </div>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="totalProducts == 0" class="card text-center">
        <div class="card-body">
            <img class="image-notfound" src="../assets/images/no-found.jpeg" alt="Not found">
            <p class="nofound">Aún no has cargado un evento.</p>
        </div>
      </div>
</div>

<ng-template #content
  let-modal>
  <div class="modal-header center">
    <h4 *ngIf="!productDeleteSuccess" class="modal-title text-center width-100"
      id="modal-title">Cancelar Evento</h4>

    <h4 *ngIf="productDeleteSuccess" class="modal-title text-center width-100"
      id="modal-title"><strong>Evento cancelado</strong></h4>
  </div>
  <div class="modal-body text-center">
    <ng-container *ngIf="!productDeleteSuccess">
      <p class="no-mrg">Se cancelará el evento</p>
      <p class="no-mrg">Esta acción no se puede deshacer.</p>
    </ng-container>

    <ng-container *ngIf="productDeleteSuccess">
      <p>Evento cancelado exitosamente</p>
    </ng-container>
  </div>
  <ng-container *ngIf="!productDeleteSuccess">
    <div class="modal-footer right">
      <a type="button"
        class="btn btn-outline-light"
        (click)="modal.close()">Cerrar</a>
      <button type="button"
        class="btn btn-inverse btn-danger"
        (click)="deleteProduct()">Cancelar</button>
    </div>
  </ng-container>
</ng-template>