import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import {DatePipe } from '@angular/common'
import { EventModel, FeatureProductModel, ProductModel, SpecificationProductModel } from '../../product.models';
import { ToastyService, ToastyConfig, ToastOptions } from 'ng2-toasty';
import { ProductsService } from 'src/app/components/services/products/products.service';
import { map, catchError } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { Location } from '@angular-material-extensions/google-maps-autocomplete';
import PlaceResult = google.maps.places.PlaceResult;
import * as moment from 'moment';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';

@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.scss']
})
export class AddProductComponent implements OnInit {

  @ViewChild("card") card: HTMLElement;

  public event : any = {
    EventTitle: '',
    EventDescription: '',
    EventDate: '',
    EventTime: '',
    BannerUrl: '',
    UserId: '',
    SiteMap: '',
    AddressFormatted: '',
    Address: {
      Street: '',
      ExtNumber: '',
      IntNumber: '',
      Neighborhood: '',
      ZipCode: '',
      State: '',
      City: '',
      Location: {
          lat: 0,
          lng: 0
      }
    },
    Zones: []
  };

  public zone: any = {
    name: '',
    price: 0,
    quantity: 0
  }

  public productForm: FormGroup;
  public formfeature: FormGroup;
  public isLoading = false;
  public submitted = false;
  public onEdit: boolean;
  public counter: number = 1;
  public productId: number;
  public id: number;
  public features: any;
  public specifications: any;
  public families: any;
  public categories: any;
  public subcategories: any;

  public step = 1;

  public featuresProduct: Array<FeatureProductModel> = [];
  public specificationsProduct: Array<SpecificationProductModel> = [];
  public selectedFeature: string;
  public valueFeature: string;
  public GroupSpecification: string;
  public selectedSpecification: string;
  public valueSpecification: string;
  public results: any;

  public requiredFileType: string;
  public ImgfileName = '';
  public responseImgFile: string = '';
  public previewImg: any;
  public viewLoaderImg = false;
  public ImgFileUrl: any;
  public completeAddress: string;
  public showMap: boolean = false;
  public showMessage: boolean = false;
  public latitude: number;
  public longitude: number;
  public zoom: number;

  /*Dirección*/
  public ExtNumber: number;
  public Street: string;
  public IntNumber: string;
  public Neighborhood: string;
  public State: string;
  public City: string;
  public ZipCode: number;

  public url = [{
    img: "assets/images/photo.png",
  }
  ]
  private subs: Array<Subscription> = [];
  zoneSubmitted: boolean = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private toastyService: ToastyService,
    private toastyConfig: ToastyConfig,
    private productService: ProductsService,
    private authService: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private sanitizer: DomSanitizer,
  ) {
      this.toastyConfig.theme = 'bootstrap';
  }

  ngOnInit() {
    this.subs.push(
      this.activatedRoute.paramMap.subscribe((data: any) => {
        if (data.params.id) {
          this.productId = data.params.id;
          this.onEdit = true;
          this.productService.getEvent(data.params.id).subscribe(
            product => this.event = product//this.setValues(product)
          );
          this.productForm = this.fb.group({
            EventTitle: ['', [Validators.required]],
            EventDate: ['', [Validators.required]],
            EventDescription: ['', Validators.required],
            Address: ['', Validators.required]
            // ImgFileUrl: ['', Validators.required],
          })
        }else {
          this.onEdit = false;
          this.event.UserId = this.authService.currentUserValue.id;
            this.productForm = this.fb.group({
              EventTitle: ['', [Validators.required]],
              EventDate: ['', [Validators.required]],
              EventDescription: ['', Validators.required],
              Address: ['', Validators.required]
            })
        }
      })
    );
    this.zoom = 16;
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }

  onImgSelected(event) {
    const file: File = event.target.files[0];
    if (file.type == 'image/png' || file.type == 'image/jpeg') {
      this.viewLoaderImg = true;
      setTimeout(() => {
        if (file) {
          const formData = new FormData();
          formData.append('profileImg', file);
          this.subs.push(
            this.productService.uploadImg(formData)
              .subscribe(resolve => {
                if (resolve.FileUrl !== null) {
                  this.responseImgFile = resolve.ImageUrl;
                  this.event.BannerUrl = resolve.ImageUrl;
                  this.ImgFileUrl = '';
                  this.viewLoaderImg = false;
                  this.viewBase64(file).then(image => {
                    this.previewImg = image;
                  })
                  this.ImgfileName = file.name;
                }
              })
          );
        }
      }, 1800);
    } else {
      this.NoTypeImg()
    }
  }

  onMapSelected(event) {
    const file: File = event.target.files[0];
    if (file.type == 'image/png' || file.type == 'image/jpeg') {
      this.viewLoaderImg = true;
      setTimeout(() => {
        if (file) {
          const formData = new FormData();
          formData.append('profileImg', file);
          this.subs.push(
            this.productService.uploadImg(formData)
              .subscribe(resolve => {
                if (resolve.FileUrl !== null) {
                  this.responseImgFile = resolve.ImageUrl;
                  this.event.SiteMap = resolve.ImageUrl;
                  this.ImgFileUrl = '';
                  this.viewLoaderImg = false;
                  this.viewBase64(file).then(image => {
                    this.previewImg = image;
                  })
                  this.ImgfileName = file.name;
                }
              })
          );
        }
      }, 1800);
    } else {
      this.NoTypeImg()
    }
  }

  viewBase64 = async ($event: any) => new Promise((resolve, reject) => {
    try {
      const unsafeImg = window.URL.createObjectURL($event);
      const image = this.sanitizer.bypassSecurityTrustUrl(unsafeImg);
      const reader = new FileReader();
      reader.readAsDataURL($event);
      reader.onload = () => {
        resolve({
          base: reader.result
        });
      };
      reader.onerror = error => {
        resolve({
          base: null
        });
      };
    } catch (e) {
      return null;
    }
  })

  readUrl(event: any, i) {
    if (event.target.files.length === 0)
      return;

    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.url[i].img = reader.result.toString();
    }
  }

  onAutocompleteSelected(result: PlaceResult) {
    this.showMessage = false;
      let address = result.address_components;
        if(address.length < 6){
          this.handleAddressFinal();
          return;
        }
        if(address.length == 8){
          this.event.Address.ExtNumber = Number(address[0].short_name),
          this.event.Address.Street = address[1].short_name,
          this.event.Address.IntNumber = address[2].short_name,
          this.event.Address.Neighborhood = address[3].short_name,
          this.event.Address.State = address[4].short_name,
          this.event.Address.City = address[5].short_name
          this.event.Address.ZipCode = Number(address[7].short_name)
        }else {
          this.event.Address.ExtNumber = Number(address[0].short_name),
          this.event.Address.Street = address[1].short_name,
          this.event.Address.IntNumber = '',
          this.event.Address.Neighborhood = address[2].short_name,
          this.event.Address.State = address[3].short_name,
          this.event.Address.City = address[4].short_name,
          this.event.Address.ZipCode = Number(address[5].short_name) || Number(address[6].short_name)
        }
    this.completeAddress = result.formatted_address;
    this.showMap = true;
  }
  
  onLocationSelected(location: Location) {
      this.latitude = location.latitude;
      this.longitude = location.longitude;
      this.event.Address.latitude = location.latitude;
      this.event.Address.longitude = location.longitude;
  }

  markerDragEnd($event: any) {
    const latitude = $event.latLng.lat();
    const longitude = $event.latLng.lng();
    this.getAddress(latitude, longitude);
  }
  
  addZone(){
    this.zoneSubmitted = true;
    if( this.zone.name != '' && this.zone.price != 0 && this.zone.quantity != 0 ){
      this.zoneSubmitted = false;
      this.event.Zones.push(this.zone);
      this.zone = {
        name: '',
        price: 0,
        quantity: 0
      }
    }
  }

  validateStep1(){
    this.submitted = true;
    const keysList = ['EventTitle', 'EventDate', 'EventDescription', 'AddressFormatted'];
    let flag = false;
    for (let i = 0; i < keysList.length; i++){
      if( !this.event[keysList[i]] ){
        flag = true;
        break;
      }
    }

    if(!flag){
      const card = document.querySelector("#card") as HTMLElement;
      const cardBack = document.querySelector("#card-back") as HTMLElement;
      const cardFront = document.querySelector("#card-front") as HTMLElement;
      cardBack.style.display = "block";
      card.classList.toggle("is-flipped");
      cardFront.style.display = "none";
      this.step++;
    }

  }

  beforeStep(){
    const card = document.querySelector("#card") as HTMLElement;
    const cardBack = document.querySelector("#card-back") as HTMLElement;
    cardBack.style.display = "none";
    const cardFront = document.querySelector("#card-front") as HTMLElement;
    cardFront.style.display = "block";
    card.classList.toggle("is-flipped");
    this.step--;
  }

  getAddress(latitude, longitude) {
    const latlng = new google.maps.LatLng(latitude, longitude);
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({'location': latlng  },  (results, status) =>{
        if (status !== google.maps.GeocoderStatus.OK) {
          alert(status);
      }
      // This is checking to see if the Geoeode Status is OK before proceeding
      if (status == google.maps.GeocoderStatus.OK) {
        this.completeAddress = (results[0].formatted_address);
        let address = results[0].address_components;
        this.productForm.controls['Address'].setValue(this.completeAddress);
        if(address.length < 6){
          this.handleAddressFinal();
          return;
        }
        if(address.length == 8){
          this.ExtNumber = Number(address[0].short_name),
          this.Street = address[1].short_name,
          this.IntNumber = '',
          this.Neighborhood = address[3].short_name,
          this.State = address[4].short_name,
          this.City = address[5].short_name
          this.ZipCode = Number(address[7].short_name)
        }else {
          this.ExtNumber = Number(address[0].short_name),
          this.Street = address[1].short_name,
          this.IntNumber = '',
          this.Neighborhood = address[2].short_name,
          this.State = address[3].short_name,
          this.City = address[4].short_name,
          this.ZipCode = Number(address[5].short_name) || Number(address[6].short_name)
        }
      }
    });
  }
  
  addFeature(){
    if (this.selectedFeature == null || this.valueFeature == null){
      alert('Debes llenar todos los campos para agregar una característica.')
    }
    else{
      this.featuresProduct.push({CatalogName: 'Feature', Property: this.selectedFeature, Value: this.valueFeature});
      this.selectedFeature = "";
      this.valueFeature = "";
      this.results = [ ...this.featuresProduct, ...this.specificationsProduct];
    }
  }

  removeFeature(feature) {
    const index: number = this.featuresProduct.indexOf(feature);
    this.featuresProduct.splice(index, 1);
    this.results = [ ...this.featuresProduct, ...this.specificationsProduct];
  }

  addSpecification(){
    if (this.GroupSpecification == null || this.selectedSpecification == null || this.valueSpecification == null){
      alert('Debes llenar todos los campos para agregar una especificación.')
    }else{
      this.specificationsProduct.push({CatalogName: 'Specification', Group: this.GroupSpecification, Property: this.selectedSpecification, Value: this.valueSpecification});
      this.GroupSpecification = "";
      this.selectedSpecification = "";
      this.valueSpecification = "";
      this.results = [ ...this.featuresProduct, ...this.specificationsProduct];
    }
  }

  removeSpecification(specification) {
    const index: number = this.specificationsProduct.indexOf(specification);
    this.specificationsProduct.splice(index, 1);
    this.results = [ ...this.featuresProduct, ...this.specificationsProduct];
  }

  public get formState() {
    return this.productForm.controls
  }

  private setValues(product: any): void {
    product.EventDate = moment(product.EventDate).format("YYYY-MM-DD");

    const values = this.productService.mapRequiredValues(product);
    this.id = values.id;
    const { controls } = this.productForm;

    for (const value in values) {
      if (controls.hasOwnProperty(value)) {
        this.productForm.controls[value].setValue(values[value]);
      }
    }
  }

  private buildProduct(): EventModel {
    if (this.authService.currentUserValue.roles === 'user') {
      return {
        EventTitle: this.productForm.controls['EventTitle'].value,
        EventDescription: this.productForm.controls['EventDescription'].value,
        EventDate: this.productForm.controls['EventDate'].value,
        BannerUrl: this.responseImgFile,
        UserId: this.authService.currentUserValue.id,
        Address: {
          Street: this.Street,
          ExtNumber: this.ExtNumber,
          IntNumber: this.IntNumber,
          Neighborhood: this.Neighborhood,
          ZipCode: this.ZipCode,
          State: this.State,
          City: this.City,
          Location: {
              lat: this.latitude,
              lng: this.longitude
          }
        }
      }
    }
    if (this.authService.currentUserValue.roles !== 'user') {
      return {
        EventTitle: this.productForm.controls['EventTitle'].value,
        EventDescription: this.productForm.controls['EventDescription'].value,
        EventDate: this.productForm.controls['EventDate'].value,
        BannerUrl: this.responseImgFile,
        UserId: null,
        Address: {
          Street: this.Street,
          ExtNumber: this.ExtNumber,
          IntNumber: this.IntNumber,
          Neighborhood: this.Neighborhood,
          ZipCode: this.ZipCode,
          State: this.State,
          City: this.City,
          Location: {
              lat: this.latitude,
              lng: this.longitude
          }
        }
      }
    } 
    
  }

  private buildProductUpdate(): EventModel {
    if (this.authService.currentUserValue.roles === 'user') {
      return {
        id: this.id,
        EventTitle: this.productForm.controls['EventTitle'].value,
        EventDescription: this.productForm.controls['EventDescription'].value,
        EventDate: this.productForm.controls['EventDate'].value,
        BannerUrl: this.responseImgFile,
        UserId: this.authService.currentUserValue.id,
        Address: {
          Street: this.Street,
          ExtNumber: this.ExtNumber,
          IntNumber: this.IntNumber,
          Neighborhood: this.Neighborhood,
          ZipCode: this.ZipCode,
          State: this.State,
          City: this.City,
          Location: {
              lat: this.latitude,
              lng: this.longitude
          }
        }
      }
    }
    if (this.authService.currentUserValue.roles !== 'user') {
      return {
        id: this.id,
        EventTitle: this.productForm.controls['EventTitle'].value,
        EventDescription: this.productForm.controls['eventDescription'].value,
        EventDate: this.productForm.controls['EventDate'].value,
        BannerUrl: this.responseImgFile,
        UserId: null,
        Address: {
          Street: this.Street,
          ExtNumber: this.ExtNumber,
          IntNumber: this.IntNumber,
          Neighborhood: this.Neighborhood,
          ZipCode: this.ZipCode,
          State: this.State,
          City: this.City,
          Location: {
              lat: this.latitude,
              lng: this.longitude
          }
        }
      }
    } 
    
  }

  onSubmit() {
    this.submitted = true;

    if (this.event.Zones.length === 0) {
        this.handleZonesError({});
        return;
    }

    if (this.onEdit) {
      this.updateProduct();
    } else {
      this.createProduct();
    } 
  }

  createProduct() {

    this.isLoading = true;

    this.subs.push(
        this.productService.createEvent(this.event)
        .pipe(
        map(() => {
            this.handleProductCreation();
            setTimeout(() => {
              if (this.authService.currentUserValue.roles !== 'user') {
                this.router.navigateByUrl('products/product-list');
              }
              if (this.authService.currentUserValue.roles === 'user') {
                this.router.navigateByUrl('products/my-user-events');
              }
            }, 1800);
        }),
        catchError(error => this.handleError(error))
        )
        .subscribe()
    );
  }

  updateProduct() {
    this.isLoading = true;
    this.subs.push(
        this.productService.updateEvent(this.productId, this.event)
        .pipe(
        map(() => {
            this.handleProductEdit();
            setTimeout(() => {
            this.router.navigateByUrl('products/product-list');
            }, 1800);
        }),
        catchError(error => this.handleErrorEdit(error))
        )
        .subscribe()
    ); 
  }

  private handleZonesError(error: any) {
    const toastOptions: ToastOptions = {
      title: 'Error',
      msg: 'Debes agregar al menos una zona a este evento',
      showClose: true,
      timeout: 2000
    };

    this.toastyService.error(toastOptions);

    return [];
  }

  private handleError(error: any) {
    const toastOptions: ToastOptions = {
      title: 'Error',
      msg: 'No se pudo crear el evento',
      showClose: true,
      timeout: 2000
    };

    this.toastyService.error(toastOptions);

    return [];
  }

  private handleErrorEdit(error: any) {
    const toastOptions: ToastOptions = {
      title: 'Error',
      msg: 'No se pudo actualizar el evento',
      showClose: true,
      timeout: 2000
    };

    this.toastyService.error(toastOptions);

    return [];
  }

  private handleProductCreation() {
    const toastOptions: ToastOptions = {
      title: 'Evento',
      msg: 'Evento creado correctamente',
      showClose: true,
      timeout: 1700
    };

    this.toastyService.success(toastOptions);
  }

  private handleProductEdit() {
    const toastOptions: ToastOptions = {
      title: 'Evento',
      msg: 'Evento actualizado correctamente',
      showClose: true,
      timeout: 1700
    };

    this.toastyService.success(toastOptions);
  }

  private NoTypeImg() {
    const toastOptions: ToastOptions = {
      title: 'Error',
      msg: 'Debes subir una imagen de tipo png o jpg',
      showClose: true,
      timeout: 1800
    };

    this.toastyService.error(toastOptions);
  }

  private handleAddressFinal() {
    const toastOptions: ToastOptions = {
      title: 'Error',
      msg: 'Por favor agrega la dirección correctamente',
      showClose: true,
      timeout: 2000
    };

    this.toastyService.error(toastOptions);
    return [];
  }

}
