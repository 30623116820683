<div class="container-fluid">
    <div class="card">
        <div class="row product-page-main card-body">
            <div class="col-xl-4">
                <section>
                    <img *ngIf="product?.ImgFileUrl" [src]="[product?.ImgFileUrl]" class="img-fluid blur-up lazyload bg-img" alt="">
                    <img *ngIf="!product?.ImgFileUrl" src="../assets/images/no-found.jpeg" class="img-fluid blur-up lazyload bg-img" alt="No image found">
                    <p *ngIf="!product?.ImgFileUrl" class="center">No se ha encontrado la imagen</p>
                </section>
            </div>
            <div class="col-xl-8">
                <div class="product-page-details product-right mb-0">
                    <h2>{{product?.EventTitle}}</h2>
                    <p>Fecha: {{product?.EventDate | date:'dd/MM/yyyy'}}</p>
                    <hr>
                    <h6 class="product-title">Descripción</h6>
                    <p class="mb-0">{{product?.EventDescription}}</p>
                </div>
            </div>
        </div>
        <div class="row product-page-main card-body ml-3 mr-3">
        <h6 class="product-title">Historial de boletos vendidos (<strong>{{product?.Tickets.length}}</strong>)</h6>
            <table datatable [dtOptions]="dtOptions" class="table table-lg table-hover row-border hover pl-3 pr-3">
                <thead>
                    <tr>
                        <th>Nombre</th>
                        <th>Correo electrónico</th>
                        <th>Teléfono</th>
                        <th>Tipo</th>
                        <th>Costo</th>
                        <th>Estatus</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let ticket of product?.Tickets">
                        <td>{{ticket.ClientName}}</td>
                        <td>{{ticket.ClientEmail}}</td>
                        <td>{{ticket.ClientPhoneNumber}}</td>
                        <td>{{ticket.Type}}</td>
                        <td>{{ticket.Cost | currency}}</td>
                        <td>
                            <span [ngClass]="{'ticket-active': ticket.Status === 'Activo', 'ticket-inactive': ticket.Status === 'Inactivo', 'ticket-apply': ticket.Status === 'Aplicado'}">{{ticket.Status}}</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
