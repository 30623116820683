import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { Subscription, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SurveysService } from '../../services/surveys/surveys.service';
import { ToastOptions, ToastyConfig, ToastyService } from 'ng2-toasty';
import { ClientRoles } from '../../services/auth/auth.models';
import { Router } from '@angular/router';


@Component({
  templateUrl: './occupation-results.component.html',
  styleUrls: ['./occupation-results.component.scss']
})
export class OccupationResultsComponent implements OnInit, OnDestroy {

  public history: any;
  public countHistory: number = 0;

  private subs: Array<Subscription> = [];

  public dtOptions: DataTables.Settings = {};
  public dtTrigger: Subject<any> = new Subject();

  public surveyId: number;
  public form: FormGroup;
  public page = 1;
  public limit = 10;
  public offset = 0;
  public UserId: string;
  public municipalities: any;
  public selectedMunicipality: string = 'Seleccione un municipio';

  public ForeignGuestAvg: number | string;
  public GuestNightAvg: number | string;
  public LocalGuestAvg: number | string;
  public RoomPriceAvg: number | string;

  public isAdmin: boolean;
	public isBusiness: boolean;

  constructor(
    private surveysService: SurveysService,
    private authService: AuthenticationService,
    private toastyService: ToastyService,
    private toastyConfig: ToastyConfig,
  ) {
    this.toastyConfig.theme = 'bootstrap';
    this.isAdmin = this.authService.currentUserValue.roles.includes(ClientRoles.Admin);
   	this.isBusiness = this.authService.currentUserValue.roles.includes(ClientRoles.Business);
  }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      language: {
        emptyTable: '',
        zeroRecords: 'No hay coincidencias',
        lengthMenu: 'Mostrar _MENU_ elementos',
        search: 'Buscar:',
        info: 'De _START_ a _END_ de _TOTAL_ elementos',
        infoEmpty: 'De 0 a 0 de 0 elementos',
        infoFiltered: '(filtrados de _MAX_ elementos totales)',
        paginate: {
          first: 'Prim.',
          last: 'Últ.',
          next: 'Sig.',
          previous: 'Ant.'
        },
      },
      order:[[0, 'desc']]
    };

    this.subs.push(
      this.surveysService
        .getMunicipalities()
        .pipe(
          catchError(err => this.authService.handleUnauthorizedError(err))
        )
        .subscribe(data => {
          this.municipalities = data;
        })
    );
  }

  viewData(){
    this.subs.push(
      this.surveysService
        .getHistoryMunicipality(this.selectedMunicipality)
        .pipe(
          catchError(err => this.authService.handleUnauthorizedError(err))
        )
        .subscribe(data => {
          this.history = data;
          this.countHistory = data.Items.length;
          if(this.countHistory === 0){
            this.NoData();
          }else if(this.countHistory > 0){
            this.ForeignGuestAvg = Number(this.history.Summary.ForeignGuestAvg).toFixed();
            this.GuestNightAvg = Number(this.history.Summary.GuestNightAvg).toFixed();
            this.LocalGuestAvg = Number(this.history.Summary.LocalGuestAvg).toFixed();
            this.RoomPriceAvg = Number(this.history.Summary.RoomPriceAvg).toFixed();
            this.dtTrigger.next();
          }else{
            return;
          }
          
        })
    );
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    this.subs.forEach(sub => sub.unsubscribe());
  }

  private NoData() {
    const toastOptions: ToastOptions = {
      title: 'Consulta',
      msg: 'El municipio seleccionado no cuenta con información para mostrar.',
      showClose: true,
      timeout: 3000
    };

    this.toastyService.info(toastOptions);
  }

  // public loadPage(page: number) {
  //   this.subs.push(
  //     this.surveysService
  //       .getHistoryHotel()
  //       .pipe(
  //         catchError(err => this.authService.handleUnauthorizedError(err))
  //       )
  //       .subscribe(data => {
  //         this.surveys = data;
  //       })
  //   );
  // }

}
