import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Subscription } from 'rxjs';
import { TripsService } from '../../services/trips/trips.service';

@Component({
  selector: 'app-view-trip',
  templateUrl: './view-trip.component.html',
  styleUrls: ['./view-trip.component.scss']
})

export class ViewTripComponent implements OnInit {

  textSearch: any;
  public trip: any;
  public tripId: any;
  sInterval: any
  public zoom: number = 16;
  private subs: Array<Subscription> = [];
  private modalContent: any;
  private modalConfig: any = {
    ariaLabelledBy: 'modal-basic'
  };
  public dtOptions: DataTables.Settings = {};
  public dtTrigger: Subject<any> = new Subject();

  destination = {
    lat: 19.172997596510534,
    lng: -96.12930345963551
  }

  constructor(
    private tripsService: TripsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal
  ) { }


  ngOnInit(): void {
    this.activatedRoute.params.subscribe(param => {
      this.tripId = param.tripId;
      this.getLocation();
      if (this.trip?.Status !== 'Cerrado') {
        this.sInterval = setInterval(() => {
          this.getLocation();
        }, 15000)
      }
    })
  }

  getLocation() {
    this.subs.push(
      this.tripsService.getLiveTrip(this.tripId).subscribe(data => {
        // console.log(data)
        this.trip = data;
      })
    )
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
    clearInterval(this.sInterval);
  }

}