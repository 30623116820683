import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Subscription } from 'rxjs';
import { TripsService } from '../../services/trips/trips.service';

@Component({
  selector: 'app-trip-detail',
  templateUrl: './trip-detail.component.html',
  styleUrls: ['./trip-detail.component.scss']
})
export class TripDetailComponent implements OnInit {

  textSearch:any;
  public trip:any;
  public zoom: number = 16;
  private subs: Array<Subscription> = [];
  private modalContent: any;
  private modalConfig: any = {
    ariaLabelledBy: 'modal-basic'
  };
  public dtOptions: DataTables.Settings = {};
  public dtTrigger: Subject<any> = new Subject();

  destination = {
    lat: 19.172997596510534, 
    lng: -96.12930345963551
  }

  constructor(
    private tripsService: TripsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(param => {
        this.subs.push(
          this.tripsService.getTripbyId(param.id).subscribe(data => {
            this.trip = data;
          })
        )
    })


  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
    
  }




}
