<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row align-items-center">
        <div class="col-sm-12 align-self-center">
            <div class="card">
                <div class="card-body text-center py-5">
                    <!-- <div>
                        <a class="btn btn-primary" style="padding: 11px 47px;" [routerLink]="['/reports/my-user-reports']">Mis Reportes</a>
                    </div> -->
                    <div class="my-4">
                        <a class="btn btn-primary" style="padding: 11px 48px;" [routerLink]="['/reports/my-user-reports/create-report']">Agregar
                            Reporte</a>
                        <!-- <button class="btn btn-primary">Agregar Reporte</button> -->
                    </div>
                    <div>
                        <!-- <a class="btn btn-primary" href="">Viaje</a> -->
                        <button class="btn btn-primary" style="padding: 11px 71px;" (click)="setModalContent(content)">Iniciar Viaje</button>
                    </div>
                    <div class="mt-4">
                        <a class="btn btn-primary"  style="padding: 11px 10px;" [routerLink]="['/settings/profile/', currentUser?.id]">Editar Ángel de la Guarda</a>
                    </div>
                </div>
                <!-- <div class="card-header">
                    <h5>Menu Lists</h5>
                </div>
                <div class="card-body">
                    <div class="custom-datatable">
                        <button class="btn btn-primary mr-1 mb-3" style="float: right;" >Delete</button>
                        <div class="clearfix"></div>
                        <ngx-datatable class="bootstrap" [rows]="menus" [columnMode]="'force'" [headerHeight]="50"
                            [footerHeight]="50" [rowHeight]="'auto'" [limit]="5" [selected]="selected"
                            [selectionType]="'checkbox'" [selectAllRowsOnPage]="false" (select)='onSelect($event)'>
                            <ngx-datatable-column [width]="30" [sortable]="false" [canAutoResize]="false"
                                [draggable]="false" [resizeable]="false" [headerCheckboxable]="true"
                                [checkboxable]="true">
                                <button type="button" class="btn btn-primery">Delete</button>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Name"></ngx-datatable-column>
                            <ngx-datatable-column name="Status"></ngx-datatable-column>
                            <ngx-datatable-column prop="created on"></ngx-datatable-column>
                        </ngx-datatable>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</div>

<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title text-center width-100 mb-3" id="modal-title"><strong>Viaje</strong></h4>
        <div class="text-center">
            <img src="../../../../assets/images/wavepeople.gif" width="45%">
        </div>
    </div>
    <div class="modal-body text-center">
        <div class="card">
            <p>!Hola¡ antes de comenzar tu viaje, por favor ingresa el <strong>número económico</strong> del taxi.</p>
            <input type="text" [(ngModel)]="economicNumber" id="" class="form-control" placeholder="Número económico">
        </div>
    </div>
    <ng-container>
        <div class="align-self-center my-3">
            <!-- <a type="button"
        class="btn btn-outline-light"
        (click)="modal.close()">Cancelar</a> -->
            <button type="button" class="btn btn-inverse btn-primary" (click)="sendMessage()">Iniciar
                Viaje</button>

        </div>
    </ng-container>
</ng-template>

<ng-template #angel let-modal>
    <div class="modal-header">
        <h4 class="modal-title text-center width-100 mb-3" id="modal-title"><strong>Ángel de la Guarda</strong></h4>
        <div class="text-center">
            <img src="../../../../assets/images/shield.gif" width="40%">
        </div>
    </div>
    <div class="modal-body text-center">
        <div class="card">
            <p>Tu Ángel de la Guarda 😇 es tu 
            compañero de viaje, le notificaremos
            en tiempo real de tu ubicación 
            cuando estés viajando en un taxi, por 
            favor ingresa su <strong>número telefónico y nombre.</strong></p>
            <input type="text" [(ngModel)]="ContactName" id="" class="form-control mb-3" placeholder="Nombre(s)">
            <input type="text" [(ngModel)]="ContactPhoneNumber" id="" class="form-control" placeholder="Número telefónico">
        </div>
    </div>
    <ng-container>
        <div class="align-self-center my-3">
            <!-- <a type="button"
        class="btn btn-outline-light"
        (click)="modal.close()">Cancelar</a> -->
            <button type="button" class="btn btn-inverse btn-primary" (click)="saveAngel()">Guardar</button>

        </div>
    </ng-container>
</ng-template>

<ng-template #angelsaved let-modal>
    <div class="modal-header">
        <div class="text-center">
            <img src="../../../../assets/images/check.png" width="15%">
        </div>
        <h4 class="modal-title text-center width-100 mb-3" id="modal-title"><strong>Ángel</strong></h4>
    </div>
    <div class="modal-body text-center">
        <div class="card">
            <p>Tu Ángel de la Guarda 😇 ha
                sido guardado, puedes 
                cambiarlo en tu perfil.
            </p>
        </div>
    </div>
    <ng-container>
        <div class="align-self-center my-3">
            <!-- <a type="button"
        class="btn btn-outline-light"
        (click)="modal.close()">Cancelar</a> -->
            <button type="button" class="btn btn-inverse btn-primary" (click)="closeModals()">Aceptar</button>

        </div>
    </ng-container>
</ng-template>