<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="card">
        <div class="card-body">
            <div id="batchDelete" class="">
                <div class="table-responsive">
                    <table datatable
                    [dtOptions]="dtOptions"
                    [dtTrigger]="dtTrigger"
                    class=" table table-lg table-hover row-border hover">
                    <thead>
                        <tr>
                        <th>Id</th>
                        <th>Cuestionario</th>
                        <th>Establecimiento</th>
                        <th>Fecha de creación</th>
                        <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let quiz of quizs">
                        <td>{{quiz.id}}</td>
                        <td>{{quiz.Questionnaire}}</td>
                        <td>{{quiz.LocalName}}</td>
                        <td>{{quiz.created | date:'dd/MM/yyyy'}}</td>
                        <td class="actions-cell">
                            <span>
                            <a class="butn-link" [routerLink]="['show', quiz.id]">
                                Ver
                            </a>
                            </span>
                            <span>
                            <a class="butn-link" [routerLink]="['survey', quiz.id]">
                                Respuestas
                            </a>
                            </span>
                        </td>
                        </tr>
                    </tbody>
                    </table>
                    <ngb-pagination (pageChange)="loadPage($event)" [collectionSize]="10" [(page)]="page" [maxSize]="5" [rotate]="true" [boundaryLinks]="true"></ngb-pagination>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->