<div class="container-fluid">
    <div class="row">
        <div class="col-xl-4">
            <div class="card">
                <div class="card-body">
                    <div class="profile-details text-center">
                        <img src="assets/images/dashboard/designer.jpg" alt=""
                            class="img-fluid img-90 rounded-circle blur-up lazyloaded">
                        <h5 class="f-w-600 mb-0">{{client?.displayName}}</h5>
                        <span>{{client?.email}}</span>
                    </div>
                    <hr>
                </div>
            </div>
        </div>
        <div class="col-xl-8">
            <div class="card tab2-card">
                <div class="card-body">
                    <div class="tab-pane fade show active" id="top-profile" role="tabpanel"
                        aria-labelledby="top-profile-tab">
                        <h5 class="f-w-600">Perfil</h5>
                        <div class="table-responsive profile-table">
                            <table class="table table-responsive">
                                <tbody>
                                    <tr>
                                        <td>Nombre completo:</td>
                                        <td>{{client?.displayName}}</td>
                                    </tr>
                                    <tr>
                                        <td>Correo electrónico:</td>
                                        <td>{{client?.email}}</td>
                                    </tr>
                                    <tr>
                                        <td>Teléfono:</td>
                                        <td>{{client?.phoneNumber}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->
  